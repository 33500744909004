import React from 'react';
// Aquí asumo que ya tienes las lecciones importadas o definidas en otro archivo
import {lessons} from './routes'; // Asegúrate de ajustar la ruta según tu estructura de archivos

export const routes = lessons.map((lesson, index) => {
    return {
        path: lesson.link,
        next: lessons[index + 1] ? lessons[index + 1].link : null,
        prev: lessons[index - 1] ? lessons[index - 1].link : null,
    };
});

const RoutesConfig = () => {
    return null; // Este componente no renderiza nada; solo exporta las rutas
};

export default RoutesConfig;
