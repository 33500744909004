import React, { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import './orderWidget.css'

// Componente Button con estilo uniforme
const ButtonStatus = ({ type }) => {
  return (
    <button style={{
      backgroundColor: "#C89D38",
      color: 'white',
      fontSize: '14px',
      margin: '0 auto',
      display: 'block',
      borderRadius: '20px',
    }}>
      {type}
    </button>
  );
}


// Asegúrate de reemplazar 'contractAddress' por la dirección de tu contrato y 'contractABI' por la ABI de tu contrato
const contractAddress = require(`../../contractsData/${'DataStorage'}-address.json`).address;
const contractABI = require(`../../contractsData/${'DataStorage'}.json`);

export default function OrderWidget() {
  const Button = ({ type }) => {
    return <button className={'orderButton ' + type}>{type}</button>
  }

  const [purchaseDetails, setPurchaseDetails] = useState([]);
  const [userAddress, setUserAddress] = useState('');
  const [totalCompras, setTotalCompras] = useState(0);
  const [packageDetails, setPackageDetails] = useState([]); // Almacena detalles de cada paquete
  const [totalPackages, setTotalPackages] = useState(0); // Almacena el total de paquetes



  async function loadPurchaseDetails(address) {
    if (!window.ethereum) {
      console.log('Ethereum object not found, install MetaMask.');
      return;
    }

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(contractAddress, contractABI, provider);

    try {
      const [packageIds, timestamps, uses] = await contract.getUserPurchases(address);
      let totalUses = 0; // Inicializa la suma de usos
      const mappedDetails = packageIds.map((packageId, index) => {
        totalUses += parseInt(uses[index]);// Suma los usos
        return {
          packageId: packageId.toString(),
          count: uses[index].toString(),
          lastPurchaseTimestamp: new Date(timestamps[index] * 1000).toLocaleString(),
        };
      });

      setPurchaseDetails(mappedDetails);
      setTotalCompras(totalUses);
      // setTotalCompras no es necesario si solo quieres mostrar las compras, pero puedes sumar 'uses' si es relevante
    } catch (error) {
      console.error("Error al cargar los detalles de las compras:", error);
    }
  }


  // Cargar los totales de compras 
  async function loadUserPackageCounts(address) {
    if (!window.ethereum) {
      console.log('Ethereum object not found, install MetaMask.');
      return;
    }

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(contractAddress, contractABI, provider);

    try {
      // Asume que tu contrato tiene una función `getUserPackageCounts` que devuelve dos arrays
      const [packageIds, counts] = await contract.getUserPackageCounts(address);
      let totalPackages = 0; // Inicializa el contador total de paquetes
      const packageDetails = packageIds.map((packageId, index) => {
        totalPackages += parseInt(counts[index]); // Suma los conteos de cada paquete
        return {
          packageId: packageId.toString(),
          count: counts[index].toString(),
        };
      });

      setTotalPackages(totalPackages)
      setPackageDetails(packageDetails)
      // Aquí puedes establecer el estado con los detalles de los paquetes y el total
      // Por ejemplo: setPackageDetails(packageDetails);
      // Y también actualizar cualquier otro estado relevante, como el total de paquetes
      // Por ejemplo: setTotalPackages(totalPackages);
    } catch (error) {
      console.error("Error al cargar los conteos de los paquetes:", error);
    }
  }



  useEffect(() => {
    const init = async () => {
      if (window.ethereum) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        try {
          const accounts = await provider.send("eth_requestAccounts", []);
          setUserAddress(accounts[0]);
          loadPurchaseDetails(accounts[0]);
          loadUserPackageCounts(accounts[0]);
        } catch (error) {
          console.error("Error obtaining user address:", error);
        }

        const handleAccountsChanged = (accounts) => {
          if (accounts.length > 0) {
            setUserAddress(accounts[0]);
            loadPurchaseDetails(accounts[0]);
            loadUserPackageCounts(accounts[0]);
          } else {
            // Manejar desconexion de MetaMask, por ejemplo, limpiando el estado
            setPurchaseDetails([]);
            setUserAddress('');
            setTotalPackages('')
          }
        };

        window.ethereum.on('accountsChanged', handleAccountsChanged);

        return () => window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
      }
    };

    init();
  }, []);


  return (
    <div className='orderContainer-wid'>
    <div className='cardsContainer-wid'>
      <div className='card-wid header-wid'>
        <div className='cardDetail-wid'><strong>Kit</strong></div>
        <div className='cardDetail-wid'><strong>Fecha/Hora de compra</strong></div>
        <div className='cardDetail-wid'><strong>Kits de regalo</strong></div>
        <div className='cardDetail-wid'><strong>Estado</strong></div>
      </div>
      {purchaseDetails.map((detail, index) => (
        <div key={index} className={`card-wid ${index % 2 === 0 ? 'even-wid' : 'odd-wid'}`}>
          <div className='cardDetail-wid'>{detail.packageId}</div>
          <div className='cardDetail-wid'>{detail.lastPurchaseTimestamp}</div>
          <div className='cardDetail-wid'>{detail.count}</div>
          <div className='cardDetail-wid'><span>APROVADO</span></div>
        </div>
      ))}
    </div>
  </div>
  


  );
}
