import { useEffect, useState } from "react"
import BackToTop from '../elements/BackToTop'
import Breadcrumb from './Breadcrumb'
import PageHead from './PageHead'
import Footer1 from './footer/Footer1'
import Footer2 from './footer/Footer2'

export default function Layout({ footerStyle, headTitle, breadcrumbTitle, children, pageCls }) {
    const [scroll, setScroll] = useState(0)

    useEffect(() => {
        const WOW = require('wowjs')
        window.wow = new WOW.WOW({
            live: false
        })
        window.wow.init()

        document.addEventListener("scroll", () => {
            const onScroll = () => {
                const scrollCheck = window.scrollY > 100;
                if (scrollCheck !== scroll) {
                    setScroll(scrollCheck);
                }
            };

            document.addEventListener("scroll", onScroll);

            // Limpieza
            return () => document.removeEventListener("scroll", onScroll);
        }, [scroll]); // Asegúrate de incluir todas las dependencias aquí

        // Agregamos el margen superior de 19vh al cuerpo de la página
        document.querySelector("body").style.marginTop = "2vh";
    }, [])

    return (
        <>
            <PageHead headTitle={headTitle} />
            <div id="wrapper">
                <div id="page" className={`pt-40 ${pageCls ? pageCls : ""}`}>
                    {breadcrumbTitle && <Breadcrumb breadcrumbTitle={breadcrumbTitle} />}
                    {children}
                    <footer style={{ position: "sticky", bottom: 0, left: '10px', top: '100%', width: "100%", padding: "20px" }}>
                        {!footerStyle && <Footer1 />}
                        {footerStyle === 1 ? <Footer1 /> : null}
                        {footerStyle === 2 ? <Footer2 /> : null}
                    </footer>
                </div>
            </div>
            <BackToTop />
        </>
    )
}
