import React, { useState, useEffect } from 'react';
import { YELLOW, WHITE, TURQUOISE } from '../../components';
import Web3 from 'web3';
import { useTranslation } from 'react-i18next';
import Layout from "../../components/layout/Layout"
import axios from 'axios';
import './RegisterForm.css'

// Ajusta el contenedor padre para incluir un relleno superior
const ParentContainer = {
  position: 'relative',
  paddingTop: '5%', /* Ajusta este valor según la altura de tu navbar */


}


const containerStyles = {
  padding: '30px 30px',
  display: 'grid', // Cambiado a 'grid'
  gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
  gridAutoRows: '400px',
  gridGap: '80px',
  alignItems: 'stretch',
  marginTop: '5%',
  marginBottom: '10%'
};

const boxStyles = {
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  height: '100%',
  padding: '20px',
  boxSizing: 'border-box',
  textAlign: 'center',
  background: TURQUOISE,
  borderTopLeftRadius: '50px',
  borderTopRightRadius: '50px',
  borderBottomLeftRadius: '150px',
  borderBottomRightRadius: '150px',
  borderWidth: '3px',
  borderStyle: 'solid',
  borderColor: YELLOW,
  boxShadow: '0 0 0 6px #0A4F5F, 0 0 0 5px #D4AE42, 0 0 0 20px #0A4F5F, 0 10px 40px rgba(0, 0, 0, 1)',
  transition: '0.5s',
};

const titleStyles = {
  marginTop: '20px',
  fontSize: '60px',
  color: YELLOW,
};

const h2Styles = {
  color: WHITE,
  margin: '0 0 0',
  padding: '0',
  alignItems: 'center',
};

const priceStyles = {
  fontSize: '70px',
  color: YELLOW,
  margin: '10px 0',
  padding: '0',
  fontSize:'bold',
  marginTop:'40px'
};

const ulStyles = {
  margin: '20px 0',
  padding: '0',
  listStyle: 'none',
};

const liStyles = {
  color: WHITE,
  padding: '10px 0',
  borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
};




export const ReferalLessRegistration = () => {


  const [web3, setWeb3] = useState(null);
  const [account, setAccount] = useState(null);
  const [isPressed, setPressed] = useState(false);
  const [ethPriceInUSD, setEthPriceInUSD] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  const url = "https://min-api.cryptocompare.com/data/pricemulti?fsyms=BTC,ETH,LTC&tsyms=USD,EUR,GBP";
  const { t } = useTranslation();

  const btnStyles = {
    display: 'inline-block',
    background: isPressed ? 'black' : '#D4AE42', // Cambia el color de fondo al presionar
    color: isPressed ? 'BLACK' : 'WHITE', // Cambia el color del texto al presionar
    border: isPressed ? '20px solid whites' : '4px solid white', // Cambia el contorno al presionar
    fontWeight: 'bold',
    padding: '10px 30px',
    marginTop: '20px',
    textDecoration: 'none',
    borderRadius: '20px',
    cursor: 'pointer',
  };

  // Asumiendo que estos son tus precios fijos en USD para cada paquete:
  const usdPrices = {
    basic: 390,
    advanced: 1950, // Asumir para el ejemplo, ajusta según tus precios
    university: 3900,
    master: 5800,
    premium: 25000,
  };




  // Inicialización de web3 y obtención de la cuenta del usuario
  // eslint-disable-next-line no-undef

  useEffect(() => {
    // Check if user is on a mobile device
    const userAgent = window.navigator.userAgent;
    setIsMobile(/Mobi/.test(userAgent));

    initializeMetaMask();

    // Función para actualizar los precios
    const updatePrices = () => {
      axios.get(url)
        .then(response => {
          // Asume que la respuesta contiene la tasa de cambio de ETH a USD
          const ethPrice = response.data.ETH.USD;
          setEthPriceInUSD(ethPrice);
        })
        .catch(error => {
          console.error("Error fetching data:", error);
        });
    };

    updatePrices(); // Actualiza inmediatamente al cargar
    const timer = setInterval(updatePrices, 60000); // Actualiza cada minuto

    return () => clearInterval(timer); // Limpia el intervalo cuando el componente se desmonta
  }, []);



  const initializeMetaMask = () => {
    if (window.ethereum) {
      const web3Instance = new Web3(window.ethereum);
      setWeb3(web3Instance);

      window.ethereum.request({ method: 'eth_requestAccounts' })
        .then(accounts => {
          setAccount(accounts[0]);
        })
        .catch(error => {
          console.error('Error requesting accounts:', error);
        });
    } else {
      console.warn('MetaMask is not available.');
      setWeb3(null); // Establecer web3 en null si MetaMask no está disponible
      setAccount(null); // Establecer la cuenta en null si MetaMask no está disponible
    }
  };









  const handleRegister = async (usdPrice, packageId) => {

    // Check if user is on mobile, if yes, return without interacting with MetaMask
    if (isMobile) {
      console.log("User is on mobile, MetaMask interaction skipped.");
      return;
    }

    if (!web3 || !account || !ethPriceInUSD) {
      console.error('Web3 is not initialized or account not found.');
      return;
    }


    const contractAddress = require(`../../contractsData/${'ReferralLessRegistration-address'}.json`).address;
    const contractABI = require(`../../contractsData/${'ReferralLessRegistration'}.json`)
    // Definición mínima de la ABI para interactuar con USDT para las funciones approve y allowance


    const contract = new web3.eth.Contract(contractABI, contractAddress);

    // Asegúrate de que ethPrice es un número y es mayor que cero para evitar divisiones por cero
    const priceInEth = usdPrice / ethPriceInUSD;
    console.log(priceInEth)
    const priceInWei = web3.utils.toWei(priceInEth.toString(), 'ether');

    // Asegurarse de que el precio en ETH no es infinito o NaN
    if (!isFinite(priceInEth)) {
      console.error('Invalid ETH price for conversion:', priceInEth);
      return;
    }

    try {

      const gasEstimate = await contract.methods.buyPackage(packageId).estimateGas({
        from: account,
        value: priceInWei
      });

      console.log(gasEstimate)

      await contract.methods.buyPackage(packageId).send({ from: account, value: priceInWei, gas: gasEstimate });
      console.log('Compra realizada con éxito');
    } catch (error) {
      console.error('Error al realizar el registro:', error);
      let errorMessage = "Error al realizar el registro: ocurrió un error desconocido.";

      // Verificar si el mensaje de error de MetaMask está en la propiedad 'message'
      if (error.message) {
        errorMessage = error.message;
      }

      // MetaMask a menudo incluye detalles específicos del error bajo 'error.data.message' o 'error.data'
      if (error.data && typeof error.data === 'object' && error.data.message) {
        errorMessage = error.data.message;
      } else if (error.data && typeof error.data === 'string') {
        // En algunos casos, el mensaje de error está directamente en 'data' como un string
        errorMessage = error.data;
      }

      // Mostrar el mensaje de error extraído
      alert(errorMessage);

    } finally {



    }
  };




  // Convertir los precios de USD a ETH usando la tasa actual de ETH (ethPriceInUSD)
  const pricesInEth = Object.keys(usdPrices).reduce((acc, key) => {
    const priceInEth = ethPriceInUSD ? (usdPrices[key] / ethPriceInUSD).toFixed(4) : "N/A";
    acc[key] = {
      usd: usdPrices[key],
      eth: priceInEth
    };
    return acc;
  }, {});



  return (
    <Layout headerStyle={1} footerStyle={1} pageCls="about-us-page">
      <div style={{ width: '100vw', display: 'flex', flexDirection: 'column' }}>
        <div className='parent' style={ParentContainer}>



          <div style={containerStyles} className="container">


            <div style={boxStyles} className="box">
              <div className="title">
                <i className="fa fa-paper-plane" aria-hidden="true" style={titleStyles}></i>
                <h2 style={h2Styles} >{t('basicLevel')}</h2>
              </div>
              <div className="price">
              <div style={priceStyles}><h4><sup>$</sup>{pricesInEth.basic.usd} / {pricesInEth.basic.eth} ETH </h4></div>
              </div>
              <div className="option">
                <ul style={ulStyles}>
                  <li style={liStyles}><i className="fa fa-check" aria-hidden="true"></i>{t('acquisitionNFT')}</li>
                  <li style={liStyles}><i className="fa fa-check" aria-hidden="true"></i>{t('gifFormatTraining')}</li>
                  <li style={liStyles}><i className="fa fa-check" aria-hidden="true"></i>{t('threeLevelEarnings')}</li>


                </ul>

              </div>
              <a className="btn" style={btnStyles} onClick={() => handleRegister(usdPrices.basic, 1)}>{t('HomeButtonStart')}</a>


            </div>

            <div style={boxStyles} className="box">
              <div className="title">
                <i className="fa fa-plane" aria-hidden="true"></i>
                <h2 style={h2Styles}>{t('advancedLevel')}</h2>
              </div>
              <div className="price">
              <div style={priceStyles}><h4><sup>$</sup>{pricesInEth.advanced.usd} / {pricesInEth.advanced.eth} ETH</h4></div>
              </div>
              <div className="option">
                <ul style={ulStyles}>
                <li style={liStyles}><i className="fa fa-check" aria-hidden="true"></i>{t('twoNFTsPerTrainingSession')} </li>
                  <li style={liStyles}><i className="fa fa-check" aria-hidden="true"></i>{t('continuousTrainingAccess')}</li>
                  <li style={liStyles}><i className="fa fa-check" aria-hidden="true"></i>{t('fiveTrainingSessions')}</li>
                  <li style={liStyles}><i className="fa fa-check" aria-hidden="true"></i>{t('fourLevelEarnings')}</li>

                </ul>

              </div>

              <a className="btn" style={btnStyles} onClick={() => handleRegister(usdPrices.advanced, 2)}>{t('HomeButtonStart')}</a>
            </div>


            <div style={boxStyles} className="box">
              <div className="title">
                <i className="fa fa-rocket" aria-hidden="true"></i>
                <h2 style={h2Styles}>{t('universityLevel')}</h2>
              </div>
              <div className="price">
              <div style={priceStyles}><h4><sup>$</sup>{pricesInEth.university.usd} / {pricesInEth.university.eth} ETH</h4></div>
              </div>
              <div className="option">
                <ul style={ulStyles}>
                <li style={liStyles}><i className="fa fa-check" aria-hidden="true"></i>{t('tenTrainingSessions')} </li>
                  <li style={liStyles}><i className="fa fa-check" aria-hidden="true"></i>{t('continuousTrainingAccess')}</li>
                  <li style={liStyles}><i className="fa fa-check" aria-hidden="true"></i>{t('twoNsPerTrainingSession')}</li>
                  <li style={liStyles}><i className="fa fa-check" aria-hidden="true"></i>{t('fourLevelEarnings')}</li>
                </ul>

              </div>
              <a className="btn" style={btnStyles} onClick={() => handleRegister(usdPrices.university, 3)}>{t('HomeButtonStart')}</a>
            </div>
            <div style={boxStyles} className="box">
              <div className="title">
                <i className="fa fa-rocket" aria-hidden="true"></i>
                <h2 style={h2Styles}><span style={{ fontSize: '30px' }}>University Certification</span></h2>
              </div>
              <div className="price">
                <h4 style={priceStyles}><sup>$</sup>1950</h4>
              </div>
              <div className="option">
                <ul style={ulStyles}>
                  <li style={liStyles}><i className="fa fa-check" aria-hidden="true"></i>1 NFT</li>
                  <li style={liStyles}><i className="fa fa-check" aria-hidden="true"></i>Formación de 170 horas con certificado universitario</li>

                </ul>

              </div>


            </div>
            <div style={boxStyles} className="box">
              <div className="title">
                <i className="fa fa-rocket" aria-hidden="true"></i>
                <h2 style={h2Styles}>Master</h2>
              </div>
              <div className="price">
              <div style={priceStyles}><h4><sup>$</sup>{pricesInEth.master.usd} / {pricesInEth.master.eth} ETH</h4></div>
              </div>
              <div className="option">fiveNFTsPerTrainingSession
                <ul style={ulStyles}>
                  <li style={liStyles}><i className="fa fa-check" aria-hidden="true"></i>{t('fiveNFTsPerTrainingSession')}</li>
                  <li style={liStyles}><i className="fa fa-check" aria-hidden="true"></i>{t('continuousTrainingAccess')}</li>
                  <li style={liStyles}><i className="fa fa-check" aria-hidden="true"></i>{t('fifteenTrainingSessions')}</li>
                  <li style={liStyles}><i className="fa fa-check" aria-hidden="true"></i>{t('fourLevelEarnings')}</li>
                </ul>

              </div>
              <a className="btn" style={btnStyles} onClick={() => handleRegister(usdPrices.master, 4)}>{t('HomeButtonStart')}</a>
            </div>
            <div style={boxStyles} className="box">
              <div className="title">
                <i className="fa fa-rocket" aria-hidden="true"></i>
                <h2 style={h2Styles}>Premium</h2>
              </div>
              <div className="price">
              <div style={priceStyles}><h4><sup>$</sup>{pricesInEth.premium.usd} / {pricesInEth.premium.eth} ETH </h4></div>
              </div>
              <div className="option">
                <ul style={ulStyles}>
                  <li style={liStyles}><i className="fa fa-check" aria-hidden="true"></i>{t('oneTwentyNFTsSixtyTraining')}</li>
                  <li style={liStyles}><i className="fa fa-check" aria-hidden="true"></i>{t('officialCertificateAccess')}</li>
                  <li style={liStyles}><i className="fa fa-check" aria-hidden="true"></i>{t('multiLevelEarnings')}</li>

                </ul>

              </div>
              <a className="btn" style={btnStyles} onClick={() => handleRegister(usdPrices.premium, 5)}>{t('HomeButtonStart')}</a>
            </div>
          </div>
        </div>

      </div>
    </Layout>
  );
};

