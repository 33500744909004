// Importa los módulos y componentes necesarios
import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import NavigationButtons from '../../components/elements/ArrowsElements';
import Layout from "../../components/layout/Layout";
import { useTranslation } from 'react-i18next';


// Define los estilos con styled-components
const Section = styled.div`
    margin-top: ${props => (props.isMobile ? '20vw' : '10%')};
    padding-bottom: 5%;
    text-align: center;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 80px;
    margin-top: ${props => (props.isMobile ? '20vw' : '10%')};
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
`;

const Heading = styled.h2`
    margin-top: ${props => (props.isMobile ? '-50px' : '5px')};
    color: black;
    font-size: ${props => (props.isMobile ? '2em' : '3em')};
    margin-bottom: ${props => (props.isMobile ? '-4px' : '20px')};
`;

const Paragraph = styled.p`
    margin-bottom: 5vw;
    text-align: justify;
`;

const FAQSectionContainer = styled.div`
    width: ${props => (props.isMobile ? '100%' : '70vw')};
    height: auto;
    margin-top: ${props => (props.isMobile ? '10vw' : 0)};
`;


const FAQItem = ({ tema, descripcion, materiales, tiempoEstimado, isActive, handleToggle }) => {
    const { t } = useTranslation();
    return (
        <div data-wow-delay="0s" className={`wow fadeInUp flat-toggle ${isActive ? 'active' : ''}`}>
            <h6 className="toggle-title" onClick={handleToggle} style={{ margin: '10px 0', fontSize: '1.5em', maxWidth: '100%', color:'#fff' }}>
                {tema}
            </h6>
            <div className="toggle-content" style={{ display: isActive ? 'block' : 'none',color:'#fff' }}>
                <p style={{color:'#80B1B7', fontSize:'20px' }}>{descripcion}</p>
                <p style={{color:'#80B1B7', fontSize:'20px' }}>{t('materials')}: {materiales}</p>
                <p style={{color:'#80B1B7', fontSize:'20px' }}>{t('estimatedTime')}: {tiempoEstimado} {t('time')}</p>
            </div>
        </div>
    );
};


const FAQSection = ({ data, isActive, handleToggle }) => {
    return (
        <div className="flat-accordion">
            {data.map((item, index) => (
                <FAQItem
                    key={index}
                    tema={item[0]}
                    descripcion={item[1]}
                    materiales={item[2]}
                    tiempoEstimado={item[3]}
                    isActive={isActive.key === index}
                    handleToggle={() => handleToggle(index)}
                />
            ))}
        </div>
    );
};

const Formacion4 = () => {
    const [isActive, setIsActive] = React.useState({ key: null });
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const { t } = useTranslation();
    

    const handleToggle = (index) => {
        setIsActive((prevState) => ({
            key: prevState.key === index ? null : index,
        }));
    };

  
    const EthereumSmartContractsCourseData = [
        [t('ethereumIntroduction'), t('ethereumIntroductionDescription'), t('ethereumAdditionalMaterials'), t('ethereumIntroductionDuration')],
        [t('smartContractsIntroduction'), t('smartContractsDetail'), t('smartContractsResources'), t('smartContractsDuration')],
        [t('ethereumDevelopment'), t('ethereumDevelopmentProcess'), t('ethereumDevelopmentResources'), t('ethereumDevelopmentDuration')],
        [t('smartContractsSecurity'), t('smartContractsSecurityConsiderations'), t('smartContractsSecurityResources'), t('smartContractsSecurityDuration')],
        [t('interactingWithSmartContracts'), t('smartContractsInteractionDetail'), t('smartContractsInteractionResources'), t('smartContractsInteractionDuration')],
        [t('ethereumWallets'), t('ethereumWalletsFunctionality'), t('ethereumWalletsResources'), t('ethereumWalletsDuration')],
        [t('walletsSecurity'), t('walletsSecurityIssues'), t('walletsSecurityResources'), t('walletsSecurityDuration')],
        [t('advancedDevelopment'), t('advancedDevelopmentConcepts'), t('advancedDevelopmentResources'), t('advancedDevelopmentDuration')],
        [t('oraclesAndExternalData'), t('oraclesUsageDetail'), t('oraclesResources'), t('oraclesDuration')],
        [t('ethereumGovernance'), t('governanceModelsDiscussion'), t('governanceResources'), t('governanceDuration')],
        [t('enterpriseUseCases'), t('enterpriseApplicationsDetail'), t('enterpriseResources'), t('enterpriseDuration')],
        [t('deployingOnPrivateNetworks'), t('privateNetworksDeploymentConsiderations'), t('privateNetworksResources'), t('privateNetworksDuration')],
        [t('futureOutlooks'), t('futureTrendsInEthereum'), t('futureOutlooksResources'), t('futureOutlooksDuration')],
        [t('totalModuleDurationEthereum'), t('moduleContentOverviewEthereum'), t('moduleDigitalResourcesEthereum'), t('moduleTotalDurationEthereum')],
      ];
      
    
      

    return (
        <Layout headerStyle={1} footerStyle={1} pageCls="contact-us-page relative">
             <div style={{ width: '100vw', display: 'flex', flexDirection: 'column' }}>
                <Section>
                    <Container isMobile={isMobile}>
                        <Heading>{t('titleFormacion4')}</Heading>
                        <NavigationButtons
                            prevTo="/formacion-3"
                            homeTo="/formacion"
                            nextTo="/formacion-5"
                        />
                        <Paragraph>
                        {t('subtitleFormacion4')}
                        </Paragraph>
                        <FAQSectionContainer isMobile={isMobile}>
                            <FAQSection data={EthereumSmartContractsCourseData} isActive={isActive} handleToggle={handleToggle} />
                        </FAQSectionContainer>
                        <NavigationButtons
                            prevTo="/formacion-3"
                            homeTo="/formacion"
                            nextTo="/formacion-5"
                        />
                    </Container>
                </Section>
            </div>
        </Layout>
    );
};

export default Formacion4;