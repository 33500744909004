import React, { useState, useEffect } from 'react';
import Layout from "../../components/layout/Layout"
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";



export default function Home() {

    const { t } = useTranslation();
    const [esPantallaPequena, setEsPantallaPequena] = useState(window.innerWidth < 600);

    useEffect(() => {
        function manejarCambioDeTamano() {
            setEsPantallaPequena(window.innerWidth < 600);
        }

        window.addEventListener('resize', manejarCambioDeTamano);
        return () => window.removeEventListener('resize', manejarCambioDeTamano);
    }, []);

    // Estilos responsivos basados en el estado
    const estilos = {
        color: 'black',
        display: 'block',
        textAlign: esPantallaPequena ? 'left' : 'justify',
        marginTop: '20px',
    };

    const [isactive, setIsActive] = useState({
        status: false,
        key: null,
    });

    const handleToggle = (key) => {
        console.log('Clicked key:', key); // Verificar qué clave se está pasando al hacer clic
        setIsActive(prevState => ({
            ...prevState,
            status: !prevState.status,
            key: prevState.key === key ? null : key
        }));
    };

    return (


        <Layout headerStyle={1} footerStyle={1} pageCls="about-us-page" >
            <div className="container-fluid" style={{ marginTop: '9.5%' }}>
                <div className="row justify-content-center align-items-center">
                    <div className="col-12 col-md-8">
                        <div className="content text-center">
                            <h1 style={{
                                color: 'black',
                                fontFamily: 'Univers, sans-serif',
                                fontSize: '40px',
                                margin: '0',
                                textShadow: '2px 2px 2px #94939A',
                            }}>{t('uniqueExperience')}</h1>
                            <br />
                            <br />
                            <p data-wow-delay="0.1s" className="wow fadeInUp">
                                <span style={estilos}> {t('CommunityParrafo')} </span></p>
                            <br />
                            <br />
                            <div data-wow-delay="0.2s" className="wow fadeInUp flat-button d-flex justify-content-center" style={{ marginTop: '20px', marginBottom: '20px' }}>
                                <Link to="/kits" className="tf-button style-1 h50 w120">{t('startButtonText')}</Link>
                            </div>

                        </div>
                    </div>
                </div>
            </div>








            <div className="tf-section-2 widget-box-icon">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="heading-section-1">
                                <h2 className="tf-title pb-40"><span style={{
                                    color: 'black',
                                    fontFamily: 'Univers, sans-serif',
                                    fontSize: '35px',
                                    margin: '0',
                                    textShadow: '2px 2px 2px #94939A',
                                }}> {t('whyChooseUs')}{t('')}</span></h2>
                            </div>
                        </div>
                        <div data-wow-delay="0s" className="wow fadeInUp col-md-4">
                            <div className="box-icon-item custom-card-size">
                                <img src="https://raw.githubusercontent.com/CoachblockchainDapp/ImagesCoachIcons/main/about-01.png" alt="" />
                                <div className="title"><Link to="#"><span style={{ color: 'white' }}>{t('paymentInstant')}{t('')}</span></Link></div>
                                <p>{t('paymentInstantDescription')}{t('')}</p>
                            </div>
                        </div>
                        <div data-wow-delay="0.1s" className="wow fadeInUp col-md-4">
                            <div className="box-icon-item custom-card-size">
                                <img src="https://raw.githubusercontent.com/CoachblockchainDapp/ImagesCoachIcons/main/about-02.png" alt="" />
                                <div className="title"><Link to="#"><span style={{ color: 'white' }}>{t('security')}{t('')}</span></Link></div>
                                <p>{t('securityDescription')}{t('')}</p>
                            </div>
                        </div>
                        <div data-wow-delay="0.2s" className="wow fadeInUp col-md-4">
                            <div className="box-icon-item custom-card-size">
                                <img src="https://raw.githubusercontent.com/CoachblockchainDapp/ImagesCoachIcons/main/about-03.png" alt="" />
                                <div className="title"><Link to="#"><span style={{ color: 'white' }}>{t('smartContracts')}{t('')}</span></Link></div>
                                <p>{t('smartContractsDescription')}{t('')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </Layout>

    )
}