// loadData.js
const requireContext = require.context('../screens/courses/data', false, /\.json$/);

const jsonFiles = requireContext.keys().reduce((acc, key) => {
    const fileName = key.replace('./', '').replace('.json', ''); // Elimina './' y '.json'
    acc[fileName] = requireContext(key); // Asigna el contenido del JSON al nombre del archivo
    return acc;
}, {});

export default jsonFiles;
