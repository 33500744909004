import React from 'react';

export const HeightSpacer = ({ height }) => {
  const spacerStyle = {
    height: height,
  };

  return <div style={spacerStyle}></div>;
};


