import Layout from "../components/layout/Layout";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function Home() {
    const { t } = useTranslation();
    return (
        <>
            <Layout headerStyle={1} footerStyle={1} pageCls="contact-us-page relative">
                <div>
                    <div className="flat-title-page">
                        <div className="themesflat-container">
                            <div className="row">
                                <div className="col-12">
                                    <h1 style={{
                                        color: 'black',
                                        fontFamily: 'Univers, sans-serif',
                                        fontSize: '40px',
                                        margin: '0',
                                        textShadow: '2px 2px 2px #94939A',
                                    }}><span style={{ color: 'black' }}>{t('contactUs')}</span></h1>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="tf-section-2 widget-box-icon">
                        <div className="themesflat-container">
                            <div className="row">
                                <div className="col-md-12">
                                </div>


                                <div data-wow-delay="0.1s" className="wow fadeInUp col-md-4 mx-auto">
                                    <div className="box-icon-item text-center">
                                        <img src="https://raw.githubusercontent.com/CoachblockchainDapp/LogoNFTs/main/email.png" alt="" />
                                        <div className="title"><Link to="#"><span style={{ color: 'white' }}>{t('email')}</span></Link></div>
                                        s
                                    </div>
                                </div>




                            </div>
                        </div>
                    </div>

                    <div className="tf-section-2 widget-box-icon">
                        <div className="themesflat-container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="heading-section-1">
                                        <p className="pb-40"><span style={{ color: 'black' }}>{t('anyQuestions')}</span></p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <form id="commentform" className="comment-form">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <fieldset className="name">
                                                    <input className="style-1" type="text" id="name" placeholder={t('name')} name="name" tabIndex={2} aria-required="true" required />
                                                </fieldset>
                                            </div>
                                            <div className="col-md-4">
                                                <fieldset className="email">
                                                    <input className="style-1" type="email" id="email" placeholder={t('email')} name="email" tabIndex={2} aria-required="true" required />
                                                </fieldset>
                                            </div>
                                            <div className="col-md-4">
                                                <fieldset className="Asunto">
                                                    <input className="style-1" type="text" id="subject" placeholder={t('subject')} name="subject" tabIndex={2} aria-required="true" required />
                                                </fieldset>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <fieldset className="mensaje">
                                                    <textarea className="style-1" id="message" name="message" rows={4} placeholder={t('message')} tabIndex={4} aria-required="true" required />
                                                </fieldset>
                                            </div>
                                        </div>
                                        <div className="btn-submit" style={{ marginTop: '50px' }}>
                                            <button className="tf-button style-1" type="submit" style={{ fontSize: '18px', padding: '10px 20px', width: '120px', marginTop:'40px' }}>
                                                {t('send')}
                                            </button>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
}
