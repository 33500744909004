import React, { useRef, useState, useEffect } from 'react';
import { FaPlay, FaPause, FaVolumeUp, FaVolumeMute, FaExpand, FaStepForward, FaStepBackward } from 'react-icons/fa';
import PropTypes from 'prop-types';

const VideoComponent = ({ videoUrl, title }) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(0.7);
  const [showBlackScreen, setShowBlackScreen] = useState(false);

  useEffect(() => {
    const style = document.createElement('style');
    style.innerHTML = `
      video::-webkit-media-controls {
        display: block !important;
      }
    `;
    document.head.appendChild(style);

    const handleVisibilityChange = () => {
      if (document.hidden) {
        setShowBlackScreen(true);
      } else {
        setShowBlackScreen(false);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('focus', handleVisibilityChange);
    window.addEventListener('blur', handleVisibilityChange);

    return () => {
      document.head.removeChild(style);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('focus', handleVisibilityChange);
      window.removeEventListener('blur', handleVisibilityChange);
    };
  }, []);

  const handlePlayPause = () => {
    if (videoRef.current.paused || videoRef.current.ended) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleMute = () => {
    videoRef.current.muted = !videoRef.current.muted;
    setIsMuted(videoRef.current.muted);
  };

  const handleVolumeChange = (e) => {
    const volumeLevel = parseFloat(e.target.value);
    videoRef.current.volume = volumeLevel;
    setVolume(volumeLevel);
    setIsMuted(volumeLevel === 0);
  };

  const handleProgressClick = (e) => {
    const progress = e.target;
    const clickX = e.nativeEvent.offsetX;
    const newTime = (clickX / progress.offsetWidth) * duration;
    videoRef.current.currentTime = newTime;
    setCurrentTime(newTime);
  };

  const handleForward = () => {
    videoRef.current.currentTime += 10;
  };

  const handleBackward = () => {
    videoRef.current.currentTime -= 10;
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <div 
      style={styles.container} 
      onContextMenu={(e) => e.preventDefault()} // Disable right-click
    >
      <div style={styles.vidcontainer}>
        {showBlackScreen && <div style={styles.blackScreen}></div>}
        <video
          ref={videoRef}
          onTimeUpdate={() => setCurrentTime(videoRef.current.currentTime)}
          onLoadedMetadata={() => setDuration(videoRef.current.duration)}
          style={styles.video}
          src={videoUrl}
          controls={false}
        >
          Your browser does not support the video tag.
        </video>
        <div style={styles.controllers}>
          <button style={styles.button} onClick={handleBackward} title="Rewind 10 seconds">
            <FaStepBackward />
          </button>
          <button style={styles.button} onClick={handlePlayPause} title="Play/Pause video">
            {isPlaying ? <FaPause /> : <FaPlay />}
          </button>
          <button style={styles.button} onClick={handleForward} title="Forward 10 seconds">
            <FaStepForward />
          </button>
          <div style={styles.timeDisplay}>
            {formatTime(currentTime)} / {formatTime(duration)}
          </div>
          <div style={styles.volumeControl}>
            <button style={styles.button} onClick={handleMute} title="Mute/Unmute sound">
              {isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
            </button>
            <input type="range" min="0" max="1" step="0.1" value={volume} onChange={handleVolumeChange} style={styles.volume} />
          </div>
          <button style={styles.button} onClick={() => videoRef.current.requestFullscreen()} title="Full screen">
            <FaExpand />
          </button>
        </div>
        <div style={styles.progress} onClick={handleProgressClick}>
          <div style={{ ...styles.timeBar, width: `${(currentTime / duration) * 100}%` }} />
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '80%', // Ajuste del ancho al 80% para que no ocupe todo el espacio horizontal
    margin: '5px auto', // Margen automático para centrar el contenedor
    padding: '0',
    backgroundColor: '#111',
    position: 'relative',
    top: '20%', // Espacio desde la parte superior para evitar que quede cubierto por el encabezado
  },
  vidcontainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 'auto',
    backgroundColor: '#000',
    paddingBottom: '10px', // Espaciado adicional debajo del video para los controles
  },
  video: {
    width: '100%', // El video ocupará el 100% del ancho del contenedor
    height: 'auto', // Mantiene la relación de aspecto
    maxHeight: 'calc(100vh - 10px)', // Ajustamos la altura para asegurar que los controles se vean desde el inicio
    objectFit: 'cover', // Asegura que el video cubra todo el espacio disponible
  },
  blackScreen: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#000',
    zIndex: 1,
    opacity: 0.9,
  },
  progress: {
    width: '100%',
    height: '10px',
    backgroundColor: '#444',
    cursor: 'pointer',
    marginTop: '5px',
  },
  timeBar: {
    height: '100%',
    backgroundColor: '#fff',
  },
  controllers: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '90%',
    padding: '0 0',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
  button: {
    backgroundColor: 'transparent',
    border: 'none',
    color: '#fff',
    fontSize: '20px',
    cursor: 'pointer',
    padding: '5px',
  },
  timeDisplay: {
    color: '#fff',
    fontSize: '14px',
  },
  volumeControl: {
    display: 'flex',
    alignItems: 'center',
  },
  volume: {
    width: '70px',
    marginLeft: '5px',
  },
};


export default VideoComponent;
