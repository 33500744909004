import React, { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import axios from 'axios';
import { ArrowDownward, KeyboardArrowUp, TrendingUp } from '@mui/icons-material'
import HorizontalLine from '../elements/HorizontalLine';
import './featured.css'

// Asegúrate de reemplazar 'contractAddress' por la dirección de tu contrato y 'contractABI' por la ABI de tu contrato
const contractAddress = require(`../../contractsData/${'DataStorage'}-address.json`).address;
const contractABI = require(`../../contractsData/${'DataStorege-data'}.json`);



const url = "https://min-api.cryptocompare.com/data/pricemulti?fsyms=BTC,ETH,USDT&tsyms=USD,EUR,GBP";

const cryptoImages = {
    BTC: 'https://cdn.icon-icons.com/icons2/1487/PNG/512/8369-bitcoin_102502.png',
    ETH: 'https://cryptologos.cc/logos/ethereum-eth-logo.png',
    USDT: 'https://www.blocknative.com/hubfs/USDT.png',
    // Agrega más criptomonedas y sus imágenes según sea necesario
};

const getBackgroundColor = (index) => (index % 2 === 0 ? '#BAD0D4' : '#7aa2aa');

const AllUsers = () => {

    const [purchaseDetails, setPurchaseDetails] = useState([]);
    const [userAddress, setUserAddress] = useState('');
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [totalCompras, setTotalCompras] = useState(0);
    const [packageDetails, setPackageDetails] = useState([]); // Almacena detalles de cada paquete
    const [totalPackages, setTotalPackages] = useState(0); // Almacena el total de paquetes
    const [totalReferidos, setTotalReferidos] = useState('0');
    const [totalMembers, setTotalMemebers] = useState('0');
    const [cryptoPrices, setCryptoPrices] = useState({});
    const [levelReferrals, setLevelReferrals] = useState({});
    const [referees, setReferees] = useState([]);

    const [allUsersInfo, setAllUsersInfo] = useState({ addresses: [], buyerInfos: [] });
    const [allData, setAllData] = useState({
        totalMembers: 0,
        allPurchases: [],
        allUserAddresses: [],
        tokenIdValue: 0,
        allHashes: [],
        allUses: [],
        allOwners: [],
        allPackageInfos: []
    });


    const provider = window.ethereum ? new ethers.providers.Web3Provider(window.ethereum) : null;
    const contract = new ethers.Contract(contractAddress, contractABI, provider);


    async function loadAllUsersInfo() {
        try {
            const [addresses, buyerInfos] = await contract.getAllUsersInfo();
            setAllUsersInfo({ addresses, buyerInfos });
        } catch (error) {
            console.error("Error al cargar toda la información de los usuarios:", error);
        }
    }

    async function loadAllData() {
        try {
            const { totalMembers_, allPurchases, allUserAddresses, tokenIdValue, allHashes, allUses, allOwners, allPackageInfos } = await contract.getAllData();
            setAllData({
                totalMembers: totalMembers_,
                allPurchases,
                allUserAddresses,
                tokenIdValue,
                allHashes,
                allUses,
                allOwners,
                allPackageInfos
            });
        } catch (error) {
            console.error("Error al cargar todos los datos:", error);
        }
    }



    async function loadTotalReferidos(address) {
        if (!window.ethereum) {
            console.log('Ethereum object not found, install MetaMask.');
            return;
        }
        try {
            const _totalReferees = await contract.getInvitationsCount(address);
            const _totalMembers = await contract.getTotalMembers();
            setTotalReferidos(_totalReferees.toString()); // Convierte BigNumber a cadena
            setTotalMemebers(_totalMembers.toString());

            // setTotalCompras no es necesario si solo quieres mostrar las compras, pero puedes sumar 'uses' si es relevante
        } catch (error) {
            console.error("Error den detalles de miembros", error);
        }
    }




    async function loadPurchaseDetails(address) {
        if (!window.ethereum) {
            console.log('Ethereum object not found, install MetaMask.');
            return;
        }



        try {
            const [packageIds, timestamps, uses] = await contract.getUserPurchases(address);
            let totalUses = 0; // Inicializa la suma de usos
            const mappedDetails = packageIds.map((packageId, index) => {
                totalUses += parseInt(uses[index]);// Suma los usos
                return {
                    packageId: packageId.toString(),
                    count: uses[index].toString(),
                    lastPurchaseTimestamp: new Date(timestamps[index] * 1000).toLocaleString(),
                };
            });

            setPurchaseDetails(mappedDetails);
            setTotalCompras(totalUses);
            // setTotalCompras no es necesario si solo quieres mostrar las compras, pero puedes sumar 'uses' si es relevante
        } catch (error) {
            console.error("Error al cargar los detalles de las compras:", error);
        }
    }


    // Cargar los totales de compras 
    async function loadUserPackageCounts(address) {
        if (!window.ethereum) {
            console.log('Ethereum object not found, install MetaMask.');
            return;
        }


        try {
            const [packageIds, counts] = await contract.getUserPackageCounts(address);
            let totalPackages = 0; // Inicializa el contador total de paquetes
            const packageDetails = packageIds.map((packageId, index) => {
                totalPackages += parseInt(counts[index]); // Suma los conteos de cada paquete
                return {
                    packageId: packageId.toString(),
                    count: counts[index].toString(),
                };
            });

            setTotalPackages(totalPackages)
            setPackageDetails(packageDetails)
        } catch (error) {
            console.error("Error al cargar los conteos de los paquetes:", error);
        }
    }

    async function loadLevelReferrals(address) {
        try {
            let referrals = {};
            for (let level = 1; level <= 6; level++) {
                const referralAddress = await contract.getLevelReferral(address, level);
                referrals[`Level ${level}`] = referralAddress;
            }
            setLevelReferrals(referrals);
        } catch (error) {
            console.error("Error al cargar referidos por nivel:", error);
        }
    }

    async function loadUsersByReferral(refereeAddress) {
        if (!window.ethereum) {
            console.log('Ethereum object not found, install MetaMask.');
            return;
        }

        try {
            const users = await contract.getUsersByReferral(refereeAddress);
            setReferees(users);
        } catch (error) {
            console.error("Error al cargar los usuarios referidos:", error);
        }
    }



    useEffect(() => {
        const initEthereum = async () => {
            if (window.ethereum) {
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                try {
                    const accounts = await provider.send("eth_requestAccounts", []);
                    const userAddress = accounts[0];
                    setUserAddress(userAddress);
                    const isUserRegistered = await contract.isUserRegistered(userAddress);

                    if (!isUserRegistered) {
                        console.log("Usuario no registrado en la dApp.");
                        return;
                    }
                    loadPurchaseDetails(userAddress);
                    loadUserPackageCounts(userAddress);
                    loadTotalReferidos(userAddress);
                    loadLevelReferrals(userAddress);
                    loadUsersByReferral(userAddress);
                    loadAllUsersInfo();
                    loadAllData();

                } catch (error) {
                    console.error("Error al obtener la dirección del usuario:", error);
                }

                const handleAccountsChanged = (accounts) => {
                    if (accounts.length > 0) {
                        const userAddress = accounts[0];
                        setUserAddress(userAddress);

                        // Verificar y cargar los datos nuevamente si el usuario cambia de cuenta
                        loadPurchaseDetails(userAddress);
                        loadUserPackageCounts(userAddress);
                        loadTotalReferidos(userAddress)
                        loadLevelReferrals(userAddress);
                        loadUsersByReferral(userAddress);
                        loadAllUsersInfo();
                        loadAllData();
                    } else {
                        // Manejar desconexion de MetaMask, por ejemplo, limpiando el estado
                        setPurchaseDetails([]);
                        setUserAddress('');
                        setTotalPackages('');
                        setTotalReferidos('0')
                        setTotalMemebers('0')

                    }
                };

                window.ethereum.on('accountsChanged', handleAccountsChanged);

                return () => window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
            }
        };

        const fetchCryptoPrices = async () => {
            try {
                const response = await axios.get(url);
                setCryptoPrices(response.data);
            } catch (error) {
                console.error("Error fetching crypto prices: ", error);
            }
        };

        // Inicializar la conexión con Ethereum y cargar los datos del usuario
        initEthereum();

        // Obtener precios de criptomonedas
        fetchCryptoPrices();
        const priceInterval = setInterval(fetchCryptoPrices, 10000); // Actualizar cada 10 segundos

        return () => clearInterval(priceInterval); // Limpiar el intervalo en el desmontaje
    }, []);

    // Renderizado del componente...


    // Ejemplo de datos, asegúrate de actualizarlos según tus necesidades
    let data = [
        {
            id: 'featuredItem',
            title: 'Kits',
            amount: totalPackages,
            icon: <KeyboardArrowUp className='featuredIcon' />,
        },
        {
            id: 'featuredItemB',
            title: 'Referidos',
            amount: totalReferidos,
            icon: <TrendingUp className='featuredIcon' />
        },
        {
            id: 'featuredItemC',
            title: 'Afiliados',
            amount: totalMembers,
            icon: <ArrowDownward className='featuredIcon negative' />
        },

    ];


    return (
        <>

            <div className='featured'>
                <div className="container " style={{ background: '#BAD0D4', width: '100%', borderRadius: '20px' }}>
                    <div className="welcomeCard" style={{ background: '#BAD0D4', width: '100%' }}>
                        <div className="welcomeMessage">Bienvenido</div>
                        <div className="userWallet">{userAddress}</div>
                    </div>


                    <div className="dataRow" style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'nowrap', // No envolver las cartas
                        gap: '5px',
                        overflowX: 'auto', // Permitir desplazamiento horizontal
                        alignItems: 'center', // Centrar las cartas verticalmente
                        justifyContent: 'center', // Centrar las cartas horizontalmente
                        backgroundColor: '#BAD0D4' // Fondo azul para el contenedor
                    }}>
                        {data.sort((a, b) => b.amount - a.amount).map((item) => (
                            <div key={item.id} className="_card-info" style={{
                                margin: '10px',
                                width: 'calc(40% - 20px)', // Ajustar el ancho para que sea más pequeño y quepan en una línea
                                padding: '10px',
                                backgroundColor: '#BAD0D4' // Fondo azul para las cartas
                            }}>
                                <div className="cardContent-info">
                                    <span className='featuredMoney' style={{ fontSize: '20px', color: '#0A5364', textAlign: 'center' }}>{item.amount}</span> {/* Texto en blanco para contraste */}
                                    <div className='featuredTitle' style={{ fontSize: '18px', color: '#0A5364' }}>{item.title}</div>
                                </div>
                            </div>
                        ))}
                    </div>

                </div>
                <br />

                <div className="welcomeMessage">Mis compras</div>
                <HorizontalLine />


                <div className='purchaseColumn-info'>
                    {Array.from({ length: 5 }).map((_, index) => {
                        const currentPackageId = index + 1;
                        const detail = packageDetails.find(detail => parseInt(detail.packageId) === currentPackageId);

                        let pk = (currentPackageId === 1) ? "BASIC" : (currentPackageId === 2) ? "ADVANCED" : (currentPackageId === 3) ? "ACADEMIC" : (currentPackageId === 4) ? "MASTER" : "PREMIUM";

                        return (
                            <>

                                <div class="_card">

                                    <div class="img"><span style={{ color: 'white', fontSize: '45px' }}>{detail ? detail.count : '0'}</span>

                                    </div>
                                    <span style={{ color: '#0C5365', fontWeight: 'bold' }}> {pk}</span>


                                </div>
                            </>
                        );
                    })}
                </div>



                <div className="welcomeMessage">Criptomonedas</div>
                <HorizontalLine />


                <div>
                    <div className="card-crypto header" style={{ backgroundColor: '#fff' }}>
                        <br />
                        <br />
                        <div className="item crypto">
                            <p style={{ color: '#000', fontWeight: 'bold' }}>Crypto</p>
                        </div>
                        <div className="item result">
                            <p style={{ color: '#000', fontWeight: 'bold' }}>USD</p>
                        </div>
                        <div className="item result">
                            <p style={{ color: '#000', fontWeight: 'bold' }}>EUR</p>
                        </div>
                        <div className="item result">
                            <p style={{ color: '#000', fontWeight: 'bold' }}>GBP</p>
                        </div>


                    </div>
                    {Object.entries(cryptoPrices).map(([key, value]) => (
                        <div key={key} className="card-crypto">
                            <div className="item crypto">
                                <img src={cryptoImages[key]} alt={key} style={{ width: '50px', height: '50px' }} />
                                <p style={{ color: '#000', fontWeight: 'bold', fontSize: '12px' }}>{key}</p>
                            </div>
                            <div className="item result">
                                <p style={{ color: '#1BFF00', fontWeight: 'bold' }}>$ {value.USD}</p>
                            </div>
                            <div className="item result">
                                <p style={{ color: '#003399', fontWeight: 'bold' }}>€ {value.EUR}</p>
                            </div>
                            <div className="item result">
                                <p style={{ color: '#CC3333', fontWeight: 'bold' }}>£ {value.GBP}</p>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="welcomeMessage">Niveles de Referidos</div>
                <HorizontalLine />
                <hr /> {/* Usamos <hr /> en lugar de HorizontalLine para una línea horizontal */}

                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ flex: 1 }}>
                        <table style={{ width: '100%', borderCollapse: 'collapse', border: 'none' }}>
                            <thead>
                                <tr>
                                    <th style={{ width: '10%', padding: '10px', textAlign: 'center', fontWeight: 'bold', border: 'none' }}>Nivel</th>
                                    <th style={{ width: '80%', padding: '10px', textAlign: 'center', fontWeight: 'bold', border: 'none' }}>Billeteras de referentes</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(levelReferrals).map(([level, address], index) => (
                                    <tr key={index} style={{ backgroundColor: getBackgroundColor(index), color: '#0A5364', textAlign: 'center', border: 'none', fontWidth: 'bold' }}>
                                        <td style={{ padding: '10px', border: 'none' }}>{level}</td>
                                        <td style={{ padding: '10px', border: 'none' }}>{address}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                </div>


                <div className="welcomeMessage">Usuarios referidos por {userAddress}</div>
                <HorizontalLine />

                <div>
                    <div></div>
                    <table style={{ borderCollapse: 'collapse', width: '100%', color: 'black', fontSize: 'bold' }}>
                        <thead>
                            <tr>
                                <th style={{ border: 'none' }}>#</th>
                                <th style={{ border: 'none' }}>Dirección del referido</th>
                            </tr>
                        </thead>
                        <tbody>
                            {referees.map((referee, index) => (
                                <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#BAD0D4' : '#7AA2AA', border: 'none' }}>
                                    <td style={{ border: 'none', color: '#0A5364', fontWidth: 'bold' }}>{index + 1}</td>
                                    <td style={{ border: 'none', color: '#0A5364', fontWidth: 'bold' }}>{referee}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div>
                    <h2>All Users Info</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>Address</th>
                                <th>Buyer Info</th>
                            </tr>
                        </thead>
                        <tbody>
                            {allUsersInfo.addresses.map((address, index) => (
                                <tr key={index}>
                                    <td>{address}</td>
                                    <td>{JSON.stringify(allUsersInfo.buyerInfos[index])}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <table>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>User Addresses</th>
                                <th>Token Hashes</th>
                                <th>Uses</th>

                            </tr>
                        </thead>
                        <tbody>
                            {allData.allPurchases.map((purchase, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{allData.allUserAddresses[index]}</td>
                                    <td>{allData.allHashes[index]}</td>
                                    <td>{allData.allUses[index].toString()}</td>

                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>




                <div className="welcomeMessage">Transaciones</div>
                <HorizontalLine />



            </div >
        </>

    );

}

export default AllUsers;