import React, { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import { Canvas } from '@react-three/fiber';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUsers, faBookBookmark, faTurnUp, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { ArrowDownward, KeyboardArrowUp, TrendingUp } from '@mui/icons-material';
import HorizontalLine from '../elements/HorizontalLine';
import SphereToPlaneWithRotation from '../animation/EarthNFT';
import WalletCard from './walletCard'
import CarrucelNFT from './carruselNFT';
import BitcoinChart from './BitcoinChart'
import EthereumChart from './EthereumChart'
import USDTEChart from './USDTEChart'

import './featured.css';

const contractAddress = require(`../../contractsData/${'DataStorage'}-address.json`).address;
const contractABI = require(`../../contractsData/${'DataStorege-data'}.json`);

const url = "https://min-api.cryptocompare.com/data/pricemulti?fsyms=BTC,ETH,USDT&tsyms=USD,EUR,GBP";

const pexel = (id) =>
    `https://raw.githubusercontent.com/CoachblockchainDapp/iconFormacion/main/${id}.png`;

const cryptoImages = {
    BTC: 'https://cdn.icon-icons.com/icons2/1487/PNG/512/8369-bitcoin_102502.png',
    ETH: 'https://cryptologos.cc/logos/ethereum-eth-logo.png',
    USDT: 'https://www.blocknative.com/hubfs/USDT.png',
    // Agrega más criptomonedas y sus imágenes según sea necesario
};

const getBackgroundColor = (index) => (index % 2 === 0 ? '#BAD0D4' : '#7aa2aa');



const getIcon = (title) => {
    switch (title) {
        case 'Afiliados':
            return faUser;
        case 'Referidos':
            return faUsers;
        case 'Kits':
            return faBookBookmark;
        default:
            return faBookBookmark; // Default to faGift if the title doesn't match any case
    }
};

export default function Featured() {
    const [purchaseDetails, setPurchaseDetails] = useState([]);
    const [userAddress, setUserAddress] = useState('');
    const [totalCompras, setTotalCompras] = useState(0);
    const [packageDetails, setPackageDetails] = useState([]);
    const [totalPackages, setTotalPackages] = useState(0);
    const [totalReferidos, setTotalReferidos] = useState('0');
    const [totalMembers, setTotalMemebers] = useState('0');
    const [cryptoPrices, setCryptoPrices] = useState({});
    const [levelReferrals, setLevelReferrals] = useState({});
    const [referees, setReferees] = useState([]);
    const [matchingReferrals, setMatchingReferrals] = useState([]);
    const provider = window.ethereum ? new ethers.providers.Web3Provider(window.ethereum) : null;
    const contract = new ethers.Contract(contractAddress, contractABI, provider);

    const updateMatchingReferrals = async () => {
        const newReferrals = await findMatchingReferrals(referees, userAddress);
        setMatchingReferrals(newReferrals);
    };

    async function loadTotalReferidos(address) {
        if (!window.ethereum) {
            console.log('Ethereum object not found, install MetaMask.');
            return;
        }
        try {
            const _totalReferees = await contract.getInvitationsCount(address);
            const _totalMembers = await contract.getTotalMembers();
            setTotalReferidos(_totalReferees.toString());
            setTotalMemebers(_totalMembers.toString());
        } catch (error) {
            console.error("Error den detalles de miembros", error);
        }
    }

    async function loadPurchaseDetails(address) {
        if (!window.ethereum) {
            console.log('Ethereum object not found, install MetaMask.');
            return;
        }
        try {
            const [packageIds, timestamps, uses] = await contract.getUserPurchases(address);
            let totalUses = 0;
            const mappedDetails = packageIds.map((packageId, index) => {
                totalUses += parseInt(uses[index]);
                return {
                    packageId: packageId.toString(),
                    count: uses[index].toString(),
                    lastPurchaseTimestamp: new Date(timestamps[index] * 1000).toLocaleString(),
                };
            });
            setPurchaseDetails(mappedDetails);
            setTotalCompras(totalUses);
        } catch (error) {
            console.error("Error al cargar los detalles de las compras:", error);
        }
    }

    async function loadUserPackageCounts(address) {
        if (!window.ethereum) {
            console.log('Ethereum object not found, install MetaMask.');
            return;
        }
        try {
            const [packageIds, counts] = await contract.getUserPackageCounts(address);
            let totalPackages = 0;
            const packageDetails = packageIds.map((packageId, index) => {
                totalPackages += parseInt(counts[index]);
                return {
                    packageId: packageId.toString(),
                    count: counts[index].toString(),
                };
            });
            setTotalPackages(totalPackages)
            setPackageDetails(packageDetails)
        } catch (error) {
            console.error("Error al cargar los conteos de los paquetes:", error);
        }
    }

    async function loadLevelReferrals(address) {
        try {
            let referrals = {};
            for (let level = 1; level <= 6; level++) {
                const referralAddress = await contract.getLevelReferral(address, level);
                referrals[`Level ${level}`] = referralAddress;
            }
            setLevelReferrals(referrals);
        } catch (error) {
            console.error("Error al cargar referidos por nivel:", error);
        }
    }

    async function loadUsersByReferral(refereeAddress) {
        if (!window.ethereum) {
            console.log('Ethereum object not found, install MetaMask.');
            return;
        }
        try {
            const users = await contract.getUsersByReferral(refereeAddress);
            setReferees(users);
        } catch (error) {
            console.error("Error al cargar los usuarios referidos:", error);
        }
    }


    async function findMatchingReferrals(referees, userAddress) {
        const matchingReferrals = [];
        for (const ref of referees) {
            for (let level = 1; level <= 6; level++) {
                const referralAddress = (await contract.getLevelReferral(ref, level)).toLowerCase();
                if (referralAddress === userAddress.toLowerCase()) {
                    matchingReferrals.push({
                        level: `Level ${level}`,
                        userAddress: referralAddress,
                        address: ref.toLowerCase(),
                    });
                }
            }
        }
        return matchingReferrals;
    }






    useEffect(() => {
        const initEthereum = async () => {
            if (window.ethereum) {
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                try {
                    const accounts = await provider.send("eth_requestAccounts", []);
                    const userAddress = accounts[0];
                    setUserAddress(userAddress);
                    const isUserRegistered = await contract.isUserRegistered(userAddress);

                    if (!isUserRegistered) {
                        console.log("Usuario no registrado en la dApp.");
                        return;
                    }
                    loadPurchaseDetails(userAddress);
                    loadUserPackageCounts(userAddress);
                    loadTotalReferidos(userAddress);
                    loadLevelReferrals(userAddress);
                    loadUsersByReferral(userAddress);
                    const results = await findMatchingReferrals(referees, userAddress); // Función para obtener datos
                    setMatchingReferrals(results);

                } catch (error) {
                    console.error("Error al obtener la dirección del usuario:", error);
                }


                const handleAccountsChanged = (accounts) => {
                    if (accounts.length > 0) {
                        const userAddress = accounts[0];
                        setUserAddress(userAddress);
                        loadPurchaseDetails(userAddress);
                        loadUserPackageCounts(userAddress);
                        loadTotalReferidos(userAddress)
                        loadLevelReferrals(userAddress);
                        loadUsersByReferral(userAddress);
                        findMatchingReferrals(referees, userAddress)



                    } else {
                        setPurchaseDetails([]);
                        setUserAddress('');
                        setTotalPackages('');
                        setTotalReferidos('0')
                        setTotalMemebers('0')

                    }
                };

                window.ethereum.on('accountsChanged', handleAccountsChanged);

                return () => window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
            }
        };

        const fetchReferrals = async () => {
            const results = await findMatchingReferrals(referees, userAddress);
            setMatchingReferrals(results);
        };


        const fetchCryptoPrices = async () => {
            try {
                const response = await axios.get(url);
                setCryptoPrices(response.data);
            } catch (error) {
                console.error("Error fetching crypto prices: ", error);
            }
        };

        initEthereum();
        fetchCryptoPrices();
        fetchReferrals();
        const priceInterval = setInterval(fetchCryptoPrices, 10000);

        return () => clearInterval(priceInterval);
    }, []);

    let data = [
        {
            id: 'featuredItem',
            title: 'Kits',
            amount: totalPackages,
            icon: <KeyboardArrowUp className='featuredIcon' />,
        },
        {
            id: 'featuredItemB',
            title: 'Referidos',
            amount: totalReferidos,
            icon: <TrendingUp className='featuredIcon' />
        },
       
    ];



    return (
        <div className='featured'>
            <div className="container_featured">
                <div className="welcomeCard" style={{ width: '50%' }}>
                    <WalletCard user={userAddress} />
                </div>
                <div className="dataRow" style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    gap: '5px',
                    overflowX: 'auto',
                    alignItems: 'center',
                    justifyContent: 'center',

                }}>
                    {data.sort((a, b) => b.amount - a.amount).map((item) => (
                        <div key={item.id} className="_card-info" style={{
                            margin: '10px',
                            width: 'calc(40% - 20px)',
                            padding: '10px',
                            backgroundColor: '#0A5364', // Set card background to blue
                            borderRadius: '5px', // Optional: adds rounded corners to the cards
                        }}>
                            <div className="cardContent-info" style={{ textAlign: 'center' }}>

                                <div className='featuredTitle' style={{
                                    fontSize: '18px',
                                    color: 'white',
                                    fontWeight: 'bold', // Make text bold
                                    marginBottom: '10px'
                                }}>{item.title}</div>


                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <FontAwesomeIcon
                                        icon={getIcon(item.title)} // Use the getIcon function to determine which icon to use
                                        style={{
                                            color: 'white',
                                            fontSize: '24px',
                                            marginRight: '0px',
                                        }}
                                    />
                                    <div style={{ flex: 1, textAlign: 'center' }}>
                                        <span className='featuredMoney' style={{
                                            fontSize: '28px',
                                            color: 'white',
                                            fontWeight: 'bold'
                                        }}>
                                            {item.amount}
                                        </span>
                                    </div>
                                </div>




                            </div>
                        </div>
                    ))}

                </div>
            </div>
            <br />
            <br />
            <br />




            <div className="welcomeMessage">Mis compras</div>
            <HorizontalLine />
            <br />
            <br />


            <div className='purchaseColumn-info' style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                alignItems: 'center',
                backgroundColor: '#FFFFFF',
                borderRadius: '10px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                padding: '20px',
                maxWidth: '98%',
                margin: 'auto',
                opacity: '0.98'
            }}>
                {Array.from({ length: 5 }).map((_, index) => {
                    const currentPackageId = index + 1;
                    const detail = packageDetails.find(detail => parseInt(detail.packageId) === currentPackageId);
                    let pk = (currentPackageId === 1) ? "BASIC" : (currentPackageId === 2) ? "ADVANCED" : (currentPackageId === 3) ? "ACADEMIC" : (currentPackageId === 4) ? "MASTER" : "PREMIUM";
                    return (
                        <div key={index} className="_card" style={{
                            background: '#ffffff',
                            margin: '10px',
                            borderRadius: '5px',
                            height: '180px', // Establece una altura fija más baja
                            display: 'flex', // Usa flexbox para alinear el contenido
                            flexDirection: 'column', // Alineación vertical
                            justifyContent: 'center', // Centra el contenido verticalmente
                            alignItems: 'center',
                            padding: '10px' // Ajuste de padding para mantener el contenido centrado

                        }}>
                            <div className="img" style={{ fontSize: '45px', color: 'white' }}>
                                <div style={{
                                    borderRadius: '10px',
                                    overflow: 'hidden',
                                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
                                    transition: 'transform 0.3s',
                                    cursor: 'pointer'
                                }}
                                    onMouseEnter={(e) => { e.currentTarget.style.transform = 'scale(1.05)'; }}
                                    onMouseLeave={(e) => { e.currentTarget.style.transform = 'scale(1)'; }}
                                >
                                    <img
                                        src={pexel(currentPackageId)}
                                        alt={`Image with id ${currentPackageId}`}
                                        style={{ display: 'block', width: '100%', height: 'auto' }}
                                    />
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <span style={{ color: '#0C5365', fontWeight: 'bold' }}>
                                    {detail ? detail.count : '0'}
                                </span>
                                <span style={{ color: '#0C5365', fontWeight: 'bold', marginLeft: '10px' }}>
                                    {pk}
                                </span>
                            </div>
                        </div>
                    );
                })}
            </div>

            <br />
            <br />
            <br />

            <div className="welcomeMessage">Criptomonedas</div>
            <HorizontalLine />
            <br />
            <br />
            <br />


            <div style={{
                display: 'flex',
                flexDirection: 'row', // Mantiene los elementos en línea horizontal
                justifyContent: 'space-between', // Coloca uno en cada extremo del contenedor
                alignItems: 'flex-start', // Alinea elementos al inicio verticalmente
                width: '98%',
                margin: 'auto',
            }}>
                {/* Tarjetas de criptomonedas al lado izquierdo */}
                <div style={{ width: '58%' }}>
                    <div className="card-crypto" style={{
                        backgroundColor: '#fff',
                        borderRadius: '10px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        margin: '10px',
                        display: 'flex',
                        flexDirection: 'row', // Asegura que los ítems en la tarjeta estén en fila
                        padding: '10px',
                    }}>
                        <div className="item crypto"><p>Crypto</p></div>
                        <div className="item result"><p>USD</p></div>
                        <div className="item result"><p>EUR</p></div>
                        <div className="item result"><p>GBP</p></div>
                    </div>

                    {Object.entries(cryptoPrices).map(([key, value]) => (
                        <div key={key} className="card-crypto" style={{
                            backgroundColor: '#fff',
                            borderRadius: '10px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            margin: '10px',
                            display: 'flex',
                            flexDirection: 'row', // Mantiene ítems de la tarjeta en fila
                            padding: '10px',
                        }}>
                            <img src={cryptoImages[key]} alt={key} style={{ width: '50px', height: '50px', marginRight: '10px' }} />
                            <div className="item crypto"><p>{key}</p></div>
                            <div className="item result"><p style={{ color: '#1BFF00' }}>$ {value.USD}</p></div>
                            <div className="item result"><p style={{ color: '#003399' }}>€ {value.EUR}</p></div>
                            <div className="item result"><p style={{ color: '#CC3333' }}>£ {value.GBP}</p></div>
                        </div>
                    ))}
                </div>

                {/* Carrusel de NFTs al lado derecho */}
                <div style={{ width: '38%', minHeight: '300px' }}>
                    <CarrucelNFT />
                </div>
            </div>


            <BitcoinChart />
            <EthereumChart />
            <USDTEChart />

            <hr />
            <div className="welcomeMessage">Niveles de Referentes Superiores</div>
            <HorizontalLine />
            <hr />


            <div style={{ display: 'flex', flexDirection: 'row', width: '98%' }}>


                <div style={{ flex: 1 }}>
                    <table
                        style={{
                            width: '100%',
                            borderCollapse: 'separate',
                            borderSpacing: '10px', // espacio entre celdas
                            border: 'none'
                        }}
                    >
                        <thead>
                            <tr>
                                <th
                                    style={{
                                        width: '10%',
                                        padding: '15px', // espacio extra para estética
                                        textAlign: 'center',
                                        fontWeight: 'bold',
                                        borderRadius: '10px', // bordes redondeados
                                        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)', // sombra ligera
                                        backgroundColor: '#0a5364', // gris para el encabezado
                                        color: 'white', // letras blancas
                                        fontSize: '18px',
                                        border: 'none', // elimina el contorno negro
                                    }}
                                >
                                    Nivel
                                </th>
                                <th
                                    style={{
                                        width: '80%',
                                        padding: '15px', // espacio extra para estética
                                        textAlign: 'center',
                                        fontWeight: 'bold',
                                        borderRadius: '10px', // bordes redondeados
                                        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)', // sombra ligera
                                        backgroundColor: '#0a5364', // gris para el encabezado
                                        color: 'white', // letras blancas
                                        fontSize: '24px',
                                        border: 'none', // elimina el contorno negro
                                    }}
                                >
                                    Billeteras de referentes
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.entries(levelReferrals).map(([level, address], index) => (
                                <tr
                                    key={index}
                                    style={{
                                        backgroundColor: getBackgroundColor(index),
                                        textAlign: 'center',
                                        fontWeight: 'bold',
                                        borderRadius: '10px', // bordes redondeados
                                        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)', // sombra ligeras
                                        color: '#0A5364', // color del texto
                                        border: 'none', // elimina el contorno negro
                                    }}
                                >
                                    <td style={{ padding: '15px', borderRadius: '10px' }}>{level}</td>
                                    <td style={{ padding: '15px', borderRadius: '10px' }}>{address}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>








                <div style={{ flex: 1 }}>
                    <Canvas>
                        <SphereToPlaneWithRotation />
                    </Canvas>
                </div>
            </div>



            <div className="welcomeMessage" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '98%' }}>
                <div>Niveles de Referidos</div>
                <button onClick={updateMatchingReferrals} style={{ padding: '10px' }}>
                    <FontAwesomeIcon
                        icon={faSyncAlt} // Icono para el botón
                        style={{ color: 'white', fontSize: '24px' }}
                    />
                    Vizualizar
                </button>
            </div>

            <HorizontalLine />

            <div style={{ border: 'none', borderRadius: '10px', padding: '10px' }}>

                <table
                    style={{
                        borderCollapse: 'separate',
                        borderSpacing: '5px',
                        width: '98%',
                        textAlign: 'center',
                        color: 'white',
                        fontWeight: 'bold',
                        border: 'none',
                    }}
                >
                    <thead>
                        <tr style={{ backgroundColor: '#0A5364', textAlign: 'center' }}>
                            <th style={{ borderRadius: '10px', padding: '5px', fontSize: '18px', width: '5%' }}>
                                <FontAwesomeIcon
                                    icon={faTurnUp}
                                    style={{ color: 'white', fontSize: '24px' }}
                                />
                            </th>
                            <th style={{ borderRadius: '10px', padding: '5px', fontSize: '18px', width: '15%' }}>Nivel</th>
                            <th style={{ borderRadius: '10px', padding: '5px', fontSize: '18px', width: '40%' }}>Dirección del referido</th>
                            <th style={{ borderRadius: '10px', padding: '5px', fontSize: '18px', width: '40%' }}>Referido por</th>
                        </tr>
                    </thead>
                    <tbody>
                        {matchingReferrals.map((referral, index) => (
                            <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#BAD0D4' : '#7AA2AA' }}>
                                <td style={{ borderRadius: '10px', padding: '5px', fontSize: '16px' }}>{index + 1}</td>
                                <td style={{ borderRadius: '10px', padding: '5px', fontSize: '16px' }}>
                                    {referral.level}
                                </td>
                                <td style={{ borderRadius: '10px', padding: '5px', fontSize: '16px', wordWrap: 'break-word' }}>
                                    {referral.userAddress}
                                </td>
                                <td style={{ borderRadius: '10px', padding: '5px', fontSize: '16px', wordWrap: 'break-word' }}>
                                    {referral.address}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

            </div>





            <div className="welcomeMessage">Transaciones</div>
            <HorizontalLine />
        </div>
    );
}
