import React from 'react';
import Layout from "../../components/layout/Layout"

function ReferralSystem() {
    const style = {
        table: {
            width: '98%',
            marginBottom: '20px',
            borderCollapse: 'separate',
            marginTop:'20px',
            borderSpacing: '10px', // Espacio entre celdas
            border: "none", // Sin bordes en la tabla
            marginLeft: 'auto',  // Centra la tabla horizontalmente
            marginRight: 'auto', // Centra la tabla horizontalmente
            textAlign: 'center', // Alineación del texto en las celdas al centro
        },
        th: {
            backgroundColor: '#0A5364',
            color: 'white',
            padding: '10px',
            borderRadius: '10px', // Bordes redondeados para los encabezados
            borderBottom: '1px solid black'
        },
        td: {
            backgroundColor: '#4a919c',
            color: 'white',
            padding: '10px',
            borderRadius: '10px', // Bordes redondeados para las celdas
            borderBottom: '1px solid gray'
        },
        tableContainer: {
            background: 'white',
            marginTop:'5%',
            padding: '20px',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)', // Sombra para el contenedor de la tabla
            display: 'flex',  // Usa flexbox para alinear elementos
            justifyContent: 'center', // Centra los elementos horizontalmente en el contenedor
            flexDirection: 'column', // Alinea los elementos en una columna
            alignItems: 'center' // Centra los elementos verticalmente en el contenedor
        }
    };
    

    return (
        <Layout>
            <div style={style.tableContainer}>
                <h1 style={{ textAlign: 'center',color:'black' }}>Sistema de Ganancias por Referido</h1>

                <h2 style={{color:'black'}}>Métodos de Registro</h2>
                <table style={style.table}>
                    <thead>
                        <tr>
                            <th style={style.th}>Método de Registro</th>
                            <th style={style.th}>Descripción</th>
                            <th style={style.th}>Costo</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={style.td}>Con código hash y wallet</td>
                            <td style={style.td}>Pago del gas de la red Ethereum para registrarse.</td>
                            <td style={style.td}>Gas de red ETH</td>
                        </tr>
                        <tr>
                            <td style={style.td}>Solo con wallet</td>
                            <td style={style.td}>Compra de uno de los cinco paquetes disponibles.</td>
                            <td style={style.td}>Depende del paquete</td>
                        </tr>
                        <tr>
                            <td style={style.td}>Sin invitación</td>
                            <td style={style.td}>Registro comprando un paquete.</td>
                            <td style={style.td}>Depende del paquete</td>
                        </tr>
                    </tbody>
                </table>

                <h2 style={{color:'black'}}>Paquetes Disponibles</h2>
                <table style={style.table}>
                    <thead>
                        <tr>
                            <th style={style.th}>Paquete</th>
                            <th style={style.th}>Costo (USD)</th>
                            <th style={style.th}>Códigos de Invitación</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr><td style={style.td}>Paquete 1</td><td style={style.td}>390</td><td style={style.td}>1</td></tr>
                        <tr><td style={style.td}>Paquete 2</td><td style={style.td}>1,950</td><td style={style.td}>5</td></tr>
                        <tr><td style={style.td}>Paquete 3</td><td style={style.td}>3,900</td><td style={style.td}>10</td></tr>
                        <tr><td style={style.td}>Paquete 4</td><td style={style.td}>5,800</td><td style={style.td}>15</td></tr>
                        <tr><td style={style.td}>Paquete 5</td><td style={style.td}>25,000</td><td style={style.td}>60</td></tr>
                    </tbody>
                </table>

                <h2 style={{color:'black'}}>Ganancias por Invitación Directa</h2>
                <p style={{color:'black'}}>Cuando invitas a alguien usando tu wallet, ganas automáticamente un porcentaje del costo del paquete que la persona adquiere.</p>
                <table style={style.table}>
                    <thead>
                        <tr>
                            <th style={style.th}>Paquete Adquirido por Invitado</th>
                            <th style={style.th}>% de Ganancia para el Invitador</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr><td style={style.td}>Cualquiera</td><td style={style.td}>25%</td></tr>
                    </tbody>
                </table>

                <h2 style={{color:'black'}}>Distribución de Ganancias por Paquete</h2>
                <p style={{color:'black'}}>Las ganancias se distribuyen en diferentes niveles según el paquete comprado por la persona invitada.</p>
                <table style={style.table}>
                    <thead>
                        <tr>
                            <th style={style.th}>Paquete</th>
                            <th style={style.th}>Costo (USD)</th>
                            <th style={style.th}>Nivel 1</th>
                            <th style={style.th}>Nivel 2</th>
                            <th style={style.th}>Nivel 3</th>
                            <th style={style.th}>Nivel 4</th>
                            <th style={style.th}>Nivel 5</th>
                            <th style={style.th}>Nivel 6</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr><td style={style.td}>1</td><td style={style.td}>390</td><td style={style.td}>20%</td><td style={style.td}>15%</td><td style={style.td}>10%</td><td style={style.td}>-</td><td style={style.td}>-</td><td style={style.td}>-</td></tr>
                        <tr><td style={style.td}>2</td><td style={style.td}>1,950</td><td style={style.td}>30%</td><td style={style.td}>15%</td><td style={style.td}>10%</td><td style={style.td}>5%</td><td style={style.td}>-</td><td style={style.td}>-</td></tr>
                        <tr><td style={style.td}>3</td><td style={style.td}>3,900</td><td style={style.td}>30%</td><td style={style.td}>15%</td><td style={style.td}>10%</td><td style={style.td}>5%</td><td style={style.td}>-</td><td style={style.td}>-</td></tr>
                        <tr><td style={style.td}>4</td><td style={style.td}>5,800</td><td style={style.td}>30%</td><td style={style.td}>15%</td><td style={style.td}>10%</td><td style={style.td}>5%</td><td style={style.td}>-</td><td style={style.td}>-</td></tr>
                        <tr><td style={style.td}>5</td><td style={style.td}>25,000</td><td style={style.td}>30%</td><td style={style.td}>15%</td><td style={style.td}>10%</td><td style={style.td}>5%</td><td style={style.td}>3%</td><td style={style.td}>2%</td></tr>
                    </tbody>
                </table>
            </div>
        </Layout>
    );
}

export default ReferralSystem;
