// constants/colors.js

export const TURQUOISE = '#0A5364';
export const BLUE='#77ADB2';
export const WHITE='#FFF';
export const YELLOW='#D4AE42';
export const YELLOW_DARK='#DAA520';
export const BLUE_LIGHT='#80B0B7';
export const GRAY='#F5F5F5';
export const BLACK='#000000';

