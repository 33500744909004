import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import axios from 'axios';

// Importa aquí otros recursos necesarios, como Web3 si lo vas a utilizar.

const url = "https://min-api.cryptocompare.com/data/pricemulti?fsyms=BTC,ETH,LTC&tsyms=USD,EUR,GBP";

const contractAddress = require('../../contractsData/RegistrationWallet-address.json').address;
const contractABI = require('../../contractsData/RegistrationWallet.json');

const packagePricesExample = {
    '1': 390, // Precio en USD para el paquete 1
    '2': 1950, // Precio en USD para el paquete 2
    '3': 3900, // Precio en USD para el paquete 3
    '4': 5800, // Precio en USD para el paquete 4
    '5': 25000  // Precio en USD para el paquete 5
};

const WalletRegisterForm = () => {
    const [web3, setWeb3] = useState(null);
    const [account, setAccount] = useState(null);
    const [walletAddress, setWalletAddress] = useState('');
    const [selectedPackage, setSelectedPackage] = useState('1');
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const [packagePrices, setPackagePrices] = useState({});
    const [ethPriceInUSD, setEthPriceInUSD] = useState(null);

    useEffect(() => {
        if (window.ethereum) {
            const web3Instance = new Web3(window.ethereum);
            setWeb3(web3Instance);
            window.ethereum.request({ method: 'eth_requestAccounts' })
                .then(accounts => {
                    setAccount(accounts[0]);
                }).catch(error => {
                    console.error('Error requesting accounts:', error);
                });
        } else {
            console.error('MetaMask is not installed!');
            setAccount(null);
            setWeb3(null);
        }

        // Función para actualizar los precios
        const updatePrices = () => {
            axios.get(url).then(response => {
                // Asume que la respuesta contiene la tasa de cambio de ETH a USD
                const ethPrice = response.data.ETH.USD;
                setEthPriceInUSD(ethPrice);
            }).catch(error => {
                console.error("Error fetching data:", error);
            });
        };

        setPackagePrices(packagePricesExample);

        updatePrices(); // Actualiza inmediatamente al cargar
        const timer = setInterval(updatePrices, 60000); // Actualiza cada minuto

        return () => clearInterval(timer); // Limpia el intervalo cuando el componente se desmonta
    }, []);

    const handleWalletRegister = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setMessage('');
        setMessageType('');

        if (!web3 || !account || !ethPriceInUSD) {
            console.error('Web3 is not initialized, account not found, or ETH price is not available.');
            setMessage('Error: revisa que todos los datos necesarios estén disponibles.');
            setMessageType('error');
            setIsLoading(false);
            return;
        }


        // Obtener el precio del paquete seleccionado desde el estado
        const price = packagePrices[selectedPackage];
        if (!price) {
            console.error('Precio del paquete no encontrado.');
            setMessage('Error: Precio del paquete no encontrado.');
            setMessageType('error');
            setIsLoading(false);
            return;
        }

        const usdPrice = packagePrices[selectedPackage];

        if (!usdPrice) {
            console.error('Precio del paquete no encontrado.');
            setMessage('Error: Precio del paquete no encontrado.');
            setMessageType('error');
            setIsLoading(false);
            return;
        }

        // Asegúrate de que ethPrice es un número y es mayor que cero para evitar divisiones por cero
        const priceInEth = usdPrice / ethPriceInUSD;
        console.log(priceInEth)

        const priceInWei = web3.utils.toWei(priceInEth.toString(), 'ether');
        const packageId = selectedPackage;


        const contract = new web3.eth.Contract(contractABI, contractAddress);

        try {

            const gasEstimate = await contract.methods.buyPackage(packageId, walletAddress).estimateGas({
                from: account
            });


            await contract.methods.buyPackage(packageId, walletAddress).send({ from: account, value: priceInWei, gas: gasEstimate });
            setMessage('Registro exitoso. Bienvenido a la dApp!');
            setMessageType('success');
            console.log('Registro realizado con éxito');
        } catch (error) {
            console.error('Error al realizar el registro:', error);
            setMessage(`Error en el registro: ${error.message}`);
            setMessageType('error');
        } finally {
            setIsLoading(false);
        }
    };



    return (
        <form className="form-container" onSubmit={handleWalletRegister}>
            <div>
                <label htmlFor="walletAddress" style={{ color: 'white', textShadow: '2px 2px 4px black' }}>Dirección de Wallet:</label>
                <input
                    id="walletAddress"
                    type="text"
                    pattern="^0x[a-fA-F0-9]{40}$"
                    title="La dirección debe ser una dirección Ethereum válida (incluyendo el '0x')."
                    value={walletAddress}
                    onChange={(e) => setWalletAddress(e.target.value)}
                    required
                    className="form-input"
                />
            </div>
            <div>
                <label htmlFor="packageSelect" style={{ color: 'white', textShadow: '2px 2px 4px black' }}>Seleccionar Paquete:</label>
                <select
                    id="packageSelect"
                    value={selectedPackage}
                    onChange={(e) => setSelectedPackage(e.target.value)}
                    required
                    className="form-input"
                >
                    <option value="1">KIT 1 - $390</option>
                    <option value="2">KIT 2 - $1,950</option>
                    <option value="3">KIT 3 - $3,900</option>
                    <option value="4">KIT 4 - $5,800</option>
                    <option value="5">KIT 5 - $25,000</option>
                </select>

            </div>
            <button
                type="submit"
                disabled={isLoading || !walletAddress}
                className="form-button-yellow"
            >
                {isLoading ? 'Conectando...' : 'Registrarse con Wallet'}
            </button>
            {message && (
                <p className={`message ${messageType === 'success' ? 'message-success' : 'message-error'}`}>
                    {message}
                </p>
            )}
        </form>
    );
};

export default WalletRegisterForm;