import { useEffect, useState } from "react";

export default function BackToTop() {
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    
    // Limpia el evento al desmontar el componente
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []); // Agrega un array de dependencias vacío para ejecutar el efecto solo una vez al montar el componente

  const onScroll = () => {
    // Cambia la lógica para evitar cambios innecesarios en el estado
    const scrolled = window.scrollY > 100;
    if (scrolled !== hasScrolled) {
      setHasScrolled(scrolled);
    }
  };

  const scrollToTop = () => {
    // Implementa la lógica para desplazarse hacia arriba
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <>
      {hasScrolled && (
        <a className="progress-wrap active-progress" href="#__next" onClick={scrollToTop}>
          <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" style={{ transition: 'stroke-dashoffset 10ms linear 0s', strokeDasharray: '505.919, 505.919', strokeDashoffset: '200.879' }} />
          </svg>
        </a>
      )}
    </>
  );
}
