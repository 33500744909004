import Layout from "../components/layout/Layout"
import BlogList from '../components/reusable/BlogList'


const currentDate = new Date();
const day = currentDate.getDate();
const month = currentDate.getMonth() + 1; // Nota: Los meses en JavaScript son indexados desde 0, por lo que sumamos 1.
const year = currentDate.getFullYear();
let date = `${day}/${month}/${year}`
const autor = 'Coach Blockchain'
const create = '"Formación"'

const pexel = (id) =>
    `https://raw.githubusercontent.com/MarquezFigueroa/Images/master/${id}.jpg`;

const avatar = "https://raw.githubusercontent.com/coachblockchain/Images/main/COACH%20ORO.png"
const blogPosts = [
  /*  {
        id: 1,
        image: pexel(1),
        category: create,
        date: date,
        title: "Introducción al Blockchain: Fundamentos y Conceptos Básicos",
        detailsLink: "/curso-basico",
        authorAvatar: avatar,
        authorName: autor,
        authorLink: "/panel",
        arrowLink: "/curso-basico"
    },*/

    {
        id: 2,
        image: 'https://firebasestorage.googleapis.com/v0/b/coashblockchain.appspot.com/o/imagenes_load%2F44a38e95297cdb028a145dabdecc1e98.gif?alt=media&token=4521749a-b1d4-40c5-92a0-fb23c2057eda',
        category: create,
        date: date,
        title: "Historia del dinero",
        detailsLink: "/historia-dinero",
        authorAvatar: avatar,
        authorName: autor,
        authorLink: "/panel",
        arrowLink: "/historia-dinero"
    },

    {
        id: 5,
        image: 'https://firebasestorage.googleapis.com/v0/b/coashblockchain.appspot.com/o/imagenes_load%2F36e4d0b856694fc471344b644a1dd6e4.gif?alt=media&token=a79eb10c-f4c4-47e8-8f04-a6f97fa2a3be',
        category: create,
        date: date,
        title: "Introducción a la Blockchain fundamentos y conceptos básicos",
        detailsLink: "/introducion-blockchain",
        authorAvatar: avatar,
        authorName: autor,
        authorLink: "/panel",
        arrowLink: "/introducion-blockchain"
    },

    
    {
        id: 3,
        image: 'https://firebasestorage.googleapis.com/v0/b/coashblockchain.appspot.com/o/imagenes_load%2F7990ec9d485a1f948269e47a3a1971fd.gif?alt=media&token=7621da24-011e-4f20-ae7c-f14f4ed1ca3d',
        category: create,
        date: date,
        title: "Blockchain en la Industria",
        detailsLink: "/blockchain-industria",
        authorAvatar: avatar,
        authorName: autor,
        authorLink: "/panel",
        arrowLink: "/blockchain-industria"
    },

    {
        id: 3,
        image: 'https://firebasestorage.googleapis.com/v0/b/coashblockchain.appspot.com/o/imagenes_load%2F61c5063eacf556af837c7a9e0158d955.gif?alt=media&token=da78d0dc-07ce-430a-960a-2fed5c2f46c6',
        category: create,
        date: date,
        title: "Bitcoin un antes y un después en la historia del dinero",
        detailsLink: "/btc-revolucion",
        authorAvatar: avatar,
        authorName: autor,
        authorLink: "/panel",
        arrowLink: "/btc-revolucion"
    },


    {
        id: 3,
        image: 'https://firebasestorage.googleapis.com/v0/b/coashblockchain.appspot.com/o/imagenes_load%2Ff6b80656b83f523c952731d895f84f03.gif?alt=media&token=e5d431ac-fd7d-4805-9c23-b1b7d736048f',
        category: create,
        date: date,
        title: "Criptomonedas y Tokens",
        detailsLink: "/crypto",
        authorAvatar: avatar,
        authorName: autor,
        authorLink: "/panel",
        arrowLink: "/crypto"
    },


    {
        id: 3,
        image: 'https://firebasestorage.googleapis.com/v0/b/coashblockchain.appspot.com/o/imagenes_load%2F61c0abb970239efc960ae9364fd4c09d.gif?alt=media&token=585088de-10d7-4a92-868d-1d46dfef2137',
        category: create,
        date: date,
        title: "Introducción a la Tecnología Blockchain",
        detailsLink: "/tecnologia-blockchain",
        authorAvatar: avatar,
        authorName: autor,
        authorLink: "/panel",
        arrowLink: "/tecnologia-blockchain"
    },

    {
        id: 3,
        image: 'https://firebasestorage.googleapis.com/v0/b/coashblockchain.appspot.com/o/imagenes_load%2F56bda6acfa173675cd190c9c36357800.gif?alt=media&token=21da02ec-894c-4747-9854-093e85aa7b72',
        category: create,
        date: date,
        title: "Minería del Blockchain",
        detailsLink: "/mineria",
        authorAvatar: avatar,
        authorName: autor,
        authorLink: "/panel",
        arrowLink: "/mineria"
    },

 


    {
        id: 3,
        image: 'https://firebasestorage.googleapis.com/v0/b/coashblockchain.appspot.com/o/imagenes_load%2F7f7f2882899755a705a2953b6fcfc263.gif?alt=media&token=d5cf4c0f-bd70-41de-92e8-6b1489fff756',
        category: create,
        date: date,
        title: "Criptografía en Blockchain",
        detailsLink: "/criptografia",
        authorAvatar: avatar,
        authorName: autor,
        authorLink: "/panel",
        arrowLink: "/criptografia"
    },

    {
        id: 3,
        image: 'https://firebasestorage.googleapis.com/v0/b/coashblockchain.appspot.com/o/imagenes_load%2F002d7e638fb463fb7a266f5ffc7ac47d.gif?alt=media&token=6b5cc3d7-5d18-4882-8558-df4dfae7fe61',
        category: create,
        date: date,
        title: "Criptografía Asimétrica y las Claves Públicas y Privadas",
        detailsLink: "/criptografia-asimetrica",
        authorAvatar: avatar,
        authorName: autor,
        authorLink: "/panel",
        arrowLink: "/criptografia-asimetrica"
    },

    {
        id: 3,
        image: 'https://firebasestorage.googleapis.com/v0/b/coashblockchain.appspot.com/o/imagenes_load%2F1396ed77fdd5296495cccd2b9c8d29e9.gif?alt=media&token=4273594f-0f0d-4e50-ae92-1fb3aeb4048b',
        category: create,
        date: date,
        title: "Explorando Hash y SHA-256",
        detailsLink: "/hash",
        authorAvatar: avatar,
        authorName: autor,
        authorLink: "/panel",
        arrowLink: "/hash"
    },

    {
        id: 3,
        image:'https://changelly.com/blog/wp-content/uploads/2020/01/merkle-tree-in-depth.png',
        category: create,
        date: date,
        title: "Árboles de Merkle en Blockchain",
        detailsLink: "/smarkle-tree",
        authorAvatar: avatar,
        authorName: autor,
        authorLink: "/panel",
        arrowLink: "/smarkle-tree"
    },


    {
        id: 3,
        image:'https://firebasestorage.googleapis.com/v0/b/coashblockchain.appspot.com/o/imagenes_load%2F3cfade47a785d1c5de43abb841e6e0bb.gif?alt=media&token=c483acb5-cdd5-4b86-9674-819f36f7fea3',
        category: create,
        date: date,
        title: "Introducción a Wallets con Blockchain",
        detailsLink: "/wallets",
        authorAvatar: avatar,
        authorName: autor,
        authorLink: "/panel",
        arrowLink: "/wallets"
    },









    {
        id: 3,
        image: "https://moneymorning.com/wp-content/blogs.dir/1/files/2021/04/shutterstock_1936167706-featured.jpg",
        category: create,
        date: date,
        title: "NFT",
        detailsLink: "/nft-formacion",
        authorAvatar: avatar,
        authorName: autor,
        authorLink: "/panel",
        arrowLink: "/nft-formacion"
    },

    {
        id: 3,
        image: 'https://firebasestorage.googleapis.com/v0/b/coashblockchain.appspot.com/o/imagenes_load%2Fc7c6f7e8b3506ea46261ab7b55fc9faf.gif?alt=media&token=12182c1e-ded3-4fbc-91e1-e2b79998de86',
        category: create,
        date: date,
        title: "Contratos Inteligentes",
        detailsLink: "/smart-contracts",
        authorAvatar: avatar,
        authorName: autor,
        authorLink: "/panel",
        arrowLink: "/smart-contracts"
    },

    
    {
        id: 3,
        image: 'https://firebasestorage.googleapis.com/v0/b/coashblockchain.appspot.com/o/imagenes_load%2Face605d0df931a39dbeb7a0bc7f62b6d.gif?alt=media&token=dd517a67-0424-4b6b-9975-6f8ac545289a',
        category: create,
        date: date,
        title: "Dapp",
        detailsLink: "/dapp",
        authorAvatar: avatar,
        authorName: autor,
        authorLink: "/panel",
        arrowLink: "/dapp"
    },

    {
        id: 4,
        image: 'https://i0.wp.com/adefinitivas.com/wp-content/uploads/2020/12/blockchain.jpg?fit=1920%2C1080&ssl=1',
        category: create,
        date: date,
        title: "Aspectos Legales y Regulatorios del blockchain",
        detailsLink: "/aspectos-legales",
        authorAvatar: avatar,
        authorName: autor,
        authorLink: "/panel",
        arrowLink: "/aspectos-legales"
    },
  



    {
        id: 4,
        image: 'https://cdn.mos.cms.futurecdn.net/9EieB365kjRPqo9kL2TWBg.jpg',
        category: create,
        date: date,
        title: "Finanzas descentralizadas DEFI sus beneficios y utilidades",
        detailsLink: "/defi",
        authorAvatar: avatar,
        authorName: autor,
        authorLink: "/panel",
        arrowLink: "/defi"
    },

    {
        id: 6,
        image: 'https://www.carreras.pe/userfiles/images/Blog/examendeadmision.jpg',
        category: "examen",
        date: date,
        title: "El examen de formación ",
        detailsLink: "/formation-exam",
        authorAvatar: avatar,
        authorName: autor,
        authorLink: "/formation-exam",
        arrowLink: "/formation-exam"
    },
    {
        id: 7,
        image: 'https://firebasestorage.googleapis.com/v0/b/coashblockchain.appspot.com/o/imagenes_load%2F881563d6444b370fa4ceea0c3183bb4c.gif?alt=media&token=25dff4c1-e2e2-4384-a2b7-90a8fb9fffde',
        category: "Formación",
        date: date,
        title: "Diplomado en Tecnologías Blockchain ",
        detailsLink: "/blockchain-consultancy",
        authorAvatar: avatar,
        authorName: autor,
        authorLink: "/blockchain-consultancy",
        arrowLink: "/blockchain-consultancy"
    }
  


 
   
    
];

export default function Home() {

    return (
        <>

            <Layout headerStyle={1} footerStyle={1}>
            
                <div style={{marginTop:'10%'}}>

                    <div className="tf-section tf-grid-blog pb-80">
                        <div className="themesflat-container">

                            <BlogList blogPosts={blogPosts} />




                        </div>
                    </div>
                </div>

            </Layout>
        </>
    )
}