import Layout from "../../components/layout/Layout";
import { useState } from "react";

const textStyle = {
    fontSize: '16px',
    color: '#5B808A',
    fontFamily: 'Arial, sans-serif',
    textAlign: 'justify', // Añadido para justificar el texto
    lineHeight: '1.6', // Añadido para interlineado
};
const listStyle = {
    fontSize: '16px',
    color: '#5B808A',
    fontFamily: 'Arial, sans-serif',
    textAlign: 'justify', // Añadido para justificar el texto
};

export default function Home() {
    const [activeIndex, setActiveIndex] = useState(1);
    const handleOnClick = (index) => {
        setActiveIndex(index);
    };

    const getCurrentDate = () => {
        const today = new Date();
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return today.toLocaleDateString('es-ES', options);
    };

    return (
        <>
            <Layout headerStyle={1} footerStyle={1}>
                <div>
                    <div className="flat-title-page">
                        <div className="themesflat-container">
                            <div className="row">
                                <div className="col-12">
                                    <h1 className="heading text-center">Términos de servicio</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tf-section-2 widget-term-condition">
                        <div className="themesflat-container">
                            <div className="row flat-tabs">
                                <div className="col-md-3 col-12">
                                    <div className="wrap-menu po-sticky">
                                        <div className="title">Menú</div>
                                        <ul className="menu-tab">
                                            <li className={activeIndex === 1 ? "item-title active" : "item-title"} onClick={() => handleOnClick(1)}>
                                                <span className="inner">1. Métodos de Registro</span>
                                            </li>
                                            <li className={activeIndex === 2 ? "item-title active" : "item-title"} onClick={() => handleOnClick(2)}>
                                                <span className="inner">2. Paquetes Disponibles</span>
                                            </li>
                                            <li className={activeIndex === 3 ? "item-title active" : "item-title"} onClick={() => handleOnClick(3)}>
                                                <span className="inner">3. Ganancias por Invitación Directa</span>
                                            </li>
                                            <li className={activeIndex === 4 ? "item-title active" : "item-title"} onClick={() => handleOnClick(4)}>
                                                <span className="inner">4. Distribución de Ganancias por Paquete</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-md-9 col-12">
                                    <div className="content-tab po-sticky-footer">
                                        <div className="content-inner" style={{ display: `${activeIndex === 1 ? "block" : "none"}` }}>
                                            <div className="date">Última actualización: {getCurrentDate()}</div>
                                            <h2 style={{ color: '#5B808A' }}>Métodos de Registro</h2>
                                            <p style={textStyle}>
                                                Existen tres métodos principales para registrarse en nuestro sistema de referidos:
                                            </p>
                                            <ul style={listStyle}>
                                                <li><strong>Con código hash y wallet:</strong> Este método implica el pago del gas de la red Ethereum para registrarse.</li>
                                                <li><strong>Solo con wallet:</strong> Consiste en la compra de uno de los cinco paquetes disponibles.</li>
                                                <li><strong>Sin invitación:</strong> Registro comprando un paquete sin necesidad de un código de invitación.</li>
                                            </ul>
                                        </div>

                                        <div className="content-inner" style={{ display: `${activeIndex === 2 ? "block" : "none"}` }}>
                                            <div className="date">Última actualización: {getCurrentDate()}</div>
                                            <h2 style={{ color: '#5B808A' }}>Paquetes Disponibles</h2>
                                            <p style={textStyle}>
                                                Ofrecemos cinco paquetes con diferentes costos y beneficios en términos de códigos de invitación:
                                            </p>
                                            <ul style={listStyle}>
                                                <li><strong>Paquete 1:</strong> Costo de 390 USD, incluye 1 código de invitación y acceso al curso básico "Introducción al Blockchain".</li>
                                                <li><strong>Paquete 2:</strong> Costo de 1,950 USD, incluye 5 códigos de invitación y acceso al curso "Consultoría en Blockchain" de 175 horas.</li>
                                                <li><strong>Paquete 3:</strong> Costo de 3,900 USD, incluye 10 códigos de invitación y acceso al curso "Consultoría en Blockchain" de 175 horas.</li>
                                                <li><strong>Paquete 4:</strong> Costo de 5,800 USD, incluye 15 códigos de invitación y acceso al curso "Consultoría en Blockchain" de 175 horas.</li>
                                                <li><strong>Paquete 5:</strong> Costo de 25,000 USD, incluye 60 códigos de invitación y acceso al curso "Consultoría en Blockchain" de 175 horas.</li>
                                            </ul>
                                        </div>

                                        <div className="content-inner" style={{ display: `${activeIndex === 3 ? "block" : "none"}` }}>
                                            <div className="date">Última actualización: {getCurrentDate()}</div>
                                            <h2 style={{ color: '#5B808A' }}>Ganancias por Invitación Directa</h2>
                                            <p style={textStyle}>
                                                Cuando invitas a alguien utilizando tu wallet, ganas automáticamente un porcentaje del costo del paquete que la persona adquiere. 
                                                Este porcentaje es del 25% para cualquier paquete adquirido por el invitado.
                                            </p>
                                        </div>

                                        <div className="content-inner" style={{ display: `${activeIndex === 4 ? "block" : "none"}` }}>
                                            <div className="date">Última actualización: {getCurrentDate()}</div>
                                            <h2 style={{ color: '#5B808A' }}>Distribución de Ganancias por Paquete</h2>
                                            <p style={textStyle}>
                                                Las ganancias se distribuyen en diferentes niveles según el paquete comprado por la persona invitada:
                                            </p>
                                            <ul style={listStyle}>
                                                <li><strong>Paquete 1 (390 USD):</strong>
                                                    <ul>
                                                        <li>Nivel 1: 20%</li>
                                                        <li>Nivel 2: 15%</li>
                                                        <li>Nivel 3: 10%</li>
                                                    </ul>
                                                </li>
                                                <li><strong>Paquete 2 (1,950 USD):</strong>
                                                    <ul>
                                                        <li>Nivel 1: 30%</li>
                                                        <li>Nivel 2: 15%</li>
                                                        <li>Nivel 3: 10%</li>
                                                        <li>Nivel 4: 5%</li>
                                                    </ul>
                                                </li>
                                                <li><strong>Paquete 3 (3,900 USD):</strong>
                                                    <ul>
                                                        <li>Nivel 1: 30%</li>
                                                        <li>Nivel 2: 15%</li>
                                                        <li>Nivel 3: 10%</li>
                                                        <li>Nivel 4: 5%</li>
                                                    </ul>
                                                </li>
                                                <li><strong>Paquete 4 (5,800 USD):</strong>
                                                    <ul>
                                                        <li>Nivel 1: 30%</li>
                                                        <li>Nivel 2: 15%</li>
                                                        <li>Nivel 3: 10%</li>
                                                        <li>Nivel 4: 5%</li>
                                                    </ul>
                                                </li>
                                                <li><strong>Paquete 5 (25,000 USD):</strong>
                                                    <ul>
                                                        <li>Nivel 1: 30%</li>
                                                        <li>Nivel 2: 15%</li>
                                                        <li>Nivel 3: 10%</li>
                                                        <li>Nivel 4: 5%</li>
                                                        <li>Nivel 5: 3%</li>
                                                        <li>Nivel 6: 2%</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
}
