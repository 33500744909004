import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faForward, faBackward } from '@fortawesome/free-solid-svg-icons';


const parseSRT = (srt) => {
  const subtitles = [];
  const srtLines = srt.split('\n');

  let subtitle = {};
  srtLines.forEach(line => {
      if (!subtitle.id && /^\d+$/.test(line)) {
          subtitle.id = parseInt(line, 10);
      } else if (!subtitle.time && line.includes('-->')) {
          const [start, end] = line.split(' --> ');
          subtitle.time = { start, end };
      } else if (line.trim() === '') {
          if (subtitle.text) {
              subtitles.push(subtitle);
              subtitle = {};
          }
      } else {
          subtitle.text = (subtitle.text ? subtitle.text + '\n' : '') + line.trim();
      }
  });

  return subtitles;
};


const YouTubePlaylist = ({ playlistId }) => {
  const [videos, setVideos] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const iframeRef = useRef(null);

  useEffect(() => {
    const fetchVideos = async () => {
      const API_KEY = 'AIzaSyDT2pSiA_wr7sTPNL0DPwJiahBWZLqTPxQ';
      const playlistResponse = await axios.get(
        `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${playlistId}&maxResults=50&key=${API_KEY}`
      );

      const videoIds = playlistResponse.data.items.map(item => item.snippet.resourceId.videoId).join(',');
      const videosResponse = await axios.get(
        `https://www.googleapis.com/youtube/v3/videos?part=contentDetails&id=${videoIds}&key=${API_KEY}`
      );

      const videosWithDuration = await Promise.all(playlistResponse.data.items.map(async (item, index) => {
        const duration = videosResponse.data.items[index].contentDetails.duration;
        const translatedTitle = await translateText(item.snippet.title, 'es');  // Traducir el título al español
        return {
          ...item,
          snippet: {
            ...item.snippet,
            title: translatedTitle // Usar el título traducido
          },
          duration: formatDuration(duration)
        };
      }));

      setVideos(videosWithDuration);
    };

    fetchVideos();
  }, [playlistId]);

  useEffect(() => {
    const onYouTubeIframeAPIReady = () => {
      const player = new window.YT.Player(iframeRef.current, {
        events: {
          onStateChange: handleVideoStateChange,
        },
      });
    };

    if (!window.YT) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      tag.onload = onYouTubeIframeAPIReady;
      document.body.appendChild(tag);
    } else {
      onYouTubeIframeAPIReady();
    }
  }, [currentIndex]);

  const handleVideoStateChange = (event) => {
    if (event.data === window.YT.PlayerState.ENDED) {
      nextVideo();
    }
    if (event.data === window.YT.PlayerState.PAUSED) {
      nextVideo(); // Si el video se pausa, pasa automáticamente al siguiente
    }
  };

  const handleVideoClick = (index) => {
    setCurrentIndex(index);
    setIsPlaying(true);
  };

  const nextVideo = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % videos.length);
    setIsPlaying(true);
  };

  const prevVideo = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + videos.length) % videos.length);
    setIsPlaying(true);
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      iframeRef.current.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
    } else {
      iframeRef.current.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
    }
    setIsPlaying(!isPlaying);
  };

  const handleForward = () => {
    iframeRef.current.contentWindow.postMessage('{"event":"command","func":"seekBy","args":[10]}', '*'); // Avanzar 10 segundos
  };

  const handleRewind = () => {
    iframeRef.current.contentWindow.postMessage('{"event":"command","func":"seekBy","args":[-10]}', '*'); // Retroceder 10 segundos
  };

  const formatDuration = (duration) => {
    const match = duration.match(/PT(\d+H)?(\d+M)?(\d+S)?/);
    const hours = (parseInt(match[1]) || 0);
    const minutes = (parseInt(match[2]) || 0);
    const seconds = (parseInt(match[3]) || 0);
    return `${hours > 0 ? `${hours}:` : ''}${minutes > 0 ? `${minutes}:` : '0:'}${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  const translateText = async (text, targetLanguage) => {
    try {
      const response = await axios.post('https://translation.googleapis.com/language/translate/v2', {}, {
        params: {
          q: text,
          target: targetLanguage,
          key: 'YOUR_GOOGLE_TRANSLATE_API_KEY'  // Reemplaza con tu API key de Google Translate
        }
      });
      return response.data.data.translations[0].translatedText;
    } catch (error) {
      console.error('Error translating text:', error);
      return text; // Devuelve el texto original si hay un error
    }
  };

  if (videos.length === 0) {
    return <div>Cargando...</div>;
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '90vw', height: '90vh', marginTop: '7%' }}>
      {/* Lista de videos a la izquierda */}
      <div style={{ marginRight: '20px', width: '300px', height: '100%', overflowY: 'auto', marginTop: '0' }}>
        <h4 style={{ fontFamily: 'Verdana, sans-serif', color: 'black' }}>Roadmap de Solidity</h4>
        <ul style={{ listStyleType: 'none', padding: 0, fontFamily: 'Verdana, sans-serif', color: 'black' }}>
          {videos.map((video, index) => (
            <li
              key={index}
              onClick={() => handleVideoClick(index)}
              style={{
                cursor: 'pointer',
                padding: '10px',
                backgroundColor: currentIndex === index ? 'lightblue' : '#fff',
                marginBottom: '5px',
                color: currentIndex === index ? '#007BFF' : 'black',
              }}
            >
              {video.snippet.title} ({video.duration})
            </li>
          ))}
        </ul>
      </div>

      {/* Video actual */}
      <div style={{ flexGrow: 1, position: 'relative', overflow: 'hidden' }}>
        <h3 style={{ fontFamily: 'Verdana, sans-serif', color: 'black', textAlign: 'center' }}>{videos[currentIndex].snippet.title}</h3>
        <div style={{ position: 'relative', paddingTop: '56.25%', overflow: 'hidden' }}>
          <iframe
            ref={iframeRef}
            src={`https://www.youtube.com/embed/${videos[currentIndex].snippet.resourceId.videoId}?enablejsapi=1&autoplay=1&mute=0&controls=0&modestbranding=1&rel=0&showinfo=0&iv_load_policy=3&fs=0&cc_load_policy=1&playsinline=1&disablekb=1&disableRelatedVideos=1`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title={videos[currentIndex].snippet.title}
            style={{
              position: 'absolute',
              top: '-10%',
              left: '0',
              width: '100%',
              height: '105%',
              border: 'none',
              pointerEvents: 'none', // Desactiva cualquier interacción sobre el iframe
            }}
          />
        </div>
        <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <button onClick={prevVideo} style={{ fontFamily: 'Verdana, sans-serif', backgroundColor: '#007BFF', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '5px', marginRight: '10px' }}>
            <FontAwesomeIcon icon={faBackward} />
          </button>
          <button onClick={handleRewind} style={{ fontFamily: 'Verdana, sans-serif', backgroundColor: '#007BFF', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '5px', marginRight: '10px' }}>
            <FontAwesomeIcon icon={faBackward} />
          </button>
          <button onClick={handlePlayPause} style={{ fontFamily: 'Verdana, sans-serif', backgroundColor: isPlaying ? '#dc3545' : '#28a745', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '5px', marginRight: '10px' }}>
            <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
          </button>
          <button onClick={handleForward} style={{ fontFamily: 'Verdana, sans-serif', backgroundColor: '#007BFF', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '5px', marginRight: '10px' }}>
            <FontAwesomeIcon icon={faForward} />
          </button>
          <button onClick={nextVideo} style={{ fontFamily: 'Verdana, sans-serif', backgroundColor: '#007BFF', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '5px' }}>
            <FontAwesomeIcon icon={faForward} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default YouTubePlaylist;
