import React, { useState, useEffect } from 'react';
import { Link, useNavigate, NavLink } from 'react-router-dom';
import { Navbar, Nav, Button, Container, Alert } from 'react-bootstrap';

import { ethers } from 'ethers';
import { Logo, WidthSpacer } from '../../components';
import styled from 'styled-components';
import LanguageSwitcher from '../elements/LanguageSwitcher';
import { useTranslation } from 'react-i18next';




// Estilos para la barra de navegación
const StyledNavbar = styled(Navbar)`
background-color: #0A5364;
height: 18vh;
overflow-y: hidden;
    
`;

// Estilos para los enlaces de navegación activos

const StyledNavLink = styled(NavLink)`
    color: #fff; /* Texto blanco por defecto */
    text-decoration: none; /* Quita el subrayado del texto */

    &:hover {
        color: #D4AE42 !important; /* Texto dorado al pasar el ratón */
    }

    &.active {
        background-color: transparent !important; /* Quita el fondo azul cuando está activo */
        color: #D4AE42 !important; /* Texto dorado cuando está activo */
    }

    span {
        font-size: 15px;
        margin-right: 10px; /* Agrega un margen derecho para separar los enlaces */
    }
`;


const StyledButton = styled(Button)`
   && {
      border: 2px solid #fff;
      border-radius: 20px;
      color: #fff;
      font-size: 12px;
      height: 6vh;
      padding: 10px 20px;
      transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      margin-bottom: 0px; /* Ajusta el margen inferior para separar los botones */
      background-color: transparent; /* Establece un fondo transparente por defecto */
      text-decoration: none; /* Asegura que el texto no esté subrayado */
   }

   &:hover, &:focus {
      background-color: #D4AE42; /* Cambia el fondo a dorado al pasar el ratón o al enfocar */
      color: #000 !important; /* Cambia el texto a negro para contrastar con el fondo dorado */
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Intensifica la sombra para un mayor efecto visual */
      text-decoration: none; /* Asegura que el texto no esté subrayado */
   }

   @media (max-width: 768px) {
      && {
         height: 4vh;
         width: 40vw;
         padding: 5px 10px;
         margin-bottom: 10px; /* Ajusta el margen inferior para separar los botones en dispositivos móviles */
         border: none; /* Elimina el borde en dispositivos móviles */
         background-color: #D4AE42; /* Fondo dorado para coherencia en dispositivos móviles */
         color: #000; /* Texto negro para asegurar la legibilidad */
         text-decoration: none; /* Asegura que el texto no esté subrayado */
      }

      margin-right: auto;
      margin-left: auto;
   }
`;





const MobileSpacing = styled.div`
    @media (max-width: 768px) {
        margin-bottom: 10px; // Ajusta el espacio entre líneas en dispositivos móviles
    }
`;


const contractAddress = require(`../../contractsData/${'DataStorage'}-address.json`).address;
const contractABI = require(`../../contractsData/${'DataStorege-data'}.json`);





export const Navigation = ({ web3Handler, account }) => {
    const { t } = useTranslation();
    const [activeLink, setActiveLink] = useState(null);
    const [isRegister, setIsRegister] = useState(false);
    const [userAddress, setUserAddress] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const navigate = useNavigate();



    const provider = window.ethereum ? new ethers.providers.Web3Provider(window.ethereum) : null;
    const contract = new ethers.Contract(contractAddress, contractABI, provider);


    useEffect(() => {
        const init = async () => {
            if (window.ethereum) {
                // Verifica si MetaMask está instalado y tiene una cuenta conectada
                try {
                    const accounts = await provider.send("eth_requestAccounts", []);
                    if (accounts.length > 0) {
                        const userAddress = accounts[0];
                        setUserAddress(userAddress);
                        loadIsRegister(userAddress); // Llama directamente para verificar si el usuario está registrado
                    }
                } catch (error) {
                    console.error("Error al obtener la dirección del usuario:", error);
                }
    
                const handleAccountsChanged = (accounts) => {
                    if (accounts.length > 0) {
                        const userAddress = accounts[0];
                        setUserAddress(userAddress);
                        loadIsRegister(userAddress); // Verifica el registro nuevamente cuando cambia la cuenta
                    } else {
                        setIsRegister(false); // Asume no registrado si no hay cuentas
                    }
                };
    
                window.ethereum.on('accountsChanged', handleAccountsChanged);
    
                return () => window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
            }
        };
    
        init();
    }, []);

    
     // Asegúrate de que la función loadIsRegister maneje correctamente el estado isRegister
     async function loadIsRegister(address) {
        if (!window.ethereum) {
            console.log('Ethereum object not found, install MetaMask.');
            return;
        }
        try {
            const _isRegister = await contract.isUserRegistered(address);
            setIsRegister(_isRegister);
        } catch (error) {
            console.error("Error al obtener detalles de registro del usuario", error);
        }
    }



    const handleRegistroClick = () => {
        navigate('/registro');
    };

    const handleLoginClick = () => {
        console.log(isRegister)
        if (isRegister === true) {
            navigate('/login');
        } else {
            // Actualiza el estado para mostrar la alerta en lugar de llamar a un método inexistente
            setAlertMessage('Usuario no registrado');
            setShowAlert(true);
            // Si quieres que la alerta desaparezca después de un tiempo, puedes agregar esto:
            setTimeout(() => setShowAlert(false), 2000); // Oculta la alerta después de 2 segundos
            alert('El usuario ' + userAddress + ' no esta registrado')
        }
    };



  

  


    return (
        <>
            {showAlert && (
                <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
                    {alertMessage}
                </Alert>
            )}
            <StyledNavbar expand="lg" variant="dark" className="fixed-top">
                <Container>
                    <Navbar.Brand>
                        <Logo width="110px" height="7vh" />

                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbar navbar-dark bg-primary" />
                    <Navbar.Collapse id="navbar navbar-dark bg-primary">
                        <Nav className="me-auto">
                            <MobileSpacing>
                                <StyledNavLink as={Link} to="/inicio" activeClassName="active" isActive={() => activeLink === '/'}>
                                    <span style={{ fontSize: '14px' }} >{t('inicio')}</span>
                                </StyledNavLink>
                            </MobileSpacing>
                            <MobileSpacing>
                                <StyledNavLink as={Link} to="/comunidad" activeClassName="active">
                                    <span style={{ fontSize: '14px' }}>{t('comunidad')}</span>
                                </StyledNavLink>
                            </MobileSpacing>
                            <MobileSpacing>
                                <StyledNavLink as={Link} to="/explora-nft" activeClassName="active">
                                    <span style={{ fontSize: '14px' }}>{t('nfts')}</span>
                                </StyledNavLink>
                            </MobileSpacing>
                            <MobileSpacing>
                                <StyledNavLink as={Link} to="/detalles" activeClassName="active">
                                    <span style={{ fontSize: '14px' }}>{t('detalles')}</span>
                                </StyledNavLink>
                            </MobileSpacing>
                            <MobileSpacing>
                                <StyledNavLink as={Link} to="/formacion" activeClassName="active">
                                    <span style={{ fontSize: '14px' }}>{t('formacion')}</span>
                                </StyledNavLink>
                            </MobileSpacing>
                            
                            <MobileSpacing>
                                <StyledNavLink as={Link} to="/guia-uso" activeClassName="active">
                                    <span style={{ fontSize: '14px' }}>Guia</span>
                                </StyledNavLink>
                            </MobileSpacing>
                            <MobileSpacing>
                                <StyledNavLink as={Link} to="/sistema-ganancias" activeClassName="active">
                                    <span style={{ fontSize: '14px' }}>Beneficios</span>
                                </StyledNavLink>
                            </MobileSpacing>





                            
                        </Nav>
                        <Nav>
                            {account ? (
                                <Nav.Link
                                    href={`https://etherscan.io/address/${account}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="button nav-button btn-sm mx-4">
                                    <StyledButton variant="outline-light">{account}</StyledButton>
                                </Nav.Link>
                            ) : (
                                <>
                                    <StyledButton onClick={handleRegistroClick} variant="outline-light">
                                        {t('registro')}
                                    </StyledButton>
                                    <WidthSpacer width="10px" />

                                    <StyledButton onClick={handleLoginClick} variant="outline-light">
                                        {t('conectarWallet')}
                                    </StyledButton>


                                    {/*    <StyledButton onClick={web3Handler} variant="outline-light">
                                        {t('conectarWallet')}
                            </StyledButton>*/}
                                </>
                            )}
                            <LanguageSwitcher />
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </StyledNavbar>
        </>
    );
};


