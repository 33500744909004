import React from 'react';
import { useTranslation } from 'react-i18next';
import Switch from 'react-switch';

function LanguageSwitcher() {
  const { t, i18n } = useTranslation();

  const changeLanguage = () => {
    const newLanguage = i18n.language === 'en' ? 'es' : 'en';
    i18n.changeLanguage(newLanguage);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <p style={{ marginRight: '5px',marginLeft:'10px',color:'#C89D38' }}>{t('  EN/ES')}</p>
      <br></br>
      <Switch
        checked={i18n.language === 'es'}
        onChange={changeLanguage}
        onColor="#C89D38"
        offColor="#ccc"
        checkedIcon={false}
        uncheckedIcon={false}
        height={20}
        width={40}
      />
    </div>
  );
}

export default LanguageSwitcher;
