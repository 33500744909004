import React, { useState } from 'react';
import Layout from "../../components/layout/Layout";
import './useguia.css'; // Asegúrate de tener un archivo CSS para estilos
import { HeightSpacer } from '../../components/reusable/HeightSpacer'

const instructions = [
  {
    title: "Registrarte en el sitio web",
    information: "Para registrarte, asegúrate de tener la extensión MetaMask instalada en tu navegador. Si no la tienes, descárgala de la tienda de extensiones. Crea o inicia sesión en tu cuenta de MetaMask. En el sitio web, elige registrarte con MetaMask y sigue las instrucciones para conectar tu billetera. Asegúrate de tener ETH para completar transacciones de blockchain necesarias durante el registro.",
    image: "https://media.geeksforgeeks.org/wp-content/uploads/20210612120413/extension.png"
  },
  {
    title: "Registro y beneficios en la red de Linea Minnest",
    information: "Para unirte a la red de Linea Minnest, inicia sesión con MetaMask. Puedes registrarte realizando una compra: selecciona un paquete entre 2 y 5, y automáticamente recibirás un 20% de tu compra. Este método no solo te registra sino que también te da un bono inicial.",
    image: "https://assets-global.website-files.com/636e894daa9e99940a604aef/65ab335aefb10eec3d85d014_Screen%20Shot%202024-01-20%20at%2011.42.webp" // Cambia esto por el enlace de imagen adecuado.
  },
  {
    title: "Acerca de Linea",
    information: "Linea, una iteración avanzada de ConsenSys zkEVM, es una solución de capa 2 de última generación diseñada para la próxima generación de dapps de Ethereum. Su red de pruebas, abierta a desarrolladores de todo el mundo, hace hincapié en un enfoque orientado a los desarrolladores con una fácil integración en herramientas como MetaMask y Truffle. Utilizando pruebas de conocimiento cero y compatibilidad total con EVM, Linea soporta el desarrollo de aplicaciones escalables y eficientes. Se centra en transacciones más rápidas, tarifas de gas más bajas y seguridad robusta, con el objetivo de impulsar la escalabilidad de Ethereum y unir su legado con el futuro anticipado de Ethereum 2.0.",
    image: "https://assets-global.website-files.com/636e894daa9e99940a604aef/65ab3368bf4e48e2d3762c00_Screen%20Shot%202024-01-20%20at%2011.41.webp" // Cambia esto por el enlace de imagen adecuado.
  },
  {
    title: "Registro mediante compra de hash y billetera MetaMask",
    information: "Otra forma de registrarte es mediante la compra de tu hash y asociación con tu billetera MetaMask. Este método está diseñado para usuarios que ya tienen experiencia con transacciones en criptomonedas y desean un método más directo.",
    image: "https://raw.githubusercontent.com/CoachblockchainDapp/Hitoria-dinero/main/Screenshot%2004-01-2024%2001.11.52.png" // Cambia esto por el enlace de imagen adecuado.
  },
  {
    title: "Registro referido por billetera MetaMask",
    information: "Si eres referido por otro usuario, puedes registrarte proporcionando la dirección de su billetera MetaMask. Necesitarás comprar un paquete para completar el registro. Esta es una gran manera de unirte si conoces a alguien ya dentro de la red.",
    image: "https://github.com/CoachblockchainDapp/Hitoria-dinero/blob/main/Screenshot%2004-01-2024%2001.16.51.png" // Cambia esto por el enlace de imagen adecuado.
  },
  {
    title: "¿Qué hacer si no tengo hash ni código de invitación para registrarme?",
    information: "Si intentas registrarte en el sitio web y no tienes un hash ni un código de invitación, puedes proceder visitando el menú 'Comprar' en el sitio. Desde allí, podrás adquirir el paquete que mejor se ajuste a tus necesidades, lo cual te permitirá completar el proceso de registro. Asegúrate de tener la extensión MetaMask instalada y configurada en tu navegador para realizar las transacciones necesarias.",
    image: "https://raw.githubusercontent.com/CoachblockchainDapp/LogoNFTs/main/Screenshot%2003-10-2024%2012.43.45.png"
  },

  {
    title: "Una vez dentro de la plataforma",
    information: "Dentro de la plataforma, ve a la sección de kits, selecciona uno para comprarlo y completa la transacción. Luego, podrás regalar el kit a otros usando la opción disponible en tu perfil o en la sección de kits, ingresando la dirección de la billetera del destinatario.",
    image: "https://raw.githubusercontent.com/CoachblockchainDapp/LogoNFTs/main/Screenshot%2003-10-2024%2012.44.40.png"
  },
  {
    title: "Cuando compras un kit",
    information: "Con la compra de un kit, obtendrás un código de regalo con usos limitados, especificados al momento de la compra. Comparte el código con quienes desees invitar. Puedes revisar los usos restantes del código en tu perfil. El código se invalidará una vez agotados sus usos.",
    image: "https://raw.githubusercontent.com/CoachblockchainDapp/LogoNFTs/main/Screenshot%2003-10-2024%2012.43.45.png"
  },

  {
    title: "Detrás de escena: ¿Por qué necesitarías MetaMask?",
    information: "Antes de comenzar con la guía de MetaMask, es importante comprender a qué accede con él. Cuando la tecnología blockchain dio a luz a Bitcoin por primera vez en 2009, su blockchain tenía un enfoque estrecho, con el objetivo de ser un sistema de pago de peer-to-peer. Como saben, la criptomoneda funciona sin Estado, sin fronteras, es deflacionaria y de dinero abierto frente al dinero fiduciario controlado, centralizado e inflacionario. Con el tiempo, el desarrollo de Bitcoin se dividió en dos especializaciones: Bitcoin Cash (BCH) para transacciones diarias rápidas y baratas y Bitcoin (BTC) similar al oro digital que tiene transacciones más caras. Mientras tanto, llegaron miles de altcoins, tratando de imitar el éxito de la criptomoneda. Entre ellos, Ethereum (ETH) tenía una filosofía de desarrollo completamente diferente. Ethereum aloja cientos de DApps, para las cuales se necesita ETH. La blockchain Ethereum tenía en mente un caso de uso de propósito general en el momento de la concepción, en lugar de solo facilitar la criptomoneda. Al tener contratos inteligentes ejecutándose dentro de sus bloques de datos, extendió su flexibilidad mucho más allá de un simple sistema de pago. Esto permite formas digitales y automatizadas de contratos legales o financieros, con características descentralizadas y de seguridad de la tecnología blockchain. En esencia, Ethereum creó las finanzas descentralizadas o DeFi. Se trata principalmente de una serie de contratos inteligentes que forman colectivamente una aplicación descentralizada o DApp. Estas DApps permiten digitalizar todos los servicios bancarios que pueda imaginar (como préstamos) sin el sistema bancario en sí. No solo eso, sino que los mercados y los juegos también pueden ejecutarse en Ethereum. Todos usan la criptomoneda nativa de Ethereum, ETH, para pagar varias tarifas. Además, pueden intercambiar ETH por los tokens de las DApps.",
    image: "https://www.investopedia.com/thmb/v5K6POcZQXnjy1MhKNSNTghQ6jM=/fit-in/1500x750/filters:format(png):fill(white):max_bytes(150000):strip_icc()/Metamask_logo-aca547fe6081482085662b03e2235f98.jpg"
  },
  {
    title: "¿Cómo instalar MetaMask?: Una guía paso a paso",
    information: "El primer paso para obtener acceso al rico ecosistema de DApps de Ethereum es descargar la extensión MetaMask del sitio web oficial. Verá soporte para los tres principales sistemas operativos: Windows, iOS y Android. En consecuencia, dependiendo de su plataforma, MetaMask puede ser de escritorio o móvil.",
    image: "https://images.ctfassets.net/9sy2a0egs6zh/6WiN0cAi4TKd2cfBu47Bzv/7eae99133c4150dfeded1bbb0d5ce51e/MM_UI_Updates__Feature_.png"
  },
  {
    title: "1. Primer paso ",
    information: "El primer paso para obtener acceso al rico ecosistema de DApps de Ethereum es descargar la extensión MetaMask del sitio web oficial. Verá soporte para los tres principales sistemas operativos: Windows, iOS y Android. En consecuencia, dependiendo de su plataforma, MetaMask puede ser de escritorio o móvil.",
    image: "https://s32659.pcdn.co/wp-content/uploads/2021/09/install.png"
  },
  {
    title: "2. Intallar Metamask en tus Navegadores  ",
    information: "El primer paso para obtener acceso al rico ecosistema de DApps de Ethereum es descargar la extensión MetaMask del sitio web oficial. Verá soporte para los tres principales sistemas operativos: Windows, iOS y Android. En consecuencia, dependiendo de su plataforma, MetaMask puede ser de escritorio o móvil.",
    image: "https://s32659.pcdn.co/wp-content/uploads/2021/09/install.png"
  },
  {
    title: "3. Una vez instalada en tu navegador ",
    information: "Una vez que haya instalado MetaMask en su navegador favorito, haga clic en el mensaje “Comenzar” para “Crear un monedero",
    image: "https://s32659.pcdn.co/wp-content/uploads/2021/09/new-to-wallet.png"
  },
  {
    title: "4.Frase semilla como punto de restauración de su monedero",
    information: "Después de hacer clic en “Crear un monedero”, se le pedirá que cree una contraseña para la propia app MetaMask. La parte más importante viene después, cuando se le pide que cree una frase de respaldo. También llamada frase inicial, este protector de seguridad de 12 palabras le permite recuperar su monedero incluso si su dispositivo se pierde.Como puede ver, tiene dos opciones: Importar monedero y Crear un monedero. Si ya había perdido su dispositivo y todos los datos que contiene, podrá recuperar sus criptomonedas seleccionando “Importar monedero” con su frase inicial.En consecuencia, intente mantener la frase inicial de 12 palabras como un elemento separado, almacenado de manera segura en una hoja de papel o en un administrador de contraseñas de hardware. Si eres nuevo en el mundo de las criptomonedas, primero lee estos 10 consejos de seguridad de las criptomonedas.Verá esta pantalla en el swap de tokens después de almacenar de forma segura su frase inicial.",
    image: "https://s32659.pcdn.co/wp-content/uploads/2021/09/token-swapping.png"
  },

  {
    title: "5. Mantenga su clave privada segura",
    information: "A continuación, verá la opción “Exportar clave privada”, tanto para verla en Etherscan como para exportarla. Por supuesto, si alguien obtiene acceso a su clave privada, también obtendría acceso a todos sus criptomonedas. De esto se tratan los hacks en los exchanges de criptomonedas. Para la conveniencia de usar un exchange, protege su clave privada, pero si los piratas informáticos las roban, también roban todos los fondos del exchange. Es por eso que siempre debe usar un monedero sin custodia, preferiblemente una de hardware. Afortunadamente, MetaMask admite los monederos de hardware más populares: Trezor y Ledger.",
    image: "https://support.metamask.io/hc/article_attachments/13174140779035"
  },
  {
    title: "6.Cómo agregar fondos a su monedero MetaMask?",
    information: "Sin embargo, antes de que pueda comenzar a intercambiar tokens o cualquier otra cosa con el monedero MetaMask, debe tener fondos. Esto significa que primero debe convertir la moneda fiduciaria a ETH. El monedero MetaMask le ofrece dos opciones: el procesador de pagos Wyre o la transferencia mediante una dirección de blockchain.",
    image: "https://s32659.pcdn.co/wp-content/uploads/2021/09/deposit-ether.png"
  },
  {
    title: "7.Si ya tiene una cuenta con algo de ETH, deposítelo en MetaMask en la sección Enviar/Recibir.",
    information: "erá la sección cuando haga clic en Monedero: Copie la dirección de recepción de su monedero MetaMask.Pega esta dirección de recepción en el cuadro de texto de envío de tu exchange.Ejecute la transferencia de ETH desde su exchange de criptomonedas (enviar) al monedero MetaMask (recibir). Tenga en cuenta que su cuenta de exchange de criptomonedas también es un monedero, pero no posee su clave privada. Por lo tanto, dicho monedero es de custodia. Por el contrario, MetaMask es un monedero sin custodia, con la que posee su clave privada. Puede, en cualquier momento, acceder y exportar su clave privada si hace clic en “Detalles de la cuenta”.",
    image: "https://s32659.pcdn.co/wp-content/uploads/2021/09/account-details.png"
  },
  {
    title: "¿Cómo conectar su monedero MetaMask a las DApps?",
    information: "Recuerde, las DApps en sí mismas son contratos inteligentes que se ejecutan en Ethereum (otras como BSC, Cardano o Solana tienen sus propias DApps). El nivel de superficie de las DApps es su interfaz web. Esto es lo que ve cuando se conecta a Uniswap, uno de los exchanges descentralizados más populares utilizados para la yield farming (agricultura de rendimiento, en español).",
    image: "https://s32659.pcdn.co/wp-content/uploads/2021/09/connect.png"
  },
  {
    title: "¿De qué otra manera puede aprovechar MetaMask?",
    information: "Por último, si desea una experiencia de DApp más ludificada, Axie Infinity es una opción convincente. Desde julio, ha generado 485 millones de dólares en ingresos. Axie Infinity es una aventura de yield farming en la que evolucionas a tus criaturas de fantasía, completas misiones, gestionas reinos e intercambias NFT.",
    image: "https://s32659.pcdn.co/wp-content/uploads/2021/09/axie.png"
  }
];


// Texto para la sección de reseteo de MetaMask
const metamaskResetInfo = {
  title: "Cuándo necesitas resetear tu cuenta de MetaMask",
  information: [
    "Olvido de la frase de recuperación: La frase de recuperación es esencial para restaurar tu cuenta en caso de que pierdas acceso a tu billetera. Si olvidas la frase de recuperación y no tienes acceso a tu cuenta, es necesario resetear la cuenta de MetaMask y generar una nueva frase de recuperación.",
    "Problemas de seguridad: Si sospechas o experimentas un compromiso de seguridad en tu cuenta de MetaMask, es recomendable resetear la cuenta como medida de seguridad.",
    "Transacciones no confirmadas o atascadas: En situaciones donde tus transacciones se quedan pendientes o se atascan en la red Ethereum, puede ser necesario resetear tu cuenta para resolver problemas de sincronización o congestión de red.",
    "Cambio de red o cadena de bloques: Si deseas cambiar de red o cadena de bloques y comenzar desde cero, puede ser conveniente resetear tu cuenta de MetaMask.",
  ],
  image: "https://www.investopedia.com/thmb/v5K6POcZQXnjy1MhKNSNTghQ6jM=/fit-in/1500x750/filters:format(png):fill(white):max_bytes(150000):strip_icc()/Metamask_logo-aca547fe6081482085662b03e2235f98.jpg",
};




const h1Style = {
  fontFamily: 'Univers, sans-serif',
  fontSize: '40px',
  margin: 0,
  textShadow: '2px 2px 2px #94939A',
  lineHeight: '1.5',
  color: '#000000',
};

const UseGuia = () => {
 



  return (
    <Layout headerStyle={1} footerStyle={1}>
      <div className='instructions-container' style={{ color: 'black', margin: '5% 5%' }}>
        <h1 style={{ ...h1Style, color: 'black', fontSize: '40px', textAlign: 'center' }}>Instrucciones para Usuarios:</h1>
        <HeightSpacer height={20} />
        <h1 style={{ ...h1Style, color: 'black', fontSize: '40px', textAlign: 'center' }}>Guía de Instalación de MetaMask</h1>
        <HeightSpacer height={20} />
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
          <iframe
            title="Guía de Instalación de MetaMask"
            src="https://firebasestorage.googleapis.com/v0/b/coashblockchain.appspot.com/o/Uso%20de%20Metamask.mp4?alt=media&token=5e5372e1-c2b8-451e-a6c5-ed227e2493b1"
            frameBorder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{ width: '70%', height: '400px' }}
          />
        </div>
        <HeightSpacer height={20} />

        <h1 style={{ ...h1Style, color: 'black', fontSize: '40px', textAlign: 'center' }}>Video Tutorial de Registro de la Plataforma</h1>
        <HeightSpacer height={20} />
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
          <iframe
            title="Video Tutorial de Registro de la Plataforma"
            src="https://firebasestorage.googleapis.com/v0/b/coashblockchain.appspot.com/o/Guia.mp4?alt=media&token=8f30f905-55ab-4b25-bf24-1c3453dde8a8"
            frameBorder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{ width: '70%', height: '400px' }}
          />
        </div>

        <HeightSpacer height={20} />

        <div className='instructions-list'>
          {instructions.map((instruction, index) => (
            <div key={index} className='instruction-item' style={{ marginBottom: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <h2 style={{ color: 'black', fontSize: '30px', textAlign: 'center' }}>{instruction.title}</h2>
              <HeightSpacer height={20} />
              <p style={{ color: 'black', textAlign: 'left', width: '100%' }}>{instruction.information}</p>
              <img src={instruction.image} alt="Instrucción" style={{ width: '60%', height: 'auto', marginTop: '20px' }} />
            </div>
          ))}
        </div>

        {/* Sección de reseteo de MetaMask */}
        <div
          className="instruction-item"
          style={{
            marginBottom: "40px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h2 style={{ color: "black", fontSize: "3em", textAlign: "center" }}>
            {metamaskResetInfo.title}
          </h2>
          <ol
            style={{
              color: "black",
              textAlign: "left",
              width: "100%",
              paddingInlineStart: "40px",
              fontSize: "1.8em",
              lineHeight: "1.5", // uso correcto de CamelCase
            }}
          >
            {metamaskResetInfo.information.map((info, index) => (
              <li key={index}>{`${index + 1}. ${info}`}</li>
            ))}
          </ol>
          <img
            src={metamaskResetInfo.image}
            alt="Resetear MetaMask"
            style={{ width: "60%", height: "auto", marginTop: "20px" }}
          />
        </div>
      </div>
    </Layout>
  );
};

export default UseGuia;