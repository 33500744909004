import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import questionsData from './questions.json'; // Importa el archivo JSON de preguntas
import './quiz.css'; // Asegúrate de importar tu archivo CSS

const contractAddress = require(`../../contractsData/${'Calificacion'}-address.json`).address;
const contractABI = require(`../../contractsData/${'Calificacion'}.json`);

function shuffle(array) {
  const shuffledArray = [...array];
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
}

function shuffleQuestionsAndAnswers(questions) {
  return questions.map(question => ({
    ...question,
    options: shuffle(question.options)
  }));
}

function Exam() {
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [startTime, setStartTime] = useState(null);
  const [isExamOver, setIsExamOver] = useState(false);
  const [score, setScore] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState(3600);
  const [hasPassedExam, setHasPassedExam] = useState(false);
  const [provider, setProvider] = useState(null);
  const [contract, setContract] = useState(null);

  useEffect(() => {
    const init = async () => {
      if (window.ethereum) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        setProvider(provider);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(contractAddress, contractABI, signer);
        setContract(contract);
      } else {
        console.log("MetaMask no está instalada. Por favor instálala para usar esta aplicación.");
      }
    };

    init();
  }, []);

  useEffect(() => {
    const passedExam = localStorage.getItem('hasPassedExam') === 'true';
    setHasPassedExam(passedExam);
  }, []);

  useEffect(() => {
    if (questionsData.questions && questionsData.questions.length > 0) {
      const randomQuestions = shuffleQuestionsAndAnswers(shuffle(questionsData.questions).slice(0, 45));
      setQuestions(randomQuestions);
      setStartTime(Date.now());
      setTimeRemaining(3600); // 1 hora en segundos
    } else {
      console.error("No hay preguntas disponibles en questionsData");
    }
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      if (startTime) {
        const elapsedTime = Math.floor((Date.now() - startTime) / 1000);
        setTimeRemaining(3600 - elapsedTime);

        if (3600 - elapsedTime <= 0) {
          clearInterval(timer);
          handleSubmit();
        }
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [startTime]);

  const handleSelect = (selectedOption) => {
    setSelectedAnswers({
      ...selectedAnswers,
      [currentQuestion]: selectedOption
    });
  };

  const handleNext = () => {
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      handleSubmit();
    }
  };

  const handlePrevious = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  const handleSubmit = () => {
    const finalScore = calculateScore();
    setScore(finalScore);
    setIsExamOver(true);
    localStorage.setItem('lastExamTime', new Date().toISOString());

    if (finalScore >= 98) {
      setHasPassedExam(true);
      localStorage.setItem('hasPassedExam', 'true');
    }
  };

  const handleRegister = async () => {
    if (contract) {
      try {
        const transaction = await contract.registerWalletAndPassExam();
        await transaction.wait();
        console.log('Registro de examen aprobado en la blockchain.');
      } catch (error) {
        console.error('Error registrando el examen aprobado:', error);
      }
    }
  };

  const calculateScore = () => {
    let score = 0;
    questions.forEach((question, index) => {
      if (selectedAnswers[index] === question.correctAnswer) {
        score += 1;
      }
    });
    return (score / questions.length) * 100;
  };

  const handleRetry = () => {
    const randomQuestions = shuffleQuestionsAndAnswers(shuffle(questionsData.questions).slice(0, 45));
    setQuestions(randomQuestions);
    setSelectedAnswers({});
    setCurrentQuestion(0);
    setStartTime(Date.now());
    setTimeRemaining(3600); // Reinicia el tiempo
    setIsExamOver(false);
    setScore(0);
    localStorage.setItem('lastExamTime', new Date().toISOString());
  };

  const renderQuestion = () => {
    if (currentQuestion < questions.length) {
      const currentQuestionObj = questions[currentQuestion];
      return (
        <div className="card-quiz">
          <div className="card-body">
            <h5 className="card-title">{currentQuestionObj.question}</h5>
            <ul className="list-group list-group-flush">
              {currentQuestionObj.options.map((option, index) => (
                <li
                  key={index}
                  className={`list-group-item ${selectedAnswers[currentQuestion] === option ? 'selected' : ''}`}
                  onClick={() => handleSelect(option)}
                >
                  <label>
                    <input
                      type="radio"
                      name={`option-${currentQuestion}`} // Asignar nombre único a cada grupo de radio buttons
                      value={option}
                      checked={selectedAnswers[currentQuestion] === option}
                      onChange={() => handleSelect(option)}
                    />
                    {option}
                  </label>
                </li>
              ))}
            </ul>
            <div className="mt-3">
              <button className="btn btn-secondary me-2" onClick={handlePrevious} disabled={currentQuestion === 0}>
                Anterior
              </button>
              {currentQuestion === questions.length - 1 ? (
                <button className="btn btn-success" onClick={handleSubmit}>
                  Enviar
                </button>
              ) : (
                <button className="btn btn-primary" onClick={handleNext}>
                  Siguiente
                </button>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return <div>No hay más preguntas.</div>;
    }
  };

  const renderResults = () => {
    return (
      <div className="mt-3">
        <h2>¡El examen ha terminado!</h2>
        <p style={{ color: 'black' }}>Tu puntuación es: {score}%</p>
        {score >= 97 && (
          <button className="btn btn-success" onClick={handleRegister}>
            Enviar
          </button>
        )}
        {score < 97 && (
          <button className="btn btn-primary" onClick={handleRetry}>
            Volver a intentarlo
          </button>
        )}
      </div>
    );
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${hours} : ${minutes} : ${remainingSeconds}`;
  };

  return (
    <div className="container-quiz">
      {isExamOver ? (
        renderResults()
      ) : (
        <div>
          <p>Tiempo restante: {formatTime(timeRemaining)}</p>
          {renderQuestion()}
        </div>
      )}
    </div>
  );
}

export default Exam;
