import React from 'react';
import { useTranslation } from 'react-i18next';


// Asegúrate de importar el componente Card y la lista de temas
import Card from './Card'; // Asume que Card está en el mismo directorio
import './card.css'

const CardContainer = () => {

    const { t } = useTranslation();

    const topics = [
        {
          title: t('introductionToBlockchainTechnology'),
          subtopics: [
            t('typesOfBlockchains'),
            t('blockchainFundamentals'),
            t('publicPrivateAndConsortium'),
            t('blockchainHistory'),
            t('useCases'),
          ],
        },
        {
          title: t('bitcoinAndCryptocurrencies'),
          subtopics: [
            t('bitcoin'),
            t('altcoins'),
            t('roleInTheMarket'),
            t('ethereum2'),
            t('usesAndApplications'),
          ],
        },
        {
          title: t('centralizationVsDecentralization'),
          subtopics: [
            t('centralization'),
            t('decentralization'),
            t('contrasts'),
            t('impactsOnFinancialSystems'),
            t('dataUsage'),
          ],
        },
        {
          title: t('walletsAndSecurity'),
          subtopics: [
            t('transactions'),
            t('securityLayers'),
            t('storageMethods'),
            t('tensor'),
            t('layer2InWallets'),
          ],
        },
        {
          title: t('futureOfCryptocurrencies'),
          subtopics: [
            t('futureProjectionsOfTheCryptoMarket'),
            t('coinMarketCap'),
            t('scalabilityInBlockchain'),
            t('technologicalInnovations'),
            t('applicationsInCryptocurrencies'),

          ],
        },
        {
          title: t('decentralizedFinance'),
          subtopics: [
            t('whatIsDeFi'),
            t('currentFunctionality'),
            t('futureTrends'),
            t('defisPotential'),
            t('impactOfCurrencyDevaluationAndInflationOnDeFi'),
          ],
        },
        {
          title: t('decentralizedApplications'),
          subtopics: [
            t('introductionToDApps'),
            t('dAppsArchitecture'),
            t('commonUseCases'),
            t('securityInDApps'),
            t('interoperabilityInDApps'),
          ],
        },
        {
          title: t('nonFungibleTokens'),
          subtopics: [
            t('technologyBehindNFTs'),
            t('usesAndApplicationsNFTs'),
            t('economyOfNFTs'),
            t('developmentOfNFTs'),
            t('culturalImpactAndFutureOfNFTs'),
          ],
        },
        {
          title: t('smartContracts'),
          subtopics: [
            t('developmentAndLanguages'),
            t('implementationsAndExamples'),
            t('securityAndAudit'),
            t('applicationsAndFuture'),
    
          ],
        },
        {
          title: t('marketsOnBlockchain'),
          subtopics: [
            t('developmentAndOperation'),
            t('typesOfMarkets'),
            t('applicationsAndUseCases'),
            t('challengesAndFutureOfMarketsOnBlockchain'),
          ],
        },
      ];
    
     
    


  return (
    <div className="container scroll-1">
      {topics.map((topic, index) => (
        <Card key={index} title={topic.title} subtopics={topic.subtopics} />
      ))}
    </div>
  );
};

export default CardContainer;
