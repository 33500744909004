
import Layout from "../components/layout/Layout"
import FeaturedCardSlider from '../components/reusable/FeaturedCardSlider'
import { useTranslation } from 'react-i18next';


const delay = '0s'
const name = 'Coach Blockchains'
const prirce = '0.17'
const avatar = 'https://raw.githubusercontent.com/CoachblockchainDapp/LogoNFTs/main/TERRA.png'
const avatarA='https://raw.githubusercontent.com/CoachblockchainDapp/LogoNFTs/main/AQUA%20.png';
const avatarE='https://raw.githubusercontent.com/CoachblockchainDapp/LogoNFTs/main/AER.png';

const pexel = (id) =>
`https://raw.githubusercontent.com/CoachblockchainDapp/TerraImages/main/${id}.png`;

const pexela = (id) =>
`https://raw.githubusercontent.com/CoachblockchainDapp/AquaCollection/main/${id}.png`;

const pexelt = (id) =>
`https://raw.githubusercontent.com/CoachblockchainDapp/ImagenesEar/main/${id}.png`;






export default function Home() {
    const { t } = useTranslation();

    const productData = [
        {
            delay: delay,
            author: { name: name, avatar: avatar },
            authorLink: '#',
            imageSrc: pexel(10181),
            itemName: t('itemName'),
            itemLink: '#',
            currentBid: prirce,
            handleBidModal: () => {
                // Lógica para manejar el modal de ofertas
            },
        },
        {
            delay: delay,
            author: { name: name, avatar: avatar },
            authorLink: '#',
            imageSrc: pexel(10183),
            itemName:t('itemName'),
            itemLink: '#',
            currentBid: prirce,
            handleBidModal: () => {
                // Lógica para manejar el modal de ofertas
            },
        },
        {
            delay: delay,
            author: { name: name, avatar: avatar },
            authorLink: '#',
            imageSrc: pexel(10185),
            itemName:t('itemName'),
            itemLink: '#',
            currentBid: prirce,
            handleBidModal: () => {
                // Lógica para manejar el modal de ofertas
            },
        },
        {
            delay: delay,
            author: { name: name, avatar: avatar },
            authorLink: '#',
            imageSrc: pexel(10187),
            itemName:t('itemName'),
            itemLink: '#',
            currentBid: prirce,
            handleBidModal: () => {
                // Lógica para manejar el modal de ofertas
            },
        },
        {
            delay: delay,
            author: { name: name, avatar: avatar },
            authorLink: '#',
            imageSrc: pexel(10188),
            itemName:t('itemName'),
            itemLink: '#',
            currentBid: prirce,
            handleBidModal: () => {
                // Lógica para manejar el modal de ofertas
            },
        },
        {
            delay: delay,
            author: { name: name, avatar: avatar },
            authorLink: '#',
            imageSrc: pexel(10189),
            itemName:t('itemName'),
            itemLink: '#',
            currentBid: prirce,
            handleBidModal: () => {
                // Lógica para manejar el modal de ofertas
            },
        },
        {
            delay: delay,
            author: { name: name, avatar: avatar },
            authorLink: '#',
            imageSrc:pexel(10190),
            itemName:t('itemName'),
            itemLink: '#',
            currentBid: prirce,
            handleBidModal: () => {
                // Lógica para manejar el modal de ofertas
            },
        },
        {
            delay: delay,
            author: { name: name, avatar: avatar },
            authorLink: '#',
            imageSrc: pexel(10191),
            itemName:t('itemName'),
            itemLink: '#',
            currentBid: prirce,
            handleBidModal: () => {
                // Lógica para manejar el modal de ofertas
            },
        },
    
    
    ];
    
    const productDataA = [
        {
            delay: delay,
            author: { name: name, avatar: avatarA },
            authorLink: '#',
            imageSrc: pexela(100),
            itemName:t('itemNamea'),
            itemLink: '#',
            currentBid: prirce,
            handleBidModal: () => {
                // Lógica para manejar el modal de ofertas
            },
        },
        {
            delay: delay,
            author: { name: name, avatar: avatarA },
            authorLink: '#',
            imageSrc: pexela(101),
            itemName:t('itemNamea'),
            itemLink: '#',
            currentBid: prirce,
            handleBidModal: () => {
                // Lógica para manejar el modal de ofertas
            },
        },
        {
            delay: delay,
            author: { name: name, avatar: avatarA },
            authorLink: '#',
            imageSrc: pexela(102),
            itemName:t('itemNamea'),
            itemLink: '#',
            currentBid: prirce,
            handleBidModal: () => {
                // Lógica para manejar el modal de ofertas
            },
        },
        {
            delay: delay,
            author: { name: name, avatar: avatarA },
            authorLink: '#',
            imageSrc: pexela(103),
            itemName:t('itemNamea'),
            itemLink: '#',
            currentBid: prirce,
            handleBidModal: () => {
                // Lógica para manejar el modal de ofertas
            },
        },
        {
            delay: delay,
            author: { name: name, avatar: avatarA },
            authorLink: '#',
            imageSrc: pexela(104),
            itemName:t('itemNamea'),
            itemLink: '#',
            currentBid: prirce,
            handleBidModal: () => {
                // Lógica para manejar el modal de ofertas
            },
        },
        {
            delay: delay,
            author: { name: name, avatar: avatarA },
            authorLink: '#',
            imageSrc: pexela(105),
            itemName:t('itemNamea'),
            itemLink: '#',
            currentBid: prirce,
            handleBidModal: () => {
                // Lógica para manejar el modal de ofertas
            },
        },
        {
            delay: delay,
            author: { name: name, avatar: avatarA },
            authorLink: '#',
            imageSrc:pexela(106),
            itemName:t('itemNamea'),
            itemLink: '#',
            currentBid: prirce,
            handleBidModal: () => {
                // Lógica para manejar el modal de ofertas
            },
        },
        {
            delay: delay,
            author: { name: name, avatar: avatarA },
            authorLink: '#',
            imageSrc: pexela(107),
            itemName:t('itemNamea'),
            itemLink: '#',
            currentBid: prirce,
            handleBidModal: () => {
                // Lógica para manejar el modal de ofertas
            },
        },
    
    
    ];
    
    const productDataE = [
        {
            delay: delay,
            author: { name: name, avatar: avatarE },
            authorLink: '#',
            imageSrc: pexelt(1),
            itemName:t('itemNamee'),
            itemLink: '#',
            currentBid: prirce,
            handleBidModal: () => {
                // Lógica para manejar el modal de ofertas
            },
        },
        {
            delay: delay,
            author: { name: name, avatar: avatarE },
            authorLink: '#',
            imageSrc: pexelt(2),
            itemName:t('itemNamee'),
            itemLink: '#',
            currentBid: prirce,
            handleBidModal: () => {
                // Lógica para manejar el modal de ofertas
            },
        },
        {
            delay: delay,
            author: { name: name, avatar: avatarE },
            authorLink: '#',
            imageSrc: pexelt(3),
            itemName:t('itemNamee'),
            itemLink: '#',
            currentBid: prirce,
            handleBidModal: () => {
                // Lógica para manejar el modal de ofertas
            },
        },
        {
            delay: delay,
            author: { name: name, avatar: avatarE },
            authorLink: '#',
            imageSrc: pexelt(4),
            itemName:t('itemNamee'),
            itemLink: '#',
            currentBid: prirce,
            handleBidModal: () => {
                // Lógica para manejar el modal de ofertas
            },
        },
        {
            delay: delay,
            author: { name: name, avatar: avatarE },
            authorLink: '#',
            imageSrc: pexelt(5),
            itemName:t('itemNamee'),
            itemLink: '#',
            currentBid: prirce,
            handleBidModal: () => {
                // Lógica para manejar el modal de ofertas
            },
        },
        {
            delay: delay,
            author: { name: name, avatar: avatarE },
            authorLink: '#',
            imageSrc: pexelt(7),
            itemName:t('itemNamee'),
            itemLink: '#',
            currentBid: prirce,
            handleBidModal: () => {
                // Lógica para manejar el modal de ofertas
            },
        },
        {
            delay: delay,
            author: { name: name, avatar: avatarE },
            authorLink: '#',
            imageSrc:pexelt(8),
            itemName:t('itemNamee'),
            itemLink: '#',
            currentBid: prirce,
            handleBidModal: () => {
                // Lógica para manejar el modal de ofertas
            },
        },
        {
            delay: delay,
            author: { name: name, avatar: avatarE },
            authorLink: '#',
            imageSrc: pexelt(9),
            itemName:t('itemNamee'),
            itemLink: '#',
            currentBid: prirce,
            handleBidModal: () => {
                // Lógica para manejar el modal de ofertas
            },
        },
    
    
    ];

    return (
        <>

            <Layout headerStyle={1} footerStyle={1}>

            <div style={{ width: '100vw', display: 'flex', flexDirection: 'column' }}>
                    <div className="flat-title-page" style={{marginTop:'5%'}}>
                        <div className="themesflat-container">
                            <div className="row">
                                <div className="col-12">
                                <h1 style={{
                                        color: 'black',
                                        fontFamily: 'Univers, sans-serif',
                                        fontSize: '40px',
                                        margin: '0',
                                        textShadow: '2px 2px 2px #94939A',
                                    }}>{t('exploreCollectionsTitle')}</h1>
                                
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="tf-section-2 discover-item loadmore-12-item">
                        <div className="themesflat-container">
                           {/* <DropdownComponent></DropdownComponent>*/}
                            <FeaturedCardSlider productData={productData}></FeaturedCardSlider>
                            

                        </div>
                    </div>

                  




                  

                    <div className="tf-section-2 discover-item loadmore-12-item">
                        <div className="themesflat-container">
                           {/* <DropdownComponent></DropdownComponent>*/}
                            <FeaturedCardSlider productData={productDataA}></FeaturedCardSlider>
                            

                        </div>
                    </div>


                  


                    <div className="tf-section-2 discover-item loadmore-12-item">
                        <div className="themesflat-container">
                           {/* <DropdownComponent></DropdownComponent>*/}
                            <FeaturedCardSlider productData={productDataE}></FeaturedCardSlider>
                            

                        </div>
                    </div>

                </div>

               
            </Layout>
        </>
    )
}