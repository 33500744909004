import { Autoplay, FreeMode, Navigation, Pagination } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"
import BidModal from "../../components/elements/BidModal"
import Layout from "../../components/layout/Layout"
import { Menu } from '@headlessui/react'
import { Link } from "react-router-dom";
import { useState } from "react"
import './details.css'




const swiperOptions = {
    modules: [Autoplay, Pagination, Navigation, FreeMode],
    loop: true,
    slidesPerView: 1,
    freeMode: true,
    observer: true,
    observeParents: true,
    spaceBetween: 20,
    autoplay: {
        delay: 2000,
        disableOnInteraction: false
    },
    breakpoints: {
        768: {
            slidesPerView: 2,
            spaceBetween: 0
        }
    }
}


export const NFTDetails = () => {
    const [isBidModal, setBidModal] = useState(false)
    const handleBidModal = () => setBidModal(!isBidModal)

    return (
        <>

            <Layout headerStyle={1} footerStyle={1}>

                <div>
                    <div className="tf-section-2 product-detail">
                        <div className="themesflat-container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="infor-product style-1">
                                        <div data-wow-delay="0s" className="wow fadeInUp text justify-center"><span style={{ fontSize: '40px' }}>Mis NFTs</span></div>
                                        <h2 data-wow-delay="0s" className="wow fadeInUp text-center pb-30 mb-0" ><span style={{ color: '#CFA647' }} >0x3d075...</span></h2>
                                        <div className="flex items-center justify-between">
                                            <div data-wow-delay="0s" className="wow fadeInLeft author flex items-center">
                                                <div className="avatar">
                                                    <img src="https://raw.githubusercontent.com/coachblockchain/Images/main/10134.png" alt="image" />
                                                </div>
                                                <div className="info">
                                                    <span>Miembro:</span>

                                                </div>
                                            </div>
                                            <div data-wow-delay="0s" className="wow fadeInUp meta">
                                                <div className="meta-item view">
                                                    <i className="icon-show" />208 view
                                                </div>
                                                <div className="meta-item rating">
                                                    <i className="icon-link-2" />Top #2 trending
                                                </div>
                                                <div className="meta-item favorites">
                                                    <i className="icon-heart" />10 favorites
                                                </div>
                                            </div>
                                            <div data-wow-delay="0s" className="wow fadeInRight menu_card position-unset">
                                                <Menu as="div" className="dropdown">
                                                    <div className="icon">
                                                        <Menu.Button as="a" className="btn-link" aria-expanded="false">
                                                            <i className="icon-link-1" />
                                                        </Menu.Button>
                                                        <Menu.Items as="div" className="dropdown-menu show d-block">
                                                            <Link className="dropdown-item" to="#"><i className="icon-link" />Copy link</Link>
                                                            <Link className="dropdown-item" to="#"><i className="icon-facebook" />Share on facebook</Link>
                                                            <Link className="dropdown-item mb-0" to="#"><i className="icon-twitter" />Share on twitter</Link>
                                                        </Menu.Items>
                                                    </div>
                                                </Menu>
                                                <Menu as="div" className="dropdown">
                                                    <div className="icon">
                                                        <Menu.Button as="a" className="btn-link" aria-expanded="false">
                                                            <i className="icon-content" />
                                                        </Menu.Button>
                                                        <Menu.Items as="div" className="dropdown-menu show d-block">
                                                            <Link className="dropdown-item" to="#"><i className="icon-refresh" />Refresh metadata</Link>
                                                            <Link className="dropdown-item mb-0" to="#"><i className="icon-report" />Report</Link>
                                                        </Menu.Items>
                                                    </div>
                                                </Menu>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <Swiper {...swiperOptions} className="swiper-container carouselfull-2">

                                        <SwiperSlide>
                                            <div className="tf-card-box style-6">
                                                <div className="card-media">
                                                    <Link to="#">
                                                        <img src="https://raw.githubusercontent.com/coachblockchain/Images/main/10134.png" alt="" />
                                                    </Link>
                                                </div>

                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="tf-card-box style-6">
                                                <div className="card-media">
                                                    <Link to="#">
                                                        <img src="https://raw.githubusercontent.com/coachblockchain/Images/main/4149.png" alt="" />
                                                    </Link>
                                                </div>

                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                                <div className="col-12">

                                    <div data-wow-delay="0s" className="wow fadeInUp product-item description">
                                        <h6><i className="icon-description" />Descripción</h6>
                                        <i className="icon-keyboard_arrow_down" />
                                        <div className="content">
                                            <p>8,888 NFTs de hermosas mujeres asiáticas meticulosamente creadas donde incluso los detalles más intrincados tienen una importancia histórica. Visionamos a 8SIAN siendo una comunidad global e inclusiva <span>ver más</span></p>
                                        </div>
                                    </div>



                                    <div data-wow-delay="0s" className="wow fadeInUp product-item details">
                                        <h6><i className="icon-description" />Detalles</h6>
                                        <i className="icon-keyboard_arrow_down" />
                                        <div className="content">
                                            <div className="details-item">
                                                <span>Dirección del Contrato</span>
                                                <span className="tf-color">0x1984...c38f</span>
                                            </div>
                                            <div className="details-item">
                                                <span>ID del Token</span>
                                                <span className="tf-color">0270</span>
                                            </div>
                                            <div className="details-item">
                                                <span>Estándar del Token</span>
                                                <span>ERC-721</span>
                                            </div>
                                            <div className="details-item">
                                                <span>Cadena</span>
                                                <span>Ethereum</span>
                                            </div>
                                            <div className="details-item">
                                                <span>Última Actualización</span>
                                                <span>hace 1 día</span>
                                            </div>

                                        </div>
                                    </div>


                                    <div data-wow-delay="0s" className="wow fadeInUp product-item traits style-1">
                                        <h6><i className="icon-description" />Rasgos</h6>

                                        <div className="content">
                                            <div className="trait-item">
                                                <p style={{ color: '#77ADB2', fontSize: '17px' }}>Indumentaria</p>
                                                <div className="title">Bata Roja 1%</div>
                                            </div>
                                            <div className="trait-item" style={{borde:'black'}}>
                                                <p style={{ color: '#77ADB2', fontSize: '17px' }}>Fondo</p>
                                                <div className="title">Naranja 5%</div>
                                            </div>
                                            <div className="trait-item">
                                                <p style={{ color: '#77ADB2', fontSize: '17px' }}>Aretes</p>
                                                <div className="title">Ninguno 60%</div>
                                            </div>
                                            <div className="trait-item">
                                                <p style={{ color: '#77ADB2', fontSize: '17px' }}>Indumentaria</p>
                                                <div className="title">Bata Roja 1%</div>
                                            </div>
                                            <div className="trait-item">
                                                <p style={{ color: '#77ADB2', fontSize: '17px' }}>Fondo</p>
                                                <div className="title">Naranja 5%</div>
                                            </div>
                                            <div className="trait-item">
                                                <p style={{ color: '#77ADB2', fontSize: '17px' }}>Aretes</p>
                                                <div className="title">Ninguno 60%</div>
                                            </div>
                                            <div className="trait-item">
                                                <p style={{ color: '#77ADB2', fontSize: '17px' }}>Indumentaria</p>
                                                <div className="title">Bata Roja 1%</div>
                                            </div>

                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tf-section-2 featured-item style-bottom">
                        <div className="themesflat-container">

                        </div>
                    </div>
                </div>
                <BidModal handleBidModal={handleBidModal} isBidModal={isBidModal} />
            </Layout>
        </>
    )
}