import React, { useState } from 'react';
import './ConsensusProtocols.css';

const ConsensusProtocols = () => {
  const protocols = [
    { name: "Proof of Work (PoW)", definition: "Cuando un usuario inicia una transacción, los \"mineros\" o supercomputadoras intentan resolver un problema o rompecabezas para verificar.", networks: "Bitcoin, Ethereum (antes de ETH 2.0)" },
    { name: "Proof of Stake (PoS)", definition: "Se recomienda a un usuario que gaste monedas hasta que se convierta en un validador para crear un bloque.", networks: "Ethereum 2.0, Cardano" },
    { name: "Delegated Proof of Stake (DPoS)", definition: "Igual que PoS, pero los usuarios con más monedas podrán votar y elegir Testigos.", networks: "EOS, Tron" },
    { name: "Leased Proof of Stake (LPoS)", definition: "Los usuarios podrán crear tokens personalizados y los usan en sus granjas para una mayor seguridad.", networks: "Waves" },
    { name: "Proof of Elapsed Time (PoET)", definition: "Un validador puede agrupar las transacciones propuestas y crear un nuevo bloque.", networks: "Hyperledger Sawtooth" },
    { name: "Práctica de la tolerancia a faltas bizantinas (PBFT)", definition: "Se enfoca en mantener a raya a usuarios deshonestos.", networks: "Hyperledger Fabric" },
    { name: "Tolerancia a faltas bizantinas simplificada (SBFT)", definition: "Bizancio utilizó una secuencia particular para mantener a raya a usuarios deshonestos.", networks: "No hay un ejemplo claro de esta variante específica" },
    { name: "Tolerancia a faltas bizantinas delegada (dBFT)", definition: "Se enfoca en una verificación de bloque con controladores de nodo profesionales.", networks: "NEO" },
    { name: "Directed Acyclic Graphs (DAG)", definition: "DAG no tienen una estructura de datos blockchain y manejan transacciones de forma asincrónica.", networks: "IOTA, Nano" },
    { name: "Proof of Activity (PoA)", definition: "Los usuarios que frecuentemente envían o reciben transacciones recibirán pagos por eso.", networks: "Decred" },
    { name: "Proof of Importance (PoI)", definition: "Utiliza tanto PoS como PoW para asegurar que los puntos de recompensa estén a tiempo.", networks: "NEM" },
    { name: "Proof of Capacity (PoC)", definition: "Con este protocolo, puedes utilizar la capacidad o el espacio de almacenamiento del disco duro del usuario.", networks: "Burstcoin" },
    { name: "Proof of Burn (PoB)", definition: "Los usuarios envían las monedas a una billetera de la que no pueden recuperarse y obtendrán recompensas según la cantidad.", networks: "Slimcoin" },
    { name: "Proof of Weight (PoWeight)", definition: "Similar a PoS, pero la diferencia es que depende de varios otros factores llamados ponderaciones.", networks: "Algorand" }
  ];

  const [selectedProtocol, setSelectedProtocol] = useState(null);

  const handleProtocolClick = (protocol) => {
    setSelectedProtocol(protocol);
  };

  return (
    <div className="protocol-container">
      <div className="circle">
        {protocols.map((protocol, index) => (
          <div 
            key={index} 
            className={`protocol ${selectedProtocol === protocol ? 'active' : ''}`} 
            onClick={() => handleProtocolClick(protocol)}
          >
            <h3>{protocol.name}</h3>
          </div>
        ))}
        <div className="center-content">
          {selectedProtocol ? (
            <div className="definition">
              <h3>{selectedProtocol.name}</h3>
              <p>{selectedProtocol.definition}</p>
              <p><strong>Ejemplos de redes:</strong> {selectedProtocol.networks}</p>
            </div>
          ) : (
            <p>Selecciona un protocolo para ver su definición y ejemplos</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConsensusProtocols;
