import React from 'react';

function Iframe({ src }) {

   


  return (
    <iframe
    title="Public Page"
    src={src}
    style={{ width: '100%', height: '100vh', border: 'none' }}
  />
  
  );
}

export default Iframe;
