import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Roadmap = () => {
    const { t } = useTranslation();
    const points = [
        {
            index: 1,
            label: t('introductionToBlockchainTitle'),
            text: t('introductionToBlockchainDescription'),
            hours: 15,
            link: "/formacion-1",
        },
        {
            index: 2,
            label: t('cryptographyAndSecurityTitle'),
            text: t('cryptographyAndSecurityDescription'),
            hours: 15,
            link: "/formacion-2",
        },
        {
            index: 3,
            label: t('bitcoinAndPublicBlockchainTitle'),
            text: t('bitcoinAndPublicBlockchainDescription'),
            hours: 15,
            link: "/formacion-3",
        },
        {
            index: 4,
            label: t('ethereumAndSmartContractsTitle'),
            text: t('ethereumAndSmartContractsDescription'),
            hours: 15,
            link: "/formacion-4",
        },
        {
            index: 5,
            label: t('decentralizedFinanceDefiTitle'),
            text: t('decentralizedFinanceDefiDescription'),
            hours: 15,
            link: "/formacion-5",
        },
        {
            index: 6,
            label: t('nonFungibleTokensNftTitle'),
            text: t('nonFungibleTokensNftDescription'),
            hours: 15,
            link: "/formacion-6",
        },
        {
            index: 7,
            label: t('blockchainInvestingAndTradingTitle'),
            text: t('blockchainInvestingAndTradingDescription'),
            hours: 15,
            link: "/formacion-7",
        },
        {
            index: 8,
            label: t('blockchainApplicationDevelopmentTitle'),
            text: t('blockchainApplicationDevelopmentDescription'),
            hours: 15,
            link: "/formacion-8",
        },
        {
            index: 9,
            label:t('businessUseCasesTitle'),
            text: t('businessUseCasesDescription'),
            hours: 15,
            link: "/formacion-9",
        },
        {
            index: 10,
            label: t('regulationAndEthicsTitle'),
            text: t('regulationAndEthicsDescription'),
            hours: 15,
            link: "/formacion-10",
        },
    ];

    const [hoveredElement, setHoveredElement] = useState(null);

    const handleMouseOver = (element) => {
        setHoveredElement(element);
    };

    const handleMouseOut = () => {
        setHoveredElement(null);
    };

    return (
        <div className="roadmap">
            {points.map((point) => (
                <div key={point.index} className="point">
                    <div className="point-index">
                        <Link
                            to={{
                                pathname: point.link,
                                state: { scrollToTop: true }
                            }}

                            style={{ color: "#fff" }}
                            onMouseOver={() => handleMouseOver(point.index)}
                            onMouseOut={handleMouseOut}
                        >
                            {point.index}
                        </Link>
                    </div>
                    <div className="point-label">
                        <Link
                            to={point.link}>  {point.label}</Link></div>
                    <div className="point-text" style={{ color: "#000" }}>
                        {point.text}
                    </div>
                </div>
            ))}
            <div className="total">
                <p>{t('totalHours')}</p>
                <p>{t('totalWeeks')}</p>
                <p>{t('hoursPerWeek')}</p>
            </div>
        </div>
    );
};

export default Roadmap;
