import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import Layout from "../../components/layout/Layout"
import './Profile.css'; // Asegúrate de tener este archivo CSS para estilos

// Suponiendo que tienes la dirección y ABI de tu contrato en archivos JSON separados
const contractAddress = require(`../../contractsData/${'DataStorage'}-address.json`).address;
const contractABI = require(`../../contractsData/${'DataStorege-data'}.json`)

function Profile() {
    const [purchaseDetails, setPurchaseDetails] = useState([]);
    const [userAddress, setUserAddress] = useState('');
    const [totalCompras, setTotalCompras] = useState(0);
    const [packageDetails, setPackageDetails] = useState([]); // Almacena detalles de cada paquete
    const [totalPackages, setTotalPackages] = useState(0); // Almacena el total de paquetes
    const [invitationDetails, setInvitationDetails] = useState([]);
    const [code, setCode] = useState([])
    const [hashes, setHash] = useState([]);
    const [usesInvitation, setUsesInvitation] = useState([])
    const [invitationUses, setInvitationUses] = useState(' ');



    async function loadPurchaseDetails(address) {
        if (!window.ethereum) {
            console.log('Ethereum object not found, install MetaMask.');
            return;
        }

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new ethers.Contract(contractAddress, contractABI, provider);

        try {
            const [packageIds, timestamps, uses] = await contract.getUserPurchases(address);
            let totalUses = 0; // Inicializa la suma de usos
            const mappedDetails = packageIds.map((packageId, index) => {
                totalUses += parseInt(uses[index]);// Suma los usos
                return {
                    packageId: packageId.toString(),
                    count: uses[index].toString(),
                    lastPurchaseTimestamp: new Date(timestamps[index] * 1000).toLocaleString(),
                };
            });

            setPurchaseDetails(mappedDetails);
            setTotalCompras(totalUses);
            // setTotalCompras no es necesario si solo quieres mostrar las compras, pero puedes sumar 'uses' si es relevante
        } catch (error) {
            console.error("Error al cargar los detalles de las compras:", error);
        }
    }

    const loadInvitationUses = async (hashes) => {
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const contract = new ethers.Contract(contractAddress, contractABI, provider);

            const allUses = await Promise.all(hashes.map(async (hash) => {

                const uses = await contract.getInvitationUses(hash);
                return parseInt(uses.toString(), 10);
            }));

            setInvitationUses(allUses);
        } catch (error) {
            console.error("Error loading invitation uses:", error);
        }
    };


    // Cargar los totales de compras 
    async function loadUserPackageCounts(address) {
        if (!window.ethereum) {
            console.log('Ethereum object not found, install MetaMask.');
            return;
        }

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new ethers.Contract(contractAddress, contractABI, provider);

        try {
            // Asume que tu contrato tiene una función `getUserPackageCounts` que devuelve dos arrays
            const [packageIds, counts] = await contract.getUserPackageCounts(address);

            let totalPackages = 0; // Inicializa el contador total de paquetes
            const packageDetails = packageIds.map((packageId, index) => {
                totalPackages += parseInt(counts[index]); // Suma los conteos de cada paquete
                return {
                    packageId: packageId.toString(),
                    count: counts[index].toString(),
                };
            });

            setTotalPackages(totalPackages)
            setPackageDetails(packageDetails)
            // Aquí puedes establecer el estado con los detalles de los paquetes y el total
            // Por ejemplo: setPackageDetails(packageDetails);
            // Y también actualizar cualquier otro estado relevante, como el total de paquetes
            // Por ejemplo: setTotalPackages(totalPackages);
        } catch (error) {
            console.error("Error al cargar los conteos de los paquetes:", error);
        }
    }

    async function loadAllInvitationDetails(address) {
        if (!window.ethereum) {
            console.log('Ethereum object not found, install MetaMask.');
            return;
        }

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new ethers.Contract(contractAddress, contractABI, provider);

        try {
            const [hashes, uses] = await contract.getAllInvitationDetailsByUser(address);


            const invitationDetails = hashes.map((hash, index) => {
                return {
                    hash: hash.toString(), // Convierte el hash de tipo bytes32 a string
                    usesRemaining: uses[index].toString(),
                };
            });

            setInvitationDetails(invitationDetails); // Asegúrate de tener este estado definido en tu componente React

            // Obtiene todos los hashes del array invitationDetails
            const hashStrings = invitationDetails.map(invitation => '0x' + invitation.hash.substring(2)); // Agregar '0x' al principio
            setHash(hashStrings);
            console.log(hashStrings);
        } catch (error) {
            console.error("Error al cargar los detalles de las invitaciones:", error);
        }
    }




    useEffect(() => {
        const init = async () => {
            if (window.ethereum) {
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                try {
                    const accounts = await provider.send("eth_requestAccounts", []);
                    setUserAddress(accounts[0]);
                    loadPurchaseDetails(accounts[0]);
                    loadUserPackageCounts(accounts[0]);
                    loadAllInvitationDetails(accounts[0]);
                    loadInvitationUses(hashes);
                    console.log('invitationUses:', invitationUses);



                } catch (error) {
                    console.error("Error obtaining user address:", error);
                }

                const handleAccountsChanged = (accounts) => {
                    if (accounts.length > 0) {
                        setUserAddress(accounts[0]);
                        loadPurchaseDetails(accounts[0]);
                        loadUserPackageCounts(accounts[0]);
                        loadAllInvitationDetails(accounts[0]);
                        loadInvitationUses(hashes);
                    } else {
                        // Manejar desconexion de MetaMask, por ejemplo, limpiando el estado
                        setPurchaseDetails([]);
                        setUserAddress('');
                        setTotalPackages('')
                        setInvitationDetails([]);
                    }
                };

                window.ethereum.on('accountsChanged', handleAccountsChanged);

                return () => window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
            }
        };

        init();
    }, [hashes]);





    // Función modificada para copiar la dirección y el código
    // Función modificada para copiar la dirección y el código
    function copyAddressAndCode(hash) {
        // Formatea el texto para incluir tanto el código como la dirección en líneas separadas
        const textToCopy = `Code: ${hash}\nAddress: ${userAddress}`;

        // Utiliza el API del portapapeles para copiar el texto formateado
        navigator.clipboard.writeText(textToCopy).then(() => {
            // Mensaje de éxito
            console.log('Code and address copied to clipboard!');
            alert('Code and address copied to clipboard!'); // Feedback visual para el usuario
        }, (err) => {
            // Mensaje de error
            console.error('Failed to copy: ', err);
            alert('Failed to copy: ' + err); // Feedback visual en caso de error
        });
    }




    return (
        <Layout headerStyle={1} footerStyle={1} pageCls="about-us-page">



            <div className="profile-container">
                <div className="userAddress">
                    <span>Mi Wallet: {userAddress}</span>
                </div>

                <div className='purchaseColumn-buy'>


                    {purchaseDetails.length > 0 ? (
                        purchaseDetails.map((detail, index) => {
                            const packageId = detail.packageId;
                            const availableUses = invitationUses; // Verifica el uso de la clave correcta
                            console.log("Valor de availableUses:", availableUses);



                            return (
                                <div key={index} className="card-column">
                                    <div className="card-section">
                                        <h3 className="card-title">Códigos y Detalles de Invitación</h3>
                                        <div className="card-details">
                                            <div className="circle-profile">
                                                <p className="circle-text">Usos: {detail.count}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-section details">
                                        <div className="info">
                                            <div className="detail-card blue">
                                                KIT Adquirido: {
                                                    parseInt(packageId, 10) === 1 ? 'BASIC' :
                                                        parseInt(packageId, 10) === 2 ? 'ADVANCED' :
                                                            parseInt(packageId, 10) === 3 ? 'ACADEMIC' :
                                                                parseInt(packageId, 10) === 4 ? 'MASTER' :
                                                                    parseInt(packageId, 10) === 5 ? 'PREMIUM' :
                                                                        'DESCONOCIDO' // en caso de valores inesperados
                                                }
                                            </div>
                                            <div className="detail-card gray">Códigos Disponibles: {availableUses}</div> {/* Muestra los usos disponibles */}
                                            <div className="detail-card dark-gray">Fecha/Hora de compra: {detail.lastPurchaseTimestamp}</div>
                                            {invitationDetails.length > index && (
                                                <>
                                                    <div className="detail-card dark-gray">Código de Invitación: </div>
                                                    <div className="detail-card dark-gray">{invitationDetails[index].hash}</div>
                                                    <button onClick={() => copyAddressAndCode(invitationDetails[index].hash)} className="copyCode">Copiar Código</button>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <div className='no-purchases'>No hay compras realizadas</div>
                    )}
                </div>




            </div >
        </Layout>

    );
}

export default Profile;

