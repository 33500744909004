// NavigationButtons.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft, faHome } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const ArrowIcon = styled(FontAwesomeIcon)`
  color: #0B5568;
  cursor: pointer;
  margin: 0 10px; /* Ajusta el espacio entre íconos según tus necesidades */
`;

const NavigationButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 200px; /* Ajusta según tus necesidades */
  margin: 20px auto; /* Ajusta según tus necesidades */
  z-index: 999; /* Ajusta el valor de z-index según tus necesidades */
`;

const NextArrow = ({ to }) => (
  <Link to={to}>
    <ArrowIcon icon={faArrowRight} size="2x" />
  </Link>
);

const PrevArrow = ({ to }) => (
  <Link to={to}>
    <ArrowIcon icon={faArrowLeft} size="2x" />
  </Link>
);

const HomeIcon = ({ to }) => (
  <Link to={to}>
    <ArrowIcon icon={faHome} size="2x" />
  </Link>
);

const NavigationButtons = ({ prevTo, homeTo, nextTo }) => (
  <NavigationButtonsContainer>
    <PrevArrow to={prevTo} />
    <HomeIcon to={homeTo} />
    <NextArrow to={nextTo} />
  </NavigationButtonsContainer>
);

export default NavigationButtons;
