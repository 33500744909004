import React from 'react';
import Layout from "../../components/layout/Layout";
import Card from './card';
import './login.module.css';


function Login({ web3Handler }) {
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '70vh',
         width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'white',
      overflow: 'hidden',
      marginTop: '10%'
    },
    containerCard: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minHeight: '70vh',
      width: '600px',
      height: '700px',
      justifyContent: 'center',
      backgroundColor: '#FFFFFF',
      overflow: 'hidden',
      borderRadius: '20px',
      boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)',
      position: 'relative',
    
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center', 
      marginTop: '20px', 
    },
    buttonConnect: {
      backgroundColor: '#0A5364',
      color: 'white',
      fontSize: '1.125rem',
      fontWeight: 'medium',
      boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)',
      transition: 'all 0.3s ease-in-out',
      cursor: 'pointer',
      borderRadius: '8px',
      border: 'none',
      outline: 'none', 
      marginBottom: '20px', 
    },
    buttonConnectHover: {
      transform: 'translateY(-4px) scale(1.1)',
    },
  };

  return (
    <Layout>
      <div style={styles.container}>
        <div style={styles.containerCard}>
          <div className="card-wallet" >
            <div className="content-wallet">
              <Card/>
              <div style={styles.buttonContainer}>
                <button
                  style={styles.buttonConnect}
                  className="buttonWallet"
                  onClick={() => web3Handler()}
                >
                  <span style={{ fontSize: '20px' }}>Conectarse</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Login;
