import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import Loader from './Loader';
import './TradingViewWidget.css'

const USDTEChart = () => {
  const [dataSet, setDataSet] = useState([]);
  const [loading, setLoading] = useState(true);

  // Cambia la URL de la API para obtener datos de USDT en USD
  const ajaxUrl = 'https://min-api.cryptocompare.com/data/histoday?fsym=USDT&tsym=USD&limit=30';

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(ajaxUrl);
      const data = await response.json();
      const parsedData = data.Data.map((item) => [item.time * 1000, item.high]);
      setDataSet(parsedData);
      setLoading(false);
    };

    fetchData();
  }, []);

  const chartOptions = {
    chart: {
      type: 'area',
      height: 350,
    },
    title: {
      text: 'Precio de USD Tether / últimos 30 días',
      align: 'left',
      style: {
        fontSize: '24px',
        color: '#213043',
      },
    },
    tooltip: {
      x: {
        format: 'dd MMM yyyy',
      },
    },
    fill: {
      opacity: 1,
      type: 'gradient',
    },
    colors: ['#50AF95'],
    series: [
      {
        name: 'USDT (USD)',
        data: dataSet,
      },
    ],
    xaxis: {
      type: 'datetime',
    },
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div id="chart_">
      <ReactApexChart options={chartOptions} series={chartOptions.series} type="area" height={350} />
    </div>
  );
};

export default USDTEChart;
