import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowLeft, faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';

const contractAddress = require(`../../contractsData/${'NFTView'}-address.json`).address;
const contractABI = require(`../../contractsData/${'NFTView'}.json`);

const contractAddressV2 = require(`../../contractsData/${'NFTView'}-address copy.json`).address;
const contractABIV2 = require(`../../contractsData/${'NFTView'} copy.json`);

const CarrucelNFT = () => {
    const [currentNftIndex, setCurrentNftIndex] = useState(0);
    const [mergedNftData, setMergedNftData] = useState([]);
    const [userAddress, setUserAddress] = useState('');
    const provider = window.ethereum ? new ethers.providers.Web3Provider(window.ethereum) : null;
    const contract = new ethers.Contract(contractAddress, contractABI, provider);
    const contractV2 = new ethers.Contract(contractAddressV2, contractABIV2, provider);

    useEffect(() => {
        const init = async () => {
            if (window.ethereum) {
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                try {
                    const accounts = await provider.send("eth_requestAccounts", []);
                    if (accounts.length > 0) {
                        setUserAddress(accounts[0]);
                        const nftData1 = await loadNftURIs(accounts[0]);
                        const nftData2 = await loadNftURIsV2(accounts[0]);
                        setMergedNftData([...nftData1, ...nftData2]);
                    }
                } catch (error) {
                    console.error("Error al obtener la dirección del usuario:", error);
                }

                const handleAccountsChanged = (accounts) => {
                    if (accounts.length > 0) {
                        setUserAddress(accounts[0]);
                        const nftData1 = loadNftURIs(accounts[0]);
                        const nftData2 = loadNftURIsV2(accounts[0]);
                        setMergedNftData([...nftData1, ...nftData2]);
                    }
                };

                window.ethereum.on('accountsChanged', handleAccountsChanged);
                return () => window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
            }
        };

        init();
    }, []);

    async function loadNftURIs(address) {
        if (!window.ethereum) {
            console.log('Ethereum object not found, install MetaMask.');
            return [];
        }
        try {
            const [tokenIds, uris] = await contract.getTokensByWalletWithId(address);
            return tokenIds.map((tokenId, index) => ({ tokenId: tokenId.toString(), uri: uris[index], contract: contractAddress }));
        } catch (error) {
            console.error("Error al obtener detalles de los NFTs del usuario", error);
            return [];
        }
    }

    async function loadNftURIsV2(address) {
        try {
            console.log("Cargando NFTs para la dirección (V2):", address);
            const [tokenIds, uris] = await contractV2.tokensOfOwner(address);
            return tokenIds.map((tokenId, index) => ({ tokenId: tokenId.toString(), uri: uris[index], contract: contractAddressV2 }));
        } catch (error) {
            console.error("Error al obtener detalles de los NFTs del usuario (V2)", error);
            return [];
        }
    }

    const handleNext = () => {
        setCurrentNftIndex((prevIndex) => (prevIndex + 1) % mergedNftData.length);
    };

    const handlePrev = () => {
        setCurrentNftIndex((prevIndex) => (prevIndex - 1 + mergedNftData.length) % mergedNftData.length);
    };

    return (
        <div style={{ width: '40vw', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {mergedNftData.length > 0 && (
                <div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <button onClick={handlePrev} disabled={currentNftIndex === 0}>
                            <FontAwesomeIcon icon={faCircleArrowLeft} />
                        </button>
                        <img
                            src={mergedNftData[currentNftIndex].uri}
                            alt={`NFT ${currentNftIndex}`}
                            style={{ width: '300px', height: '300px', margin: '0 20px' }}
                        />
                        <button onClick={handleNext} disabled={currentNftIndex === mergedNftData.length - 1}>
                            <FontAwesomeIcon icon={faCircleArrowRight} />
                        </button>
                    </div>
                    <h2 style={{ color: '#0A5364' }}>#{mergedNftData[currentNftIndex].tokenId}</h2>
                    <h2 style={{ color: '#0A5364', fontSize: '10px' }}>
                        Contract: {mergedNftData[currentNftIndex].contract}
                    </h2>
                </div>
            )}
        </div>
    );
};

export default CarrucelNFT;
