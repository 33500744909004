import React, { useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';



import {
  Navigation,
  NavigationLogin,
  Home,
  About,
  Create,
  MyListedItems,
  MyPurchases,
  PricingBoxes,
  NFTDetails,
} from '../components';


import { ReferalLessRegistration } from '../screens/registration/ReferalLessRegistration'
import RegistrationCard from '../screens/registration/RegistrationCard'

import HomeAbout from '../screens/about/about-us'
import Explora from '../pages/explore-1'
import Detalles from '../pages/product-detail-1'
import Contact from '../pages/contact-us'
import TermsCondictions from '../pages/terms-condition'
import FAQWithHours from '../screens/traning/FAQWithHours'
import Dashboard from '../screens/dashboard/panel'
import UseGuia from '../screens/guia/UseGuia'
import ReferralSystem from '../screens/Earns/earns'
import Servicio from '../screens/politicas/TerminosServicio'






import MarketplaceAbi from '../contractsData/Marketplace.json';
import MarketplaceAddress from '../contractsData/Marketplace-address.json';
import NFTAbi from '../contractsData/NFT.json';
import NFTAddress from '../contractsData/NFT-address.json';

import { ethers } from 'ethers';
import './App.css';
import Formacion1 from '../screens/traning/Formacion-1'
import Formacion2 from '../screens/traning/Formacion-2'
import Formacion3 from '../screens/traning/Formacion-3'
import Formacion4 from '../screens/traning/Formacion-4'
import Formacion5 from '../screens/traning/Formacion-5'
import Formacion6 from '../screens/traning/Formacion-6'
import Formacion7 from '../screens/traning/Formacion-7'
import Formacion8 from '../screens/traning/Formacion-8'
import Formacion9 from '../screens/traning/Formacion-9'
import Formacion10 from '../screens/traning/Formacion-10'

import Login from '../screens/authentication/Login'
import AllUsers from '../components/featured/allUsers'
import Certificate from '../screens/certificate/Certificate';
import NFTViewer from '../screens/NFT/NFTView';

import Profile from '../screens/profile/Profile';


import ScrollToTop from './elements/ScrollToTop';
import '../config/i18n';



import Formaciones from '../pages/blog-grid';


import Iframe from '../screens/courses/finanzas/Iframe'
import enlaces from '../screens/courses/components/enlaces'

import VideoComponent from '../screens/QUIZ/VideoComponent'
import Quiz from '../screens/QUIZ/quiz'


import SHA256Component from '../screens/courses/sha256'
import Glosario from '../screens/courses/Glosario'
import CasosDeUso from '../screens/courses/BlockchainCasosUso'
import P2P from '../screens/courses/P2P'

import Roadmap from '../screens/courses/roadmap/Infographic'
import Maintenance from '../screens/Error/MaintenancePage'



import ConsensusProtocols from '../screens/courses/ConsensusProtocols'
import TextComponent from '../screens/courses/TextComponent'
import allData from '../util/loadData'


import YoutubeComponent from './video/VideoSolidity';


function App() {

  const [loading, setLoading] = useState(true);
  const [account, setAccount] = useState(null);
  const [nft, setNFT] = useState({});
  const [marketplace, setMarketplace] = useState({});



  const web3Handler = async () => {
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    console.log(accounts)
    setAccount(accounts[0]);
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

    window.ethereum.on('chainChanged', (chainId) => {
      window.location.reload();
    })

    window.ethereum.on('accountsChanged', async function (accounts) {
      setAccount(accounts[0]);
      await web3Handler();
    })

    loadContracts(signer);
  }

  const loadContracts = async (signer) => {
    const marketplace = new ethers.Contract(MarketplaceAddress.address, MarketplaceAbi.abi, signer);
    setMarketplace(marketplace);
    const nft = new ethers.Contract(NFTAddress.address, NFTAbi.abi, signer);
    setNFT(nft);
    setLoading(false);
  }



  return (
    <BrowserRouter>
      <div className='App'>

        <ScrollToTop />

        {loading ? (<>
          <Navigation web3Handler={web3Handler} account={account} />



        </>) : (<>

          <NavigationLogin web3Handler={web3Handler} account={account} />
        </>)}

        <div>
          {loading ? (
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '80vh',
              background: "white",
            }}>
              <Routes>



                <Route path="*" element={<Navigate to="/" replace />} />

                <Route path="/" element={
                  <Home marketplace={marketplace} nft={nft} />

                } />

                <Route path="/conocenos" element={
                  <About marketplace={marketplace} nft={nft} />
                } />

                <Route path="/comunidad" element={
                  <>
                    <HomeAbout /></>
                } />

                <Route path="/explora-nft" element={
                  <Explora />
                } />

                <Route path="/contactanos" element={
                  <Contact />
                } />

                <Route path="/detalles" element={
                  <Detalles />

                } />

                <Route path="/kits" element={
                  <ReferalLessRegistration marketplace={marketplace} nft={nft} />

                } />

                <Route path="/terms-condictions" element={
                  <TermsCondictions marketplace={marketplace} nft={nft} />

                } />



                <Route path="/formacion" element={
                  < FAQWithHours />
                } />

                <Route path="/formacion-1" element={
                  < Formacion1 />
                } />

                <Route path="/formacion-2" element={
                  < Formacion2 />
                } />


                <Route path="/formacion-3" element={
                  < Formacion3 />
                } />




                <Route path="/formacion-4" element={
                  < Formacion4 />
                } />


                <Route path="/formacion-5" element={
                  < Formacion5 />
                } />

                <Route path="/formacion-6" element={<Formacion6 />} />
                <Route path="/formacion-7" element={<Formacion7 />} />
                <Route path="/formacion-8" element={<Formacion8 />} />
                <Route path="/formacion-9" element={<Formacion9 />} />
                <Route path="/formacion-10" element={<Formacion10 />} />

                <Route path="/registro" element={
                  <RegistrationCard web3Handler={web3Handler} account={account} />

                } />

                <Route path="/guia-uso" element={<UseGuia />} />



                <Route path="/login" element={<Login web3Handler={web3Handler} account={account} />} />

                <Route path="/sistema-ganancias" element={<ReferralSystem />} />

                <Route path="/terminos-servicio" element={<Servicio />} />






              </Routes>
            </div>
          ) : (

            <Routes>

              <Route path="*" element={<Navigate replace to="/panel" />} />

              <Route path="/mis-compras" element={
                <Profile />
              } />

              <Route path="/panel" element={
                <Dashboard />
              } />

              <Route path="/create" element={
                <Create marketplace={marketplace} nft={nft} />
              } />

              <Route path="/my-listed-items" element={
                <MyListedItems marketplace={marketplace} nft={nft} account={account} />
              } />

              <Route path="/mis-compras" element={
                <MyPurchases marketplace={marketplace} nft={nft} account={account} />
              } />

              <Route path="/kits" element={
                <PricingBoxes marketplace={marketplace} nft={nft} />
              } />

              {/* <Course marketplace={marketplace} nft={nft} />*/}


              <Route path="/nuestros-cursos" element={

                < Formaciones />
              } />




              <Route path="/nft-datelles" element={
                <NFTDetails marketplace={marketplace} nft={nft} />
              } />

              <Route path="/explorar" element={
                <Explora />
              } />

              <Route path="/all-users" element={
                <AllUsers />
              } />

              <Route path="/certificate" element={
                <Certificate />
              } />

              <Route path="/mis-NFTs" element={
                <NFTViewer />
              } />

              <Route path="/historia-dinero" element={<Iframe src={enlaces[0].url} />} />

              <Route path="/introducion-blockchain" element={<Iframe src={enlaces[1].url} />} />

              <Route path="/blockchain-industria" element={<Iframe src={enlaces[2].url} />} />

              <Route path="/btc-revolucion" element={<Iframe src={enlaces[3].url} />} />

              <Route path="/crypto" element={<Iframe src={enlaces[4].url} />} />

              <Route path="/tecnologia-blockchain" element={<Iframe src={enlaces[5].url} />} />

              <Route path="/web3" element={<Iframe src={enlaces[6].url} />} />

              <Route path="/mineria" element={<Iframe src={enlaces[7].url} />} />

              <Route path="/criptografia" element={<Iframe src={enlaces[8].url} />} />

              <Route path="/criptografia-asimetrica" element={<Iframe src={enlaces[9].url} />} />

              <Route path="/hash" element={<Iframe src={enlaces[10].url} />} />

              <Route path="/wallets" element={<Iframe src={enlaces[11].url} />} />

              <Route path="/smarkle-tree" element={<Iframe src={enlaces[12].url} />} />



              <Route path="/smart-contracts" element={<Iframe src={enlaces[13].url} />} />

              <Route path="/nft-formacion" element={<Iframe src={enlaces[14].url} />} />




              <Route path="/defi" element={<Iframe src={enlaces[16].url} />} />

              <Route path="/dapp" element={<Iframe src={enlaces[18].url} />} />








              <Route path="/aspectos-legales" element={<Iframe src={enlaces[15].url} />} />


              <Route path="/dapp" element={<Iframe src={enlaces[17].url} />} />

              <Route path="/formation-exam" element={<Quiz />} />

              <Route path="/quiz" element={<VideoComponent videoUrl="https://firebasestorage.googleapis.com/v0/b/coashblockchain.appspot.com/o/Untitled_video%20(9).mp4?alt=media&token=b2ad4515-3b02-4b04-9e84-991443657824" />} />

              <Route path="/blockchain-consultancy-course" element={<VideoComponent videoUrl="https://firebasestorage.googleapis.com/v0/b/coashblockchain.appspot.com/o/Copy_of_Untitled_video%20(11).mp4?alt=media&token=11c0d26a-5756-4f2c-a3fa-9a917b4f1138" />} />

              <Route
                path="/intro-blockchain"
                element={<TextComponent data={allData.IndocutionBlockchain} />} />
            

              <Route
                path="/escalabilidad"
                element={<TextComponent data={allData.Scalabilidad} />} />

              <Route
                path="/merkle"
                element={<TextComponent data={allData.Merkle} />} />

              <Route path="/sha256" element={<TextComponent data={allData.SHA} />} />
              <Route path="/glosario"element={<TextComponent data={allData.Glo}  />} />
              <Route path="/genesis" element={<TextComponent data={allData.Ge} />} />
              <Route path="/flow" element={<TextComponent data={allData.Flow} />} />
              <Route path="/chanels-state" element={<TextComponent data={allData.Chanels} />} />
              <Route path="/blockchain-consultancy-v8" element={<TextComponent data={allData.Casos} />} />
              <Route path="/blockchain-consultancy-v7" element={<TextComponent data={allData.Libro} />} />
              <Route path="/blockchain-consultancy-v9" element={<TextComponent data={allData.mineria} />} />
              <Route path="/blockchain-consultancy-v10" element={<TextComponent data={allData.Nonce} />} />
              <Route path="/blockchain-consultancy-v11" element={<TextComponent data={allData.Puzze} />} />
              <Route path="/blockchain-consultancy-v12" element={<TextComponent data={allData.Bizantinas} />} />


              <Route path="/blockchain-consultancy-v13" element={<TextComponent data={allData.MecanismoConsesum} />} />
              <Route path="/blockchain-consultancy-v14" element={<TextComponent data={allData.PoW} />} />
              <Route path="/blockchain-consultancy-v15" element={<TextComponent data={allData.PoS} />} />
              <Route path="/blockchain-consultancy-v16" element={<TextComponent data={allData.Defenza} />} />
              <Route path="/blockchain-consultancy-v18" element={<ConsensusProtocols />} />
              <Route path="/blockchain-consultancy-v17" element={<TextComponent data={allData.BloquesHuerfanos} />} />
              <Route path="/blockchain-consultancy-v20" element={<TextComponent data={allData.BloquesHO} />} />
              <Route path="/blockchain-consultancy-v19" element={<TextComponent data={allData.Forks} />} />
              <Route path="/blockchain-consultancy-v21" element={<TextComponent data={allData.ArquitecturaBlockchain} />} />
              <Route path="/blockchain-consultancy-v22" element={<TextComponent data={allData.Capas} />} />
              <Route path="/blockchain-consultancy-v23" element={<TextComponent data={allData.ArquitecturaETH} />} />
              <Route path="/blockchain-consultancy-v24" element={<TextComponent data={allData.ArquitecturaBTC} />} />
              <Route path="/blockchain-consultancy-v25" element={<TextComponent data={allData.ArquitecturaHiperlager} />} />
              <Route path="/blockchain-consultancy-v27" element={<TextComponent data={allData.ArquitecturaTezos} />} />


              {/* Nuevas rutas basadas en JSON con la convención */}
              <Route path="/blockchain-consultancy-V28" element={<TextComponent data={allData.ArquitecturaCardano} />} />
              <Route path="/wax" element={<TextComponent data={allData.ArquitecturaWAX} />} />
              <Route path="/blockchain-consultancy-V30" element={<TextComponent data={allData.ArquitecturaPolygon} />} />
              <Route path="/blockchain-consultancy-V31" element={<TextComponent data={allData.ArquitecturaTezos} />} />
              <Route path="/blockchain-consultancy-V32" element={<TextComponent data={allData.BridgesBlockchain} />} />
              <Route path="/gas" element={<TextComponent data={allData.GAS} />} />
              <Route path="/skelly" element={<TextComponent data={allData.Skelly} />} />
              <Route path="/Sharding" element={<TextComponent data={allData.Sharding} />} />
              <Route path="/sidechains" element={<TextComponent data={allData.side} />} />
              <Route path="/plasma" element={<TextComponent data={allData.Plasma} />} />
              <Route path="/rollups" element={<TextComponent data={allData.Rollups} />} />
              <Route path="/riks" element={<TextComponent data={allData.Riks} />} />
              <Route path="/scalable" element={<TextComponent data={allData.EscalabilidadEnLosDatos} />} />
              <Route path="/security" element={<TextComponent data={allData.Security} />} />

              <Route path="/hibric-chains" element={<TextComponent data={allData.CadenasHibridas} />} />
              <Route path="/permissioned-chains" element={<TextComponent data={allData.CadenasDeBloquesPermisionadas} />} />
              <Route path="/defi-intro" element={<TextComponent data={allData.IntroducciónALasFinanzasDescentralizadas} />} />



              <Route path="/defi-wallets" element={<TextComponent data={allData.CarterasYSeguridadEnDeFi} />} />
              <Route path="/stablecoins" element={<TextComponent data={allData.Stablecoins} />} />
              <Route path="/defi-projects" element={<TextComponent data={allData.ProyectosDeFi} />} />
              <Route path="/dex" element={<TextComponent data={allData.DEX} />} />
              <Route path="/defi-lending" element={<TextComponent data={allData.Plata} />} />
              <Route path="/yield-farming" element={<TextComponent data={allData.CreaciónYVentaDeNFTs} />} />
              <Route path="/amm" element={<TextComponent data={allData.SolidityFundamentosBásicos} />} />
              <Route path="/defi-risks" element={<TextComponent data={allData.SeguridadYRiesgosEnNFTs} />} />
              <Route path="/defi-governance" element={<TextComponent data={allData.ModelosDeGobernanzaEnDAO} />} />
              <Route path="/dao-projects" element={<TextComponent data={allData.CasosDeUsoDeDAO} />} />
              <Route path="/dao-governance" element={<TextComponent data={allData.ModelosDeGobernanzaEnDAO} />} />
              <Route path="/dao-tokenomics" element={<TextComponent data={allData.EconomíaDeTokensEnDAO} />} />
              <Route path="/dao-use-cases" element={<TextComponent data={allData.CasosDeUsoDeDAO} />} />
              <Route path="/nft-intro" element={<TextComponent data={allData.NFTsIntroducciónYConceptosBásicos} />} />
              <Route path="/nft-creation" element={<TextComponent data={allData.CreaciónYVentaDeNFTs} />} />
              <Route path="/nft-art-culture" element={<TextComponent data={allData.NFTsEnElArteYLaCultura} />} />
              <Route path="/nft-gaming" element={<TextComponent data={allData.JuegosBlockchainYNFTs} />} />
              <Route path="/nft-ip" element={<TextComponent data={allData.NFTsYPropiedadIntelectual} />} />
              <Route path="/nft-security-risks" element={<TextComponent data={allData.SeguridadYRiesgosEnNFTs} />} />
              <Route path="/token-standards" element={<TextComponent data={allData.EstandaresDeTokensERC20ERC721} />} />
              <Route path="/solidity-basics" element={<TextComponent data={allData.SolidityFundamentosBásicos} />} />
              <Route path="/smart-contracts-course" element={<TextComponent data={allData.ContratosInteligentes} />} />
              <Route path="/security-audit-scale" element={<TextComponent data={allData.AuditoriaDeSeguridadEnBlockchain} />} />
              <Route path="/privacy" element={<TextComponent data={allData.PrivacidadEnBlockchain} />} />
              <Route path="/regulation-ethics" element={<TextComponent data={allData.RegulaciónYÉticaEnBlockchain} />} />
              <Route path="/blockchain-future" element={<TextComponent data={allData.FuturoDeBlockchain} />} />


             {/* <Route path="/blockchain-consultancy" element={<Roadmap />} />  */} 
              <Route path="/blockchain-consultancy" element={<Maintenance />} />
              <Route
                path="/solidity"
                element={<YoutubeComponent />}
              />





















            </Routes>
          )}
        </div>


        {/*
       {loading ? (<>
          <Footer />
        </>) : (<>
          <Footer /></>)}

       
       
       
       */}
      </div>


    </BrowserRouter>
  );
}

export default App;