import React from 'react';
import './wallet.css'

function WalletCard({ user }) {
  return (
    <div className="card_wallet wallet">
     <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div className="overlay"></div>
        <div className="circle_wallet">
          <svg xmlnsXlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="23 29 78 60" height="60px" width="78px">
            <defs></defs>
            <g transform="translate(23.000000, 29.500000)" fillRule="evenodd" fill="none" strokeWidth="1" stroke="none" id="icon">
              <rect rx="4.70247832" height="21.8788565" width="9.40495664" y="26.0333433" x="67.8357511" fill="#137e96" id="Rectangle-3"></rect>
              <rect rx="4.70247832" height="10.962961" width="9.40495664" y="38.776399" x="67.8357511" fill="#073b47" id="Rectangle-3"></rect>
              <polygon points="57.3086772 0 67.1649301 26.3776902 14.4413177 45.0699507 4.58506484 18.6922605" fill="#073b47" id="Rectangle-2"></polygon>
              <path fill="#0A5364" id="Rectangle" d="M0,19.6104296 C0,16.2921718 2.68622235,13.6021923 5.99495032,13.6021923 L67.6438591,13.6021923 C70.9547788,13.6021923 73.6388095,16.2865506 73.6388095,19.6104296 L73.6388095,52.6639057 C73.6388095,55.9821635 70.9525871,58.672143 67.6438591,58.672143 L5.99495032,58.672143 C2.68403068,58.672143 0,55.9877847 0,52.6639057 L0,19.6104296 Z"></path>
              <path fill="#0A5364" id="Fill-12" d="M47.5173769,27.0835169 C45.0052827,24.5377699 40.9347162,24.5377699 38.422622,27.0835169 L36.9065677,28.6198808 L35.3905134,27.0835169 C32.8799903,24.5377699 28.8078527,24.5377699 26.2957585,27.0835169 C23.7852354,29.6292639 23.7852354,33.7559532 26.2957585,36.3001081 L36.9065677,47.0530632 L47.5173769,36.3001081 C50.029471,33.7559532 50.029471,29.6292639 47.5173769,27.0835169"></path>
              <rect height="12.863158" width="15.6082259" y="26.1162588" x="58.0305835" fill="#137e96" id="Rectangle-4"></rect>
              <ellipse ry="2.23319575" rx="2.20116007" cy="33.0919007" cx="65.8346965" fill="#FFFFFF" id="Oval"></ellipse>
            </g>
          </svg>

        </div>
        <p style={{ fontSize: '25px', marginLeft: '10px', color:'#4a919c' }}>Mi Wallet</p>
      </div>
      <p>{user}</p>

    </div>
  );
}

export default WalletCard;
