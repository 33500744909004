export const BasicEducation = [
  'Bitcoin',
  'Stablecoin (moneda estable)',
  'Exchange Centralizado',
  'Exchange Descentralizado',
  'Cold Wallet (cartera fría)',
  '¿Qué es blockchain?',
  '¿Cómo operar en este mercado?',
  'Importancia de los NFTs y ¿Qué son?',
];


  