import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import Layout from '../../components/layout/Layout';
import './NFTview.css';

const contractAddress = require(`../../contractsData/${'NFTView'}-address.json`).address;
const contractABI = require(`../../contractsData/${'NFTView'}.json`);
const contractAddressV2 = require(`../../contractsData/${'NFTView'}-address copy.json`).address;
const contractABIV2 = require(`../../contractsData/${'NFTView'} copy.json`);


const NFTViewer = () => {
  const [tokenId, setTokenId] = useState(null);
  const [nftData, setNftData] = useState([]);
  const [nftDataV2, setNftDataV2] = useState([]);
  const [userAddress, setUserAddress] = useState('');
  const [destinatario, setDestinatario] = useState('');
  const [provider, setProvider] = useState(null);
  const [contract, setContract] = useState(null);
  const [contractV2, setContractV2] = useState(null);

  useEffect(() => {
    const init = async () => {
      if (window.ethereum) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        setProvider(provider);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(contractAddress, contractABI, signer);
        const contractV2 = new ethers.Contract(contractAddressV2, contractABIV2, signer);
        setContract(contract);
        setContractV2(contractV2);

        try {
          const accounts = await provider.send("eth_requestAccounts", []);
          if (accounts.length > 0) {
            const userAddress = accounts[0];
            setUserAddress(userAddress);
            await loadNftURIs(contract, userAddress);
            await loadNftURIsV2(contractV2, userAddress);
          }
        } catch (error) {
          console.error("Error al obtener la dirección del usuario:", error);
        }

        window.ethereum.on('accountsChanged', async (accounts) => {
          if (accounts.length > 0) {
            const userAddress = accounts[0];
            setUserAddress(userAddress);
            await loadNftURIs(contract, userAddress);
            await loadNftURIsV2(contractV2, userAddress);
          } else {
            setUserAddress('');
            setNftData([]);
            
          }
        });
      } else {
        console.log("MetaMask no está instalada. Por favor instálala para usar esta aplicación.");
      }
    };

    init();
  }, []);

  const loadNftURIs = async (contract, address) => {
    try {
      console.log("Cargando NFTs para la dirección:", address);
      const [tokenIds, uris] = await contract.getTokensByWalletWithId(address);
      const nftData = tokenIds.map((tokenId, index) => ({ tokenId: tokenId.toString(), uri: uris[index] }));
      console.log("Datos de NFTs cargados:", nftData);
      setNftData(nftData);
    } catch (error) {
      console.error("Error al obtener detalles de los NFTs del usuario", error);
    }
  };

  
  const loadNftURIsV2 = async (contractV2, address) => {
    try {
      console.log("Cargando NFTs para la dirección (V2):", address);
      const [tokenIds, uris] = await contractV2.tokensOfOwner(address);
      const nftDataV2 = tokenIds.map((tokenId, index) => ({ tokenId: tokenId.toString(), uri: uris[index] }));
      console.log("Datos de NFTs cargados (V2):", nftDataV2);
      setNftDataV2(nftDataV2);
    } catch (error) {
      console.error("Error al obtener detalles de los NFTs del usuario (V2)", error);
    }
  };

  const regalarNFT = async (from, to, tokenId) => {
    try {
      if (!provider || !contract) return;
      console.log(`Intentando transferir el NFT con ID ${tokenId} de ${from} a ${to}`);
      const transaction = await contract["safeTransferFrom(address,address,uint256)"](from, to, tokenId);
      await transaction.wait();
      console.log("NFT transferido con éxito");
      // Actualiza los NFTs después de la transferencia
      await loadNftURIs(contract, from);
      await loadNftURIs(contract, to);
    } catch (error) {
      console.error("Error al transferir el NFT:", error);
    }
  };

  const downloadImage = async (url, id) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const urlObject = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = urlObject;
      link.setAttribute('download', id);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(urlObject);
      console.log('Imagen descargada con éxito');
    } catch (error) {
      console.error('Error al descargar la imagen:', error);
    }
  };

  const handleRegalarClick = (tokenId) => {
    const promptDestinatario = window.prompt("Ingrese la dirección del destinatario:");
    if (promptDestinatario) {
      setDestinatario(promptDestinatario);
      setTokenId(tokenId);
      console.log(`Destinatario establecido: ${promptDestinatario}`);
    }
  };

  const handleConfirmTransferir = () => {
    if (destinatario) {
      regalarNFT(userAddress, destinatario, tokenId);
      setDestinatario('');
    }
  };

  return (
    <Layout headerStyle={1} footerStyle={1} pageCls="about-us-page">
      <div style={{ width: '100vw', display: 'flex', flexDirection: 'column' }}>
        <div style={{ marginTop: '10%' }}>
          <h1 style={{ color: 'black' }}>Mis NFTs</h1>
          <div className="nft-row">
            {nftData.map((nft, index) => (
              <div key={index}>
                <div className="nft">
                  <div className="main">
                    <img src={nft.uri} alt={`NFT ${index}`} />
                    <h2> # {nft.tokenId}</h2>
                    <div className="tokenInfo"></div>
                    <hr />
                    <div className="creator">
                      <div className="wrapper">
                        <img src="https://raw.githubusercontent.com/CoachblockchainDapp/Formaciones/main/FIRMA%20para%20WEB.png" alt="Creator" />
                      </div>
                      <p><ins>Creado por : </ins> CoachBlockchain</p>
                    </div>
                  </div>
                  <div className="containerB" style={{ display: 'flex', justifyContent: 'space-between', width: '400px', alignItems: 'center', marginTop: '10px', marginBottom: '10px', marginLeft: '50px' }}>
                    <button style={{ backgroundColor: '#0A5364', padding: '10px 20px', border: '1px solid #C89D38', cursor: 'pointer', borderRadius: '0', opacity: '1', fontWeight: 'bold', justifyContent: 'center', alignItems: 'center', width: 'calc(40% - 0.5rem)' }} onClick={() => downloadImage(nft.uri, nft.tokenId)}>Descargar</button>
                    <button style={{ backgroundColor: '#0A5364', padding: '10px 20px', border: '1px solid #C89D38', cursor: 'pointer', borderRadius: '0', opacity: '1', fontWeight: 'bold', justifyContent: 'center', alignItems: 'center', width: 'calc(40% - 0.5rem)' }} onClick={() => handleRegalarClick(nft.tokenId)}>Transferir</button>
                  </div>
                </div>
              </div>
            ))}
    
            {nftDataV2.map((nft, index) => (
              <div key={index}>
                <div className="nft">
                  <div className="main">
                    <img src={nft.uri} alt={`NFT ${index}`} />
                    <h2> # {nft.tokenId}</h2>
                    <div className="tokenInfo"></div>
                    <hr />
                    <div className="creator">
                      <div className="wrapper">
                        <img src="https://raw.githubusercontent.com/CoachblockchainDapp/Formaciones/main/FIRMA%20para%20WEB.png" alt="Creator" />
                      </div>
                      <p><ins>Creado por : </ins> CoachBlockchain</p>
                    </div>
                  </div>
                  <div className="containerB" style={{ display: 'flex', justifyContent: 'space-between', width: '400px', alignItems: 'center', marginTop: '10px', marginBottom: '10px', marginLeft: '50px' }}>
                    <button style={{ backgroundColor: '#0A5364', padding: '10px 20px', border: '1px solid #C89D38', cursor: 'pointer', borderRadius: '0', opacity: '1', fontWeight: 'bold', justifyContent: 'center', alignItems: 'center', width: 'calc(40% - 0.5rem)' }} onClick={() => downloadImage(nft.uri, nft.tokenId)}>Descargar</button>
                    <button style={{ backgroundColor: '#0A5364', padding: '10px 20px', border: '1px solid #C89D38', cursor: 'pointer', borderRadius: '0', opacity: '1', fontWeight: 'bold', justifyContent: 'center', alignItems: 'center', width: 'calc(40% - 0.5rem)' }} onClick={() => handleRegalarClick(nft.tokenId)}>Transferir</button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {destinatario && (
            <div>
              <p>Confirmar transferencia a: {destinatario}</p>
              <button onClick={handleConfirmTransferir}>Confirmar</button>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default NFTViewer;
