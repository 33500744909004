import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import Roadmap from '../roadmap/Roadmap'
import { useTranslation } from 'react-i18next';

import CardContainer from './cardBasicFomation/CardContainer'

import Layout from "../../components/layout/Layout"

const Section = styled.div`
    margin-top:  ${props => (props.isMobile ? '20vw' : '10%')};
    padding-bottom: 5%;
    text-align: center;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 80px;
    max-width: ${props => (props.isMobile ? '90%' : '1200px')}; 
    margin-left: auto;
    margin-right: auto;
`;



const Heading = styled.h2`
    margin-top: ${props => (props.isMobile ? '-50px' : '5px')};
    color: black;
    font-size: ${props => (props.isMobile ? '2em' : '3em')}; 
    margin-bottom: ${props => (props.isMobile ? '-4px' : '20px')}; 
`;

const IntroductionBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px; 
  width: 85vw;
  background-color: #77ADB2;
  color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  font-size:1.5em; 
  gap: 20px;
  
  @media (max-width: 768px) {
    width: auto;
    font-size: 0.8em;
  }
`;

const Column = styled.div`
  width: 100%; 
  margin-bottom: 20px; 
`;

const Pointed = styled.p`
  color: #000;
  margin-bottom: 10px;
  font-size: 2.2em; 
  text-align: center;
`;

const Paragraph = styled.p`
  margin-bottom: 20px;
  text-align: justify;
  font-size: 2.2em; /* Cambiar este valor según tus necesidades */
`;



const Title = styled.h3`
  font-size:1.8em;
  color: inherit;
  margin-bottom: 10px;
`;


const FAQWithHours = () => {
    const [esPantallaPequena, setEsPantallaPequena] = useState(window.innerWidth < 600);
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const { t } = useTranslation();

    useEffect(() => {
        function manejarCambioDeTamano() {
            setEsPantallaPequena(window.innerWidth < 600);
        }

        window.addEventListener('resize', manejarCambioDeTamano);
        return () => window.removeEventListener('resize', manejarCambioDeTamano);
    }, []);

    // Estilos responsivos basados en el estado
    const estilos = {
        color: 'black',
        display: 'block',
        textAlign: esPantallaPequena ? 'left' : 'justify',

    };


    return (
        <Layout headerStyle={1} footerStyle={1}  >
            <div style={{ width: '100vw', display: 'flex', flexDirection: 'column' }}>
                <Section>
                    <Container isMobile={isMobile}>
                        <Heading><h1 style={{
                            color: 'black',
                            fontFamily: 'Univers, sans-serif',
                            fontSize: '32px',
                            textShadow: '2px 2px 2px #94939A',
                            textAlign: 'center',
                        }}>{t('titleDominandoBlockchain')}</h1></Heading>
                        <Paragraph>
                            <span style={estilos}>
                                {t('descriptionCourse')}
                            </span>
                        </Paragraph>
                        <Heading>{t('depthLevelTitle')}</Heading>
                        <Paragraph>
                            <span style={estilos}>
                                {t('depthLevelDescription')}
                            </span>
                        </Paragraph>

                        <Heading>{t('targetAudienceTitle')}</Heading>
                        <Paragraph>
                            <span style={estilos}>
                                {t('targetAudienceDescription')}
                            </span>
                        </Paragraph>
                        <Heading>{t('basicBlockchainTrainingHeading')}</Heading>
                        <Pointed>
                            <strong>{t('basicBlockchainTrainingAccessibilityTitle')}:</strong>{t('basicBlockchainTrainingAccessibilityDescription')}
                        </Pointed>
                        <Pointed>
                            <strong>{t('basicBlockchainTrainingEssentialContentTitle')}</strong> {t('basicBlockchainTrainingEssentialContentDescription')}
                        </Pointed>
                        <Pointed>
                            <strong>{t('basicBlockchainTrainingSkillDevelopmentTitle')}</strong> {t('basicBlockchainTrainingSkillDevelopmentDescription')}
                        </Pointed>
                        <Pointed>
                            <strong>{t('basicBlockchainTrainingAdvancedPreparationTitle')}</strong> {t('basicBlockchainTrainingAdvancedPreparationDescription')}
                        </Pointed>
                        <Pointed>
                            <strong>{t('basicBlockchainTrainingInclusivityDiversityTitle')}</strong> {t('basicBlockchainTrainingInclusivityDiversityDescription')}
                        </Pointed>
                        <br></br>
                        <br></br>
                        <CardContainer />
                        <br></br>
                        <br></br>
                        <Heading>{t('additionalResourcesTitle')}</Heading>
                        <Paragraph>
                            <span style={estilos}>
                                {t('additionalResourcesDescription')}
                            </span>
                        </Paragraph>

                        <Heading>{t('studentFeedbackTitle')}</Heading>
                        <Paragraph>
                            <span style={estilos}>
                                {t('studentFeedbackDescription')}
                            </span>
                        </Paragraph>
                        <Heading>{t('learningPathTitle')}</Heading>
                        <Roadmap></Roadmap>
                        <IntroductionBox>
                            <Column>
                                <Title>{t('queVamosAprender')}</Title>
                                <Pointed>{t('courseContentOrigin')}</Pointed>
                                <Pointed>{t('courseContentArchitecture')}</Pointed>
                            </Column>
                            <Column>
                                <Title>{t('courseContentTitle')}</Title>
                                <Pointed>{t('courseContentCryptography')}</Pointed>
                                <Pointed>{t('courseContentHashing')}</Pointed>
                            </Column>
                        </IntroductionBox>
                        <Paragraph>
                            <span style={estilos}>
                                {t('economicFocusDescription')}
                            </span>
                        </Paragraph>
                        <IntroductionBox>
                            <Column>
                                <Title>{t('queVamosAprender')}</Title>
                                <Pointed>{t('courseContentFundamentalConcepts')}</Pointed>
                                <Pointed>{t('courseContentFinancialArchitecture')}</Pointed>
                            </Column>
                            <Column>
                                <Title>{t('courseContentTitle')}</Title>
                                <Pointed>{t('courseContentFinancialTransactionsExploration')}</Pointed>
                                <Pointed>{t('courseContentFinancialStrategiesDevelopment')}</Pointed>
                            </Column>
                        </IntroductionBox>
                        <Paragraph>
                            <span style={estilos}>
                                {t('totalHoursDescription')}
                            </span>
                        </Paragraph>
                    </Container>
                </Section>

            </div>
        </Layout>
    );
};

export default FAQWithHours;

