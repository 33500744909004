import React from 'react';
import { Link } from "react-router-dom";

const BlogCard = ({ post }) => (
    <div className="fl-blog fl-item2 col-lg-4 col-md-6">
        <article className="tf-card-article">
            <div className="card-media">
                <Link to={post.link || "#"}>
                    <img 
                        src={post.image} 
                        alt={post.title} 
                        style={{ 
                            width: '100%', 
                            height: '200px', 
                            objectFit: 'cover' 
                        }} 
                    />
                </Link>
            </div>
            <div className="meta-info flex">
                <div className="item art active">{post.category}</div>
                <div className="item date">{post.date}</div>
            </div>
            <div className="card-title">
                <h5><Link to={post.detailsLink}><span style={{ color: 'white' }}>{post.title}</span></Link></h5>
            </div>
            <div className="card-bottom flex items-center justify-between">
                <div className="author flex items-center justify-between">
                    <div className="avatar">
                        <img src={post.authorAvatar} alt={post.authorName} />
                    </div>
                    <div className="info">
                        <span style={{ color: '#fff', textDecoration: 'none', fontFamily: 'Arial, sans-serif' }} >Creado Por:</span>
                        <h6><Link to={post.authorLink}> <span style={{ color: 'white' }}>{post.authorName}</span></Link></h6>
                    </div>
                </div>
                <Link className="link" to={post.arrowLink}><i className="icon-arrow-up-right2" style={{ color: 'white' }} /></Link>
            </div>
        </article>
    </div>
);

export default BlogCard;
