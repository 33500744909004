export const lessons = [
    { status: 'active', title: 'Introducción a la Blockchain', link: '/intro-blockchain', imageUrl: 'https://images.prismic.io/fabrick/e6583d54-b92d-4871-8de9-000a31884eb7_Blockchain%20-%20Articolo.png?ixlib=gatsbyFP&auto=compress%2Cformat&fit=max' },
    { status: 'active', title: 'Bloque Génesis', link: '/genesis', imageUrl: 'https://i.pinimg.com/564x/87/ec/3d/87ec3d03451f6fa4e8e6de7acc92c660.jpg' },
    { status: 'active', title: 'Blockchain en Detalle', link: '/glosario', imageUrl: 'https://i.pinimg.com/564x/85/06/37/8506378e21f247e335a73885cd3b61ae.jpg' },
    { status: 'active', title: 'Escalabilidad del Blockchain', link: '/escalabilidad', imageUrl: 'https://i.pinimg.com/564x/84/b3/f6/84b3f62b676e28cd0fff11aa68d33c0b.jpg' },
    { status: 'active', title: 'SHA-256', link: '/sha256', imageUrl: 'https://i.pinimg.com/564x/83/06/3e/83063eb9b2f572fc5560823f61561e38.jpg' },
    { status: 'active', title: 'Los Árboles de Merkle', link: '/merkle', imageUrl: 'https://i.pinimg.com/564x/68/f9/c7/68f9c7e82d34493e17483a612c17b245.jpg' },
    { status: 'active', title: 'Casos de Uso de Blockchain', link: '/blockchain-consultancy-v8', imageUrl: 'https://i.pinimg.com/564x/34/26/7d/34267d5ab37fb94135d5e5d8ae0834db.jpg' },
    { status: 'active', title: 'El Libro Mayor Inmutable y Redes Distribuidas P2P', link: '/blockchain-consultancy-v7', imageUrl: 'https://i.pinimg.com/564x/58/7b/f3/587bf38a14308753480d3c8e397dd7af.jpg' },
    { status: 'active', title: 'Minado', link: '/blockchain-consultancy-v9', imageUrl: 'https://i.pinimg.com/736x/21/34/e5/2134e59e17586b72947f354d47e1916f.jpg' },
    { status: 'active', title: 'El Nonce', link: '/blockchain-consultancy-v10', imageUrl: 'https://i.pinimg.com/736x/8d/b8/30/8db83073c2bcf39019d80b1658519781.jpg' },
    { status: 'active', title: 'Puzzle Criptográfico', link: '/blockchain-consultancy-v11', imageUrl: 'https://i.pinimg.com/564x/45/fd/7d/45fd7d99cc487d350fa56d17e378b3a1.jpg' },
    { status: 'active', title: 'Tolerancia a Faltas Bizantinas', link: '/blockchain-consultancy-v12', imageUrl: 'https://i.pinimg.com/564x/44/34/10/443410ce3cd3da937192bd682066765f.jpg' },
    { status: 'active', title: 'Protocolos de Consenso', link: '/blockchain-consultancy-v13', imageUrl: 'https://imgs.search.brave.com/QWlTh6fGtedQuPBkg6_erb0BTat4gx5tVBLI0uEmowk/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9jcnlw/dG9jb25leGlvbi5j/b20vd3AtY29udGVu/dC91cGxvYWRzLzIw/MjIvMTEvUHJvdG9j/b2xvLWRlLUNvbmNl/bnNvLVBvRVQtMTAy/NHg2ODMud2VicA' },
    { status: 'active', title: 'Protocolos de Consenso: Proof of Work (PoW)', link: '/blockchain-consultancy-v14', imageUrl: 'https://imgs.search.brave.com/wpJr5l6G6i4P1ue8w4SUJTvUD3r6vEuKCgvQhDYBKMY/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9lZGl0/b3IuYW5hbHl0aWNz/dmlkaHlhLmNvbS91/cGxvYWRzLzQ1ODMw/V2VicC5uZXQtcmVz/aXplaW1hZ2UuanBn' },
    { status: 'active', title: 'Protocolos de Consenso: Proof of Stake (PoS)', link: '/blockchain-consultancy-v15', imageUrl: 'https://imgs.search.brave.com/VcVXf9ZvQCiQHi5-6_Br2dhWScUYGWerSv9a6UdBNr4/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9ibG9n/LmJpdG5vdm8uY29t/L3dwLWNvbnRlbnQv/dXBsb2Fkcy8yMDIx/LzA2L1F1ZSVDQyU4/MS1lcy1Qcm9vZi1v/Zi1TdGFrZTAxLmpw/Zw' },
    { status: 'active', title: 'Protocolos de Consenso: Defensa contra Ataques', link: '/blockchain-consultancy-v16', imageUrl: 'https://imgs.search.brave.com/LZ5ixqVqcN879B0YNOOIrVOEtJY20nbN1DUCttQBv78/rs:fit:860:0:0/g:ce/aHR0cHM6Ly93d3cu/Y3JpcHRvbm90aWNp/YXMuY29tL3dwLWNv/bnRlbnQvdXBsb2Fk/cy8yMDIwLzA5L2Jp/dGNvaW4tbGlnaHRu/aW5nLW5ldHdvcmst/aW51bmRhY2lvbi5q/cGc' },
    { status: 'active', title: 'Protocolos de Consenso y Bloques Huérfanos', link: '/blockchain-consultancy-v17', imageUrl: 'https://imgs.search.brave.com/nprwKVGnbVe3iHuh-xuK6l6eU7l-PnOTGmWL2sno9w4/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9jcmlw/dG90YXJpby5jb20v/d3AtY29udGVudC91/cGxvYWRzLzIwMTgv/MTEvQmxvcXVlcy1o/dWVyZmFub3MtdGlv/cy15LW9ic29sZXRv/cy0xLnBuZw' },
    { status: 'active', title: 'Revisión de los Protocolos de Consenso', link: '/blockchain-consultancy-v18', imageUrl: 'https://imgs.search.brave.com/NLLotJtvJkj1lPonWWEIaNPvhPV172derX0ECJeBN6s/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9jcnlw/dG9jb25leGlvbi5j/b20vd3AtY29udGVu/dC91cGxvYWRzLzIw/MjIvMTEvUHJvdG9j/b2xvLWRlLUNvbmNl/bnNvLVBvSS0xMDI0/eDU3Ni53ZWJw' },
    { status: 'active', title: 'Hard Forks y Soft Forks', link: '/blockchain-consultancy-v19', imageUrl: 'https://imgs.search.brave.com/YgWeEZ65GTxwtp1ANTspKPKwwDcmxlqrz4smZzJcqBA/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly93d3cu/aW52ZXN0b3BlZGlh/LmNvbS90aG1iL3VP/STRDQ3BaUF9FSzZI/ZzZneHVYeTZaTEZJ/Zz0vNDAweDMwMC9m/aWx0ZXJzOm5vX3Vw/c2NhbGUoKTptYXhf/Ynl0ZXMoMTUwMDAw/KTpzdHJpcF9pY2Mo/KS9hLXZpc3VhbC1y/ZXByZXNlbnRhdGlv/bi1vZi10aGUtZGln/aXRhbC1jcnlwdG9j/dXJyZW5jeS05NzMw/Mzk0MzgtYzQyZDE2/NGM1MTNmNGVlYTky/Yzk4NTRjNzUxYzUx/NjcuanBn' },
    { status: 'active', title: 'Comparación de Redes Blockchain: Públicas, Privadas y de Consorcio', link: '/blockchain-consultancy-v20', imageUrl: 'https://blogs.iadb.org/conocimiento-abierto/wp-content/uploads/sites/10/2018/06/banner-tipos-de-blockchain.png' },
    { status: 'active', title: 'Introducción a la Arquitectura de Blockchain', link: '/blockchain-consultancy-v21', imageUrl: 'https://hbinformatica.com.br/wp-content/uploads/2022/07/615-ai.png' },
    { status: 'active', title: 'Blockchain por Capas', link: '/blockchain-consultancy-v22', imageUrl: 'https://wiki.lemon.me/wp-content/uploads/2022/04/WIKI-Escalabilidad_INTERIOR_02.jpg' },
    { status: 'active', title: 'Arquitectura de Ethereum', link: '/blockchain-consultancy-v23', imageUrl: 'https://th.bing.com/th/id/OIP.36lIBVM8wxXqeyAVVy-hqwHaEO?w=702&h=400&rs=1&pid=ImgDetMain' },
    { status: 'active', title: 'Arquitectura de Bitcoin', link: '/blockchain-consultancy-v24', imageUrl: 'https://picx.zhimg.com/v2-142fa0f60f761404d5f418bad3f21a94_720w.jpg?source=172ae18b' },
    { status: 'active', title: 'Arquitectura de Hyperledger', link: '/blockchain-consultancy-v25', imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8qtQQD4IKUXU0Zx8fgBiCF4KbIiqBsRj0rQ&s' },
    { status: 'active', title: 'Arquitectura de Tezos', link: '/blockchain-consultancy-v27', imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5M7OB2rhVNjOixAb4okdPz0weGWtbAZxkdQ&s' },
    { status: 'active', title: 'Arquitectura de Cardano', link: '/blockchain-consultancy-v28', imageUrl: 'https://web-assets.esetstatic.com/wls/2018/09/blockchain-que-es-como-funciona.jpg' },
    { status: 'active', title: 'Arquitectura de Flow', link: '/flow', imageUrl: 'https://cdn.prod.website-files.com/5bf4437b68f8b29e67b7ebdc/605234147cf377aba340ce2c_flow-blockchain-quantstamp-social.png' },
    { status: 'active', title: 'Arquitectura de WAX', link: '/wax', imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHnvFs4Qz5eC3vqxQujGNstc990K4SRHYIwQ&s' },
    { status: 'active', title: 'Arquitectura de Polygon', link: '/blockchain-consultancy-v30', imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUx2xkaGp6M1dD9EoQ0V_8D6j2O9VQf62iEA&s' },
    { status: 'active', title: 'Bridges en Blockchain', link: '/blockchain-consultancy-v32', imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9bBMZJv5Bp0mLmEM8r6Y7dH__ID_1semMug&s' },
    { status: 'active', title: 'GAS', link: '/gas', imageUrl: 'https://storage.googleapis.com/prod-bkt-01-cms/sites/portalcms/b1e6bef2-post_blockchain.jpg' },
    { status: 'active', title: 'Skelly', link: '/skelly', imageUrl: 'https://img.freepik.com/foto-gratis/fondo-tecnologia-blockchain-azul-degradado_53876-124648.jpg?size=626&ext=jpg&ga=GA1.1.2008272138.1724112000&semt=ais_hybrid' },
    { status: 'active', title: 'Canales de Estado', link: '/chanels-state', imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGKN3x4L8PutIbZWnZk6Z5kj2ApjWY4qHcwA&s' },
    { status: 'active', title: 'Sharding', link: '/Sharding', imageUrl: 'https://t3.ftcdn.net/jpg/01/93/54/54/360_F_193545415_6pzIxsRxtPenzgxfVqtuXCG2BE8vitif.jpg' },
    { status: 'active', title: 'Sidechains', link: '/sidechains', imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRAPLjs6ClzMo1LW5b5QTkSoAVO65FgBDkSpQ&s' },
    { status: 'active', title: 'Plasma', link: '/plasma', imageUrl: 'https://static.vecteezy.com/system/resources/thumbnails/007/313/155/small/3d-render-blockchain-technology-for-security-with-digital-code-in-abstract-background-photo.jpg' },
    { status: 'active', title: 'Rollups', link: '/rollups', imageUrl: 'https://t4.ftcdn.net/jpg/02/80/23/05/360_F_280230556_JAkW4REfJhMvcwSvcn3IaaRHWtieFVwP.jpg' },
    { status: 'active', title: 'Riks', link: '/riks', imageUrl: 'https://static.vecteezy.com/system/resources/thumbnails/007/313/158/small/3d-render-blockchain-technology-for-security-with-digital-code-in-abstract-background-photo.jpg' },
    { status: 'active', title: 'Escalabilidad en los Datos', link: '/scalable', imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSRdoORARNhDBR5afIaQVGEPO2fG_zYpd-JResCePo77UYcPnY8Ss8t4DbRl16wRn9xoTw&usqp=CAU' },
    { status: 'active', title: 'Security', link: '/security', imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTidqizdmweFqbO-_z_GKhqLpIPMfymQASRzA0rajamk-Fivbst7w8rSC1RtdhFBPwoSHY&usqp=CAU' },
    { status: 'active', title: 'Cadenas de Bloques Híbridas', link: '/hibric-chains', imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBf4dSKAiH8lkMfK0l8DgyUgfoexah4XQFvqN4TZsQBX8Sg1IHApTWQxK94h3W7wEtvAI&usqp=CAU' },
    { status: 'active', title: 'Cadenas de Bloques Permisionadas', link: '/permissioned-chains', imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTwSk7sumP9xRaasImiVyoQDJQYsP8vNtsXbnPVX7Gx67fWpg1jsdAwRPsZ4OsdItQP2Vg&usqp=CAU' },
    { status: 'active', title: 'Introducción a las Finanzas Descentralizadas (DeFi)', link: '/defi-intro', imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQAzbo3QyvkJdpTRVWSwcFCFzGEAm7cho-VZk1lrpVdY_q66xufUqhRD9HST1o2xkKLQ0&usqp=CAU' },
    { status: 'active', title: 'Carteras y Seguridad en DeFi', link: '/defi-wallets', imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKoAw8pw5ZlkGtmXptj-gOlY24Ru1Dg9hrtb7I3_sdN__rXCxeYueMil9IoEv_lIUsh40&usqp=CAU' },
    { status: 'active', title: 'Stablecoins', link: '/stablecoins', imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxi3kWo4RQV3_cwthL5pt7bTZ-7MwLaoe03Q&s' },
    { status: 'active', title: 'Proyectos DeFi Populares', link: '/defi-projects', imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREy0UkvBEopwfmLEKzKmQXLFFN9_M1XqW8MDn5zUDL0yrwBzLSwoLu3jl54oCtDs88LLs&usqp=CAU' },
    { status: 'active', title: 'Intercambios Descentralizados (DEX)', link: '/dex', imageUrl: 'https://qph.cf2.quoracdn.net/main-qimg-fcd6271741b8f4d790ba6e51ce5587ef-lq' },
    { status: 'active', title: 'Plataformas de Préstamos en DeFi', link: '/defi-lending', imageUrl: 'https://images.theconversation.com/files/529878/original/file-20230602-15-hjwz91.jpg?ixlib=rb-4.1.0&rect=25%2C0%2C5665%2C3788&q=20&auto=format&w=320&fit=clip&dpr=2&usm=12&cs=strip' },
    { status: 'active', title: 'Liquidez y Yield Farming', link: '/yield-farming', imageUrl: 'https://www.4cornerresources.com/wp-content/uploads/2023/09/Blockchain-in-hiring.png' },
    { status: 'active', title: 'Automated Market Makers (AMM)', link: '/amm', imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTW_I305Y5QzOUqeKfI23vUn77VnEBcqMZ7B1hjhZ9kkJiwQe6BV09yaY6zw9ZT32BJLIc&usqp=CAU' },
    { status: 'active', title: 'Riesgos en DeFi', link: '/defi-risks', imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTh3vweREvuDi5dfTkBeRPTMdmpb53UIF0AmA&s' },
    { status: 'active', title: 'Gobernanza en DeFi', link: '/defi-governance', imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTAEXtNCAmC7fsCJOrWer49tNsEzWI_Cx9ffg&s' },
    { status: 'active', title: 'Proyectos DAO', link: '/dao-projects', imageUrl: 'https://www.shutterstock.com/image-illustration/ethereum-community-developers-network-blockchain-600nw-2218080597.jpg' },
    { status: 'active', title: 'Modelos de Gobernanza en DAO', link: '/dao-governance', imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLizwV-6Iz_tCtztKALfOrtcnREtGLGhKqzw&s' },
    { status: 'active', title: 'Economía de Tokens en DAO', link: '/dao-tokenomics', imageUrl: 'https://strapi-public-bucket.s3.amazonaws.com/medium_23_DAO_16259f54e7.png' },
    { status: 'active', title: 'Casos de Uso de DAO', link: '/dao-use-cases', imageUrl: 'https://www.criptonoticias.com/wp-content/uploads/2024/07/DAO-consenso-participacion-inversionistas.jpg' },
    { status: 'active', title: 'NFTs: Introducción y Conceptos Básicos', link: '/nft-intro', imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRFmut9RtsBn9D5j29CObUG-xOac07fGstKcA&s' },
    { status: 'active', title: 'Creación y Venta de NFTs', link: '/nft-creation', imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0L5rAluk3tGWmJNlFFqry9r3F8IKTZ1EuFA&s' },
    { status: 'active', title: 'NFTs en el Arte y la Cultura', link: '/nft-art-culture', imageUrl: 'https://www.ibsafoundation.org/hubfs/Asset/Arte%20e%20Scienza/Articles/NFT.png' },
    { status: 'active', title: 'Juegos Blockchain y NFTs', link: '/nft-gaming', imageUrl: 'https://blog.bitso.com/wp-content/uploads/2022/06/o-que-e-nft-1-scaled.jpg' },
    { status: 'active', title: 'NFTs y Propiedad Intelectual', link: '/nft-ip', imageUrl: 'https://cdn-3.expansion.mx/dims4/default/6c60a36/2147483647/strip/true/crop/1207x869+0+0/resize/1200x864!/format/webp/quality/60/?url=https%3A%2F%2Fcdn-3.expansion.mx%2Fad%2F60%2Fc20a59884952a93cd77c84e3da68%2Fque-paso-con-los-nft.jpg' },
    { status: 'active', title: 'Seguridad y Riesgos en NFTs', link: '/nft-security-risks', imageUrl: 'https://www.cryptopolitan.com/wp-content/uploads/2022/10/JasperArt_2023-10-16_19.39.55_upscaled.jpg' },
    { status: 'active', title: 'Estandares de Tokens (ERC-20, ERC-721, etc.)', link: '/token-standards', imageUrl: 'https://miro.medium.com/v2/resize:fit:1024/0*7tCgGNa0pN_WNwZj.png' },
    { status: 'active', title: 'Solidity: Fundamentos Básicos', link: '/solidity-basics', imageUrl: 'https://www.simplilearn.com/ice9/free_resources_article_thumb/what_is_smart_contract.jpg' },
    { status: 'active', title: 'Contratos Inteligentes', link: '/smart-contracts-course', imageUrl: 'https://blockchain.oodles.io/wp-content/uploads/Solidity-Development-2.jpg' },
    { status: 'active', title: 'Auditoría de Seguridad en Blockchain', link: '/security-audit-scale', imageUrl: 'https://itcomunicacion.com.mx/wp-content/uploads/2018/04/SeguridadFisicaDigital.jpg' },
    { status: 'active', title: 'Privacidad en Blockchain', link: '/privacy', imageUrl: 'https://xertify.co/wp-content/uploads/2023/09/Captura-de-pantalla-2023-09-01-161911.png' },
    { status: 'active', title: 'Regulación y Ética en Blockchain', link: '/regulation-ethics', imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMCMLsaNJRgKvAm74hPpbrLm_Istt5qjjXrw&s' },
    { status: 'active', title: 'Futuro de Blockchain', link: '/blockchain-future', imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2xrjiQ5Xk1nQeb7PNJHZTb54IePbmxiUgkg&s' },
];

