import { Autoplay, Navigation, Pagination } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"

const swiperOptions = {
    modules: [Autoplay, Pagination, Navigation],
    loop: true,
    slidesPerView: 'auto',
    spaceBetween: 14,
    direction: 'vertical',
    speed: 10000,
    observer: true,
    observeParents: true,
    autoplay: {
        delay: 0,
        disableOnInteraction: false
    }


}

export default function AutoSlider1() {
    return (
        <>
            {/* <Swiper {...swiperOptions} className="abc">
                <SwiperSlide>Slide 1</SwiperSlide>
            </Swiper> */}

        
                <Swiper {...swiperOptions} className="swiper-container autoslider3reverse swiper-container-vertical ">
                    <SwiperSlide>
                     <img src="https://raw.githubusercontent.com/CoachblockchainDapp/Formaciones/main/1%20(3).png" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                    <img src="https://raw.githubusercontent.com/CoachblockchainDapp/Formaciones/main/1%20(8).webp" alt="" />
                    </SwiperSlide>
                </Swiper>
        </>
    )
}
