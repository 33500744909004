import React, { useState } from 'react';
import './card.css'

const Card = ({ title, subtopics }) => {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(!isClicked);
  };

  return (
    <div className="cardBox" onClick={handleClick}>
      <div className={`card_ ${isClicked ? 'clicked' : ''}`}>
        {!isClicked && <span className="card__title">{title.toUpperCase()}</span>}
        <div className={`content_ ${isClicked ? 'show' : ''}`}>
          <div className="h_3" style={{color:'#D4AE42'}}>¿QUÉ APRENDERÁS?</div>
          {subtopics.map((subtopic, index) => (
            <p key={index} className="li" style={{color:'white'}}>{subtopic.toUpperCase()}</p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Card;