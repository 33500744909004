import React, { useState, useEffect } from 'react';
import sectionsData from './data/CasosDeUso.json'; // Ajusta la ruta según corresponda
import VideoComponent from '../QUIZ/VideoComponent';

const TextComponent = ({ data }) => {
    const [sections, setSections] = useState([]);

    useEffect(() => {
        if (data && data.sections) {
            setSections(data.sections);
        }
    }, [data]);

    return (
        <div style={{ width: '90%', fontFamily: 'Arial', padding: '20px', marginTop: '12%', textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
            {sections.map((section, index) => (
                <div key={index} style={{ marginBottom: '40px' }}>
                    <h1 style={{ color: '#004D5F', marginBottom: '20px', fontSize: '45px', lineHeight: '1.5' }}><strong>{section.tema}</strong></h1>

                    {section.subtemas.map((subtema, subIndex) => (
                        <div key={subIndex} style={{ marginBottom: '20px', maxWidth: '90%', marginLeft: 'auto', marginRight: 'auto', textAlign: 'left' }}>
                            {subtema.title && (
                                <h2 style={{ color: '#F1C40F', marginTop: '20px', marginBottom: '10px', fontSize: '32px', textAlign: 'center', lineHeight: '1.4' }}>
                                    <strong>{subtema.title}</strong>
                                </h2>
                            )}

                            {subtema.content && subtema.content.map((paragraph, pIndex) => (
                                <p key={pIndex} style={{ textAlign: 'justify', marginBottom: '20px', fontSize: '24px', lineHeight: '1.8' }}>{paragraph}</p>
                            ))}

                            {subtema.tabla && (
                                subtema.title === "Ventajas y Desventajas de la Tolerancia a Fallas Bizantinas" ? (
                                    <table style={{ width: '100%', marginBottom: '20px', borderCollapse: 'collapse', color: '#333', backgroundColor: '#f9f9f9' }}>
                                        <thead>
                                            <tr style={{ backgroundColor: '#000', color: '#fff' }}>
                                                <th style={{ padding: '10px', fontSize: '20px', fontWeight: 'bold', border: '1px solid #ddd' }}>Ventajas</th>
                                                <th style={{ padding: '10px', fontSize: '20px', fontWeight: 'bold', border: '1px solid #ddd' }}>Desventajas</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={{ padding: '10px', fontSize: '20px', textAlign: 'justify', border: '1px solid #ddd' }}>
                                                    {subtema.tabla.filter(row => row.index === "Ventajas").map((row, i) => (
                                                        <span key={i}>{row.text}<br /></span>
                                                    ))}
                                                </td>
                                                <td style={{ padding: '10px', fontSize: '20px', textAlign: 'justify', border: '1px solid #ddd' }}>
                                                    {subtema.tabla.filter(row => row.index === "Desventajas").map((row, i) => (
                                                        <span key={i}>{row.text}<br /></span>
                                                    ))}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                ) : (
                                    <table style={{ width: '100%', marginBottom: '20px', borderCollapse: 'collapse', color: '#333', backgroundColor: '#f9f9f9' }}>
                                        <tbody>
                                            {subtema.tabla.map((row, rowIndex) => (
                                                <tr key={rowIndex} style={{ borderBottom: '1px solid #ddd' }}>
                                                    <td style={{ padding: '10px', fontSize: '20px', fontWeight: 'bold', width: '10%', border: '1px solid #ddd' }}>{row.index}</td>
                                                    <td style={{ padding: '10px', fontSize: '20px', textAlign: 'justify', border: '1px solid #ddd' }}>{row.text}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                )
                            )}

                            {subtema.media && subtema.media.map((mediaItem, mediaIndex) => (
                                <div key={mediaIndex} style={{ margin: '20px 0', textAlign: 'center' }}>
                                    {mediaItem.type === 'image' && (
                                        <></>  // Aquí puedes usar tu componente de imagen personalizado
                                    )}
                                    {mediaItem.type === 'video' && (
                                        <VideoComponent videoUrl={mediaItem.url} text="" />  // Aquí se pasa la URL del video al componente personalizado
                                    )}
                                </div>
                            ))}

                            {subtema.material_estudio && (
                                <div style={{ marginTop: '20px', textAlign: 'center', maxWidth: '95%', marginLeft: 'auto', marginRight: 'auto' }}>
                                    <h2 style={{ color: '#F1C40F', marginBottom: '10px', fontSize: '28px', lineHeight: '1.4' }}><strong>Material de Estudio</strong></h2>
                                    {subtema.material_estudio.map((link, linkIndex) => (
                                        <p key={linkIndex} style={{ fontSize: '20px', marginBottom: '10px' }}>
                                            <a href={link.url} target="_blank" rel="noopener noreferrer">{link.text}</a>
                                        </p>
                                    ))}
                                </div>
                            )}

                            {subtema.expansion_y_detalles && (
                                <div style={{ marginTop: '20px', textAlign: 'left', maxWidth: '98%', marginLeft: 'auto', marginRight: 'auto' }}>
                                    {Object.entries(subtema.expansion_y_detalles).map(([key, value], index) => (
                                        <div key={index} style={{ marginBottom: '20px' }}>
                                            <h3 style={{ color: 'blue', fontSize: '20px', fontWeight: 'bold' }}>{key}</h3>
                                            {value.map((detail, i) => (
                                                <p key={i} style={{ fontSize: '20px', textAlign: 'justify', marginBottom: '10px' }}>{detail}</p>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}

                    {section.material_estudio && (
                        <div style={{ marginTop: '20px', textAlign: 'center', maxWidth: '800px', marginLeft: 'auto', marginRight: 'auto' }}>
                            <h2 style={{ color: '#F1C40F', marginBottom: '10px', fontSize: '28px', lineHeight: '1.4' }}><strong>Material de Estudio</strong></h2>
                            {section.material_estudio.map((link, linkIndex) => (
                                <p key={linkIndex} style={{ fontSize: '20px', marginBottom: '10px' }}>
                                    <a href={link.url} target="_blank" rel="noopener noreferrer">{link.text}</a>
                                </p>
                            ))}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

const App = () => {
    return (
        <TextComponent data={sectionsData} />
    );
};

export default App;
