import React from 'react';
import Layout from '../../components/layout/Layout'
import './certificate.css'; // Asumiendo que tienes un archivo CSS para estilos

function Certificate() {
  return (
    <Layout headerStyle={1} footerStyle={1} pageCls="about-us-page" >
      <div style={{ width: '100vw', display: 'flex', flexDirection: 'column' }}>
        <div className="certificate-container">
          <img src="https://raw.githubusercontent.com/CoachblockchainDapp/Certificate/main/1.png" alt="Certificate" className="certificate-image" />
        </div>
      </div>
    </Layout>

  );
}

export default Certificate;
