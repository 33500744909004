const enlaces = [
    {
      key: '0',
      titulo: 'Historia del dinero',
      url: 'https://blockchain2-bd9d5.web.app/historia-del-dinero-scorm12/index.html'
    },
    {
      key: '1',
      titulo: 'Introducción a la Blockchain fundamentos y conceptos básicos',
      url: 'https://blockchain2-bd9d5.web.app/introduccion-a-la-blockchain-fundamentos-y-conceptos-basicos-scorm12/index.html'
    },
    {
      key: '2',
      titulo: 'B Industria',
      url: 'https://blockchain2-bd9d5.web.app/blockchain-en-la-industria-scorm12/index.html'
    },
    {
        key: '3',
        titulo: 'BTC',
        url: 'https://blockchain2-bd9d5.web.app/bitcoin-un-antes-y-un-despues-en-la-historia-del-dinero-scorm12/index.html'
      },
    {
      key: '4',
      titulo: 'Criptomonedas y Tokens',
      url: 'https://blockchain2-bd9d5.web.app/criptomonedas-y-tokens-scorm12/index.html'
    },
    // Agrega más cursos según sea necesario
    {
        key: '5',
        titulo: 'tecnologia',
        url: 'https://blockchain2-bd9d5.web.app/introduccion-a-la-tecnologia-blockchain-scorm12/index.html'
      },
      {
        key: '6',
        titulo: 'Web3',
        url: 'https://blockchain2-bd9d5.web.app/dominando-web3-scorm12/index.html'
      },
      {
        key: '7',
        titulo: 'Mineria',
        url: 'https://blockchain2-bd9d5.web.app/mineria-del-blockchain-scorm12/index.html'
      },
      {
          key: '8',
          titulo: 'Criptografía en Blockchain',
          url: 'https://blockchain2-bd9d5.web.app/criptografia-en-blockchain-scorm12/index.html'
        },
        {
            key: '9',
            titulo: 'Criptografía Asimétrica y las Claves Públicas y Privadas',
            url: 'https://blockchain2-bd9d5.web.app/criptografia-asimetrica-y-las-claves-publicas-y-privadas-scorm12/index.html'
          },



      {
        key: '10',
        titulo: 'Hash y Sha256',
        url: 'https://blockchain2-bd9d5.web.app/explorando-hash-y-sha-256-scorm12/index.html'
      },
      {
        key: '11',
        titulo: 'Introducción a Wallets con Blockchain',
        url: 'https://blockchain2-bd9d5.web.app/introduccion-a-wallets-con-blockchain-scorm12/index.html'
      },
      {
        key: '12',
        titulo: 'B Industria',
        url: 'https://blockchain2-bd9d5.web.app/arboles-de-merkle-en-blockchain-scorm12/index.html'
      },
      {
          key: '13',
          titulo: 'sc',
          url: 'https://blockchain2-bd9d5.web.app/contratos-inteligentes-scorm12/index.html'
        },
      {
        key: '14',
        titulo: 'Criptomonedas y Tokens',
        url: 'https://blockchain2-bd9d5.web.app/nft-scorm12/index.html'
      },
      {
        key: '15',
        titulo: 'Criptomonedas y Tokens',
        url: 'https://blockchain2-bd9d5.web.app/aspectos-legales-y-regulatorios-del-blockchain-scorm12/index.html'
      },
      {
        key: '16',
        titulo: 'Criptomonedas y Tokens',
        url: 'https://blockchain2-bd9d5.web.app/finanzas-descentralizadas-defi-sus-beneficios-y-utilidades-scorm12/index.html'
      },
      {
        key: '17',
        titulo: 'Criptomonedas y Tokens',
        url: 'https://blockchain2-bd9d5.web.app/criptomonedas-y-tokens-scorm12/index.html'
      },
      {
        key: '18',
        titulo: 'Dapps',
        url: 'https://blockchain2-bd9d5.web.app/desarrollo-de-dapps-creando-aplicaciones-en-la-blockchain-scorm12/index.html'
      },
  ];
  
  export default enlaces;
  