import React from "react";
import Featured from "../../components/featured/Featured";
import OrderWidget from "../../components/orderwidget/OrderWidget";
import Layout from "../../components/layout/Layout"
import "./panel.css";

export default function Dashboard() {
  return (
    <Layout>
      <div className="homeContainer">

        <Featured />
    
     
      
        <div className="orderAndMenberWidget">
          <OrderWidget />

        </div>
      </div>
    </Layout>
  );
}


