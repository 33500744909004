import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import WalletRegisterForm from './WalletRegisterForm'; // Asegúrate de que la ruta sea correcta
import { RegisterForm } from './RegistrationForm';
import Layout from "../../components/layout/Layout"



const RegistrationCard = () => {
    const [activeForm, setActiveForm] = useState('code'); // 'code' o 'wallet'
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/kits');
    };

    return (
        <Layout headerStyle={1} footerStyle={1} pageCls="my-layout-container">

            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '80%',
                margin: '0 auto',
                padding: '20px 0',
                backgroundColor: '#0A5364',
                borderRadius: '25px',
                border: '5px solid #093e49',
                color: 'white',
                fontFamily: '"Segoe UI", Tahoma, Geneva, Verdana, sans-serif'
            }}>
                <p style={{
                    fontSize: '30px',
                    color: 'white',
                    textAlign: 'center',
                    marginBottom: '20px' // Añade un margen si es necesario para separar el texto del botón
                }}>
                    Si no cuentas con una wallet o un código de referido, puedes ir a la sección de
                </p>
                <button
                    onClick={handleClick}
                    style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: '#CFA647',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                        fontSize: '40px',
                        textAlign: 'center' // Asegura que el estilo de texto del botón está centrado
                    }}>
                    Compra Directa
                </button>
                <p style={{
                    fontSize: '30px',
                    color: 'white',
                    textAlign: 'center',
                    marginTop: '20px' // Añade un margen si es necesario para separar el texto del botón
                }}>
                    para poder registrarte.
                </p>
            </div>



            <div style={{ width: '100vw', display: 'flex', flexDirection: 'column' }}>
                <div className="registration-card">
                    <h2 className="registration-title">Registro en la Plataforma</h2>

                    <div className="form-selection">
                        <button
                            className={`form-button ${activeForm === 'code' ? 'active' : ''}`}
                            onClick={() => setActiveForm('code')}>
                            Registro con Código
                        </button>
                        <button
                            className={`form-button ${activeForm === 'wallet' ? 'active' : ''}`}
                            onClick={() => setActiveForm('wallet')}>
                            Registro con Wallet
                        </button>
                    </div>
                    <div className="form-container">
                        {activeForm === 'code' ? <RegisterForm /> : <WalletRegisterForm />}
                    </div>

                </div>
            </div>
        </Layout>
    );
};

export default RegistrationCard;
