// Importa las bibliotecas
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Configura i18next
i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: {
                    welcomeMessage: 'Welcome to my website!',
                    TitleHome: 'The value of Blockchain Technology',
                    SubtitleHome: 'Learn about Blockchain Technology and Digital Art by training with the best professionals.',
                    HomeButtonStart: 'Start',
                    DiscoverButton: 'Discover',


                    //Comunidad
                    CommunityParrafo: "Start with the easiest and safest platform to buy, trade, and learn about digital art and blockchain technology. Join our educational community and explore the exciting world of blockchain technology and NFTs.",
                    uniqueExperience: 'Unique experience in the world of blockchain education',
                    startButtonText: 'Start',
                    whyChooseUs: 'Why Choose Us?',
                    paymentInstant: 'Instant Payments',
                    paymentInstantDescription: 'We make instant payments, providing a fast and efficient experience for our users.',
                    security: 'Security',
                    securityDescription: 'Our platform ensures the security of your transactions and personal data, using the latest security measures.',
                    smartContracts: 'Management with Smart Contracts',
                    smartContractsDescription: 'We offer efficient management through smart contracts, facilitating transparent and automated processes for our users.',
                    frequentlyAskedQuestions: 'Frequently Asked Questions',
                    whyNFTPopular: '1. Why are NFTs becoming popular?',
                    whyNFTPopularAnswer: 'NFTs (Non-Fungible Tokens) have become popular due to their ability to represent unique and indivisible ownership of digital assets, such as artwork and exclusive collections, using blockchain technology.',
                    howKeepNFTSafe: '2. How do I keep my NFTs safe?',
                    howKeepNFTSafeAnswer: 'To keep your NFTs secure, it is crucial to use reliable wallets and platforms, enable two-factor authentication, and follow best practices for digital security, such as safeguarding private keys.',
                    whatIsBlockchain: '3. What is blockchain?',
                    whatIsBlockchainAnswer: 'Blockchain is a shared and immutable ledger that facilitates the process of recording transactions and tracking assets on a business network. It can represent both tangible and intangible assets, offering security and efficiency.',
                    whatIsNFT: '4. What is an NFT?',
                    whatIsNFTAnswer: 'An NFT (Non-Fungible Token) is a unique digital asset that uses blockchain technology to ensure its authenticity and ownership. Each NFT is unique and cannot be exchanged on a one-to-one basis with other tokens.',
                    howSetUpLedger: '5. How do I set up my Ledger?',
                    howSetUpLedgerAnswer: 'Setting up your Ledger to store your NFTs involves following the specific procedures provided by the manufacturer. Generally, it involves connecting your Ledger to a compatible wallet and following the setup steps.',
                    typesOfWallets: '6. What are the types of wallets?',
                    typesOfWalletsAnswer: 'There are various types of wallets, with the most common being software wallets (online, desktop, and mobile), hardware wallets (physical devices), and paper wallets (storage in printed form). Each type has its own advantages in terms of accessibility and security.',
                    whatAreStablecoins: '7. What are stablecoins?',
                    whatAreStablecoinsAnswer: 'Stablecoins are cryptocurrencies designed to maintain a stable value by linking to external assets such as fiat currencies or commodities. Their main function is to offer price stability compared to more volatile cryptocurrencies, such as Bitcoin, facilitating their use in everyday transactions.',
                    decentralization: '8. What is decentralization?',
                    decentralizationAnswer: 'Decentralization involves the distribution of control and decision-making in a cryptographic network among multiple participants, eliminating dependence on a central authority. This improves security and resistance to censorship, fundamental features in the design of cryptocurrencies.',
                    whatIsBitcoin: '9. What is Bitcoin and how does it work?',
                    whatIsBitcoinAnswer: 'Bitcoin is the first and most well-known cryptocurrency, created in 2009 by an entity or person using the pseudonym Satoshi Nakamoto. It operates on a decentralized peer-to-peer network, where users can make direct transactions without the need for intermediaries like banks. The underlying technology, called blockchain, securely and immutably records all transactions. Additionally, Bitcoin uses a process called mining, where network participants compete to validate transactions and add new blocks to the chain, being rewarded with new bitcoins. Its main purpose is to act as a censorship-resistant digital form of money, allowing value transfers without relying on a central entity. Bitcoin has gained popularity as a digital asset and store of value.',
                    whyUseBlockchainTechnology: '10. Why is blockchain technology used?',
                    whyUseBlockchainTechnologyAnswer: 'Blockchain technology is used in cryptocurrencies like Bitcoin due to its fundamental features of decentralization, security, and transparency. The blockchain acts as a distributed and immutable ledger of all transactions, eliminating the need for a central control entity. This not only provides security by preventing data tampering but also allows direct peer-to-peer transactions without intermediaries. The inherent decentralization of the blockchain ensures there is no single point of failure, and the network is resistant to censorship. Additionally, the transparency of the blockchain allows participants to verify all transactions, building a reliable and trustworthy system on which cryptocurrencies like Bitcoin operate.',
                    stillHaveQuestions: 'Still have questions?',
                    chatWithTeam: 'Chat with our friendly team',
                    chatPrompt: "Chat with our friendly team",

                    //Details 
                    pageTitle: 'NFTS',
                    artDigitalDescription1: 'Digital art is a creative artistic discipline that uses digital technology to create works of art. Unlike other forms of art, digital art relies on the use of digital programs and tools for the creation of images, videos, animations, and more.',
                    artDigitalDescription2: 'Digital Art has gained popularity and relevance in the recent years of the digital era, being used in various contexts such as advertising, graphic design, animation, and in the production of video games. It is a form of art that can be accredited with a certificate of authenticity and exhibited in galleries and museums.',
                    newCollectionsTitle: 'New Collections',
                    discoverMoreLink: 'Discover More',
                    nftDescription: "NFT (Non-Fungible Token) are digital works that can be bought and sold like any other type of property, but they do not have a tangible form. For example, a digital photograph or video are pieces of art that do not exist in the physical world. Until now, artists working with digital media had real difficulties in marketing their product because they could not sign and serialize their work as would be done with an engraving or a printed photo, that is, it was impossible to distinguish the original from a copy. With the inclusion of an NFT, authenticity can be confirmed, with the added benefit of recording the history of future transactions, which means that the artist can also obtain copyright for each transaction. Let's be clear that an NFT is a certificate. It is not a type of artwork, a creation technique, or an art movement.",
                    nftRevolutionText: 'NFTs have revolutionized the art world, offering new opportunities for artists to showcase their talent and for collectors to invest in art like never before. Join us in the world of NFTs and embark on a journey where art, culture, and blockchain technology meet.',


                    //Explore 
                    exploreCollectionsTitle: 'Explore Collections',

                    //Formaciones 
                    courseTitle: 'Mastering Blockchain: Comprehensive Training in Finance and Development',
                    courseDescription: 'Upon completing this comprehensive training, you will have gained complete mastery of the principles and applications of Blockchain Technology. You will be able to develop and create Blockchain applications, understand and write smart contracts, explore scalable networks like Polygon or Binance Smart Chain, and conceptualize real projects using Python and Ethereum. Additionally, you will acquire solid knowledge in Solidity, security, and decentralized finance.',
                    depthLevel: 'Depth Level',
                    depthLevelDescription: 'This course offers a comprehensive approach, from basics to advanced levels. The depth of coverage is tailored to provide complete and accessible learning.',
                    targetAudience: 'Target Audience',
                    targetAudienceDescription: "This training program is designed for tax lawyers, accountants, economists, advisors, managers, entrepreneurs, and students from various fields, including computer science and finance, who wish to immerse themselves in the world of blockchains. With a comprehensive approach, this program caters to both experienced individuals and beginners, ensuring a solid understanding of fundamental concepts.",
                    basicTraining: 'Basic Blockchain Training',
                    basicTrainingPoints: [
                        'Accessibility: Blockchain training should be accessible to all individuals.',
                        'Essential Content: Understand the fundamental concepts of blockchain technology.',
                        'Skills Development: Learn to develop applications, smart contracts, finance, and the future of blockchain.',
                        'Preparation for Advanced Levels: Acquire knowledge that serves as a foundation for advanced blockchain training.',
                        'Inclusivity and Diversity: Foster inclusive and diverse participation in blockchain training.'
                    ],
                    additionalResources: 'Additional Resources',
                    additionalResourcesDescription: 'Additional resources such as reading materials, videos, and practical exercises will be provided to enrich the learning experience. These resources will support students with different learning styles.',
                    studentFeedback: 'Student Feedback',
                    studentFeedbackDescription: 'Student feedback is valued and will be used to adjust the duration of modules, ensuring that the comprehension needs of each student are met.',
                    learningPathContent: 'This course will also include a significant focus on economic and financial topics, providing a deep understanding of the economic implications of Blockchain technology. The future of decentralized finance will be explored, offering a comprehensive view of how this technology will transform traditional economic models.',


                    //FOOTER
                    mercado: "Market",
                    recursos: "Resources",
                    cuenta: "Account",
                    empresa: "Marketplace",
                    additionalResources: "Additional Resources",
                    additionalResourcesDescription: "Additional resources, such as reading material, videos, and practical exercises, will be provided to enrich the learning experience. These resources will support students with different learning styles.",
                    studentFeedback: "Student Feedback",
                    studentFeedbackDescription: "Student feedback is valued and will be used to adjust the duration of modules, ensuring that the comprehension needs of each student are met.",
                    learningPath: "Learning Path",
                    allNFTs: "All NFTs",
                    virtualWorlds: "Details",
                    ourNFTs: "Our NFTs",
                    digitalArt: "Digital Art",
                    helpCenter: "Help Center",
                    platformStatus: "Platform Status",
                    aboutUs: "About Us",
                    discover: "Discover",
                    packages: "Packages",
                    authors: "Authors",
                    collectionProfile: "Collection Profile",
                    privacyPolicy: "Privacy Policy",
                    termsOfService: "Terms of Service",
                    widgetTitle: "Join the community!",

                    //Sladier colletion
                    delay: '0s',
                    name: 'Coach Blockchains',
                    itemName: 'TERRA NFT COLLECTION',
                    itemNamea: 'AQUA NFT COLLECTION',
                    itemNamee: 'EAR NFT COLLECTION',

                    //Butons
                    createdBy: "Created By:",
                    join: "Join",

                    //Navbar
                    contacto: "Soporte",
                    inicio: "Home",
                    nfts: "NFTs",
                    detalles: "Details",
                    comunidad: "Community",
                    formacion: "Training",
                    registro: "Register",
                    conectarWallet: "Connect Wallet",


                    //Entrenamiento Card topics
                    introductionToBlockchainTechnology: "Introduction to Blockchain Technology",
                    typesOfBlockchains: "Types of Blockchains",
                    publicPrivateAndConsortium: "Public, Private, and Consortium",
                    blockchainFundamentals: "Blockchain Fundamentals",
                    blockchainHistory: "Blockchain History",
                    useCases: "Use Cases",
                    bitcoinAndCryptocurrencies: "Bitcoin and Cryptocurrencies",
                    bitcoin: "Bitcoin",
                    altcoins: "Altcoins",
                    roleInTheMarket: "Role in the Market",
                    diversityOfAltcoins: "Diversity of Altcoins",
                    ethereum2: "Ethereum 2.0",
                    stablecoins: "Stablecoins",
                    typesOfStablecoins: "Types of Stablecoins",
                    usesAndApplications: "Uses and Applications",
                    ecosystems: "Ecosystems",
                    evolution: "Evolution",
                    centralizationVsDecentralization: "Centralization vs. Decentralization",
                    centralization: "Centralization",
                    decentralization: "Decentralization",
                    contrasts: "Contrasts",
                    impactsOnFinancialSystems: "Impacts on Financial Systems",
                    dataUsage: "Data Usage",
                    walletsAndSecurity: "Wallets and Security",
                    hotWallets: "Hot Wallets",
                    hardware: "Hardware",
                    software: "Software",
                    coldWallets: "Cold Wallets",
                    paper: "Paper",
                    transactions: "Transactions",
                    securityLayers: "Security Layers",
                    storageMethods: "Storage Methods",
                    tensor: "Trezor",
                    metamask: "Metamask",
                    layer2InWallets: "Layer-2 in Wallets",
                    futureOfCryptocurrencies: "Future of Cryptocurrencies",
                    futureProjectionsOfTheCryptoMarket: "Future Projections of the Crypto Market",
                    coinMarketCap: "CoinMarketCap",
                    scalabilityInBlockchain: "Scalability in Blockchain",
                    technologicalInnovations: "Technological Innovations",
                    applicationsInCryptocurrencies: "Applications in Cryptocurrencies",
                    emergingDevelopments: "Emerging Developments",
                    theirInfluence: "Their Influence",
                    decentralizedFinance: "Decentralized Finance (DeFi)",
                    whatIsDeFi: "What is DeFi?",
                    currentFunctionality: "Current Functionality",
                    futureTrends: "Future Trends",
                    defisPotential: "Potential of DeFi",
                    impactOfCurrencyDevaluationAndInflationOnDeFi: "Impact of Currency Devaluation and Inflation on DeFi",
                    decentralizedApplications: "Decentralized Applications (DApps)",
                    introductionToDApps: "Introduction to DApps",
                    dAppsArchitecture: "DApps Architecture",
                    commonUseCases: "Common Use Cases",
                    securityInDApps: "Security in DApps",
                    interoperabilityInDApps: "Interoperability in DApps",
                    challengesAndFutureOfDApps: "Challenges and Future of DApps",
                    nonFungibleTokens: "Non-Fungible Tokens (NFTs)",
                    whatAreNFTs: "What Are NFTs?",
                    technologyBehindNFTs: "Technology Behind NFTs",
                    usesAndApplicationsNFTs: "Uses and Applications of NFTs",
                    economyOfNFTs: "Economy of NFTs",
                    developmentOfNFTs: "Development of NFTs",
                    culturalImpactAndFutureOfNFTs: "Cultural Impact and Future of NFTs",
                    smartContracts: "Smart Contracts",
                    developmentAndLanguages: "Development and Languages",
                    implementationsAndExamples: "Implementations and Examples",
                    securityAndAudit: "Security and Audit",
                    applicationsAndFuture: "Applications and Future",
                    marketsOnBlockchain: "Markets on Blockchain",
                    typesOfMarkets: "Types of Markets",
                    developmentAndOperation: "Development and Operation",
                    applicationsAndUseCases: "Applications and Use Cases",
                    challengesAndFutureOfMarketsOnBlockchain: "Challenges and Future of Markets on Blockchain",

                    //formacion
                    titleDominandoBlockchain: "Dominating Blockchain: Comprehensive Training in Finance and Development",
                    descriptionCourse: "Upon completing this comprehensive training, you will have achieved complete mastery of the principles and applications of Blockchain Technology. You will be able to develop and create Blockchain applications, understand and write smart contracts, explore scalable networks such as Polygon or Binance Smart Chain, and conceptualize real projects using Python and Ethereum. In addition, you will acquire solid knowledge in Solidity, security, and decentralized finance.",
                    depthLevelTitle: "Depth Level",
                    depthLevelDescription: "This course offers an integral approach, from the most basic to advanced levels. The depth of coverage is adapted to provide a complete and accessible learning experience.",
                    targetAudienceTitle: "Target Audience",
                    targetAudienceDescription: "This training program is designed for tax lawyers, accountants, entrepreneurs, and students from various fields, including computer science and finance, who wish to dive into the world of blockchains. With an integral approach, this program is suitable for both those with experience and beginners, ensuring a solid understanding of the fundamental concepts.",
                    basicBlockchainTrainingTitle: "Basic Blockchain Training",
                    basicBlockchainTrainingAccessibility: "Blockchain training must be accessible to everyone.",
                    basicBlockchainTrainingContent: "Understand the fundamental concepts of blockchain technology.",
                    basicBlockchainTrainingDevelopment: "Learn to develop applications, smart contracts, finance, and the future of blockchain.",
                    basicBlockchainTrainingPreparation: "Acquire knowledge that will serve as a foundation for advanced blockchain training.",
                    basicBlockchainTrainingInclusivity: "Encourage inclusive and diverse participation in blockchain training.",
                    additionalResourcesTitle: "Additional Resources",
                    additionalResourcesDescription: "Additional resources, such as reading material, videos, and practical exercises, will be provided to enrich the learning experience. These resources will support students with different learning styles.",
                    studentFeedbackTitle: "Student Feedback",
                    studentFeedbackDescription: "Student feedback is valued and will be used to adjust the duration of the modules, ensuring that each student's understanding needs are met.",
                    learningPathTitle: "Learning Path",
                    queVamosAprender: "What Will We Learn?",
                    courseContentFundamentalConcepts: "Fundamental concepts in finance and their relationship with cryptocurrencies.",
                    courseContentFinancialArchitecture: "Analysis of the financial architecture that facilitates the management of digital assets.",

                    courseContentTitle: "Course Content",
                    courseContentOrigin: "Understand the origins of Blockchain creation.",
                    courseContentArchitecture: "Study the architecture that allows for the distribution of information.",
                    courseContentCryptography: "Introduction to public key cryptography through the study of the unique characteristics of the blockchain.",
                    courseContentHashing: "Development of the hashing algorithm that acts as a cornerstone in Blockchain, understanding mining and the PoW and PoS protocols.",
                    economicFocusTitle: "Economic Focus",
                    economicFocusDescription: "This course will also include a significant focus on economic and financial topics, providing a deep understanding of the economic implications of Blockchain Technology. The future of decentralized finance will be explored, and a comprehensive vision of how this technology will transform traditional economic models will be obtained.",
                    totalHoursDescription: "With a total of 170 hours of training and counseling, with 160 hours dedicated to effective training and the rest reserved for specialized consultations, this balanced course will prepare students to tackle challenges and opportunities in both the technical and economic aspects of Blockchain Technology.",

                    basicBlockchainTrainingHeading: "Basic Blockchain Training",
                    basicBlockchainTrainingAccessibilityTitle: "Accessibility",
                    basicBlockchainTrainingAccessibilityDescription: "Blockchain training should be accessible to everyone.",
                    basicBlockchainTrainingEssentialContentTitle: "Essential Content",
                    basicBlockchainTrainingEssentialContentDescription: "Understand the fundamental concepts of blockchain technology.",
                    basicBlockchainTrainingSkillDevelopmentTitle: "Skill Development",
                    basicBlockchainTrainingSkillDevelopmentDescription: "Learn to develop applications, smart contracts, finance, and the future of blockchain.",
                    basicBlockchainTrainingAdvancedPreparationTitle: "Preparation for Advanced Levels",
                    basicBlockchainTrainingAdvancedPreparationDescription: "Acquire knowledge that serves as a foundation for advanced blockchain training.",
                    basicBlockchainTrainingInclusivityDiversityTitle: "Inclusivity and Diversity",
                    basicBlockchainTrainingInclusivityDiversityDescription: "Promote inclusive and diverse participation in blockchain training.",

                    //Road map
                    introductionToBlockchainTitle: "Introduction to Blockchain",
                    introductionToBlockchainDescription: "Basic concepts and fundamental principles.",
                    cryptographyAndSecurityTitle: "Cryptography and Security",
                    cryptographyAndSecurityDescription: "Understanding the underlying cryptography and security in Blockchain.",
                    bitcoinAndPublicBlockchainTitle: "Bitcoin and Public Blockchain",
                    bitcoinAndPublicBlockchainDescription: "Exploring the first application of Blockchain: Bitcoin.",
                    ethereumAndSmartContractsTitle: "Ethereum and Smart Contracts",
                    ethereumAndSmartContractsDescription: "Delving into Ethereum and smart contract development.",
                    decentralizedFinanceDefiTitle: "Decentralized Finance (DeFi)",
                    decentralizedFinanceDefiDescription: "Understanding how DeFi is transforming traditional finance.",
                    nonFungibleTokensNftTitle: "Non-Fungible Tokens (NFT)",
                    nonFungibleTokensNftDescription: "Exploring the world of NFTs and their impact on art and digital ownership.",
                    blockchainInvestingAndTradingTitle: "Investments and Trading in Blockchain",
                    blockchainInvestingAndTradingDescription: "Learning investment and trading strategies in the cryptocurrency market.",
                    blockchainApplicationDevelopmentTitle: "Blockchain Application Development",
                    blockchainApplicationDevelopmentDescription: "Diving into the development of decentralized applications (DApps).",
                    businessUseCasesTitle: "Business Use Cases",
                    businessUseCasesDescription: "Exploring how businesses are adopting Blockchain for their operations.",
                    regulationAndEthicsTitle: "Regulation and Ethics",
                    regulationAndEthicsDescription: "Understanding the ethical and legal aspects of Blockchain technology.",

                    totalHours: "Total hours: 170 hours",
                    totalWeeks: "Total weeks: 31 weeks",
                    hoursPerWeek: "Hours per week: 5 hours/week",

                    //Boxing de registro
                    acquisitionNFT: 'Acquisition of an NFT for training',
                    gifFormatTraining: 'Training: Introduction to Blockchain',
                    threeLevelEarnings: 'Referral Earnings: 20%, 15%, 10%',
                    noSupport: 'No support',
                    twoNFTsPerTrainingSession: 'Acquisition of one NFT for training',
                    fiveTrainingSessions: 'Five Introduction to Blockchain Courses',
                    fourLevelEarnings: 'Referral Earnings: 25%, 15%, 10%, 5%',
                    tenTrainingSessions: 'Acquisition of two NFTs for training',
                    twoNsPerTrainingSession: 'Ten Introduction to Blockchain Courses',
                    fourLevelEarnings: 'Referral Earnings: 30%, 15%, 10%, 5%',
                    fifteenTrainingSessions: 'Fifteen Introduction to Blockchain Courses',
                    fiveNFTsPerTrainingSession: 'Acquisition of two NFTs for training',
                    fourLevelEarnings: 'Referral Earnings: 30%, 15%, 10%, 5%',
                    oneTwentyNFTsSixtyTraining: '120 NFTs for the buyer and 1 NFT per training',
                    continuousTrainingAccess: 'Access to continuous training',
                    officialCertificateAccess: 'Access to an official certificate: 1 Blockchain Consulting Course and the rest Introduction to Blockchain Courses',
                    multiLevelEarnings: 'Referral Earnings: 30%, 15%, 10%, 5%, 3%, 2%',


                    basicLevel: 'Basic',
                    advancedLevel: 'Advanced',
                    universityLevel: 'Academic',

                    //Contactos 
                    // Nuevas claves y traducciones en inglés
                    contactUs: 'Contact Us',
                    name: 'Name*',
                    email: 'Email*',
                    message: 'Message*',
                    subject: 'Subject*',
                    anyQuestions: 'Any questions? Need assistance? Feel free to contact us.',
                    send: 'Send',

                    //Formacion-1
                    materials: "Materials",
                    estimatedTime: "Estimated time",
                    time: "hours",

                    introductionToCryptography: 'Introduction to Cryptography',
                    cryptographyFundamentals: 'Basic principles and fundamentals of cryptography.',
                    cryptographyResources: 'Articles, Tutorials',
                    introductionDuration: '2:00',
                    publicKeyCryptography: 'Public Key Cryptography',
                    detailedPublicKeyCryptography: 'Detailed study of public key cryptography in the context of Blockchain.',
                    publicKeyCryptographyDocuments: 'Documentation, Practical Examples',
                    publicKeyCryptographyDuration: '3:00',
                    hashingAlgorithms: 'Hashing Algorithms',
                    hashingDevelopment: 'Development and application of hashing algorithms in information security.',
                    hashingTutorials: 'Tutorials, Code Examples',
                    hashingDuration: '2:30',
                    financialTransactionsSecurity: 'Security in Financial Transactions',
                    financialTransactionsExploration: 'Exploration of cryptography applied to financial transactions and data security.',
                    financialTransactionsGuides: 'Guides, Articles',
                    financialTransactionsDuration: '5:00',
                    blockchainSecurityProtocols: 'Security Protocols in Blockchain',
                    securityProtocolsStudy: 'Study of security protocols such as SSL/TLS in the context of Blockchain.',
                    blockchainSecurityProtocolsDocuments: 'Documentation, Case Studies',
                    blockchainSecurityProtocolsDuration: '4:30',
                    securityAuditingAndTesting: 'Auditing and Security Testing',
                    securityAuditingTechniques: 'Techniques and best practices for auditing and testing security in Blockchain systems.',
                    securityAuditingGuides: 'Guides, Practical Examples',
                    securityAuditingDuration: '2:00',
                    quantumCryptography: 'Quantum Cryptography',
                    quantumCryptographyIntroduction: 'Introduction to quantum cryptography and its impact on information security.',
                    quantumCryptographyArticles: 'Articles, Research',
                    quantumCryptographyDuration: '1:30',
                    currentSecurityChallenges: 'Current Challenges in Security',
                    securityChallengesExploration: 'Exploration of current challenges and threats in the field of computer security.',
                    securityChallengesAnalysis: 'Analysis, Case Studies',
                    securityChallengesDuration: '1:30',
                    totalModuleDuration: 'Total Module Duration',
                    moduleDurationDescription: 'This provides an overview of the content and duration of the Cryptography and Security course.',
                    digitalResources: 'Digital Resources',
                    totalDuration: 'Approximately 20:00',

                    //Formacion-1
                    blockchainIntroduction: 'Welcome to the World of Blockchain!',
                    blockchainIntroductionDescription: 'A warm introduction to the course, exploring the basics of blockchain.',
                    blockchainAdditionalMaterials: 'Additional materials available on the website.',
                    blockchainIntroductionDuration: '54:06',

                    blockchainUniverseExploration: 'Exploring the Blockchain Universe',
                    blockchainUniverseDetail: 'A detailed journey through the fundamentals of blockchain, including its origin, structure, and operation.',
                    blockchainUniverseResources: 'Documentation, Practical Examples',
                    blockchainUniverseDuration: '1:19:00',

                    blockchainArchitecture: 'Architecture at the Core of Blockchain',
                    blockchainArchitectureInsights: 'Discovering the structure and architecture that underlie blockchain technology.',
                    blockchainArchitectureResources: 'Articles, Tutorials',
                    blockchainArchitectureDuration: '11:02',

                    blockchainKeyAttributes: 'Key Attributes of Blockchain',
                    blockchainAttributesAnalysis: 'Investigating the essential characteristics that distinguish blockchain as a unique and powerful technology.',
                    blockchainAttributesResources: 'Guides, Code Examples',
                    blockchainAttributesDuration: '32:37',

                    technicalAspectsOfBlockchain: 'Technical Aspects of Blockchain and Digital Currencies',
                    blockchainTechnicalAspectsDetail: 'Delving into the technical aspects of blockchain and its relationship with cryptocurrencies.',
                    blockchainTechnicalAspectsResources: 'Documentation, Case Studies',
                    blockchainTechnicalAspectsDuration: '1:18:04',

                    ethereumAndSmartContracts: 'Ethereum and Its Role in Smart Contracts',
                    ethereumRoleDetail: 'A detailed analysis of Ethereum and its vital role in the execution of smart contracts.',
                    ethereumResources: 'Tutorials, Articles',
                    ethereumDuration: '45:14',

                    blockchainForks: 'Forks on the Road: The Forks',
                    forksImpactAnalysis: 'Exploring "forks" in the context of blockchain and their impact on networks.',
                    forksResources: 'Guides, Code Examples',
                    forksDuration: '13:30',

                    tokensDeciphering: 'Deciphering Tokens',
                    tokensRoleExploration: 'Examining the concept of tokens and their essential role in the blockchain ecosystem.',
                    tokensResources: 'Documentation, Tutorials',
                    tokensDuration: '21:35',

                    blockchainMethods: 'Methods in Blockchain',
                    methodsExploration: 'Exploring the methods used in the development and operation of blockchain.',
                    methodsAdditionalMaterials: 'Additional materials available on the website.',
                    methodsDuration: '1:00:00',

                    essentialResources: 'Essential Resources',
                    resourcesCompilation: 'Compilation of key resources for learning and working with blockchain technology.',
                    resourcesLinks: 'Links to recommended books, videos, and courses.',
                    resourcesDuration: '1:00:00',

                    altCoinsInsight: 'Extra: A Deep Dive into Altcoins',
                    altCoinsAnalysis: 'A closer analysis of "Alt Coins" and their position in the vibrant cryptocurrency market.',
                    altCoinsResources: 'Articles, Research',
                    altCoinsDuration: '45:20',

                    totalModuleDurationKey: 'Total Module Duration',
                    moduleDurationOverview: 'This provides an overview of the content and duration of the blockchain introduction course.',
                    moduleDigitalResources: 'Digital Resources',
                    totalDuration: 'Approximately 10:00',

                    titleFormacion1: 'Introduction to Blockchain',
                    subtitleFormacion1: 'Upon completing this module, students will have gained a comprehensive understanding of the principles and applications of Blockchain Technology. They will develop skills to create Blockchain applications, understand and write smart contracts, explore networks like Polygon or Binance Smart Chain, and conceptualize real projects using Python and Ethereum.',

                    //Formacion-2
                    introductionToCryptography: 'Introduction to Cryptography',
                    cryptographyFundamentals: 'Basic principles and fundamentals of cryptography.',
                    cryptographyResources: 'Articles, Tutorials',
                    introductionDuration: '2:00',
                    publicKeyCryptography: 'Public Key Cryptography',
                    detailedPublicKeyCryptography: 'In-depth study of public key cryptography in the context of Blockchain.',
                    publicKeyCryptographyDocuments: 'Documentation, Practical Examples',
                    publicKeyCryptographyDuration: '3:00',
                    hashingAlgorithms: 'Hashing Algorithms',
                    hashingDevelopment: 'Development and application of hashing algorithms in information security.',
                    hashingTutorials: 'Tutorials, Code Examples',
                    hashingDuration: '2:30',
                    financialTransactionsSecurity: 'Security in Financial Transactions',
                    financialTransactionsExploration: 'Exploration of cryptography applied to financial transactions and data security.',
                    financialTransactionsGuides: 'Guides, Articles',
                    financialTransactionsDuration: '5:00',
                    blockchainSecurityProtocols: 'Security Protocols in Blockchain',
                    securityProtocolsStudy: 'Study of security protocols like SSL/TLS in the context of Blockchain.',
                    blockchainSecurityProtocolsDocuments: 'Documentation, Case Studies',
                    blockchainSecurityProtocolsDuration: '4:30',
                    securityAuditingAndTesting: 'Security Auditing and Testing',
                    securityAuditingTechniques: 'Techniques and best practices for auditing and testing security in Blockchain systems.',
                    securityAuditingGuides: 'Guides, Practical Examples',
                    securityAuditingDuration: '2:00',
                    quantumCryptography: 'Quantum Cryptography',
                    quantumCryptographyIntroduction: 'Introduction to quantum cryptography and its impact on information security.',
                    quantumCryptographyArticles: 'Articles, Research',
                    quantumCryptographyDuration: '1:30',
                    currentSecurityChallenges: 'Current Security Challenges',
                    securityChallengesExploration: 'Exploration of current challenges and threats in the field of computer security.',
                    securityChallengesAnalysis: 'Analysis, Case Studies',
                    securityChallengesDuration: '1:30',
                    totalModuleDuration: 'Total Module Duration',
                    moduleDurationDescription: 'This provides an overview of the content and duration of the Cryptography and Security course.',
                    digitalResources: 'Digital Resources',
                    totalDuration: 'Approximately 20:00',


                    titleFormacion2: 'Cryptography and Security',
                    subtitleFormacion2: "Upon completing this module, students will gain advanced knowledge of cryptography and security in the context of Blockchain technology. Principles of encryption, security protocols, and current challenges in information protection will be explored.",

                    //Formacion-3
                    bitcoinIntroduction: 'Introduction to Bitcoin',
                    bitcoinIntroductionDescription: 'Fundamental principles of Bitcoin and its impact on finance and technology.',
                    bitcoinAdditionalMaterials: 'Articles, Tutorials',
                    bitcoinIntroductionDuration: '2:00',

                    publicBlockchainFunctioning: 'Functioning of the Public Blockchain',
                    publicBlockchainDetail: 'In-depth exploration of the technology behind the Public Blockchain.',
                    publicBlockchainResources: 'Documentation, Practical Examples',
                    publicBlockchainDuration: '3:00',

                    bitcoinMining: 'Bitcoin Mining',
                    bitcoinMiningProcess: 'Bitcoin mining process and its role in security and the issuance of new coins.',
                    bitcoinMiningResources: 'Tutorials, Code Examples',
                    bitcoinMiningDuration: '1:30',

                    bitcoinTransactions: 'Transactions in Bitcoin',
                    bitcoinTransactionsStudy: 'Study of transactions and their security on the Bitcoin network.',
                    bitcoinTransactionsResources: 'Guides, Articles',
                    bitcoinTransactionsDuration: '1:30',

                    smartContractsInBitcoin: 'Smart Contracts in Bitcoin',
                    smartContractsCapability: 'Exploration of the ability to implement smart contracts on the Bitcoin network.',
                    smartContractsResources: 'Documentation, Case Studies',
                    smartContractsDuration: '1:30',

                    publicBlockchainApplicationsDevelopment: 'Applications and Development on the Public Blockchain',
                    publicBlockchainPotentialApps: 'Potential applications and development on the Public Blockchain.',
                    publicBlockchainAppsResources: 'Guides, Practical Examples',
                    publicBlockchainAppsDuration: '1:30',

                    bitcoinSecurityCurrentChallenges: 'Security and Current Challenges in Bitcoin',
                    bitcoinSecurityIssues: 'Security aspects and current challenges in the use of Bitcoin.',
                    bitcoinSecurityResources: 'Analysis, Case Studies',
                    bitcoinSecurityDuration: '1:30',

                    futurePerspectives: 'Future Perspectives',
                    futureTrendsInBitcoinBlockchain: 'Vision of future trends and developments in Bitcoin and Public Blockchain technology.',
                    futurePerspectivesResources: 'Articles, Research',
                    futurePerspectivesDuration: '1:00',

                    totalModuleDuration: 'Total Module Duration',
                    moduleContentOverview: 'This provides an overview of the content and duration of the Bitcoin and Public Blockchain course.',
                    moduleDigitalResources: 'Digital Resources',
                    moduleTotalDuration: 'Approximately 10:00',

                    titleFormacion3: 'Bitcoin and Public Blockchain',
                    subtitleFormacion3: 'This module provides a deep understanding of Bitcoin and Public Blockchain technology. From fundamental principles to current applications and challenges, students will gain a comprehensive insight into this revolutionary financial and technological system.',


                    //Formacion-4
                    ethereumIntroduction: 'Introduction to Ethereum',
                    ethereumIntroductionDescription: 'Fundamental principles of Ethereum and its impact on blockchain technology.',
                    ethereumAdditionalMaterials: 'Articles, Tutorials',
                    ethereumIntroductionDuration: '3:00',

                    smartContractsIntroduction: 'Smart Contracts',
                    smartContractsDetail: 'In-depth exploration of smart contracts and their implementation on Ethereum.',
                    smartContractsResources: 'Documentation, Practical Examples',
                    smartContractsDuration: '5:00',

                    ethereumDevelopment: 'Development on Ethereum',
                    ethereumDevelopmentProcess: 'Development and deployment process of applications on the Ethereum platform.',
                    ethereumDevelopmentResources: 'Tutorials, Code Examples',
                    ethereumDevelopmentDuration: '4:00',

                    smartContractsSecurity: 'Security in Smart Contracts',
                    smartContractsSecurityConsiderations: 'Security considerations when programming and deploying smart contracts.',
                    smartContractsSecurityResources: 'Guides, Articles',
                    smartContractsSecurityDuration: '4:00',

                    interactingWithSmartContracts: 'Interacting with Smart Contracts',
                    smartContractsInteractionDetail: 'How to interact with and use smart contracts from external applications.',
                    smartContractsInteractionResources: 'Documentation, Case Studies',
                    smartContractsInteractionDuration: '4:00',

                    ethereumWallets: 'Wallets on Ethereum',
                    ethereumWalletsFunctionality: 'Types of wallets available on Ethereum and how they function.',
                    ethereumWalletsResources: 'Guides, Practical Examples',
                    ethereumWalletsDuration: '4:00',

                    walletsSecurity: 'Wallet Security',
                    walletsSecurityIssues: 'Security considerations when managing wallets and funds on Ethereum.',
                    walletsSecurityResources: 'Analysis, Case Studies',
                    walletsSecurityDuration: '4:00',

                    advancedDevelopment: 'Advanced Development',
                    advancedDevelopmentConcepts: 'Exploration of advanced concepts in Ethereum application development.',
                    advancedDevelopmentResources: 'Articles, Practical Examples',
                    advancedDevelopmentDuration: '4:00',

                    oraclesAndExternalData: 'Oracles and External Data',
                    oraclesUsageDetail: 'How smart contracts can interact with external data through oracles.',
                    oraclesResources: 'Documentation, Case Studies',
                    oraclesDuration: '3:00',

                    ethereumGovernance: 'Governance in Ethereum',
                    governanceModelsDiscussion: 'Discussion on governance models and decision-making in the Ethereum ecosystem.',
                    governanceResources: 'Guides, Research',
                    governanceDuration: '3:00',

                    enterpriseUseCases: 'Enterprise Use Cases',
                    enterpriseApplicationsDetail: 'Enterprise applications of Ethereum technology and smart contracts.',
                    enterpriseResources: 'Case Studies, Documentation',
                    enterpriseDuration: '4:00',

                    deployingOnPrivateNetworks: 'Deployment on Private Networks',
                    privateNetworksDeploymentConsiderations: 'Considerations and processes for deploying Ethereum applications on private networks.',
                    privateNetworksResources: 'Guides, Practical Examples',
                    privateNetworksDuration: '3:00',

                    futureOutlooks: 'Future Outlooks',
                    futureTrendsInEthereum: 'Vision of future trends and developments in Ethereum and smart contracts.',
                    futureOutlooksResources: 'Articles, Research',
                    futureOutlooksDuration: '3:00',

                    totalModuleDurationEthereum: 'Total Module Duration (Ethereum)',
                    moduleContentOverviewEthereum: 'This provides an overview of the content and duration of the Ethereum and Smart Contracts course.',
                    moduleDigitalResourcesEthereum: 'Digital Resources',
                    moduleTotalDurationEthereum: 'Approximately 46:00',
                    titleFormacion4: 'Ethereum and Smart Contracts',
                    subtitleFormacion4: 'This module provides a detailed understanding of Ethereum and smart contracts. From fundamental principles to security and practical interaction, students will gain comprehensive knowledge of this powerful blockchain platform.',


                    //Formacion-5

                    DeFiIntroduction: 'Introduction to Decentralized Finance (DeFi)',
                    DeFiIntroductionDescription: 'Fundamental principles and key concepts of DeFi.',
                    DeFiAdditionalMaterials: 'Articles, Tutorials',
                    DeFiIntroductionDuration: '1:00',

                    LendingAndFlashLoans: 'Lending and Flash Loans Platforms',
                    LendingAndFlashLoansDetail: 'Exploration of platforms that enable lending and flash loans in the DeFi ecosystem.',
                    LendingAndFlashLoansResources: 'Documentation, Practical Examples',
                    LendingAndFlashLoansDuration: '1:00',

                    DecentralizedExchanges: 'Decentralized Exchanges (DEX)',
                    DecentralizedExchangesFunctioning: 'Functionality and usage of Decentralized Exchanges in DeFi.',
                    DecentralizedExchangesResources: 'Tutorials, Code Examples',
                    DecentralizedExchangesDuration: '1:00',

                    StakingAndFarming: 'Staking and Farming',
                    StakingAndFarmingDetail: 'Participation in staking and farming to earn yields in DeFi.',
                    StakingAndFarmingResources: 'Guides, Articles',
                    StakingAndFarmingDuration: '1:00',

                    DeFiRisksAndChallenges: 'Risks and Challenges in DeFi',
                    DeFiRisksAndChallengesDetail: 'Considerations about risks and challenges associated with decentralized finance.',
                    DeFiRisksAndChallengesResources: 'Documentation, Case Studies',
                    DeFiRisksAndChallengesDuration: '1:00',

                    StablecoinsInDeFi: 'Stablecoins in DeFi',
                    StablecoinsInDeFiDetail: 'Exploration of stablecoins and their role in the DeFi ecosystem.',
                    StablecoinsInDeFiResources: 'Guides, Practical Examples',
                    StablecoinsInDeFiDuration: '1:00',

                    AltcoinsAndTokensInDeFi: 'Altcoins and Tokens in DeFi',
                    AltcoinsAndTokensInDeFiAnalysis: 'Analysis of altcoins and tokens used in DeFi applications.',
                    AltcoinsAndTokensInDeFiResources: 'Articles, Case Studies',
                    AltcoinsAndTokensInDeFiDuration: '1:00',

                    OraclesAndDataInDeFi: 'Oracles and Data in DeFi',
                    OraclesAndDataInDeFiUsage: 'How oracles provide real-world data for DeFi applications.',
                    OraclesAndDataInDeFiResources: 'Tutorials, Research',
                    OraclesAndDataInDeFiDuration: '1:00',

                    CentralizationVsDecentralizationInDeFi: 'Centralization vs. Decentralization in DeFi',
                    CentralizationVsDecentralizationDiscussion: 'Discussion on the aspects of centralization and decentralization in the DeFi ecosystem.',
                    CentralizationVsDecentralizationResources: 'Guides, Articles',
                    CentralizationVsDecentralizationDuration: '1:00',

                    TotalModuleDurationDeFi: 'Total Module Duration (DeFi)',
                    ModuleContentOverviewDeFi: 'This provides an overview of the content and duration of the Decentralized Finance (DeFi) course.',
                    ModuleDigitalResourcesDeFi: 'Digital Resources',
                    TotalDurationDeFi: 'Approximately 9:00',

                    titleFormacion5: 'Decentralized Finance (DeFi)',
                    subtitleFormacion5: 'This module provides a comprehensive introduction to Decentralized Finance (DeFi). From fundamental concepts to platforms and associated risks, students will gain a comprehensive understanding of this exciting financial field.',


                    //Formacion-6
                    NFTIntroduction: 'Introduction to Non-Fungible Tokens (NFTs)',
                    NFTIntroductionDescription: 'Fundamental principles and key concepts of Non-Fungible Tokens (NFTs).',
                    NFTAdditionalMaterials: 'Articles, Tutorials',
                    NFTIntroductionDuration: '1:00',

                    NFTCreationAndIssuance: 'NFT Creation and Issuance',
                    NFTCreationAndIssuanceDetail: 'Process of creating and issuing Non-Fungible Tokens (NFTs).',
                    NFTCreationAndIssuanceResources: 'Documentation, Practical Examples',
                    NFTCreationAndIssuanceDuration: '1:00',

                    NFTMarketAndTrading: 'NFT Market and Trading',
                    NFTMarketAndTradingDetail: 'Exploration of markets and platforms for trading Non-Fungible Tokens (NFTs).',
                    NFTMarketAndTradingResources: 'Tutorials, Code Examples',
                    NFTMarketAndTradingDuration: '1:00',

                    NFTApplicationsAndCreativeUses: 'Applications and Creative Uses',
                    NFTApplicationsAndCreativeUsesDetail: 'Applications and creative uses of Non-Fungible Tokens (NFTs) in various fields.',
                    NFTApplicationsAndCreativeUsesResources: 'Guides, Articles',
                    NFTApplicationsAndCreativeUsesDuration: '1:00',

                    NFTChallengesAndFuture: 'Challenges and Future of NFTs',
                    NFTChallengesAndFutureDetail: 'Considerations regarding current and future challenges in the world of Non-Fungible Tokens (NFTs).',
                    NFTChallengesAndFutureResources: 'Documentation, Case Studies',
                    NFTChallengesAndFutureDuration: '1:00',

                    NFTUtility: 'Utility of NFTs',
                    NFTUtilityDetail: "Exploration of the practical utility of Non-Fungible Tokens (NFTs) in today's society.",
                    NFTUtilityResources: 'Interviews, Case Studies',
                    NFTUtilityDuration: '1:00',

                    HybridNFTTokens: 'Hybrid NFT Tokens',
                    HybridNFTTokensDetail: 'Analysis of Hybrid NFT Tokens and their relationship with other digital assets.',
                    HybridNFTTokensResources: 'Articles, Research',
                    HybridNFTTokensDuration: '1:00',

                    NFTLegalityAndOwnership: 'Legality and Ownership of NFTs',
                    NFTLegalityAndOwnershipDetail: 'Legal aspects and ownership issues associated with Non-Fungible Tokens (NFTs).',
                    NFTLegalityAndOwnershipResources: 'Guides, Documentation',
                    NFTLegalityAndOwnershipDuration: '1:00',

                    NFTInteroperability: 'Interoperability in the NFT Space',
                    NFTInteroperabilityDetail: 'How Non-Fungible Tokens (NFTs) interact and integrate with different platforms and ecosystems.',
                    NFTInteroperabilityResources: 'Tutorials, Practical Examples',
                    NFTInteroperabilityDuration: '1:00',

                    NFTSocialImpact: 'Social Impact of NFTs',
                    NFTSocialImpactDetail: 'Exploration of the social and cultural impact of Non-Fungible Tokens (NFTs).',
                    NFTSocialImpactResources: 'Articles, Interviews',
                    NFTSocialImpactDuration: '1:00',

                    TotalModuleDurationNFT: 'Total Module Duration (NFT)',
                    ModuleContentOverviewNFT: 'This provides an overview of the content and duration of the Non-Fungible Tokens (NFT) course.',
                    ModuleDigitalResourcesNFT: 'Digital Resources',
                    TotalDurationNFT: 'Approximately 10:00',

                    titleFormacion6: 'Non-Fungible Tokens (NFTs)',
                    subtitleFormacion6: 'This module provides a comprehensive introduction to Non-Fungible Tokens (NFTs). From fundamental concepts to creation, trading, and creative applications, students will gain a complete understanding of this fascinating aspect of the crypto space.',

                    //Formacion-7

                    BlockchainTradingBasics: 'Blockchain Trading Basics',
                    BlockchainTradingBasicsDescription: 'Introduction to fundamental trading principles in the context of blockchain technology.',
                    BlockchainTradingBasicsMaterials: 'Articles, Tutorials',
                    BlockchainTradingBasicsDuration: '1:00',

                    TechnicalAndFundamentalAnalysis: 'Technical and Fundamental Analysis',
                    TechnicalAndFundamentalAnalysisDetail: 'Exploration of technical and fundamental analysis methods applied to blockchain trading.',
                    TechnicalAndFundamentalAnalysisResources: 'Documentation, Practical Examples',
                    TechnicalAndFundamentalAnalysisDuration: '2:00',

                    TradingPlatformsAndTools: 'Trading Platforms and Tools',
                    TradingPlatformsAndToolsDetail: 'Review of essential platforms and tools for cryptocurrency trading.',
                    TradingPlatformsAndToolsResources: 'Tutorials, Code Examples',
                    TradingPlatformsAndToolsDuration: '1:30',

                    RiskManagementAndStrategies: 'Risk Management and Strategies',
                    RiskManagementAndStrategiesDetail: 'Developing skills in risk management and effective trading strategies.',
                    RiskManagementAndStrategiesResources: 'Guides, Articles',
                    RiskManagementAndStrategiesDuration: '2:00',

                    LongTermInvestmentsAndHodling: 'Long-Term Investments and Hodling',
                    LongTermInvestmentsAndHodlingDetail: 'Exploration of long-term investment strategies and the "hodling" philosophy.',
                    LongTermInvestmentsAndHodlingResources: 'Documentation, Case Studies',
                    LongTermInvestmentsAndHodlingDuration: '2:00',

                    MarketEventsImpact: 'Market Events Impact',
                    MarketEventsImpactDetail: 'Analysis of the impact of events such as forks, halvings, and regulations on the cryptocurrency market.',
                    MarketEventsImpactResources: 'Guides, Practical Examples',
                    MarketEventsImpactDuration: '1:00',

                    BlockchainDerivativesAndOptions: 'Blockchain Derivatives and Options',
                    BlockchainDerivativesAndOptionsDetail: 'Study of derivative products and options available in the blockchain space.',
                    BlockchainDerivativesAndOptionsResources: 'Articles, Research',
                    BlockchainDerivativesAndOptionsDuration: '1:00',

                    LegalAndRegulatoryAspects: 'Legal and Regulatory Aspects',
                    LegalAndRegulatoryAspectsDetail: 'Legal and regulatory considerations in blockchain trading and investments.',
                    LegalAndRegulatoryAspectsResources: 'Guides, Documentation',
                    LegalAndRegulatoryAspectsDuration: '1:00',

                    EmergingTrendsInInvestments: 'Emerging Trends in Investments',
                    EmergingTrendsInInvestmentsDetail: 'Exploration of emerging trends in investment strategies and how they may evolve in the future.',
                    EmergingTrendsInInvestmentsResources: 'Articles, Interviews',
                    EmergingTrendsInInvestmentsDuration: '1:30',

                    CryptocurrenciesAndTraditionalBanking: 'Cryptocurrencies and Traditional Banking',
                    CryptocurrenciesAndTraditionalBankingDetail: 'Analysis of the relationship between cryptocurrencies and traditional banking, including potential collaboration or competition scenarios.',
                    CryptocurrenciesAndTraditionalBankingResources: 'Guides, Case Studies',
                    CryptocurrenciesAndTraditionalBankingDuration: '1:30',

                    DeFiFuture: 'Decentralized Finance (DeFi) in the Future',
                    DeFiFutureDetail: 'Examining the role of DeFi in the future of finance and how it could transform the global financial landscape.',
                    DeFiFutureResources: 'Documentation, Practical Examples',
                    DeFiFutureDuration: '2:00',

                    CryptocurrencyIntegrationInEconomies: 'Cryptocurrency Integration in Economies',
                    CryptocurrencyIntegrationInEconomiesDetail: 'How cryptocurrencies could integrate into traditional economies and impact global financial systems.',
                    CryptocurrencyIntegrationInEconomiesResources: 'Tutorials, Research',
                    CryptocurrencyIntegrationInEconomiesDuration: '1:30',

                    CryptocurrencyMassAdoption: 'Cryptocurrency Mass Adoption',
                    CryptocurrencyMassAdoptionDetail: 'Exploration of possible scenarios and challenges associated with the mass adoption of cryptocurrencies in society.',
                    CryptocurrencyMassAdoptionResources: 'Articles, Case Studies',
                    CryptocurrencyMassAdoptionDuration: '2:00',

                    EthicalInvestmentsInCryptocurrency: 'Ethical Investments in Cryptocurrency',
                    EthicalInvestmentsInCryptocurrencyDetail: 'Ethical considerations in cryptocurrency-related investments and how investors can address ethical issues in their decisions.',
                    EthicalInvestmentsInCryptocurrencyResources: 'Guides, Interviews',
                    EthicalInvestmentsInCryptocurrencyDuration: '1:30',

                    TotalModuleDurationTradingInvestments: 'Total Duration of Current Topics',
                    ModuleContentOverviewTradingInvestments: 'Cryptocurrency Investment',
                    ModuleDigitalResourcesTradingInvestments: 'Digital Resources',
                    TotalDurationTradingInvestments: 'Approximately 15:30',

                    titleFormacion7: 'Investments and Trading in Blockchain',
                    subtitleFormacion7: 'This module provides in-depth knowledge of trading principles, technical and fundamental analysis, risk management, long-term investment strategies, and more in the context of blockchain technology.',



                    //Formacion-8
                    BlockchainAppIntroduction: 'Introduction to Blockchain Application Development',
                    BlockchainAppIntroductionDescription: 'Initial concepts and fundamental principles of application development in blockchain technology.',
                    BlockchainAppIntroductionMaterials: 'Articles, Tutorials',
                    BlockchainAppIntroductionDuration: '1:30',

                    DAppsArchitecture: 'Decentralized Applications (DApps) Architecture',
                    DAppsArchitectureDetail: 'Exploration of decentralized applications architecture and its implementation in blockchain.',
                    DAppsArchitectureResources: 'Documentation, Practical Examples',
                    DAppsArchitectureDuration: '2:00',

                    SmartContractsProgramming: 'Smart Contracts Programming',
                    SmartContractsProgrammingDetail: 'Practical development of smart contracts using languages like Solidity on platforms such as Ethereum.',
                    SmartContractsProgrammingResources: 'Tutorials, Code Examples',
                    SmartContractsProgrammingDuration: '3:00',

                    BlockchainAPIInteraction: 'Blockchain Interaction through APIs',
                    BlockchainAPIInteractionDetail: 'How to interact with blockchain and its data through APIs and web services.',
                    BlockchainAPIInteractionResources: 'Guides, Articles',
                    BlockchainAPIInteractionDuration: '2:00',

                    BlockchainAppSecurity: 'Security in Blockchain Application Development',
                    BlockchainAppSecurityDetail: 'Considerations and best practices for security in blockchain application development.',
                    BlockchainAppSecurityResources: 'Documentation, Case Studies',
                    BlockchainAppSecurityDuration: '3:00',

                    DeploymentAndMaintenance: 'Deployment and Maintenance',
                    DeploymentAndMaintenanceDetail: 'Deployment and maintenance process of blockchain applications in production environments.',
                    DeploymentAndMaintenanceResources: 'Guides, Practical Examples',
                    DeploymentAndMaintenanceDuration: '2:00',

                    UseCasesAndProjects: 'Use Cases and Practical Projects',
                    UseCasesAndProjectsDetail: 'Exploration of common use cases and development of practical projects in blockchain.',
                    UseCasesAndProjectsResources: 'Articles, Research',
                    UseCasesAndProjectsDuration: '4:00',

                    EmergingTechIntegration: 'Integration of Emerging Technologies',
                    EmergingTechIntegrationDetail: 'How to integrate emerging technologies like IoT and machine learning into blockchain applications.',
                    EmergingTechIntegrationResources: 'Guides, Code Examples',
                    EmergingTechIntegrationDuration: '2:00',

                    TotalModuleDurationBlockchainApp: 'Total Module Duration',
                    ModuleContentOverviewBlockchainApp: 'This provides an overview of the content and duration of the Blockchain Application Development course.',
                    ModuleDigitalResourcesBlockchainApp: 'Digital Resources',
                    TotalDurationBlockchainApp: 'Approximately 20:30',

                    titleFormacion8: 'Blockchain Application Development',
                    subtitleFormacion8: 'This module provides a comprehensive immersion into blockchain application development, covering everything from DApp architecture to smart contract programming and the integration of emerging technologies.',


                    //Formacion-9
                    EnterpriseIntroduction: 'Introduction to Business Use Cases',
                    EnterpriseIntroductionDescription: 'Initial concepts and fundamental principles of business use cases in blockchain technology.',
                    EnterpriseIntroductionMaterials: 'Articles, Tutorials',
                    EnterpriseIntroductionDuration: '1:00',

                    SupplyChainManagement: 'Supply Chain Management',
                    SupplyChainManagementDetail: 'Blockchain applications in the transparent and efficient management of the supply chain.',
                    SupplyChainManagementResources: 'Documentation, Practical examples',
                    SupplyChainManagementDuration: '1:30',

                    ImmutableDocumentRegistry: 'Immutable Document Registry',
                    ImmutableDocumentRegistryDetail: 'Use of blockchain for the creation of immutable and verifiable document registries.',
                    ImmutableDocumentRegistryResources: 'Tutorials, Code examples',
                    ImmutableDocumentRegistryDuration: '1:30',

                    AssetTokenization: 'Asset Tokenization',
                    AssetTokenizationDetail: 'Business use cases of asset tokenization on the blockchain.',
                    AssetTokenizationResources: 'Guides, Articles',
                    AssetTokenizationDuration: '1:00',

                    GovernanceAndCompliance: 'Governance and Compliance',
                    GovernanceAndComplianceDetail: 'Applications in governance and compliance management using blockchain technology.',
                    GovernanceAndComplianceResources: 'Documentation, Case studies',
                    GovernanceAndComplianceDuration: '1:30',

                    DigitalIdentity: 'Digital Identity',
                    DigitalIdentityDetail: 'Implementation of digital identity systems based on blockchain.',
                    DigitalIdentityResources: 'Guides, Practical examples',
                    DigitalIdentityDuration: '1:30',

                    VotingAndElectoralProcesses: 'Voting and Electoral Processes',
                    VotingAndElectoralProcessesDetail: 'Use of blockchain in secure and transparent voting systems.',
                    VotingAndElectoralProcessesResources: 'Articles, Research',
                    VotingAndElectoralProcessesDuration: '1:00',

                    DataPrivacyAndSecurity: 'Data Privacy and Security',
                    DataPrivacyAndSecurityDetail: 'Business use cases to improve data privacy and security with blockchain.',
                    DataPrivacyAndSecurityResources: 'Guides, Code examples',
                    DataPrivacyAndSecurityDuration: '1:00',

                    BusinessAdoptionAndOutlook: 'Business Adoption and Outlook',
                    BusinessAdoptionAndOutlookDetail: 'How businesses adopt blockchain and future outlooks.',
                    BusinessAdoptionAndOutlookResources: 'Digital Resources',
                    BusinessAdoptionAndOutlookDuration: '1:00',

                    TotalModuleDurationEnterprise: 'Total Module Duration',
                    ModuleContentOverviewEnterprise: 'This provides an overview of the content and duration of the Business Use Cases course.',
                    ModuleDigitalResourcesEnterprise: 'Digital Resources',
                    TotalDurationEnterprise: 'Approximately 10:00',

                    titleFormacion9: 'Enterprise Use Cases',
                    subtitleFormacion9: 'This module explores various enterprise use cases of blockchain technology, from supply chain management to digital identity and voting in electoral processes.',




                    //Formacion-10

                    RegulationEthicsIntroduction: 'Introduction to Regulation and Ethics',
                    RegulationEthicsIntroductionDescription: 'Initial concepts on regulation and ethics in the blockchain space.',
                    RegulationEthicsIntroductionMaterials: 'Articles, Tutorials',
                    RegulationEthicsIntroductionDuration: '1:00',

                    LegalRegulatoryFramework: 'Legal and Regulatory Framework',
                    LegalRegulatoryFrameworkDetail: 'Exploration of the legal and regulatory framework surrounding blockchain technology.',
                    LegalRegulatoryFrameworkResources: 'Documentation, Practical examples',
                    LegalRegulatoryFrameworkDuration: '1:30',

                    EthicalConsiderationsBlockchain: 'Ethical Considerations in Blockchain',
                    EthicalConsiderationsBlockchainDetail: 'Analysis of ethical considerations in the development and use of blockchain technology.',
                    EthicalConsiderationsBlockchainResources: 'Tutorials, Code examples',
                    EthicalConsiderationsBlockchainDuration: '1:00',

                    RegulatoryCompliance: 'Regulatory Compliance',
                    RegulatoryComplianceDetail: 'How to ensure regulatory compliance when working with blockchain.',
                    RegulatoryComplianceResources: 'Guides, Articles',
                    RegulatoryComplianceDuration: '0:30',

                    EthicalChallengesPerspectives: 'Ethical Perspectives and Challenges',
                    EthicalChallengesPerspectivesDetail: 'Exploration of ethical perspectives and challenges in adopting blockchain technology.',
                    EthicalChallengesPerspectivesResources: 'Documentation, Case studies',
                    EthicalChallengesPerspectivesDuration: '1:00',

                    ICORegulationImpact: 'Impact of Regulation on ICOs',
                    ICORegulationImpactDetail: 'Analysis of the impact of regulation on Initial Coin Offerings (ICOs) and their evolution.',
                    ICORegulationImpactResources: 'Articles, Case studies',
                    ICORegulationImpactDuration: '0:30',

                    DataPrivacyProtection: 'Privacy and Data Protection',
                    DataPrivacyProtectionDetail: 'Considerations on privacy and data protection in the context of blockchain technology.',
                    DataPrivacyProtectionResources: 'Guides, Practical examples',
                    DataPrivacyProtectionDuration: '1:00',

                    SmartContractsEthics: 'Ethics in Smart Contracts',
                    SmartContractsEthicsDetail: 'Exploration of ethical considerations when programming and executing smart contracts on blockchain.',
                    SmartContractsEthicsResources: 'Tutorials, Case studies',
                    SmartContractsEthicsDuration: '0:30',

                    RecentLegalDevelopments: 'Recent Legal Developments',
                    RecentLegalDevelopmentsDetail: 'Summary of the most recent legal developments related to blockchain technology.',
                    RecentLegalDevelopmentsResources: 'Documentation, Articles',
                    RecentLegalDevelopmentsDuration: '1:00',

                    AMLCompliance: 'AML Compliance',
                    AMLComplianceDetail: 'How to comply with anti-money laundering regulations when working with digital assets.',
                    AMLComplianceResources: 'Guides, Code examples',
                    AMLComplianceDuration: '1:00',

                    BlockchainHumanRights: 'Blockchain and Human Rights',
                    BlockchainHumanRightsDetail: 'Exploration of how blockchain technology can impact human rights and associated ethical issues.',
                    BlockchainHumanRightsResources: 'Research, Interviews',
                    BlockchainHumanRightsDuration: '1:00',

                    BlockchainAuditing: 'Blockchain Auditing',
                    BlockchainAuditingDetail: 'Processes and best practices for conducting audits on applications and smart contracts in blockchain.',
                    BlockchainAuditingResources: 'Tutorials, Practical examples',
                    BlockchainAuditingDuration: '0:30',

                    DecentralizedProtocolsGovernance: 'Governance in Decentralized Protocols',
                    DecentralizedProtocolsGovernanceDetail: 'The role of governance in decentralized protocols and associated ethical considerations.',
                    DecentralizedProtocolsGovernanceResources: 'Documentation, Case studies',
                    DecentralizedProtocolsGovernanceDuration: '0:30',

                    GlobalCryptocurrencyRegulation: 'Global Cryptocurrency Regulation',
                    GlobalCryptocurrencyRegulationDetail: 'Overview of global regulation on cryptocurrencies and its impact on the ecosystem.',
                    GlobalCryptocurrencyRegulationResources: 'Articles, Case studies',
                    GlobalCryptocurrencyRegulationDuration: '1:00',

                    BlockchainSustainability: 'Blockchain and Sustainability',
                    BlockchainSustainabilityDetail: 'Ethical and regulatory considerations on the sustainability of blockchain technology.',
                    BlockchainSustainabilityResources: 'Guides, Research',
                    BlockchainSustainabilityDuration: '0:30',

                    TotalModuleDurationRegulationEthics: 'Total Module Duration',
                    ModuleContentOverviewRegulationEthics: 'This provides an overview of the content and duration of the Regulation and Ethics course.',
                    ModuleDigitalResourcesRegulationEthics: 'Digital Resources',
                    TotalDurationRegulationEthics: 'Approximately 10:00',

                    Formacion10Title: 'Regulation and Ethics',
                    Formacion10Subtitle: 'This module addresses regulatory and ethical aspects related to blockchain technology, exploring the legal framework, ethical considerations, and perspectives on blockchain adoption.',

                    //Dashboard
                    titleDasboard: "Explore the digital art and our courses",
                    Colecciones: "Colletions",
                    TopSeller: "Memberships ",
                    BasicTraining: "Basic Training",
                }
            },

            es: {
                translation: {
                    //Home
                    welcomeMessage: '¡Bienvenido a mi sitio web!',
                    TitleHome: 'El valor de la Tecnología Blockchain',
                    SubtitleHome: 'Conoce la Tecnología Blockchain y el Arte Digital formándote con los mejores profesionales.',
                    HomeButtonStart: 'Empezar',
                    DiscoverButton: 'Descubre',

                    // Comunidad 
                    CommunityParrafo: 'Comienza con la plataforma más fácil y segura para comprar, intercambiar y aprender sobre arte digital y tecnología blockchain. Únete a nuestra comunidad educativa y explora el emocionante mundo de la tecnología blockchain y los NFTs.',
                    uniqueExperience: 'Experiencia única en la formación del mundo blockchain',
                    startButtonText: 'Empezar',
                    whyChooseUs: '¿Por qué elegirnos?',
                    paymentInstant: 'Pagos al Instante',
                    paymentInstantDescription: 'Realizamos pagos al instante, proporcionando una experiencia rápida y eficiente para nuestros usuarios.',
                    security: 'Seguridad',
                    securityDescription: 'Nuestra plataforma garantiza la seguridad de tus transacciones y datos personales, utilizando las últimas medidas de seguridad.',
                    smartContracts: 'Gestión con Contratos Inteligentes',
                    smartContractsDescription: 'Ofrecemos una gestión eficiente mediante contratos inteligentes, facilitando procesos transparentes y automatizados para nuestros usuarios.',
                    frequentlyAskedQuestions: 'Preguntas frecuentes',
                    whyNFTPopular: '1. ¿Por qué se están volviendo populares los NFT?',
                    whyNFTPopularAnswer: 'Los NFT (Tokens No Fungibles) se han vuelto populares debido a su capacidad para representar la propiedad única e indivisible de activos digitales, como obras de arte y colecciones exclusivas, utilizando la tecnología blockchain.',
                    howKeepNFTSafe: '2. ¿Cómo mantengo seguros mis NFT?',
                    howKeepNFTSafeAnswer: 'Para mantener seguros tus NFT, es crucial utilizar billeteras y plataformas confiables, habilitar autenticación de dos factores, y seguir las mejores prácticas de seguridad digital, como resguardar claves privadas.',
                    whatIsBlockchain: '3. ¿Qué es blockchain?',
                    whatIsBlockchainAnswer: 'Blockchain es un registro compartido e inmutable que facilita el proceso de registrar transacciones y rastrear activos en una red empresarial. Puede representar tanto activos tangibles como intangibles, ofreciendo seguridad y eficiencia.',
                    whatIsNFT: '4. ¿Qué es un NFT?',
                    whatIsNFTAnswer: 'Un NFT (Token No Fungible) es un activo digital único que utiliza la tecnología blockchain para garantizar su autenticidad y propiedad. Cada NFT es único y no se puede intercambiar de manera equivalente con otros tokens.',
                    howSetUpLedger: '5. ¿Cómo configuro mi Ledger?',
                    howSetUpLedgerAnswer: 'Configurar tu Ledger para almacenar tus NFT implica seguir los procedimientos específicos proporcionados por el fabricante. Generalmente, implica conectar tu Ledger a una billetera compatible y seguir los pasos de configuración.',
                    typesOfWallets: '6. ¿Cuáles son los tipos de wallets?',
                    typesOfWalletsAnswer: 'Existen varios tipos de wallets, siendo las más comunes las wallets de software (online, desktop, y móviles), wallets de hardware (dispositivos físicos), y wallets de papel (almacenamiento en forma impresa). Cada tipo tiene sus propias ventajas en términos de accesibilidad y seguridad.',
                    whatAreStablecoins: '7. ¿Qué son las stablecoins?',
                    whatAreStablecoinsAnswer: 'Las stablecoins son criptomonedas diseñadas para mantener un valor estable vinculándose a activos externos como monedas fiduciarias o commodities. Su función principal es ofrecer estabilidad de precio en comparación con las criptomonedas más volátiles, como Bitcoin, facilitando así su uso en transacciones cotidianas.',
                    decentralization: '8. ¿En qué consiste la descentralización ?',
                    decentralizationAnswer: 'La descentralización implica la distribución del control y la toma de decisiones en una red criptográfica entre múltiples participantes, eliminando así la dependencia de una autoridad central. Esto mejora la seguridad y resistencia a la censura, características fundamentales en el diseño de las criptomonedas.',
                    whatIsBitcoin: '9. ¿Qué es Bitcoin y cómo funciona?',
                    whatIsBitcoinAnswer: 'Bitcoin es la primera y más conocida criptomoneda, creada en 2009 por una entidad o persona bajo el seudónimo de Satoshi Nakamoto. Funciona en una red peer-to-peer descentralizada, donde los usuarios pueden realizar transacciones directas sin necesidad de intermediarios como bancos. La tecnología subyacente, llamada blockchain, registra todas las transacciones de manera segura e inmutable. Además, Bitcoin utiliza un proceso llamado minería, donde los participantes de la red compiten para validar transacciones y agregar nuevos bloques a la cadena, siendo recompensados con nuevos bitcoins. Su propósito principal es actuar como una forma de dinero digital resistente a la censura y la manipulación gubernamental, permitiendo transferencias de valor sin la necesidad de depender de una entidad central. Bitcoin ha ganado popularidad como un activo digital y una reserva de valor.',
                    whyUseBlockchainTechnology: '10. ¿Por qué se utiliza la tecnología blockchain?',
                    whyUseBlockchainTechnologyAnswer: 'La tecnología blockchain se utiliza en criptomonedas como Bitcoin debido a sus características fundamentales de descentralización, seguridad y transparencia. La blockchain actúa como un registro distribuido e inmutable de todas las transacciones realizadas, lo que elimina la necesidad de una entidad central de control. Esto no solo proporciona seguridad al evitar la alteración de datos, sino que también permite transacciones directas entre pares sin intermediarios. La descentralización inherente a la blockchain garantiza que no haya un solo punto de falla y que la red sea resistente a la censura. Además, la transparencia de la blockchain permite a los participantes verificar todas las transacciones, construyendo así un sistema confiable y de confianza en el que se basa el funcionamiento de las criptomonedas como Bitcoin.',
                    stillHaveQuestions: '¿Todavía tienes preguntas?',
                    chatWithTeam: 'Chatea con nuestro amable equipo',
                    chatPrompt: "Chatea con nuestro amable equipo",

                    //Detalles
                    pageTitle: 'Los NFTS',
                    artDigitalDescription1: 'El Arte digital es una disciplina creativa artística que utiliza la tecnología digital para crear obras de arte. A diferencia de otras formas de arte, el digital se basa en el uso de programas y herramientas digitales para la creación de imágenes, videos, animaciones, entre otros.',
                    artDigitalDescription2: 'El Arte Digital ha adquirido popularidad y relevancia en los últimos años de la era digital, utilizándose en multitud de contextos como la publicidad, diseño gráfico, animación y en la producción de videojuegos. Se trata de una forma de arte que puede estar acreditado con un certificado de autenticidad y exhibirse en galerías y museos.',
                    newCollectionsTitle: 'Nuevas Colecciones',
                    discoverMoreLink: 'Descubre más',
                    nftDescription: 'NFT (Token No Fungible, por sus siglas en inglés) son obras digitales que pueden ser compradas y vendidas como cualquier otro tipo de propiedad, pero no tienen forma tangible. Por ejemplo, una fotografía digital o un vídeo, son piezas de arte que no existen en el mundo físico. Hasta ahora, los artistas que trabajaban con soportes digitales tenían verdaderas dificultades para comercializar su producto porque no podían firmar y seriar su obra como se haría con un grabado o una foto impresa, es decir, era imposible distinguir el original de una copia. Con la inclusión de un NFT se puede confirmar la autenticidad, con el añadido de que se registra la historia de compraventas futuras, lo que supone que el artista puede obtener también los derechos de autoría de cada transacción. Tengamos claro que un NFT es un certificado. No es un tipo de obra de arte ni una técnica de creación ni un movimiento artístico.',
                    nftRevolutionText: 'Los NFT han revolucionado el mundo del arte, ofreciendo nuevas oportunidades para que los artistas muestren su talento y para que los coleccionistas inviertan en arte como nunca antes. Únete a nosotros en el mundo de los NFT y emprende un viaje donde el arte, la cultura y la tecnología blockchain se encuentran.',

                    //Explore 
                    exploreCollectionsTitle: 'Explora las colecciones',


                    //Formaciones

                    // Otros textos en español...

                    //Footer
                    mercado: "Mercado",
                    recursos: "Recursos",
                    cuenta: "Cuenta",
                    empresa: "Marketplace",
                    additionalResources: "Recursos Adicionales",
                    additionalResourcesDescription: "Se proporcionarán recursos adicionales, como material de lectura, videos y ejercicios prácticos, para enriquecer la experiencia de aprendizaje. Estos recursos apoyarán a los estudiantes con diferentes estilos de aprendizaje.",
                    studentFeedback: "Feedback de los Estudiantes",
                    studentFeedbackDescription: "La retroalimentación de los estudiantes es valorada y se utilizará para ajustar la duración de los módulos, asegurando que se satisfagan las necesidades de comprensión de cada estudiante.",
                    learningPath: "Ruta de Aprendizaje",
                    allNFTs: "Todos los NFT",
                    virtualWorlds: "Detalles",
                    ourNFTs: "Nuestros NFT",
                    digitalArt: "Arte digital",
                    helpCenter: "Centro de ayuda",
                    platformStatus: "Estado de la plataforma",
                    aboutUs: "Conócenos",
                    discover: "Descubrir",
                    packages: "Paquetes",
                    authors: "Autores",
                    collectionProfile: "Perfil del colecciones",
                    privacyPolicy: "Política de privacidad",
                    termsOfService: "Términos de servicio",
                    widgetTitle: "¡Únete a la comunidad!",


                    //Slider Explore 
                    delay: '0s',

                    itemName: 'COLECCIÓN TERRA NFT',
                    itemNamea: 'COLECCIÓN AQUA NFT',
                    itemNamee: 'COLECCIÓN EAR NFT',

                    //Botones y slider
                    createdBy: "Creado Por:",
                    join: "Únete",

                    //NAvbar
                    contacto: "Contacto",
                    CompraN: "Comprar",
                    inicio: "Inicio",
                    nfts: "NFTs",
                    detalles: "Detalles",
                    comunidad: "Comunidad",
                    formacion: "Formación",
                    registro: "Registro",
                    conectarWallet: "Conectar Wallet",


                    //Card formacion 

                    introductionToBlockchainTechnology: "Introducción a la Tecnología Blockchain",
                    typesOfBlockchains: "Tipos de Blockchains",
                    publicPrivateAndConsortium: "Públicas, Privadas y Consorcio",
                    blockchainFundamentals: "Fundamentos de la Blockchain",
                    blockchainHistory: "Historia de la Blockchain",
                    useCases: "Casos de Uso",
                    bitcoinAndCryptocurrencies: "Bitcoin y Criptomonedas",
                    bitcoin: "Bitcoin",
                    altcoins: "ALTCOINS",
                    roleInTheMarket: "Función en el Mercado",
                    diversityOfAltcoins: "Diversidad de Altcoins",
                    ethereum2: "Ethereum 2.0",
                    stablecoins: "STABLECOINS",
                    typesOfStablecoins: "Tipos de STABLECOINS",
                    usesAndApplications: "Usos y Aplicaciones",
                    ecosystems: "Ecosistemas",
                    evolution: "Evolución",
                    centralizationVsDecentralization: "Centralización vs. Descentralización",
                    centralization: "Centralización",
                    decentralization: "Descentralización",
                    contrasts: "Contrastes",
                    impactsOnFinancialSystems: "Impactos en Sistemas Financieros",
                    dataUsage: "Uso de Datos",
                    walletsAndSecurity: "Wallets y Seguridad",
                    hotWallets: "Billeteras Calientes",
                    hardware: "Hardware",
                    software: "Software",
                    coldWallets: "Billeteras Frias",
                    paper: "Papel",
                    transactions: "Transacciones",
                    securityLayers: "Capas de Seguridad",
                    storageMethods: "Métodos de Almacenamiento",
                    tensor: "Trezor",
                    metamask: "Metamask",
                    layer2InWallets: "Layer-2 en Wallets",
                    futureOfCryptocurrencies: "Futuro de las Criptomonedas",
                    futureProjectionsOfTheCryptoMarket: "Proyecciones Futuras del Mercado Cripto",
                    coinMarketCap: "CoinMarketCap",
                    scalabilityInBlockchain: "Escalabilidad en Blockchain",
                    technologicalInnovations: "Innovaciones Tecnológicas",
                    applicationsInCryptocurrencies: "Aplicaciones en Criptomonedas",
                    emergingDevelopments: "Desarrollos Emergentes",
                    theirInfluence: "Su Influencia",
                    decentralizedFinance: "Finanzas Descentralizadas (DeFi)",
                    whatIsDeFi: "¿Qué es DeFi?",
                    currentFunctionality: "Funcionalidad Actual",
                    futureTrends: "Tendencias Futuras",
                    defisPotential: "Potencial de DeFi",
                    impactOfCurrencyDevaluationAndInflationOnDeFi: "Impacto de la Devaluación de la Moneda y la Inflación en DeFi",
                    decentralizedApplications: "Aplicaciones Descentralizadas (DApps)",
                    introductionToDApps: "Introducción a las DApps",
                    dAppsArchitecture: "Arquitectura de las DApps",
                    commonUseCases: "Casos de Uso Comunes",
                    securityInDApps: "Seguridad en las DApps",
                    interoperabilityInDApps: "Interoperabilidad en DApps",
                    challengesAndFutureOfDApps: "Retos y Futuro de las DApps",
                    nonFungibleTokens: "Tokens No Fungibles (NFTs)",
                    whatAreNFTs: "¿Qué son los NFTs?",
                    technologyBehindNFTs: "Tecnología detrás de los NFTs",
                    usesAndApplicationsNFTs: "Usos y Aplicaciones de los NFTs",
                    economyOfNFTs: "Economía de los NFTs",
                    developmentOfNFTs: "Desarrollo de los NFTs",
                    culturalImpactAndFutureOfNFTs: "Impacto Cultural y Futuro de los NFTs",
                    smartContracts: "Contratos Inteligentes",
                    developmentAndLanguages: "Desarrollo y Lenguajes",
                    implementationsAndExamples: "Implementaciones y Ejemplos",
                    securityAndAudit: "Seguridad y Auditoría",
                    applicationsAndFuture: "Aplicaciones y Futuro",
                    marketsOnBlockchain: "Mercados en Blockchain",
                    typesOfMarkets: "Tipos de Mercados",
                    developmentAndOperation: "Desarrollo y Funcionamiento",
                    applicationsAndUseCases: "Aplicaciones y Casos de Uso",
                    challengesAndFutureOfMarketsOnBlockchain: "Desafíos y Futuro de los Mercados en Blockchain",

                    //Formación

                    titleDominandoBlockchain: "Dominando Blockchain: Formacion Integral en Finanzas y Desarrollo",
                    descriptionCourse: "Al finalizar esta formación integral, habrás adquirido un dominio completo de los principios y aplicaciones de la Tecnología Blockchain. Serás capaz de desarrollar y crear aplicaciones Blockchain, comprender y escribir contratos inteligentes, explorar redes escalables como Polygon o Binance Smart Chain, y conceptualizar proyectos reales utilizando Python y Ethereum. Además, adquirirás conocimientos sólidos en Solidity, seguridad y finanzas descentralizadas",
                    depthLevelTitle: "Nivel de Profundidad",
                    depthLevelDescription: "Este curso ofrece un enfoque integral, desde lo más básico hasta niveles avanzados. La profundidad de la cobertura se adapta para proporcionar un aprendizaje completo y accesible",
                    targetAudienceTitle: "Audiencia Objetivo",
                    targetAudienceDescription: "Este programa de formación está diseñado para abogados, fiscales, contables, economistas, asesores, gestores, empresarios y estudiantes de diversos campos, incluyendo informática y finanzas, que deseen sumergirse en el mundo de las blockchains. Con un enfoque integral, este programa se adapta tanto a aquellos con experiencia como a principiantes, garantizando una comprensión sólida de los conceptos fundamentales.",
                    basicBlockchainTrainingTitle: "Formación Básica en Blockchain",
                    basicBlockchainTrainingAccessibility: "La formación en blockchain debe ser accesible para todas las personas",
                    basicBlockchainTrainingContent: "Comprender los conceptos fundamentales de la tecnología blockchain",
                    basicBlockchainTrainingDevelopment: "Aprender a desarrollar aplicaciones, contratos inteligentes, finanzas y el futuro del blockchain",
                    basicBlockchainTrainingPreparation: "Adquirir conocimientos que sirvan como base para formación avanzada en blockchain",
                    basicBlockchainTrainingInclusivity: "Fomentar la participación inclusiva y diversa en la formación blockchain",
                    additionalResourcesTitle: "Recursos Adicionales",
                    additionalResourcesDescription: "Se proporcionarán recursos adicionales, como material de lectura, videos y ejercicios prácticos, para enriquecer la experiencia de aprendizaje. Estos recursos apoyarán a los estudiantes con diferentes estilos de aprendizaje",
                    studentFeedbackTitle: "Feedback de los Estudiantes",
                    studentFeedbackDescription: "La retroalimentación de los estudiantes es valorada y se utilizará para ajustar la duración de los módulos, asegurando que se satisfagan las necesidades de comprensión de cada estudiante",
                    learningPathTitle: "Ruta de Aprendizaje",
                    courseContentFundamentalConcepts: "Conceptos fundamentales en finanzas y su relación con las criptomonedas.",
                    courseContentFinancialArchitecture: "Análisis de la arquitectura financiera que facilita la gestión de activos digitales.",
                    courseContentFinancialTransactionsExploration: "Exploración aplicada a transacciones financieras.",
                    courseContentFinancialStrategiesDevelopment: "Desarrollo de estrategias financieras en el entorno de las criptomonedas y blockchain.",
                    courseContentFinancialTransactionsExploration: "Exploration applied to financial transactions.",
                    courseContentFinancialStrategiesDevelopment: "Development of financial strategies in the cryptocurrency and blockchain environment.",

                    queVamosAprender: "¿Qué vamos a aprender?",
                    courseContentTitle: "Contenido del Curso",
                    courseContentOrigin: "Entender los orígenes de la creación de Blockchain",
                    courseContentArchitecture: "Estudio de la arquitectura que permite la distribución de la información",
                    courseContentCryptography: "Introducción a la criptografía de clave pública mediante el estudio de las características propias de la cadena de bloques",
                    courseContentHashing: "Desarrollo del algoritmo de hashing que actúa como piedra angular en Blockchain, entendiendo la minería y los protocolos PoW y PoS",
                    economicFocusTitle: "Enfoque Económico",
                    economicFocusDescription: "Este curso también incluirá un enfoque significativo en temas económicos y financieros, proporcionando una comprensión profunda de las implicaciones económicas de la tecnología Blockchain. Se explorará el futuro de las finanzas descentralizadas y se obtendrá una visión completa de cómo esta tecnología transformará los modelos económicos tradicionales",
                    totalHoursDescription: " Con un total de 170 horas de formación y asesoría, con 160 horas dedicadas a la formación efectiva y el resto reservado para consultorías especializadas, este curso equilibrado preparará a los estudiantes para abordar desafíos y oportunidades tanto en el ámbito técnico como en el económico de la Tecnología Blockchain.",



                    basicBlockchainTrainingHeading: "Formación Básica",
                    basicBlockchainTrainingAccessibilityTitle: "Accesibilidad",
                    basicBlockchainTrainingAccessibilityDescription: "La formación en blockchain debe ser accesible para todas las personas.",
                    basicBlockchainTrainingEssentialContentTitle: "Contenido Esencial",
                    basicBlockchainTrainingEssentialContentDescription: "Comprender los conceptos fundamentales de la tecnología blockchain.",
                    basicBlockchainTrainingSkillDevelopmentTitle: "Desarrollo de Habilidades",
                    basicBlockchainTrainingSkillDevelopmentDescription: "Aprender a desarrollar aplicaciones, contratos inteligentes, finanzas y el futuro del blockchain.",
                    basicBlockchainTrainingAdvancedPreparationTitle: "Preparación para Niveles Avanzados",
                    basicBlockchainTrainingAdvancedPreparationDescription: "Adquirir conocimientos que sirvan como base para formación avanzada en blockchain.",
                    basicBlockchainTrainingInclusivityDiversityTitle: "Inclusividad y Diversidad",
                    basicBlockchainTrainingInclusivityDiversityDescription: "Fomentar la participación inclusiva y diversa en la formación blockchain.",

                    //Roadmap
                    introductionToBlockchainTitle: "Introducción a Blockchain",
                    introductionToBlockchainDescription: "Conceptos básicos y principios fundamentales.",
                    cryptographyAndSecurityTitle: "Criptografía y Seguridad",
                    cryptographyAndSecurityDescription: "Entender la criptografía subyacente y la seguridad en Blockchain.",
                    bitcoinAndPublicBlockchainTitle: "Bitcoin y Blockchain Pública",
                    bitcoinAndPublicBlockchainDescription: "Explorar la primera aplicación de Blockchain: Bitcoin.",
                    ethereumAndSmartContractsTitle: "Ethereum y Contratos Inteligentes",
                    ethereumAndSmartContractsDescription: "Profundizar en Ethereum y el desarrollo de contratos inteligentes.",
                    decentralizedFinanceDefiTitle: "Finanzas Descentralizadas (DeFi)",
                    decentralizedFinanceDefiDescription: "Comprender cómo DeFi está transformando las finanzas tradicionales.",
                    nonFungibleTokensNftTitle: "Tokens No Fungibles (NFT)",
                    nonFungibleTokensNftDescription: "Explorar el mundo de los NFT y su impacto en el arte y la propiedad digital.",
                    blockchainInvestingAndTradingTitle: "Inversiones y Trading en Blockchain",
                    blockchainInvestingAndTradingDescription: "Aprender estrategias de inversión y trading en el mercado de criptomonedas.",
                    blockchainApplicationDevelopmentTitle: "Desarrollo de Aplicaciones Blockchain",
                    blockchainApplicationDevelopmentDescription: "Sumergirse en el desarrollo de aplicaciones descentralizadas (DApps).",
                    businessUseCasesTitle: "Casos de Uso Empresariales",
                    businessUseCasesDescription: "Explorar cómo las empresas están adoptando Blockchain para sus operaciones.",
                    regulationAndEthicsTitle: "Regulación y Ética",
                    regulationAndEthicsDescription: "Comprender los aspectos éticos y legales de la tecnología Blockchain.",

                    totalHours: "Total de horas: 170 horas",
                    totalWeeks: "Total de semanas: 31 semanas",
                    hoursPerWeek: "Horas por semana: 5 horas/semana",


                    //Paquetes registro
                    acquisitionNFT: 'Adquisición de un NFT por formación',
                    gifFormatTraining: 'Formación: Introducción al Blockchain',
                    threeLevelEarnings: 'Ganancias por Referidos: 20%, 15%, 10%',
                    noSupport: 'Sin soporte',

                    twoNFTsPerTrainingSession: 'Adquisición de un NFT por formación',
                    fiveTrainingSessions: '+ Cuatro Cursos de Introducción al Blockchain',
                    fourLevelEarnings: 'Ganancias por Referidos: 25%, 15%, 10%, 5%',
                    tenTrainingSessions: 'Adquisición de dos NFTs por formación',


                    twoNsPerTrainingSession: '+ Nueve Cursos de Introducción al Blockchain',
                    fourLevelEarnings: 'Ganancias por Referidos: 30%, 15%, 10%, 5%',

                    fifteenTrainingSessions: '+ Catorce de Introducción al Blockchain',
                    fiveNFTsPerTrainingSession: 'Adquisición de tres NFTs por formación',
                    fourLevelEarnings: 'Ganancias por Referidos: 30%, 15%, 10%, 5%',

                    oneTwentyNFTsSixtyTraining: '120 NFTs para el comprador y 1 NFT por formación',
                    continuousTrainingAccess: 'Un Curso de Consultoría en Blockchain',
                    officialCertificateAccess: '+ Cincuenta y Nueve Cursos de Introducción al Blockchain',
                    multiLevelEarnings: 'Ganancias por Referidos: 30%, 15%, 10%, 5%, 3%, 2%',



                    basicLevel: 'Basic',
                    advancedLevel: 'Advanced',
                    universityLevel: ' Academic',

                    //Action3
                    Capasitate: 'Capacítate con nuestra formación de 1500 USDT',
                    Certificado: 'CON DIPLOMATURA DE UNIVERSIDAD ',
                    advertise: '¡Mira nuestras ofertas atractivas!',


                    //Contactos 
                    // Nuevas claves y traducciones en español
                    contactUs: 'Contáctanos',
                    name: 'Nombre*',
                    email: 'Correo Electrónico*',
                    message: 'Mensaje*',
                    subject: 'Asunto*',
                    anyQuestions: '¿Tienes alguna pregunta? ¿Necesitas ayuda? No dudes en escribirnos.',
                    send: 'Enviar',

                    //Formacion-2
                    materials: "Materiales",
                    estimatedTime: "Tiempo estimado",
                    time: "horas",

                    introductionToCryptography: 'Introducción a la Criptografía',
                    cryptographyFundamentals: 'Principios básicos y fundamentos de la criptografía.',
                    cryptographyResources: 'Artículos, Tutoriales',
                    introductionDuration: '2:00',
                    publicKeyCryptography: 'Criptografía de Clave Pública',
                    detailedPublicKeyCryptography: 'Estudio detallado de la criptografía de clave pública en el contexto de Blockchain.',
                    publicKeyCryptographyDocuments: 'Documentación, Ejemplos prácticos',
                    publicKeyCryptographyDuration: '3:00',
                    hashingAlgorithms: 'Algoritmos de Hashing',
                    hashingDevelopment: 'Desarrollo y aplicación de algoritmos de hashing en la seguridad de la información.',
                    hashingTutorials: 'Tutoriales, Ejemplos de código',
                    hashingDuration: '2:30',
                    financialTransactionsSecurity: 'Seguridad en Transacciones Financieras',
                    financialTransactionsExploration: 'Exploración de la criptografía aplicada a transacciones financieras y seguridad de datos.',
                    financialTransactionsGuides: 'Guías, Artículos',
                    financialTransactionsDuration: '5:00',
                    blockchainSecurityProtocols: 'Protocolos de Seguridad en Blockchain',
                    securityProtocolsStudy: 'Estudio de protocolos de seguridad como SSL/TLS en el contexto de Blockchain.',
                    blockchainSecurityProtocolsDocuments: 'Documentación, Casos de estudio',
                    blockchainSecurityProtocolsDuration: '4:30',
                    securityAuditingAndTesting: 'Auditoría y Pruebas de Seguridad',
                    securityAuditingTechniques: 'Técnicas y mejores prácticas para auditar y probar la seguridad en sistemas Blockchain.',
                    securityAuditingGuides: 'Guías, Ejemplos prácticos',
                    securityAuditingDuration: '2:00',
                    quantumCryptography: 'Criptografía Cuántica',
                    quantumCryptographyIntroduction: 'Introducción a la criptografía cuántica y su impacto en la seguridad de la información.',
                    quantumCryptographyArticles: 'Artículos, Investigación',
                    quantumCryptographyDuration: '1:30',
                    currentSecurityChallenges: 'Desafíos Actuales en Seguridad',
                    securityChallengesExploration: 'Exploración de desafíos y amenazas actuales en el ámbito de la seguridad informática.',
                    securityChallengesAnalysis: 'Análisis, Estudios de caso',
                    securityChallengesDuration: '1:30',
                    totalModuleDuration: 'Duración Total del módulo',
                    moduleDurationDescription: 'Esto proporciona una visión general del contenido y la duración del curso de Criptografía y Seguridad.',
                    digitalResources: 'Recursos Digitales',
                    totalDuration: 'Aproximadamente 20:00',

                    titleFormacion2: 'Criptografía y Seguridad',
                    subtitleFormacion2: " Al completar este módulo, los estudiantes adquirirán conocimientos avanzados sobre criptografía y seguridad en el contexto de la tecnología Blockchain. Se explorarán principios de encriptación, protocolos de seguridad y desafíos actuales en la protección de la información.",

                    //Formacion-1
                    blockchainIntroduction: '¡Bienvenidos al Mundo del Blockchain!',
                    blockchainIntroductionDescription: 'Una cálida introducción al curso, explorando los conceptos básicos del blockchain.',
                    blockchainAdditionalMaterials: 'Material adicional disponible en el sitio.',
                    blockchainIntroductionDuration: '54:06',

                    blockchainUniverseExploration: 'Explorando el Universo Blockchain',
                    blockchainUniverseDetail: 'Un viaje detallado a través de los fundamentos del blockchain, incluyendo su origen, estructura y funcionamiento.',
                    blockchainUniverseResources: 'Documentación, Ejemplos prácticos',
                    blockchainUniverseDuration: '1:19:00',

                    blockchainArchitecture: 'La Arquitectura en el Centro del Blockchain',
                    blockchainArchitectureInsights: 'Descubriendo la estructura y arquitectura que sustentan la tecnología blockchain.',
                    blockchainArchitectureResources: 'Artículos, Tutoriales',
                    blockchainArchitectureDuration: '11:02',

                    blockchainKeyAttributes: 'Atributos Claves del Blockchain',
                    blockchainAttributesAnalysis: 'Investigando las características esenciales que distinguen al blockchain como una tecnología única y potente.',
                    blockchainAttributesResources: 'Guías, Ejemplos de código',
                    blockchainAttributesDuration: '32:37',

                    technicalAspectsOfBlockchain: 'Aspectos Técnicos de Blockchain y Monedas Digitales',
                    blockchainTechnicalAspectsDetail: 'Profundizando en los aspectos técnicos de blockchain y su relación con las criptomonedas.',
                    blockchainTechnicalAspectsResources: 'Documentación, Casos de estudio',
                    blockchainTechnicalAspectsDuration: '1:18:04',

                    ethereumAndSmartContracts: 'Ethereum y su Papel en los Contratos Inteligentes',
                    ethereumRoleDetail: 'Un análisis detallado de Ethereum y su función vital en la ejecución de contratos inteligentes.',
                    ethereumResources: 'Tutoriales, Artículos',
                    ethereumDuration: '45:14',

                    blockchainForks: 'Divisiones en el Camino: Los Forks',
                    forksImpactAnalysis: 'Explorando los "forks" en el contexto del blockchain y su impacto en las redes.',
                    forksResources: 'Guías, Ejemplos de código',
                    forksDuration: '13:30',

                    tokensDeciphering: 'Descifrando los Tokens',
                    tokensRoleExploration: 'Examinando el concepto de tokens y su papel esencial en el ecosistema blockchain.',
                    tokensResources: 'Documentación, Tutoriales',
                    tokensDuration: '21:35',

                    blockchainMethods: 'Métodos en Blockchain',
                    methodsExploration: 'Exploración de los métodos utilizados en el desarrollo y funcionamiento de blockchain.',
                    methodsAdditionalMaterials: 'Material adicional disponible en el sitio.',
                    methodsDuration: '1:00:00',

                    essentialResources: 'Recursos Esenciales',
                    resourcesCompilation: 'Recopilación de recursos clave para aprender y trabajar con tecnología blockchain.',
                    resourcesLinks: 'Enlaces a libros, videos y cursos recomendados.',
                    resourcesDuration: '1:00:00',

                    altCoinsInsight: 'Extra: Un Vistazo Profundo a las Monedas Alternativas',
                    altCoinsAnalysis: 'Un análisis más detenido de las "Alt Coins" (monedas alternativas) y su posición en el vibrante mercado de criptomonedas.',
                    altCoinsResources: 'Artículos, Investigación',
                    altCoinsDuration: '45:20',

                    totalModuleDurationKey: 'Duración Total del módulo',
                    moduleDurationOverview: 'Esto proporciona una visión general del contenido y la duración del curso de introducción al blockchain.',
                    moduleDigitalResources: 'Recursos Digitales',
                    totalDuration: 'Aproximadamente 10:00',

                    titleFormacion1: 'Introducción a Blockchain',
                    subtitleFormacion1: 'Al completar este módulo, los estudiantes habrán adquirido un dominio completo de los principios y aplicaciones de la Tecnología Blockchain. Desarrollarán habilidades para crear aplicaciones Blockchain, comprender y escribir contratos inteligentes, explorar redes como Polygon o Binance Smart Chain, y conceptualizar proyectos reales utilizando Python y Ethereum.',




                    //Formacion-3
                    bitcoinIntroduction: 'Introducción a Bitcoin',
                    bitcoinIntroductionDescription: 'Principios fundamentales de Bitcoin y su impacto en las finanzas y la tecnología.',
                    bitcoinAdditionalMaterials: 'Artículos, Tutoriales',
                    bitcoinIntroductionDuration: '2:00',

                    publicBlockchainFunctioning: 'Funcionamiento de la Blockchain Pública',
                    publicBlockchainDetail: 'Exploración detallada de la tecnología detrás de la Blockchain Pública.',
                    publicBlockchainResources: 'Documentación, Ejemplos prácticos',
                    publicBlockchainDuration: '3:00',

                    bitcoinMining: 'Minería de Bitcoin',
                    bitcoinMiningProcess: 'Proceso de minería de Bitcoin y su papel en la seguridad y emisión de nuevas monedas.',
                    bitcoinMiningResources: 'Tutoriales, Ejemplos de código',
                    bitcoinMiningDuration: '1:30',

                    bitcoinTransactions: 'Transacciones en Bitcoin',
                    bitcoinTransactionsStudy: 'Estudio de las transacciones y su seguridad en la red de Bitcoin.',
                    bitcoinTransactionsResources: 'Guías, Artículos',
                    bitcoinTransactionsDuration: '1:30',

                    smartContractsInBitcoin: 'Contratos Inteligentes en Bitcoin',
                    smartContractsCapability: 'Exploración de la capacidad de implementar contratos inteligentes en la red de Bitcoin.',
                    smartContractsResources: 'Documentación, Casos de estudio',
                    smartContractsDuration: '1:30',

                    publicBlockchainApplicationsDevelopment: 'Aplicaciones y Desarrollo en la Blockchain Pública',
                    publicBlockchainPotentialApps: 'Potenciales aplicaciones y desarrollo en la Blockchain Pública.',
                    publicBlockchainAppsResources: 'Guías, Ejemplos prácticos',
                    publicBlockchainAppsDuration: '1:30',

                    bitcoinSecurityCurrentChallenges: 'Seguridad y Desafíos Actuales en Bitcoin',
                    bitcoinSecurityIssues: 'Aspectos de seguridad y desafíos actuales en el uso de Bitcoin.',
                    bitcoinSecurityResources: 'Análisis, Estudios de caso',
                    bitcoinSecurityDuration: '1:30',

                    futurePerspectives: 'Perspectivas Futuras',
                    futureTrendsInBitcoinBlockchain: 'Visión de las futuras tendencias y desarrollos en Bitcoin y la tecnología Blockchain Pública.',
                    futurePerspectivesResources: 'Artículos, Investigación',
                    futurePerspectivesDuration: '1:00',

                    totalModuleDuration: 'Duración Total del módulo',
                    moduleContentOverview: 'Esto proporciona una visión general del contenido y la duración del curso de Bitcoin y Blockchain Pública.',
                    moduleDigitalResources: 'Recursos Digitales',
                    moduleTotalDuration: 'Aproximadamente 10:00',


                    titleFormacion3: 'Bitcoin y Blockchain Pública',
                    subtitleFormacion3: ' Este módulo proporciona una comprensión profunda de Bitcoin y la tecnología de la Blockchain Pública. Desde los principios fundamentales hasta las aplicaciones y desafíos actuales, los estudiantes obtendrán una visión completa de este revolucionario sistema financiero y tecnológico.',


                    //Formacion-4
                    ethereumIntroduction: 'Introducción a Ethereum',
                    ethereumIntroductionDescription: 'Principios fundamentales de Ethereum y su impacto en la tecnología blockchain.',
                    ethereumAdditionalMaterials: 'Artículos, Tutoriales',
                    ethereumIntroductionDuration: '3:00',

                    smartContractsIntroduction: 'Contratos Inteligentes',
                    smartContractsDetail: 'Exploración detallada de los contratos inteligentes y su implementación en Ethereum.',
                    smartContractsResources: 'Documentación, Ejemplos prácticos',
                    smartContractsDuration: '5:00',

                    ethereumDevelopment: 'Desarrollo en Ethereum',
                    ethereumDevelopmentProcess: 'Proceso de desarrollo y despliegue de aplicaciones en la plataforma Ethereum.',
                    ethereumDevelopmentResources: 'Tutoriales, Ejemplos de código',
                    ethereumDevelopmentDuration: '4:00',

                    smartContractsSecurity: 'Seguridad en Contratos Inteligentes',
                    smartContractsSecurityConsiderations: 'Consideraciones de seguridad al programar y desplegar contratos inteligentes.',
                    smartContractsSecurityResources: 'Guías, Artículos',
                    smartContractsSecurityDuration: '4:00',

                    interactingWithSmartContracts: 'Interacción con Contratos Inteligentes',
                    smartContractsInteractionDetail: 'Cómo interactuar y utilizar contratos inteligentes desde aplicaciones externas.',
                    smartContractsInteractionResources: 'Documentación, Casos de estudio',
                    smartContractsInteractionDuration: '4:00',

                    ethereumWallets: 'Wallets en Ethereum',
                    ethereumWalletsFunctionality: 'Tipos de wallets disponibles en Ethereum y su funcionamiento.',
                    ethereumWalletsResources: 'Guías, Ejemplos prácticos',
                    ethereumWalletsDuration: '4:00',

                    walletsSecurity: 'Seguridad en Wallets',
                    walletsSecurityIssues: 'Consideraciones de seguridad al gestionar wallets y fondos en Ethereum.',
                    walletsSecurityResources: 'Análisis, Estudios de caso',
                    walletsSecurityDuration: '4:00',

                    advancedDevelopment: 'Desarrollo Avanzado',
                    advancedDevelopmentConcepts: 'Exploración de conceptos avanzados en el desarrollo de aplicaciones Ethereum.',
                    advancedDevelopmentResources: 'Artículos, Ejemplos prácticos',
                    advancedDevelopmentDuration: '4:00',

                    oraclesAndExternalData: 'Oráculos y Datos Externos',
                    oraclesUsageDetail: 'Cómo los contratos inteligentes pueden interactuar con datos externos mediante oráculos.',
                    oraclesResources: 'Documentación, Casos de estudio',
                    oraclesDuration: '3:00',

                    ethereumGovernance: 'Gobernanza en Ethereum',
                    governanceModelsDiscussion: 'Discusión sobre los modelos de gobernanza y toma de decisiones en el ecosistema Ethereum.',
                    governanceResources: 'Guías, Investigación',
                    governanceDuration: '3:00',

                    enterpriseUseCases: 'Casos de Uso Empresariales',
                    enterpriseApplicationsDetail: 'Aplicaciones empresariales de la tecnología Ethereum y contratos inteligentes.',
                    enterpriseResources: 'Estudios de caso, Documentación',
                    enterpriseDuration: '4:00',

                    deployingOnPrivateNetworks: 'Despliegue en Redes Privadas',
                    privateNetworksDeploymentConsiderations: 'Consideraciones y procesos para desplegar aplicaciones Ethereum en redes privadas.',
                    privateNetworksResources: 'Guías, Ejemplos prácticos',
                    privateNetworksDuration: '3:00',

                    futureOutlooks: 'Perspectivas Futuras',
                    futureTrendsInEthereum: 'Visión de las futuras tendencias y desarrollos en Ethereum y contratos inteligentes.',
                    futureOutlooksResources: 'Artículos, Investigación',
                    futureOutlooksDuration: '3:00',

                    totalModuleDurationEthereum: 'Duración Total del módulo (Ethereum)',
                    moduleContentOverviewEthereum: 'Esto proporciona una visión general del contenido y la duración del curso de Ethereum y Contratos Inteligentes.',
                    moduleDigitalResourcesEthereum: 'Recursos Digitales',
                    moduleTotalDurationEthereum: 'Aproximadamente 46:00',


                    titleFormacion4: 'Ethereum y Contratos Inteligentes',
                    subtitleFormacion4: 'Este módulo proporciona una comprensión detallada de Ethereum y los contratos inteligentes. Desde los principios fundamentales hasta la seguridad y la interacción práctica, los estudiantes obtendrán un conocimiento integral de esta poderosa plataforma blockchain.',


                    //Formacion-5
                    DeFiIntroduction: 'Introducción a Finanzas Descentralizadas (DeFi)',
                    DeFiIntroductionDescription: 'Principios fundamentales y conceptos clave de DeFi.',
                    DeFiAdditionalMaterials: 'Artículos, Tutoriales',
                    DeFiIntroductionDuration: '1:00',

                    LendingAndFlashLoans: 'Plataformas de Préstamos y Préstamos Flash',
                    LendingAndFlashLoansDetail: 'Exploración de plataformas que permiten préstamos y préstamos flash en el ecosistema DeFi.',
                    LendingAndFlashLoansResources: 'Documentación, Ejemplos prácticos',
                    LendingAndFlashLoansDuration: '1:00',

                    DecentralizedExchanges: 'Exchanges Descentralizados (DEX)',
                    DecentralizedExchangesFunctioning: 'Funcionamiento y uso de Exchanges Descentralizados en DeFi.',
                    DecentralizedExchangesResources: 'Tutoriales, Ejemplos de código',
                    DecentralizedExchangesDuration: '1:00',

                    StakingAndFarming: 'Staking y Farming',
                    StakingAndFarmingDetail: 'Participación en staking y farming para obtener rendimientos en DeFi.',
                    StakingAndFarmingResources: 'Guías, Artículos',
                    StakingAndFarmingDuration: '1:00',

                    DeFiRisksAndChallenges: 'Riesgos y Desafíos en DeFi',
                    DeFiRisksAndChallengesDetail: 'Consideraciones sobre riesgos y desafíos asociados con las finanzas descentralizadas.',
                    DeFiRisksAndChallengesResources: 'Documentación, Casos de estudio',
                    DeFiRisksAndChallengesDuration: '1:00',

                    StablecoinsInDeFi: 'Stablecoins en DeFi',
                    StablecoinsInDeFiDetail: 'Exploración de las stablecoins y su papel en el ecosistema DeFi.',
                    StablecoinsInDeFiResources: 'Guías, Ejemplos prácticos',
                    StablecoinsInDeFiDuration: '1:00',

                    AltcoinsAndTokensInDeFi: 'Altcoins y Tokens en DeFi',
                    AltcoinsAndTokensInDeFiAnalysis: 'Análisis de altcoins y tokens utilizados en aplicaciones DeFi.',
                    AltcoinsAndTokensInDeFiResources: 'Artículos, Casos de estudio',
                    AltcoinsAndTokensInDeFiDuration: '1:00',

                    OraclesAndDataInDeFi: 'Oráculos y Datos en DeFi',
                    OraclesAndDataInDeFiUsage: 'Cómo los oráculos proporcionan datos del mundo real para las aplicaciones DeFi.',
                    OraclesAndDataInDeFiResources: 'Tutoriales, Investigación',
                    OraclesAndDataInDeFiDuration: '1:00',

                    CentralizationVsDecentralizationInDeFi: 'Centralización vs. Descentralización en DeFi',
                    CentralizationVsDecentralizationDiscussion: 'Discusión sobre los aspectos de centralización y descentralización en el ecosistema DeFi.',
                    CentralizationVsDecentralizationResources: 'Guías, Artículos',
                    CentralizationVsDecentralizationDuration: '1:00',

                    TotalModuleDurationDeFi: 'Duración Total del módulo',
                    ModuleContentOverviewDeFi: 'Esto proporciona una visión general del contenido y la duración del curso de Finanzas Descentralizadas (DeFi).',
                    ModuleDigitalResourcesDeFi: 'Recursos Digitales',
                    TotalDurationDeFi: 'Aproximadamente 9:00',

                    titleFormacion5: 'Finanzas Descentralizadas (DeFi)',
                    subtitleFormacion5: 'Este módulo proporciona una introducción completa a las Finanzas Descentralizadas (DeFi). Desde los conceptos fundamentales hasta las plataformas y los riesgos asociados, los estudiantes obtendrán una visión integral de este emocionante campo financiero.',

                    //Formacion-6
                    NFTIntroduction: 'Introducción a Tokens No Fungibles (NFT)',
                    NFTIntroductionDescription: 'Principios fundamentales y conceptos clave de los Tokens No Fungibles (NFT).',
                    NFTAdditionalMaterials: 'Artículos, Tutoriales',
                    NFTIntroductionDuration: '1:00',

                    NFTCreationAndIssuance: 'Creación y Emisión de NFT',
                    NFTCreationAndIssuanceDetail: 'Proceso de creación y emisión de Tokens No Fungibles (NFT).',
                    NFTCreationAndIssuanceResources: 'Documentación, Ejemplos prácticos',
                    NFTCreationAndIssuanceDuration: '1:00',

                    NFTMarketAndTrading: 'Mercado y Comercio de NFT',
                    NFTMarketAndTradingDetail: 'Exploración de mercados y plataformas para el comercio de Tokens No Fungibles (NFT).',
                    NFTMarketAndTradingResources: 'Tutoriales, Ejemplos de código',
                    NFTMarketAndTradingDuration: '1:00',

                    NFTApplicationsAndCreativeUses: 'Aplicaciones y Usos Creativos',
                    NFTApplicationsAndCreativeUsesDetail: 'Aplicaciones y usos creativos de Tokens No Fungibles (NFT) en diversos campos.',
                    NFTApplicationsAndCreativeUsesResources: 'Guías, Artículos',
                    NFTApplicationsAndCreativeUsesDuration: '1:00',

                    NFTChallengesAndFuture: 'Desafíos y Futuro de los NFT',
                    NFTChallengesAndFutureDetail: 'Consideraciones sobre desafíos actuales y futuros del mundo de los Tokens No Fungibles (NFT).',
                    NFTChallengesAndFutureResources: 'Documentación, Casos de estudio',
                    NFTChallengesAndFutureDuration: '1:00',

                    NFTUtility: 'Utilidad de los NFT',
                    NFTUtilityDetail: 'Exploración de la utilidad práctica de los Tokens No Fungibles (NFT) en la sociedad actual.',
                    NFTUtilityResources: 'Entrevistas, Estudios de caso',
                    NFTUtilityDuration: '1:00',

                    HybridNFTTokens: 'Tokens NFT Híbridos',
                    HybridNFTTokensDetail: 'Análisis de los Tokens NFT Híbridos y su relación con otros activos digitales.',
                    HybridNFTTokensResources: 'Artículos, Investigación',
                    HybridNFTTokensDuration: '1:00',

                    NFTLegalityAndOwnership: 'Legalidad y Propiedad de NFT',
                    NFTLegalityAndOwnershipDetail: 'Aspectos legales y cuestiones de propiedad asociadas con los Tokens No Fungibles (NFT).',
                    NFTLegalityAndOwnershipResources: 'Guías, Documentación',
                    NFTLegalityAndOwnershipDuration: '1:00',

                    NFTInteroperability: 'Interoperabilidad en el Espacio NFT',
                    NFTInteroperabilityDetail: 'Cómo los Tokens No Fungibles (NFT) interactúan y se integran en diferentes plataformas y ecosistemas.',
                    NFTInteroperabilityResources: 'Tutoriales, Ejemplos prácticos',
                    NFTInteroperabilityDuration: '1:00',

                    NFTSocialImpact: 'Impacto Social de los NFT',
                    NFTSocialImpactDetail: 'Exploración del impacto social y cultural de los Tokens No Fungibles (NFT).',
                    NFTSocialImpactResources: 'Artículos, Entrevistas',
                    NFTSocialImpactDuration: '1:00',

                    TotalModuleDurationNFT: 'Duración Total del módulo (NFT)',
                    ModuleContentOverviewNFT: 'Esto proporciona una visión general del contenido y la duración del curso de Tokens No Fungibles (NFT).',
                    ModuleDigitalResourcesNFT: 'Recursos Digitales',
                    TotalDurationNFT: 'Aproximadamente 10:00',


                    titleFormacion6: 'Tokens No Fungibles (NFT)',
                    subtitleFormacion6: 'Este módulo proporciona una introducción completa a los Tokens No Fungibles (NFT). Desde los conceptos fundamentales hasta la creación, comercio y aplicaciones creativas, los estudiantes obtendrán una visión completa de este fascinante aspecto del espacio criptográfico.',

                    //Formacion-7
                    BlockchainTradingBasics: 'Principios Básicos del Trading en Blockchain',
                    BlockchainTradingBasicsDescription: 'Introducción a los principios fundamentales del trading en el contexto de la tecnología blockchain.',
                    BlockchainTradingBasicsMaterials: 'Artículos, Tutoriales',
                    BlockchainTradingBasicsDuration: '1:00',

                    TechnicalAndFundamentalAnalysis: 'Análisis Técnico y Fundamental',
                    TechnicalAndFundamentalAnalysisDetail: 'Exploración de los métodos de análisis técnico y fundamental aplicados al trading en blockchain.',
                    TechnicalAndFundamentalAnalysisResources: 'Documentación, Ejemplos prácticos',
                    TechnicalAndFundamentalAnalysisDuration: '2:00',

                    TradingPlatformsAndTools: 'Plataformas y Herramientas de Trading',
                    TradingPlatformsAndToolsDetail: 'Revisión de las plataformas y herramientas esenciales para el trading en criptomonedas.',
                    TradingPlatformsAndToolsResources: 'Tutoriales, Ejemplos de código',
                    TradingPlatformsAndToolsDuration: '1:30',

                    RiskManagementAndStrategies: 'Gestión de Riesgos y Estrategias',
                    RiskManagementAndStrategiesDetail: 'Desarrollo de habilidades en gestión de riesgos y estrategias efectivas en el trading.',
                    RiskManagementAndStrategiesResources: 'Guías, Artículos',
                    RiskManagementAndStrategiesDuration: '2:00',

                    LongTermInvestmentsAndHodling: 'Inversiones a Largo Plazo y Hodling',
                    LongTermInvestmentsAndHodlingDetail: 'Exploración de estrategias de inversión a largo plazo y la filosofía de "hodling".',
                    LongTermInvestmentsAndHodlingResources: 'Documentación, Casos de estudio',
                    LongTermInvestmentsAndHodlingDuration: '2:00',

                    MarketEventsImpact: 'Impacto de Eventos en el Mercado',
                    MarketEventsImpactDetail: 'Análisis del impacto de eventos como bifurcaciones, halvings y regulaciones en el mercado de criptomonedas.',
                    MarketEventsImpactResources: 'Guías, Ejemplos prácticos',
                    MarketEventsImpactDuration: '1:00',

                    BlockchainDerivativesAndOptions: 'Derivados y Opciones en Blockchain',
                    BlockchainDerivativesAndOptionsDetail: 'Estudio de productos derivados y opciones disponibles en el espacio blockchain.',
                    BlockchainDerivativesAndOptionsResources: 'Artículos, Investigación',
                    BlockchainDerivativesAndOptionsDuration: '1:00',

                    LegalAndRegulatoryAspects: 'Aspectos Legales y Regulatorios',
                    LegalAndRegulatoryAspectsDetail: 'Consideraciones legales y regulatorias en el trading y las inversiones en blockchain.',
                    LegalAndRegulatoryAspectsResources: 'Guías, Documentación',
                    LegalAndRegulatoryAspectsDuration: '1:00',

                    EmergingTrendsInInvestments: 'Tendencias Emergentes en Inversiones',
                    EmergingTrendsInInvestmentsDetail: 'Exploración de las tendencias emergentes en estrategias de inversión y cómo estas podrían evolucionar en el futuro.',
                    EmergingTrendsInInvestmentsResources: 'Artículos, Entrevistas',
                    EmergingTrendsInInvestmentsDuration: '1:30',

                    CryptocurrenciesAndTraditionalBanking: 'Criptomonedas y Banca Tradicional',
                    CryptocurrenciesAndTraditionalBankingDetail: 'Análisis de la relación entre las criptomonedas y la banca tradicional, incluyendo posibles escenarios de colaboración o competencia.',
                    CryptocurrenciesAndTraditionalBankingResources: 'Guías, Estudios de caso',
                    CryptocurrenciesAndTraditionalBankingDuration: '1:30',

                    DeFiFuture: 'Descentralización Financiera (DeFi) en el Futuro',
                    DeFiFutureDetail: 'Examinar el papel de la DeFi en el futuro de las finanzas y cómo podría transformar el panorama financiero global.',
                    DeFiFutureResources: 'Documentación, Ejemplos prácticos',
                    DeFiFutureDuration: '2:00',

                    CryptocurrencyIntegrationInEconomies: 'Integración de Criptomonedas en Economías',
                    CryptocurrencyIntegrationInEconomiesDetail: 'Cómo las criptomonedas podrían integrarse en las economías tradicionales y el impacto en los sistemas financieros globales.',
                    CryptocurrencyIntegrationInEconomiesResources: 'Tutoriales, Investigación',
                    CryptocurrencyIntegrationInEconomiesDuration: '1:30',

                    CryptocurrencyMassAdoption: 'Adopción Masiva de Criptomonedas',
                    CryptocurrencyMassAdoptionDetail: 'Exploración de los posibles escenarios y desafíos asociados con la adopción masiva de criptomonedas en la sociedad.',
                    CryptocurrencyMassAdoptionResources: 'Artículos, Estudios de caso',
                    CryptocurrencyMassAdoptionDuration: '2:00',

                    EthicalInvestmentsInCryptocurrency: 'Ética en Inversiones Criptográficas',
                    EthicalInvestmentsInCryptocurrencyDetail: 'Consideraciones éticas en las inversiones relacionadas con criptomonedas y cómo los inversores pueden abordar cuestiones éticas en sus decisiones.',
                    EthicalInvestmentsInCryptocurrencyResources: 'Guías, Entrevistas',
                    EthicalInvestmentsInCryptocurrencyDuration: '1:30',

                    TotalModuleDurationTradingInvestments: 'Duración Total de Temas Actuales',
                    ModuleContentOverviewTradingInvestments: 'La inversión en criptomonedas',
                    ModuleDigitalResourcesTradingInvestments: 'Recursos Digitales',
                    TotalDurationTradingInvestments: 'Aproximadamente 15:30',

                    titleFormacion7: 'Inversiones y Trading en Blockchain',
                    subtitleFormacion7: 'Este módulo brinda conocimientos profundos sobre los principios del trading, análisis técnico y fundamental, gestión de riesgos, estrategias de inversión a largo plazo, y mucho más en el contexto de la tecnología blockchain.',

                    //Formacion-8
                    BlockchainAppIntroduction: 'Introducción al Desarrollo de Aplicaciones Blockchain',
                    BlockchainAppIntroductionDescription: 'Conceptos iniciales y principios fundamentales del desarrollo de aplicaciones en la tecnología blockchain.',
                    BlockchainAppIntroductionMaterials: 'Artículos, Tutoriales',
                    BlockchainAppIntroductionDuration: '1:30',

                    DAppsArchitecture: 'Arquitectura de Aplicaciones Descentralizadas (DApps)',
                    DAppsArchitectureDetail: 'Exploración de la arquitectura de las aplicaciones descentralizadas y su implementación en blockchain.',
                    DAppsArchitectureResources: 'Documentación, Ejemplos prácticos',
                    DAppsArchitectureDuration: '2:00',

                    SmartContractsProgramming: 'Programación de Contratos Inteligentes',
                    SmartContractsProgrammingDetail: 'Desarrollo práctico de contratos inteligentes utilizando lenguajes como Solidity en plataformas como Ethereum.',
                    SmartContractsProgrammingResources: 'Tutoriales, Ejemplos de código',
                    SmartContractsProgrammingDuration: '3:00',

                    BlockchainAPIInteraction: 'Interacción con Blockchain a través de APIs',
                    BlockchainAPIInteractionDetail: 'Cómo interactuar con blockchain y sus datos mediante APIs y servicios web.',
                    BlockchainAPIInteractionResources: 'Guías, Artículos',
                    BlockchainAPIInteractionDuration: '2:00',

                    BlockchainAppSecurity: 'Seguridad en Desarrollo de Aplicaciones Blockchain',
                    BlockchainAppSecurityDetail: 'Consideraciones y mejores prácticas de seguridad en el desarrollo de aplicaciones blockchain.',
                    BlockchainAppSecurityResources: 'Documentación, Casos de estudio',
                    BlockchainAppSecurityDuration: '3:00',

                    DeploymentAndMaintenance: 'Despliegue y Mantenimiento',
                    DeploymentAndMaintenanceDetail: 'Proceso de despliegue y mantenimiento de aplicaciones blockchain en entornos de producción.',
                    DeploymentAndMaintenanceResources: 'Guías, Ejemplos prácticos',
                    DeploymentAndMaintenanceDuration: '2:00',

                    UseCasesAndProjects: 'Casos de Uso y Proyectos Prácticos',
                    UseCasesAndProjectsDetail: 'Exploración de casos de uso comunes y desarrollo de proyectos prácticos en blockchain.',
                    UseCasesAndProjectsResources: 'Artículos, Investigación',
                    UseCasesAndProjectsDuration: '4:00',

                    EmergingTechIntegration: 'Integración de Tecnologías Emergentes',
                    EmergingTechIntegrationDetail: 'Cómo integrar tecnologías emergentes como IoT y machine learning en aplicaciones blockchain.',
                    EmergingTechIntegrationResources: 'Guías, Ejemplos de código',
                    EmergingTechIntegrationDuration: '2:00',

                    TotalModuleDurationBlockchainApp: 'Duración Total del módulo',
                    ModuleContentOverviewBlockchainApp: 'Esto proporciona una visión general del contenido y la duración del curso de Desarrollo de Aplicaciones Blockchain.',
                    ModuleDigitalResourcesBlockchainApp: 'Recursos Digitales',
                    TotalDurationBlockchainApp: 'Aproximadamente 20:30',
                    titleFormacion8: 'Desarrollo de Aplicaciones Blockchain',
                    subtitleFormacion8: 'Este módulo proporciona una inmersión completa en el desarrollo de aplicaciones blockchain, cubriendo desde la arquitectura de DApps hasta la programación de contratos inteligentes y la integración de tecnologías emergentes.',

                    //Formacion-9
                    EnterpriseIntroduction: 'Introducción a Casos de Uso Empresariales',
                    EnterpriseIntroductionDescription: 'Conceptos iniciales y principios fundamentales de casos de uso empresariales en tecnología blockchain.',
                    EnterpriseIntroductionMaterials: 'Artículos, Tutoriales',
                    EnterpriseIntroductionDuration: '1:00',

                    SupplyChainManagement: 'Gestión de la Cadena de Suministro',
                    SupplyChainManagementDetail: 'Aplicaciones de blockchain en la gestión transparente y eficiente de la cadena de suministro.',
                    SupplyChainManagementResources: 'Documentación, Ejemplos prácticos',
                    SupplyChainManagementDuration: '1:30',

                    ImmutableDocumentRegistry: 'Registro Inmutable de Documentos',
                    ImmutableDocumentRegistryDetail: 'Utilización de blockchain para la creación de registros inmutables y verificables de documentos.',
                    ImmutableDocumentRegistryResources: 'Tutoriales, Ejemplos de código',
                    ImmutableDocumentRegistryDuration: '1:30',

                    AssetTokenization: 'Tokenización de Activos',
                    AssetTokenizationDetail: 'Casos de uso empresariales de tokenización de activos en la blockchain.',
                    AssetTokenizationResources: 'Guías, Artículos',
                    AssetTokenizationDuration: '1:00',

                    GovernanceAndCompliance: 'Gobierno y Cumplimiento',
                    GovernanceAndComplianceDetail: 'Aplicaciones en la gestión de gobierno y cumplimiento utilizando tecnología blockchain.',
                    GovernanceAndComplianceResources: 'Documentación, Casos de estudio',
                    GovernanceAndComplianceDuration: '1:30',

                    DigitalIdentity: 'Identidad Digital',
                    DigitalIdentityDetail: 'Implementación de sistemas de identidad digital basados en blockchain.',
                    DigitalIdentityResources: 'Guías, Ejemplos prácticos',
                    DigitalIdentityDuration: '1:30',

                    VotingAndElectoralProcesses: 'Votación y Procesos Electorales',
                    VotingAndElectoralProcessesDetail: 'Uso de blockchain en sistemas de votación seguros y transparentes.',
                    VotingAndElectoralProcessesResources: 'Artículos, Investigación',
                    VotingAndElectoralProcessesDuration: '1:00',

                    DataPrivacyAndSecurity: 'Privacidad y Seguridad de Datos',
                    DataPrivacyAndSecurityDetail: 'Casos de uso empresariales para mejorar la privacidad y seguridad de datos con blockchain.',
                    DataPrivacyAndSecurityResources: 'Guías, Ejemplos de código',
                    DataPrivacyAndSecurityDuration: '1:00',

                    BusinessAdoptionAndOutlook: 'Adopción Empresarial y Perspectivas',
                    BusinessAdoptionAndOutlookDetail: 'Cómo las empresas adoptan blockchain y las perspectivas futuras.',
                    BusinessAdoptionAndOutlookResources: 'Recursos Digitales',
                    BusinessAdoptionAndOutlookDuration: '1:00',

                    TotalModuleDurationEnterprise: 'Duración Total del módulo',
                    ModuleContentOverviewEnterprise: 'Esto proporciona una visión general del contenido y la duración del curso de Casos de Uso Empresariales.',
                    ModuleDigitalResourcesEnterprise: 'Recursos Digitales',
                    TotalDurationEnterprise: 'Aproximadamente 10:00',

                    titleFormacion9: 'Casos de Uso Empresariales',
                    subtitleFormacion9: 'Este módulo explora diversos casos de uso empresariales de la tecnología blockchain, desde la gestión de la cadena de suministro hasta la identidad digital y la votación en procesos electorales.',
                    //Formacion 10
                    RegulationEthicsIntroduction: 'Introducción a la Regulación y Ética',
                    RegulationEthicsIntroductionDescription: 'Conceptos iniciales sobre regulación y ética en el espacio blockchain.',
                    RegulationEthicsIntroductionMaterials: 'Artículos, Tutoriales',
                    RegulationEthicsIntroductionDuration: '1:00',

                    LegalRegulatoryFramework: 'Marco Legal y Regulatorio',
                    LegalRegulatoryFrameworkDetail: 'Exploración del marco legal y regulatorio que rodea la tecnología blockchain.',
                    LegalRegulatoryFrameworkResources: 'Documentación, Ejemplos prácticos',
                    LegalRegulatoryFrameworkDuration: '1:30',

                    EthicalConsiderationsBlockchain: 'Consideraciones Éticas en Blockchain',
                    EthicalConsiderationsBlockchainDetail: 'Análisis de consideraciones éticas en el desarrollo y uso de tecnología blockchain.',
                    EthicalConsiderationsBlockchainResources: 'Tutoriales, Ejemplos de código',
                    EthicalConsiderationsBlockchainDuration: '1:00',

                    RegulatoryCompliance: 'Cumplimiento Normativo',
                    RegulatoryComplianceDetail: 'Cómo garantizar el cumplimiento normativo al trabajar con blockchain.',
                    RegulatoryComplianceResources: 'Guías, Artículos',
                    RegulatoryComplianceDuration: '0:30',

                    EthicalChallengesPerspectives: 'Perspectivas y Desafíos Éticos',
                    EthicalChallengesPerspectivesDetail: 'Exploración de perspectivas y desafíos éticos en la adopción de tecnología blockchain.',
                    EthicalChallengesPerspectivesResources: 'Documentación, Casos de estudio',
                    EthicalChallengesPerspectivesDuration: '1:00',

                    ICORegulationImpact: 'Impacto de la Regulación en las ICOs',
                    ICORegulationImpactDetail: 'Análisis del impacto de la regulación en las Ofertas Iniciales de Monedas (ICOs) y su evolución.',
                    ICORegulationImpactResources: 'Artículos, Estudios de caso',
                    ICORegulationImpactDuration: '0:30',

                    DataPrivacyProtection: 'Privacidad y Protección de Datos',
                    DataPrivacyProtectionDetail: 'Consideraciones sobre privacidad y protección de datos en el contexto de la tecnología blockchain.',
                    DataPrivacyProtectionResources: 'Guías, Ejemplos prácticos',
                    DataPrivacyProtectionDuration: '1:00',

                    SmartContractsEthics: 'Ética en Contratos Inteligentes',
                    SmartContractsEthicsDetail: 'Exploración de consideraciones éticas al programar y ejecutar contratos inteligentes en blockchain.',
                    SmartContractsEthicsResources: 'Tutoriales, Casos de estudio',
                    SmartContractsEthicsDuration: '0:30',

                    RecentLegalDevelopments: 'Desarrollos Legales Recientes',
                    RecentLegalDevelopmentsDetail: 'Resumen de los desarrollos legales más recientes relacionados con la tecnología blockchain.',
                    RecentLegalDevelopmentsResources: 'Documentación, Artículos',
                    RecentLegalDevelopmentsDuration: '1:00',

                    AMLCompliance: 'Compliance y Anti-Lavado de Dinero (AML)',
                    AMLComplianceDetail: 'Cómo cumplir con las regulaciones anti-lavado de dinero al trabajar con activos digitales.',
                    AMLComplianceResources: 'Guías, Ejemplos de código',
                    AMLComplianceDuration: '1:00',

                    BlockchainHumanRights: 'Blockchain y Derechos Humanos',
                    BlockchainHumanRightsDetail: 'Exploración de cómo la tecnología blockchain puede afectar los derechos humanos y cuestiones éticas asociadas.',
                    BlockchainHumanRightsResources: 'Investigación, Entrevistas',
                    BlockchainHumanRightsDuration: '1:00',

                    BlockchainAuditing: 'Auditoría Blockchain',
                    BlockchainAuditingDetail: 'Procesos y mejores prácticas para realizar auditorías en aplicaciones y contratos inteligentes en blockchain.',
                    BlockchainAuditingResources: 'Tutoriales, Ejemplos prácticos',
                    BlockchainAuditingDuration: '0:30',

                    DecentralizedProtocolsGovernance: 'Gobernanza en Protocolos Descentralizados',
                    DecentralizedProtocolsGovernanceDetail: 'El papel de la gobernanza en los protocolos descentralizados y consideraciones éticas asociadas.',
                    DecentralizedProtocolsGovernanceResources: 'Documentación, Casos de estudio',
                    DecentralizedProtocolsGovernanceDuration: '0:30',

                    GlobalCryptocurrencyRegulation: 'Regulación Global de Criptomonedas',
                    GlobalCryptocurrencyRegulationDetail: 'Resumen de la regulación global sobre criptomonedas y su impacto en el ecosistema.',
                    GlobalCryptocurrencyRegulationResources: 'Artículos, Estudios de caso',
                    GlobalCryptocurrencyRegulationDuration: '1:00',

                    BlockchainSustainability: 'Blockchain y Sostenibilidad',
                    BlockchainSustainabilityDetail: 'Consideraciones éticas y regulatorias sobre la sostenibilidad de la tecnología blockchain.',
                    BlockchainSustainabilityResources: 'Guías, Investigación',
                    BlockchainSustainabilityDuration: '0:30',

                    TotalModuleDurationRegulationEthics: 'Duración Total del módulo',
                    ModuleContentOverviewRegulationEthics: 'Esto proporciona una visión general del contenido y la duración del curso de Regulación y Ética.',
                    ModuleDigitalResourcesRegulationEthics: 'Recursos Digitales',
                    TotalDurationRegulationEthics: 'Aproximadamente 10:00',

                    Formacion10Title: 'Regulación y Ética',
                    Formacion10Subtitle: 'Este módulo aborda los aspectos regulatorios y éticos relacionados con la tecnología blockchain, explorando el marco legal, consideraciones éticas y perspectivas sobre la adopción de blockchain.',



                    //Dashboard
                    titleDasboard: "Explora el arte digital y nuestras formaciones",
                    Colecciones: "Colecciones",
                    TopSeller: "Usuarios Agregados",
                    BasicTraining: "Formación Basica"

                },
            },

        },


        lng: 'es',  // Establece el idioma predeterminado
        fallbackLng: 'es', // Establece un idioma de respaldo
        interpolation: {
            escapeValue: false, // React ya se encarga de escapar las variables
        },
    });

export default i18n;


