import React ,{useState}from "react";
import { Link, useNavigate, NavLink } from 'react-router-dom';
import { Navbar, Nav, Button, Container } from "react-bootstrap";
import { Logo } from "../../components"; // Asegúrate de que este componente exista y esté correctamente importado
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import LanguageSwitcher from "../elements/LanguageSwitcher"; // Asegúrate de que este componente exista y esté correctamente importado
import styled from 'styled-components';


// Estilos para la barra de navegación
const StyledNavbar = styled(Navbar)`
    background-color: #0A5364;
`;

// Estilos para los enlaces de navegación activos

const StyledNavLink = styled(NavLink)`
    color: #fff; /* Texto blanco por defecto */

    &:hover {
        color: #D4AE42 !important; /* Texto negro al pasar el ratón */
    }

    &.active {
        background-color: yellow !important;
        color: #000 !important; /* Texto negro cuando está activo */
    }

    span {
        font-size: 18px;
        margin-right: 10px; /* Agrega un margen derecho para separar los enlaces */
    }
`;

// Estilos para los botones
const StyledButton = styled(Button)`
   && {
      border: 2px solid #fff;
      border-radius: 20px;
      color: #fff;
      font-size: 12px;
      height: 40px;
      padding: 10px 20px;
      transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      margin-bottom: 10px; /* Ajusta el margen inferior para separar los botones */
   }

   &&:hover {
      background-color: #d4ae42;
      color: #000;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
   }

   @media (max-width: 768px) {
      && {
         height: 5vh;
         width: 40vw;
         padding: 5px 10px;
         margin-bottom: 10px; /* Ajusta el margen inferior para separar los botones en dispositivos móviles */
         border: none; /* Elimina el borde en dispositivos móviles */
      }

      margin-right: auto;
      margin-left: auto;
   }
`;




const MobileSpacing = styled.div`
    @media (max-width: 768px) {
        margin-bottom: 20px; // Ajusta el espacio entre líneas en dispositivos móviles
    }
`;


export const NavigationLogin = ({ web3Handler, account, logoutHandler }) => {
    const navigate = useNavigate();
    const [activeLink, setActiveLink] = useState(null);

    const handleLogout = () => {
          // Después de cerrar la sesión, refresca la página.
    window.location.reload();
    };

    const handleButtonClick = () => {
        if (account) {
            handleLogout();
        } else {
            web3Handler(); // Función para conectar el monedero
        }
    };

    return (

        <StyledNavbar expand="lg" variant="dark" className="fixed-top">
            <Container>
                <Navbar.Brand>
                    <Logo width="110px" height="40px" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <MobileSpacing>
                            <StyledNavLink as={Link} to="/panel" activeClassName="active" isActive={() => activeLink === '/'}>
                            <span style={{ fontSize: '16px' }} >Panel</span></StyledNavLink>
                        </MobileSpacing>
                       {/*  <MobileSpacing>
                            <StyledNavLink as={Link} to="/nft-datelles" activeClassName="active">
                            <span style={{ fontSize: '16px' }}>NFTs </span>
                            
                            </StyledNavLink>
                        </MobileSpacing>*/}
                        <MobileSpacing>
                            
                            <StyledNavLink as={Link} to="/mis-NFTs" activeClassName="active">
                            <span style={{ fontSize: '16px' }}> NFTs</span>
                            </StyledNavLink>
                        </MobileSpacing>
                        <MobileSpacing>
                            
                            <StyledNavLink as={Link} to="/kits" activeClassName="active">
                            <span style={{ fontSize: '16px' }}> Kits</span>
                            </StyledNavLink>
                        </MobileSpacing>
                        <MobileSpacing>
                            <StyledNavLink as={Link} to="/mis-compras" activeClassName="active">
                            <span style={{ fontSize: '16px' }}> Compras</span>
                            </StyledNavLink>
                        </MobileSpacing>
                        <MobileSpacing>
                            <StyledNavLink as={Link} to="/nuestros-cursos" activeClassName="active">
                            <span style={{ fontSize: '16px' }}>Formaciones</span>
                                </StyledNavLink>
                        </MobileSpacing>
                       {/* <MobileSpacing>
                                <StyledNavLink as={Link} to="/all-users" activeClassName="active">
                                    <span style={{ fontSize: '16px' }}>Información</span>
                                </StyledNavLink>
                            </MobileSpacing>*/}
                            <MobileSpacing>
                                <StyledNavLink as={Link} to="/certificate" activeClassName="active">
                                    <span style={{ fontSize: '16px' }}>Certificación</span>
                                </StyledNavLink>
                            </MobileSpacing>

                        

                    </Nav>
                    <Nav>
                        {account ? (
                                <Nav.Link
                                href={`https://etherscan.io/address/${account}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="button nav-button btn-sm mx-4">
                                <StyledButton variant="outline-light">{account}</StyledButton>
                            </Nav.Link>
                        ) : (
                            <StyledButton onClick={handleButtonClick}>
                                Connect Wallet
                            </StyledButton>
                        )}
                    </Nav>
                    <LanguageSwitcher />
                    <button onClick={handleLogout} style={{
                        marginLeft: "20px",
                        border: "none",
                        background: "transparent",
                        cursor: "pointer"
                    }}>
                        <FontAwesomeIcon icon={faSignOutAlt} size="lg" style={{ color: "white" }} />
                    </button>
                </Navbar.Collapse>
            </Container>
        </StyledNavbar>
    );
}
