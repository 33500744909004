import React from 'react';
import YouTubePlaylist from './YouTubePlaylist';
import VideoComponent from './VideoComponent';

const App = () => {
  return (
    <div>
      <h1>Mi Aplicación de Videos</h1>
      <YouTubePlaylist playlistId="PLVH3pvCIcjhvdW6m109wN0B6v7jyOPdAl" />
    
    </div>
  );
};

export default App;
