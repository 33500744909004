import { Margin } from "@mui/icons-material";
import React from "react";

const MaintenancePage = () => {
  return (
    <div style={styles.bg404}>
      

      <div style={styles.gearsImg} className="sm-hide">
        <svg className="machine" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 645 526" fill="url(#grad1)">
          <defs>
            <linearGradient id="grad1" x1="0%" y1="0%" x2="100%">
              <stop offset="0%" style={styles.gearsGrd1} />
              <stop offset="100%" style={styles.gearsGrd2} />
            </linearGradient>
          </defs>
          <g>
            <path className="large-shadow" style={styles.largeShadow} d="M645 194v-21l-29-4c-1-10-3-19-6-28l25-14 -8-19 -28 7c-5-8-10-16-16-24L602 68l-15-15 -23 17c-7-6-15-11-24-16l7-28 -19-8 -14 25c-9-3-18-5-28-6L482 10h-21l-4 29c-10 1-19 3-28 6l-14-25 -19 8 7 28c-8 5-16 10-24 16l-23-17L341 68l17 23c-6 7-11 15-16 24l-28-7 -8 19 25 14c-3 9-5 18-6 28l-29 4v21l29 4c1 10 3 19 6 28l-25 14 8 19 28-7c5 8 10 16 16 24l-17 23 15 15 23-17c7 6 15 11 24 16l-7 28 19 8 14-25c9 3 18 5 28 6l4 29h21l4-29c10-1 19-3 28-6l14 25 19-8 -7-28c8-5 16-10 24-16l23 17 15-15 -17-23c6-7 11-15 16-24l28 7 8-19 -25-14c3-9 5-18 6-28L645 194zM471 294c-61 0-110-49-110-110S411 74 471 74s110 49 110 110S532 294 471 294z" />
          </g>
          <g>
            <path className="medium-shadow" style={styles.mediumShadow} d="M402 400v-21l-28-4c-1-10-4-19-7-28l23-17 -11-18L352 323c-6-8-13-14-20-20l11-26 -18-11 -17 23c-9-4-18-6-28-7l-4-28h-21l-4 28c-10 1-19 4-28 7l-17-23 -18 11 11 26c-8 6-14 13-20 20l-26-11 -11 18 23 17c-4 9-6 18-7 28l-28 4v21l28 4c1 10 4 19 7 28l-23 17 11 18 26-11c6 8 13 14 20 20l-11 26 18 11 17-23c9 4 18 6 28 7l4 28h21l4-28c10-1 19-4 28-7l17 23 18-11 -11-26c8-6 14-13 20-20l26 11 11-18 -23-17c4-9 6-18 7-28L402 400zM265 463c-41 0-74-33-74-74 0-41 33-74 74-74 41 0 74 33 74 74C338 430 305 463 265 463z" />
          </g>
          <g>
            <path className="small-shadow" style={styles.smallShadow} d="M210 246v-21l-29-4c-2-10-6-18-11-26l18-23 -15-15 -23 18c-8-5-17-9-26-11l-4-29H100l-4 29c-10 2-18 6-26 11l-23-18 -15 15 18 23c-5 8-9 17-11 26L10 225v21l29 4c2 10 6 18 11 26l-18 23 15 15 23-18c8 5 17 9 26 11l4 29h21l4-29c10-2 18-6 26-11l23 18 15-15 -18-23c5-8 9-17 11-26L210 246zM110 272c-20 0-37-17-37-37s17-37 37-37c20 0 37 17 37 37S131 272 110 272z" />
          </g>
          <g>
            <path className="small" style={styles.small} d="M200 236v-21l-29-4c-2-10-6-18-11-26l18-23 -15-15 -23 18c-8-5-17-9-26-11l-4-29H90l-4 29c-10 2-18 6-26 11l-23-18 -15 15 18 23c-5 8-9 17-11 26L0 215v21l29 4c2 10 6 18 11 26l-18 23 15 15 23-18c8 5 17 9 26 11l4 29h21l4-29c10-2 18-6 26-11l23 18 15-15 -18-23c5-8 9-17 11-26L200 236zM100 262c-20 0-37-17-37-37s17-37 37-37c20 0 37 17 37 37S121 262 100 262z" />
          </g>
          <g>
            <path className="large" style={styles.large} d="M635 184v-21l-29-4c-1-10-3-19-6-28l25-14 -8-19 -28 7c-5-8-10-16-16-24L592 58l-15-15 -23 17c-7-6-15-11-24-16l7-28 -19-8 -14 25c-9-3-18-5-28-6L472 0h-21l-4 29c-10 1-19 3-28 6L405 9l-19 8 7 28c-8 5-16 10-24 16l-23-17L331 58l17 23c-6 7-11 15-16 24l-28-7 -8 19 25 14c-3 9-5 18-6 28l-29 4v21l29 4c1 10 3 19 6 28l-25 14 8 19 28-7c5 8 10 16 16 24l-17 23 15 15 23-17c7 6 15 11 24 16l-7 28 19 8 14-25c9 3 18 5 28 6l4 29h21l4-29c10-1 19-3 28-6l14 25 19-8 -7-28c8-5 16-10 24-16l23 17 15-15 -17-23c6-7 11-15 16-24l28 7 8-19 -25-14c3-9 5-18 6-28L635 184zM461 284c-61 0-110-49-110-110S401 64 461 64s110 49 110 110S522 284 461 284z" />
          </g>
        </svg>
      </div>

      <section style={styles.pd5vw}>
        <div>
          <div style={{ ...styles.textSize10vh, ...styles.dpd20, ...styles.textTurquoise, zIndex: '1' }}>
            En Mantenimiento
          </div>
          <div style={{ ...styles.textSizeExpanding, ...styles.textPurple, ...styles.dpd20 }}>
            <strong>
              Volveremos Pronto.
            </strong>
          </div>
        </div>
        <div style={{ ...styles.textHeight15, ...styles.textGrey, ...styles.textSize18, ...styles.dpd20 }}>
          Estamos ocupados actualizándonos con nueva tecnología.
          Pedimos disculpas por los inconvenientes.
        </div>
        <div>
          Puedes volver a la página de inicio:
        </div>
        <br />
        <div>
          <a href="/nuestros-cursos" style={styles.textHome}>Cursos</a>
        </div>
      </section>

     
    </div>
  );
};

const styles = {
  bg404: {
    height: "100%",
    position: "relative",
    background: "#eee",
    marginTop:'18%',
    backgroundImage: "linear-gradient(to bottom, rgba(232,247,252,1) 0%,rgba(249,249,249,1) 100%)",
  },
  pd5vw: {
    padding: "5vw",
    zIndex: 1
  },
  dpd20: {
    paddingBottom: "20px",
  },
  textPurple: {
    color: "#6435c9",
  },
  textGrey: {
    color: "#5f7f89",
  },
  textTurquoise: {
    color: "#4fc1ea",
  },
  textSize10vh: {
    fontSize: "10vh",
  },
  textSizeExpanding: {
    fontSize: "12vh",
    transition: "all 0.5s ease",
    ':hover': {
      fontSize: "14vh",
    },
  },
  textSize18: {
    fontSize: "18px",
  },
  textHeight15: {
    lineHeight: "1.5",
  },
  textHome: {
    color: "#f0b429",
    textDecoration: "none",
  },
  bottomCopy: {
    position: "fixed",
    bottom: "0",
    left: "0",
    right: "0",
    minHeight: "140px",
    textAlign: "center",
  },
  gearsGrd1: {
    stopColor: "#4fc1ea",
    stopOpacity: "1",
  },
  gearsGrd2: {
    stopColor: "#6435c9",
    stopOpacity: "1",
  },
  gearsImg: {
    position: "absolute",
    textAlign: "right",
    right: "10vw",
    bottom: "10vh",
    width: "40%",
    zIndex:0
  },
  largeShadow: {
    fill: "rgba(0, 0, 0, 0.05)",
  },
  mediumShadow: {
    fill: "rgba(0, 0, 0, 0.05)",
  },
  smallShadow: {
    fill: "rgba(0, 0, 0, 0.05)",
  },
  small: {
    animation: "counter-rotation 2.5s infinite linear",
    transformOrigin: "100.136px 225.345px",
  },
  medium: {
    animation: "rotation 3.75s infinite linear",
    transformOrigin: "254.675px 379.447px",
  },
  large: {
    animation: "counter-rotation 5s infinite linear",
    transformOrigin: "461.37px 173.694px",
  },
  svg: {
    shapeRendering: "geometricPrecision",
    textRendering: "geometricPrecision",
    imageRendering: "optimizeQuality",
  },
  fil0: {
    fill: "#000000",
  },
};

export default MaintenancePage;
