import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import '../../../public/assets/css/style.css';



export default function Footer1() {


    const { t } = useTranslation();



    return (
        <>
            <footer id="footer" >
                <div className="themesflat-container"  >
                    <div className="row"  >
                        <div className="col-12">


                            <div className="footer-content flex flex-grow">
                                <div className="widget-logo flex-grow">
                                    <div className="logo-footer" id="logo-footer">
                                        <Link to="/">
                                            <img
                                                id="logo_footer"
                                                src="https://raw.githubusercontent.com/coachblockchain/Images/main/COACH%20ORO.png"
                                                alt="Logo de la empresa"
                                                style={{ maxWidth: '70%', height: 'auto' }}
                                            />


                                        </Link>
                                    </div>
                                </div>


                                <div className="widget-row">
                                    <div className="widget-col">
                                        <div className="widget widget-menu style-1"  >
                                            <h5 className="title-widget" >{t('mercado')}</h5>
                                            <ul>
                                                <li><Link to="/explora-nft">{t('allNFTs')}</Link></li>
                                                <li><Link to="/detalles">{t('virtualWorlds')}</Link></li>
                                                <li><Link to="/explora-nft">{t('ourNFTs')}</Link></li>
                                                <li><Link to="/detalles">{t('digitalArt')}</Link></li>

                                            </ul>
                                        </div>
                                    </div>

                                    <div className="widget-col">
                                        <div className="widget widget-menu style-2">
                                            <h5 className="title-widget">{t('recursos')}</h5>
                                            <ul>
                                                <li><Link to="/guia-uso">{t('helpCenter')}</Link></li>
                                                <li><Link to="/maintenance">{t('platformStatus')}</Link></li>
                                                <li><Link to="/formacion">{t('aboutUs')}</Link></li>
                                                <li><Link to="/comunidad">{t('discover')}</Link></li>
                                                <li><Link to="/kits">{t('packages')}</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>



                                <div className="widget-row">
                                    <div className="widget-col">
                                        <div className="widget widget-menu style-3">
                                            <h5 className="title-widget">{t('cuenta')}</h5>
                                            <ul>
                                                <li><Link to="/explora-nft">{t('authors')}</Link></li>
                                                <li><Link to="/explora-nft">{t('collectionProfile')}</Link></li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="widget-col">
                                        <div className="widget-last">
                                            <div className="widget-menu style-4">
                                                <h5 className="title-widget">{t('empresa')}</h5>
                                                <ul>
                                                <li><Link to="/guia-uso">{t('helpCenter')}</Link></li>
                                                </ul>
                                            </div>
                                            <h5 className="title-widget mt-3" style={{ textAlign: 'center' }}>{t('widgetTitle')}</h5>
      
                                        </div>
                                    </div>

                                </div>
                            </div>




                        </div>
                    </div>
                    <div className="footer-bottom">
                        <p>© {new Date().getFullYear()} COACH BLOCKCHAIN</p>
                        <ul className="flex">
                            <li>
                            <Link to="/terms-condictions">{t('privacyPolicy')}</Link>
                            </li>
                            <li>
                            <Link to="/terminos-servicio">{t('termsOfService')}</Link>
                            </li>

                        </ul>
                    </div>
                </div>
            </footer>

        </>
    )
}
