import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import './RegisterForm.css';

export const RegisterForm = () => {
    const [web3, setWeb3] = useState(null);
    const [account, setAccount] = useState(null);
    const [referralCode, setReferralCode] = useState('');
    const [userAddress, setUserAddress] = useState(''); // Cambié el nombre de setReferrerAddress a setUserAddress para mayor claridad.
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const [registerErrorMessage, setRegisterErrorMessage] = useState('');




    useEffect(() => {
        initializeMetaMask();
    }, []);



    const initializeMetaMask = () => {
        if (window.ethereum) {
            const web3Instance = new Web3(window.ethereum);
            setWeb3(web3Instance);
            window.ethereum.request({ method: 'eth_requestAccounts' })
                .then(accounts => {
                    setAccount(accounts[0]);
                }).catch(error => {
                    console.error('Error requesting accounts:', error);
                    setAccount(null); // Establecer la cuenta en null si hay un error al solicitar cuentas
                    setWeb3(null); // Establecer web3 en null si hay un error al solicitar cuentas
                });
        } else {
            console.error('MetaMask is not installed!');
            setAccount(null); // Establecer la cuenta en null si MetaMask no está instalado
            setWeb3(null); // Establecer web3 en null si MetaMask no está instalado
        }
    };


    const handleRegister = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setMessage('');

        if (!web3 || !account) {
            console.error('Web3 is not initialized or account not found.');
            setIsLoading(false);
            return;
        }

        const contractAddress = require(`../../contractsData/inviteCode-address.json`).address;
        const contractABI = require(`../../contractsData/InvitationRegistry-data.json`);
        const contract = new web3.eth.Contract(contractABI, contractAddress);




        contract.events.RegistrationFailed({ fromBlock: 'latest' })
            .on('data', event => {
                const errorMsg = `Error en el registro: ${event.returnValues.reason}`;
                setRegisterErrorMessage(errorMsg); // Store the error message from the event
                setMessage(errorMsg);
                setIsLoading(false);
            });
        try {
            if (!web3.utils.isAddress(userAddress)) {
                throw new Error("La dirección del wallet no es válida");
            }

            const gasEstimate = await contract.methods.registerUser(userAddress, referralCode).estimateGas({
                from: account
            });

            

            await contract.methods.registerUser(userAddress, referralCode).send({ from: account, gas: gasEstimate }) // Ajusta el límite de gas según sea necesario
            // Nota: asegúrate de que el contrato maneje correctamente el referralCode como hash.
            setMessage('Registro exitoso. Bienvenido a la dApp!');
            setMessageType('success'); // Agregar esta línea en caso de éxito
            console.log('Registro realizado con éxito');
        } catch (error) {

            console.error('Error al realizar el registro:', error);
            setMessage('Error en el registro. Por favor, verifica los datos e inténtalo de nuevo.');
            setMessageType('error'); // Agregar esta línea en caso de error
            let errorMessage = "Error al realizar el registro: ocurrió un error desconocido.";

            // Verificar si el mensaje de error de MetaMask está en la propiedad 'message'
            if (error.message) {
                errorMessage = error.message;
            }

            // MetaMask a menudo incluye detalles específicos del error bajo 'error.data.message' o 'error.data'
            if (error.data && typeof error.data === 'object' && error.data.message) {
                errorMessage = error.data.message;
            } else if (error.data && typeof error.data === 'string') {
                // En algunos casos, el mensaje de error está directamente en 'data' como un string
                errorMessage = error.data;
            }

            // Mostrar el mensaje de error extraído
            alert(errorMessage);

        } finally {
            setIsLoading(false);


        }
    };

    return (


        <form className="form-container" onSubmit={handleRegister}>
            <div>
                <label htmlFor="referralCode" style={{ color: 'white', textShadow: '2px 2px 4px black' }}>Código de Referencia (Hash de 256 bits):</label>
                <input
                    id="referralCode"
                    type="text"
                    pattern="^0x[a-fA-F0-9]{64}$"
                    title="El código de referencia debe ser un hash hexadecimal de 256 bits (incluyendo el prefijo '0x')."
                    value={referralCode}
                    onChange={(e) => setReferralCode(e.target.value)}
                    required
                    className="form-input"
                />
            </div>
            <div>
                <label htmlFor="referrerAddress" style={{ color: 'white', textShadow: '2px 2px 4px black' }}> Dirección del Referido:</label>
                <input
                    id="referrerAddress"
                    type="text"
                    pattern="^0x[a-fA-F0-9]{40}$"
                    title="La dirección debe ser una dirección Ethereum válida (incluyendo el '0x')."
                    value={userAddress}
                    onChange={(e) => setUserAddress(e.target.value)}
                    required
                    className="form-input"
                />
            </div>
            <button
                type="submit"
                disabled={isLoading || !referralCode || !userAddress}
                className="form-button-yellow"
            >
                {isLoading ? 'Conectando...' : 'Registrarse'}
            </button>

            {message && (
                <p className={`message ${messageType === 'success' ? 'message-success' : 'message-error'}`}>
                    <span style={{ color: 'white' }}>{message}</span>
                </p>
            )}
        </form>

    );
};


